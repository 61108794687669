import React from "react";
import { LogoWithoutText } from "../../assets/images";
// import animationData from "../../assets/lotties/LoadingAnimation.json";
function Loading({ load }) {
  return (
    <div>
      {load && (
        <div className="absolute z-50 inset-0 flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
          {/* <Lottie
            animationData={animationData}
            loop={true}
            style={{ width: 100, height: 100 }}
          /> */}
        </div>
      )}
    </div>
  );
}

export default Loading;
