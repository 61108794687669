import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { meetingAPI } from "../../constants/API";
import MoMCard from "../../components/Resident/Meetings/MoMCard";
import MoMCardSkeleton from "../../components/Resident/Meetings/MoMCardSkeleton";
import MoMReadModal from "../../components/Resident/Meetings/MoMOnlyReadModal";

const MoMs = () => {
  const cookies = new Cookies();
  const [meetings, setMeetings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMoM, setSelectedMoM] = useState(null);
  const [load, setLoad] = useState(false);
  const [isOwner, setIsOwner] = useState();

  const fetchMoMs = async () => {
    if (cookies.get("companyType") === "owners") {
      setIsOwner(true);
    } else if (cookies.get("companyType") === "tenant") {
      setIsOwner(false);
    }

    try {
      setLoad(true);
      const response = await axios.get(`${meetingAPI}/residentsmeeting`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      // console.log(response.data);
      setMeetings(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchMoMs();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMoM(null);
  };

  const handleViewMeeting = (meeting) => {
    setSelectedMoM(meeting);
    setShowModal(true);
  };

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <div className="flex pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            MoM
          </h1>
        </div>
        <div className="">
          {load ? (
            <MoMCardSkeleton />
          ) : meetings.length > 0 ? (
            meetings.map((meeting, index) => (
              <div key={index}>
                <MoMCard
                  mom={meeting}
                  onView={() => handleViewMeeting(meeting)}
                />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4">
              No meetings found
            </div>
          )}
        </div>
      </div>

      {/* Add/Edit Meeting Modal */}
      {showModal && (
        <MoMReadModal
          mom={selectedMoM}
          onClose={handleCloseModal}
          fetchMoms={fetchMoMs}
          isOwner={isOwner}
        />
      )}
    </div>
  );
};

export default MoMs;
