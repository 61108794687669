import React from "react";
import Skeleton from "react-loading-skeleton";

function MoMCardSkeleton({ cards = 5 }) {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
      >
        <div className="flex-col lg:w-[80%] justify-center leading-normal">
          <div className="space-y-1.5">
            {/* Title */}
            <div className="text-secondaryl euclidbold text-2xl break-words w-full md:w-[70%] lg:w-[50%]">
              <Skeleton />
            </div>

            <div className="w-[80%] md:w-32 lg:w-32">
              <Skeleton />
            </div>

            <Skeleton count={2} />
          </div>
        </div>

        <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
          <Skeleton height={40} />
        </div>
      </div>
    ));
}

export default MoMCardSkeleton;
