import React, { useEffect } from "react";
import Signup from "../../../components/Contractor/Signup/Signup.jsx";
import Layout from "../../../components/Layout/Layout.jsx";

function ContractorsSignup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Signup />
    </Layout>
  );
}

export default ContractorsSignup;
