import React from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
function MeetingRequestCard({ meeting, onView, updateRequestStatus }) {
  const cookies = new Cookies();
  return (
    <div
      className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      <div className="flex-col lg:w-[80%] justify-center leading-normal">
        <div className="space-y-1.5">
          <div className="text-secondaryl euclidbold text-2xl break-words">
            {meeting?.Owner
              ? meeting?.Owner?.First_Name + " " + meeting?.Owner?.Last_Name
              : meeting?.Tenant?.First_Name + " " + meeting?.Tenant?.Last_Name}
          </div>

          <div className="text-base flex gap-1 items-center">
            <Icon
              icon="carbon:location-filled"
              className="text-secondaryl text-lg"
            />

            <p className="text-infromation break-words line-clamp-3">
              {meeting?.Owner
                ? meeting?.Owner?.Residential_Address
                : meeting?.Tenant?.Residential_Address}
            </p>
          </div>

          <div className="text-base flex gap-1 items-center">
            <Icon
              icon="fluent:meet-now-28-filled"
              className="text-secondaryl text-lg"
            />

            <p className="text-infromation break-words">
              {meeting?.Meeting?.Meeting_Topic}
            </p>
          </div>

          {/* Request Date */}
          <div className="text-base flex gap-1 items-center ">
            <Icon
              icon="bi:calendar-event-fill"
              className="text-secondaryl ml-[0.1rem]"
            />

            <p className="text-infromation hover:text-gray-700 line-clamp-2 break-all">
              {meeting?.CreatedDate?.split("T")[0]}
            </p>
          </div>
        </div>
      </div>
      {/* Card Buttons */}
      <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
        {cookies.get("access") === "property manager" && (
          <>
            {meeting?.Status === 0 && (
              <div
                onClick={() =>
                  updateRequestStatus(
                    meeting?.ID,
                    1,
                    meeting?.Owner ? true : false
                  )
                }
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                APPROVE
              </div>
            )}

            {meeting?.Status === 0 && (
              <div
                onClick={() => {
                  updateRequestStatus(
                    meeting?.ID,
                    2,
                    meeting?.Owner ? true : false
                  );
                }}
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                REJECT
              </div>
            )}
          </>
        )}

        <button
          className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          onClick={onView}
        >
          VIEW
        </button>
      </div>
    </div>
  );
}

export default MeetingRequestCard;
