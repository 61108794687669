import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import BlogDetails from "../../components/Blog/BlogDetails";
import Cookies from "universal-cookie";

const BlogDetailsLayout = () => {
  const cookies = new Cookies();
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));

  useEffect(() => {
    setIsLogin(cookies.get("companyType"));
  }, []);

  return isLogin ? (
    <BlogDetails />
  ) : (
    <Layout>
      <BlogDetails />
    </Layout>
  );
};

export default BlogDetailsLayout;
