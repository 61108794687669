import ContractorsSignup from "./pages/contractors/signup/ContractorsSignup";
import DetailsContractListing from "./pages/ContractDetalis/DetailsContractListing";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CorporationForm from "./pages/corporations/signup/Signup";
import ContractListing from "./pages/contractors/dashboard/Contract/ContractListing";
import ContractorLogin from "./pages/contractors/login/ContractorLogin";
import CorporationLogin from "./pages/corporations/login/CorporationLogin";
import BoardMemberLogin from "./pages/corporations/login/BoardMemberLogin";
import SecurityMemberLogin from "./pages/corporations/login/SecurityMemberLogin";
import AppliedContracts from "./pages/contractors/dashboard/Contract/AppliedContracts";
import ProtectedContractor from "./pages/Protected/ProtectedContractor";
import Home from "./pages/Home/Home";
// import ContractorProfile from "./pages/contractors/profile/ContractorProfile";
import ContractorProfile from "./pages/contractors/dashboard/Profile/ContractorProfile";
import CorporationProfile from "./pages/corporations/dashboard/Profile/CorporationProfile";
import ContractorReviews from "./pages/contractors/dashboard/Reviews/ContractorReviews";

import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Messages from "./pages/Messages/Messages";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ProtectedCorporation from "./pages/Protected/ProtectedCorporation";
import Unprotected from "./pages/Unprotected";
import Admin from "./pages/admin/Admin";
import AdminLogin from "./pages/admin/AdminLogin";

import EditContract from "./pages/corporations/dashboard/Home/EditContract";
import SendMail from "./pages/Reset/Sendmail";
import ResetPassword from "./pages/Reset/ResetPassword";
import SetReviews from "./pages/corporations/Reviews/SetReviews";
import SavePassword from "./pages/Reset/SavePassword";
import ProtectedAdmin from "./pages/Protected/ProtectedAdmin";
import ProtectedContractorOrCorporation from "./pages/Protected/ProtectedContractorOrCorporation";
import VerifyBoardMember from "./pages/corporations/boardmember/VerifyBoardMember";
import VerifySecurityMember from "./pages/corporations/securitymember/VerifySecurityMember";
import ContractorMessages from "./pages/Messages/ContractorMessages";
import TermsAndCondition from "./pages/TermsAndPrivacy/TermsAndCondition";
import PrivacyPolicy from "./pages/TermsAndPrivacy/PrivacyPolicy";
import AdminProfile from "./pages/admin/AdminProfile";
import "./constants/CSS.css";
import "./styles.css";
import ApprovedContracts from "./pages/contractors/dashboard/Contract/ApprovedContracts";
import Test from "./pages/Test";
import Test2 from "./pages/Test2";
import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/Blog/BlogDetails";
import OwnersLogin from "./pages/Owners/login/OwnersLogin";
import TenantLogin from "./pages/Tenant/login/TenantLogin";
import CreateTenant from "./pages/corporations/Residents/CreateTenant";
import CreateOwners from "./pages/corporations/Residents/CreateOwners";
import Test3 from "./pages/Test3";
import Test4 from "./pages/Test4";
import ProtectedOwners from "./pages/Protected/ProtectedOwners";
import ResidentDashboard from "./pages/Resident/Dashboard";
import ProtectedTenant from "./pages/Protected/ProtectedTenant";
import CorporationDashboardLayout from "./pages/corporations/dashboard/CorporationDashboardLayout";
import ManageOwnersTenant from "./pages/corporations/dashboard/Resident/ManageOwnersTenant";
import CreateContract from "./pages/corporations/dashboard/Contract/CreateContract";
import { LoadingProvider } from "./context/LoadingContext";
import ArchivesContract from "./pages/corporations/dashboard/Contract/ArchivesContract";
import DashboardHome from "./pages/corporations/dashboard/Home/DashboardHome";
import ProposalViewWork from "./components/ContractDetalis/ProposalViewWork";
import ContractorDashboardLayout from "./pages/contractors/dashboard/ContractorDashboardLayout";
import CorporationMoM from "./pages/corporations/dashboard/Meetings/Meetings";
import ResidentMoMs from "./pages/Resident/MoMs";
import OwnersDashboardLayout from "./pages/Owners/dashboard/OwnersDashboardLayout";
import CorporationMoMRequest from "./pages/corporations/dashboard/Meetings/CorporationMeetingsRequest";
import TenantDashboardLayout from "./pages/Tenant/dashboard/TenantDashboardLayout";
import ProtectedCommanPage from "./pages/Protected/ProtectedCommanPage";
import CreateTenantForm from "./components/Corporation/Dashboard/Resident/CreateTenantForm";
import CreateOwnersForm from "./components/Corporation/Dashboard/Resident/CreateOwnersForm";
import DocumentDownload from "./pages/Owners/documentdownload/DocumentDownload";
import ProtectedAccessPropertyManager from "./pages/Protected/ProtectedAccessPropertyManager";
import CreateContractByResidents from "./pages/Resident/CreateContractByResidents";

import RequestContract from "./pages/corporations/dashboard/Contract/RequestContract";
import AdminDashboardLayout from "./pages/AdminTest/Dashboard/AdminDashboardLayout";
import AdminHome from "./pages/AdminTest/Dashboard/Home/Home";
import ManageCorporations from "./pages/AdminTest/Dashboard/ManageCorporation/ManageCorporation";
import ManageContractors from "./pages/AdminTest/Dashboard/ManageContractors/ManageContractors";
import { ManageEnquiry } from "./pages/AdminTest/Dashboard/ManageEnquiry/ManageEnquiry";
import RequestService from "./pages/Resident/RequestService";
import ManageService from "./pages/corporations/dashboard/Service/ManageService";
import Details from "./pages/Resident/Details";
import ServiceDashboard from "./pages/corporations/dashboard/Service/ServiceDashboard";
import ServiceDetails from "./pages/corporations/dashboard/Service/ServiceDetails";
import BookedService from "./pages/corporations/dashboard/Service/BookedService";
import CorporationDocument from "./pages/corporations/dashboard/Documents/CorporationDocument";
import CorporationsDashboardOverview from "./pages/corporations/dashboard/Overview/Overview";
import CorporationNotices from "./pages/corporations/dashboard/Documents/CorporationNotices";
import Notices from "./pages/Resident/Notices";
import ResidentDocument from "./pages/Resident/Document";
import PIC from "./pages/corporations/dashboard/Documents/PIC";
import PICResident from "./pages/Resident/PIC";
import ComplaintSuggestionResident from "./pages/Resident/ComplaintSuggestion";
import ComplaintSuggestionCorporation from "./pages/corporations/dashboard/ComplaintSuggestion/ComplaintSuggestion";
import ProtectedAccessPMBM from "./pages/Protected/ProtectedAccessPMBM";
import Incident from "./pages/corporations/dashboard/Incident/Incident";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/corporations/login",
    element: (
      <ProtectedCommanPage>
        <CorporationLogin />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/corporations/boardmember/login",
    element: (
      <ProtectedCommanPage>
        <BoardMemberLogin />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/corporations/securitymember/login",
    element: (
      <ProtectedCommanPage>
        <SecurityMemberLogin />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/contractors/login",
    element: (
      <Unprotected>
        <ContractorLogin />
      </Unprotected>
    ),
  },
  {
    path: "/owners/signup",
    element: (
      <ProtectedCommanPage>
        <CreateOwners />
      </ProtectedCommanPage>
    ),
  },

  {
    path: "/tenant/signup",
    element: (
      <ProtectedCommanPage>
        <CreateTenant />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/owners/login",
    element: (
      <ProtectedCommanPage>
        <OwnersLogin />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/tenant/login",
    element: (
      <ProtectedCommanPage>
        <TenantLogin />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/corporations/signup",
    element: (
      <ProtectedCommanPage>
        <CorporationForm />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/contractors/signup",
    element: (
      <ProtectedCommanPage>
        <ContractorsSignup />
      </ProtectedCommanPage>
    ),
  },

  { path: "/corporations/reset", element: <SendMail /> },
  {
    path: "/corporations/propertymanager/reset",
    element: <SendMail />,
  },
  {
    path: "/corporations/boardmember/reset",
    element: <SendMail />,
  },
  {
    path: "/corporations/securitymember/reset",
    element: <SendMail />,
  },
  {
    path: "/contractors/reset",
    element: <SendMail />,
  },
  {
    path: "/owners/reset",
    element: <SendMail />,
  },
  {
    path: "/tenants/reset",
    element: <SendMail />,
  },
  {
    path: "/passwordreset",
    element: <ResetPassword />,
  },
  {
    path: "/savepassword",
    element: <SavePassword />,
  },
  // {
  //   path: "/corporations/reviews/:id",
  //   element: (
  //     <ProtectedCorporation>
  //       <SetReviews />
  //     </ProtectedCorporation>
  //   ),
  // },

  // /////////////// Owners Dashboard V2 /////////////////
  {
    path: "/owners/dashboard",
    element: (
      <ProtectedOwners>
        <OwnersDashboardLayout />
      </ProtectedOwners>
    ),
    children: [
      { path: "", element: <ResidentDashboard /> },
      { path: "home", element: <ResidentDashboard /> },
      { path: "home/details", element: <Details /> },
      { path: "maintenance", element: <CreateContractByResidents /> },
      { path: "requestservice", element: <RequestService /> },
      { path: "rules", element: <ResidentDocument /> },
      { path: "bylaws", element: <ResidentDocument /> },
      {
        path: "pic",
        element: <PICResident />,
      },
      { path: "declaration", element: <ResidentDocument /> },
      { path: "budget", element: <ResidentDocument /> },
      { path: "notices", element: <Notices /> },
      { path: "mom", element: <ResidentMoMs /> },
      { path: "complaintsuggestion", element: <ComplaintSuggestionResident /> },
      { path: "blog", element: <Blog /> },
      { path: "blog/:id", element: <BlogDetails /> },
      { path: "contact", element: <Contact /> },
      { path: "about", element: <About /> },
    ],
  },

  {
    path: "/tenant/dashboard",
    element: (
      <ProtectedTenant>
        <TenantDashboardLayout />
      </ProtectedTenant>
    ),
    children: [
      { path: "", element: <ResidentDashboard /> },
      { path: "home", element: <ResidentDashboard /> },
      { path: "home/details", element: <Details /> },
      { path: "maintenance", element: <CreateContractByResidents /> },
      { path: "requestservice", element: <RequestService /> },
      { path: "rules", element: <ResidentDocument /> },
      {
        path: "bylaws",
        element: <ResidentDocument />,
      },
      {
        path: "declaration",
        element: <ResidentDocument />,
      },
      { path: "notices", element: <Notices /> },
      {
        path: "pic",
        element: <PICResident />,
      },
      { path: "mom", element: <ResidentMoMs /> },
      { path: "complaintsuggestion", element: <ComplaintSuggestionResident /> },
      { path: "blog", element: <Blog /> },
      { path: "blog/:id", element: <BlogDetails /> },
      { path: "contact", element: <Contact /> },
      { path: "about", element: <About /> },
    ],
  },

  /////////////////////////  Corporation dashboard  V2    ///////////////////////////////////////////////
  {
    path: "/corporations/dashboard",
    element: (
      <ProtectedCorporation>
        {" "}
        <CorporationDashboardLayout />{" "}
      </ProtectedCorporation>
    ),
    children: [
      {
        path: "",
        element: <CorporationsDashboardOverview />,
      },
      {
        path: "overview",
        element: <CorporationsDashboardOverview />,
      },
      {
        path: "home",
        element: (
          <ProtectedAccessPMBM>
            <DashboardHome />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "contract",
        element: (
          <ProtectedAccessPropertyManager>
            <CreateContract />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedAccessPropertyManager>
            <CorporationProfile />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "home/proposal/:id",
        element: <ProposalViewWork />,
      },
      {
        path: "owners",
        element: (
          <ProtectedAccessPMBM>
            <ManageOwnersTenant />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "owners/createowners",
        element: (
          <ProtectedAccessPropertyManager>
            <CreateOwnersForm />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "tenant",
        element: (
          <ProtectedAccessPMBM>
            <ManageOwnersTenant />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "tenant/createtenant",
        element: (
          <ProtectedAccessPropertyManager>
            <CreateTenantForm />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "mom/managemom",
        element: (
          <ProtectedAccessPMBM>
            {" "}
            <CorporationMoM />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "mom/managemomrequest",
        element: (
          <ProtectedAccessPMBM>
            {" "}
            <CorporationMoMRequest />{" "}
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "rules",
        element: (
          <ProtectedAccessPMBM>
            {" "}
            <CorporationDocument />{" "}
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "pic",
        element: (
          <ProtectedAccessPMBM>
            {" "}
            <PIC />{" "}
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "bylaws",
        element: (
          <ProtectedAccessPMBM>
            <CorporationDocument />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "declaration",
        element: (
          <ProtectedAccessPMBM>
            <CorporationDocument />{" "}
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "budget",
        element: (
          <ProtectedAccessPMBM>
            <CorporationDocument />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "notices",
        element: (
          <ProtectedAccessPMBM>
            {" "}
            <CorporationNotices />
          </ProtectedAccessPMBM>
        ),
      },
      {
        path: "complaintsuggestion",
        element: <ComplaintSuggestionCorporation />,
      },
      {
        path: "incident",
        element: <Incident />,
      },
      { path: "blog", element: <Blog /> },
      { path: "blog/:id", element: <BlogDetails /> },
      { path: "reviews/:id", element: <SetReviews /> },
      {
        path: "message",
        element: (
          <ProtectedAccessPMBM>
            <Messages />{" "}
          </ProtectedAccessPMBM>
        ),
      },
      { path: "contact", element: <Contact /> },
      { path: "about", element: <About /> },
      { path: "archivescontract", element: <ArchivesContract /> },
      { path: "requestlisting", element: <RequestContract /> },
      {
        path: "requestlisting/contract/:id",
        element: (
          <ProtectedAccessPropertyManager>
            <EditContract residentRequest={true} />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "editcontract/:id",
        element: (
          <ProtectedAccessPropertyManager>
            <EditContract />
          </ProtectedAccessPropertyManager>
        ),
      },
      {
        path: "archivescontract/detailscontract/:id",
        element: <DetailsContractListing />,
      },
      {
        path: "manageservice",
        element: <ManageService />,
      },
      {
        path: "managerequest",
        element: <ServiceDashboard />,
      },
      { path: "managerequest/bookedservice", element: <BookedService /> },
      {
        path: "details",
        element: <ServiceDetails />,
      },
    ],
  },
  /////////////////////    Contractor Dashboard V2    ///////////////////////////////////////////////
  {
    path: "/contractors/dashboard",
    element: (
      <ProtectedContractor>
        <ContractorDashboardLayout />
      </ProtectedContractor>
    ),
    children: [
      { path: "currentlisting", element: <ContractListing /> },
      { path: "appliedcontracts", element: <AppliedContracts /> },
      { path: "approvedcontracts", element: <ApprovedContracts /> },
      {
        path: "detailscontract/:id",
        element: <DetailsContractListing />,
      },
      { path: "blog", element: <Blog /> },
      { path: "blog/:id", element: <BlogDetails /> },
      {
        path: "message",
        element: <ContractorMessages />,
      },
      { path: "contact", element: <Contact /> },
      { path: "about", element: <About /> },
      {
        path: "review",
        element: (
          <ProtectedContractor>
            <ContractorReviews />
          </ProtectedContractor>
        ),
      },
      { path: "profile", element: <ContractorProfile /> },
    ],
  },

  {
    path: "/verifyboardmember",
    element: <VerifyBoardMember />,
  },
  {
    path: "/verifysecuritymember",
    element: <VerifySecurityMember />,
  },
  {
    path: "/about",
    element: (
      <ProtectedCommanPage>
        <About />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/blog",
    element: (
      <ProtectedCommanPage>
        <Blog />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/blog/:id",
    element: (
      <ProtectedCommanPage>
        <BlogDetails />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/contact",
    element: (
      <ProtectedCommanPage>
        <Contact />
      </ProtectedCommanPage>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedAdmin>
        <Admin />
      </ProtectedAdmin>
    ),
  },
  {
    path: "/admin/profile",
    element: (
      <ProtectedAdmin>
        <AdminProfile />
      </ProtectedAdmin>
    ),
  },
  { path: "/terms", element: <TermsAndCondition /> },
  { path: "/privacypolicy", element: <PrivacyPolicy /> },
  {
    path: "/auth",
    element: (
      <ProtectedCommanPage>
        <AdminLogin />
      </ProtectedCommanPage>
    ),
  },
  { path: "*", element: <PageNotFound /> },
  // { path: "/test", element: <Test /> },
  { path: "/test", element: <CorporationDashboardLayout /> },
  { path: "download-document", element: <DocumentDownload /> },
  { path: "/test2", element: <Test2 /> },
  { path: "/test3", element: <Test3 /> },
  { path: "/test4", element: <Test4 /> },

  // ADMIN TEST ROUTES

  {
    path: "/admin/dashboard",
    element: (
      <ProtectedAdmin>
        <AdminDashboardLayout />
      </ProtectedAdmin>
    ),
    children: [
      { path: "home", element: <AdminHome /> },
      { path: "managecorporations", element: <ManageCorporations /> },
      { path: "managecontractors", element: <ManageContractors /> },
      { path: "manageenquiry", element: <ManageEnquiry /> },
      { path: "manageservices", element: <ManageCorporations /> },
      { path: "manageworkingarea", element: <ManageCorporations /> },
      { path: "manageblog", element: <ManageCorporations /> },
    ],
  },
]);

export default function App() {
  return (
    <LoadingProvider>
      <RouterProvider router={router} exact={true} />
    </LoadingProvider>
  );
}
