import React from "react";
import Skeleton from "react-loading-skeleton";

const RuleCardSkeleton = ({ cards = 5 }) => {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="w-full flex items-center lg:flex-row flex-col  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        <div className="h-auto relative lg:w-[12%] ml-8 w-full flex-none bg-cover rounded-xl text-center flex justify-center">
          <Skeleton width={160} height={100} />
        </div>
        <div className="flex-col lg:w-[75%] w-full  justify-center leading-normal">
          <div className="space-y-2">
            {/* Title */}
            <div className="text-secondaryl euclidbold text-2xl break-words">
              <Skeleton />
            </div>
          </div>
        </div>
        {/* Card Buttons */}
        <div className="flex-col gap-2 justify-center my-auto relative w-full lg:w-[20%] space-y-3">
          <Skeleton height={40} />
          <Skeleton height={40} />
        </div>
      </div>
    ));
};

export default RuleCardSkeleton;
