import InputBox from "../../../../components/Elements/InputBox";
import { Icon } from "@iconify/react";
import { InputFileIcon, Spinner } from "../../../../assets/images";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Elements/Loading";
import toast from "react-hot-toast";
import { useLoading } from "../../../../context/LoadingContext";
import axios from "axios";
import Cookies from "universal-cookie";
import { serviceCorporationApi } from "../../../../constants/API";
import { serviceSchema } from "../../../../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const AddService = ({ setShowAddService, fetchServices, setLoad }) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(serviceSchema) });
  const cookies = new Cookies();
  const navigate = useNavigate();
  const notifysuccess = (props) => toast.success(props, { duration: 4000 });
  const notifyfailure = (props) => toast.error(props, { duration: 2000 });
  const [subServiceCount, setSubServiceCount] = useState(0);
  const [Service_Image, setService_Image] = useState(null); // For main service image
  const [displayService_Image, setDisplayService_Image] = useState(null); // Display main service image
  const [subServiceImages, setSubServiceImages] = useState(Array(6).fill(null));
  const [displaySubServiceImages, setDisplaySubServiceImages] = useState(
    Array(6).fill(null)
  );

  const [Service_ImageError, setService_ImageError] = useState("");
  const [Service_ImageUploading, setService_ImageUploading] = useState(false);

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]; // Example supported file types
  const handleChangeService_Image = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= FILE_SIZE_LIMIT &&
      SUPPORTED_FILE_TYPES.includes(file.type)
    ) {
      setService_Image(file);
      setDisplayService_Image(URL.createObjectURL(file));
    } else {
      setService_ImageError(
        "File type should be .jpeg, .jpg, .png and size less than 5MB."
      );
    }
  };

  const handleChangeSubServiceImage = (event, index) => {
    const file = event.target.files[0];
    if (
      file &&
      file.size <= FILE_SIZE_LIMIT &&
      SUPPORTED_FILE_TYPES.includes(file.type)
    ) {
      setSubServiceImages((prev) => {
        const updatedImages = [...prev];
        updatedImages[index] = file;
        return updatedImages;
      });
      setDisplaySubServiceImages((prev) => {
        const updatedDisplayImages = [...prev];
        updatedDisplayImages[index] = URL.createObjectURL(file);
        return updatedDisplayImages;
      });
    } else {
      setService_ImageError(
        "File type should be .jpeg, .jpg, .png and size less than 5MB."
      );
    }
  };

  const handlerAddSubService = () => {
    let subServiceArray = getValues("Sub_Services");
    if (!subServiceArray) {
      subServiceArray = [];
    }
    subServiceArray.push({
      Sub_Service_Name: "",
      Sub_Service_Description: "",
    });

    const length = subServiceArray.length;
    setSubServiceCount(length);
    setValue("Sub_Services", subServiceArray);
  };

  const handleDeleteSubService = (indexToDelete) => {
    let subServiceArray = getValues("Sub_Services");
    const updatedSubService = subServiceArray.filter(
      (_, index) => index !== indexToDelete
    );
    const length = updatedSubService.length;
    setSubServiceCount(length);
    // Loop to shift all elements from indexToDelete onward
    while (indexToDelete < subServiceImages.length - 1) {
      // Replace current index with the next index value
      subServiceImages[indexToDelete] = subServiceImages[indexToDelete + 1];
      displaySubServiceImages[indexToDelete] =
        displaySubServiceImages[indexToDelete + 1];
      indexToDelete++;
    }
    displaySubServiceImages.pop();
    subServiceImages.pop();
    setValue("Sub_Services", updatedSubService);
  };

  const handleRemoveServiceImage = () => {
    setService_Image(null);
    setDisplayService_Image(null);
  };

  const handleRemoveSubServiceImage = (index) => {
    setSubServiceImages((prev) => {
      const updatedImages = { ...prev };
      delete updatedImages[index];
      return updatedImages;
    });

    setDisplaySubServiceImages((prev) => {
      const updatedDisplayImages = { ...prev };
      delete updatedDisplayImages[index];
      return updatedDisplayImages;
    });
  };

  // console.log("errors", errors);

  const onCreateService = async (data) => {
    // console.log("service data", data);

    const formData = new FormData();
    // Append main service data to FormData
    Object.keys(data).forEach((key) => {
      // If the key is "Sub_Services", stringify it for the FormData
      if (key === "Sub_Services" && Array.isArray(data[key])) {
        const subServicesString = JSON.stringify(data[key]);
        formData.append(key, subServicesString);
      } else {
        formData.append(key, data[key]);
      }
    });

    formData.delete("Service_Image");

    // Append the main service image if it exists
    if (Service_Image) {
      formData.set("Service_Image", Service_Image);
    } else {
      formData.set("Service_Image", null);
    }

    const keysToRemove = [
      "Sub_Service_Image_0",
      "Sub_Service_Image_1",
      "Sub_Service_Image_2",
      "Sub_Service_Image_3",
      "Sub_Service_Image_4",
      "Sub_Service_Image_5",
    ];

    keysToRemove.forEach((key) => formData.delete(key));

    subServiceImages.forEach((image, index) => {
      if (image) {
        formData.append(`Sub_Service_Image_${index}`, image);
      }
    });

    // Final log to show the constructed FormData entries
    // console.log("FormData entries:");
    for (let [key, value] of formData.entries()) {
      // console.log(`${key}:`, value);
    }
    setLoad(true);
    try {
      await axios.post(serviceCorporationApi, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      notifysuccess("Service Create Successfully", { duration: 4000 });
      // reset();
      setShowAddService(false);
      fetchServices();
      navigate("/corporations/dashboard/manageservice");
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to Create Contract. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onCreateService)}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            Add Service
          </h2>
          <button
            onClick={() => setShowAddService(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✖
          </button>
        </div>

        <div className="mb-6">
          {/* service name */}
          <InputBox
            title={"Service Name"}
            register={register}
            filedName={"Service_Name"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />

          {/* service Description */}
          <div className="mb-6">
            <label
              htmlFor="projectDescription"
              className="block mb-2 text-secondaryl"
            >
              Service Description
              <span className="text-red-500 text-base">*</span>
            </label>
            <textarea
              id="Service_Description"
              name="Service_Description"
              {...register("Service_Description")}
              className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                errors?.Service_Description ? "ring-[0.5px] ring-red-500" : ""
              }`}
              placeholder="Enter service description"
            ></textarea>

            <div className="h-[2vh] pt-1">
              {errors?.Service_Description && (
                <p className="text-red-500 text-xs leading-none">
                  {errors?.Service_Description.message}
                </p>
              )}
            </div>
          </div>
          <div className="">
            <div className="mb-2 lg:mb-0">
              <label
                className={`block font-normal mb-3 text-secondaryl text-[12px] sm:text-[12px] md:text-base mt-4 `}
              >
                Service Image (Optional)
              </label>

              <div className="flex flex-wrap gap-3 max-w-[720px]">
                {displayService_Image ? (
                  <div className="relative">
                    <img
                      src={displayService_Image}
                      alt=""
                      className="object-cover h-52 w-52 rounded-sm "
                    />

                    <Icon
                      onClick={handleRemoveServiceImage}
                      icon="icon-park-solid:close-one"
                      className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                    />
                  </div>
                ) : (
                  <div key={`Service_Image`}>
                    <div className="col-span-2 sm:col-span-2 md:col-span-1">
                      <input
                        type="file"
                        id={`Service_Image`}
                        name={`Service_Image`}
                        className="hidden"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => handleChangeService_Image(e)}
                        disabled={Service_ImageUploading}
                        multiple
                      />

                      <label
                        htmlFor={`Service_Image`}
                        className={`block bg-[#FFFFFF80] h-52 w-52 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                          Service_ImageUploading
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        style={{
                          boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                        }}
                        disabled={Service_ImageUploading}
                      >
                        <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                          <InputFileIcon width="40" height="45" />

                          <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                            .jpg, .png, .jpeg files only
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                )}

                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {Service_ImageUploading && <Spinner />}

                  {Service_ImageError && (
                    <p className="text-red-500 text-xs leading-none">
                      {Service_ImageError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-center my-3 ">
            <div
              id="addSubService"
              className="cursor-pointer flex gap-3 hover:opacity-80"
              onClick={handlerAddSubService}
            >
              <Icon
                icon="bi:plus-square"
                className="text-secondaryl text-2xl"
              />
              <label className="cursor-pointer text-secondaryl ">
                Add Sub Services
              </label>
            </div>
          </div>
          {/* Sub Service Form */}
          <div className="">
            {subServiceCount > 0 && (
              <h2 className="text-secondaryl font-extrabold text-xl euclidbold mb-3">
                Add Sub Service
              </h2>
            )}
            {Array.from({ length: subServiceCount }).map((_, index) => (
              <div key={index} className="mb-3">
                <div className="">
                  <div className="mb-3 w-full">
                    <label
                      htmlFor={`Sub_Service_Name-${index}`}
                      className="block font-normal text-secondaryl"
                    >
                      Service Name {index + 2}
                      <sup className="text-red-500 text-base">*</sup>
                    </label>
                    <input
                      id={`Sub_Service_Name-${index}`}
                      name={`Sub_Services[${index}].Sub_Service_Name`}
                      type="text"
                      placeholder={`Enter Service Name ${index + 2}`}
                      {...register(`Sub_Services[${index}].Sub_Service_Name`)}
                      className=" block w-full  h-11 rounded-sm text-black bg-[#FFFFFF80]  px-2  ps-10 focus:ring-[0.5px] focus:ring-secondaryl   focus:outline-none"
                    />
                    <div className="h-[2vh] pt-1">
                      {errors["Sub_Services"] &&
                        errors["Sub_Services"][index] &&
                        errors["Sub_Services"][index]["Sub_Service_Name"] && (
                          <p className="text-red-500 text-xs leading-none">
                            {
                              errors["Sub_Services"][index]["Sub_Service_Name"]
                                .message
                            }
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="mb-3 w-full">
                    <label
                      htmlFor={`Sub_Service_Description-${index}`}
                      className="block font-normal text-secondaryl"
                    >
                      Service Description {index + 2}
                      <sup className="text-red-500 text-base">*</sup>
                    </label>
                    <textarea
                      id={`Sub_Service_Description-${index}`}
                      name={`Sub_Services[${index}].Sub_Service_Description`}
                      className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                        errors.Service_Description
                          ? "ring-[0.5px] ring-red-500"
                          : ""
                      }`}
                      placeholder={`Enter Service Name ${index + 2}`}
                      {...register(
                        `Sub_Services[${index}].Sub_Service_Description`
                      )}
                    ></textarea>
                    <div className="h-[2vh] pt-1">
                      {errors["Sub_Services"] &&
                        errors["Sub_Services"][index] &&
                        errors["Sub_Services"][index][
                          "Sub_Service_Description"
                        ] && (
                          <p className="text-red-500 text-xs leading-none">
                            {
                              errors["Sub_Services"][index][
                                "Sub_Service_Description"
                              ].message
                            }
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 place-items-end">
                  <div className="place-self-start">
                    <div className="mb-2 lg:mb-0 w-full">
                      <label
                        className={`block font-normal mb-3 text-secondaryl text-[12px] sm:text-[12px] md:text-base`}
                      >
                        Service Image (Optional)
                      </label>

                      <div className="flex flex-wrap gap-3 max-w-[720px]">
                        {displaySubServiceImages[index] ? (
                          <div
                            key={`subServiceImage${index}`}
                            className="relative"
                          >
                            <img
                              src={displaySubServiceImages[index]}
                              alt=""
                              className="object-cover h-52 w-52 rounded-sm "
                            />

                            <Icon
                              onClick={() => handleRemoveSubServiceImage(index)}
                              icon="icon-park-solid:close-one"
                              className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                            />
                          </div>
                        ) : (
                          <div key={`Sub_Service_Image${index}`}>
                            <div className="col-span-2 sm:col-span-2 md:col-span-1">
                              <input
                                type="file"
                                id={`Sub_Service_Image${index}`}
                                name={`Sub_Service_Image${index}`}
                                className="hidden"
                                accept=".jpg,.jpeg,.png"
                                onChange={(e) =>
                                  handleChangeSubServiceImage(e, index)
                                }
                                disabled={Service_ImageUploading}
                                multiple
                              />

                              <label
                                htmlFor={`Sub_Service_Image${index}`}
                                className={`block bg-[#FFFFFF80] h-52 w-52 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                                  Service_ImageUploading
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }`}
                                style={{
                                  boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                                }}
                                disabled={Service_ImageUploading}
                              >
                                <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                                  <InputFileIcon width="40" height="45" />

                                  <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                    .jpg, .png, .jpeg files only
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        )}

                        {/* Error */}
                        <div className="h-[3vh] mb-3">
                          {Service_ImageUploading && <Spinner />}

                          {Service_ImageError && (
                            <p className="text-red-500 text-xs leading-none">
                              {Service_ImageError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className=" h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out"
                    onClick={() =>  handleDeleteSubService(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Upload Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
        h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  ${
          Service_ImageUploading ? "cursor-not-allowed" : "cursor-pointer"
        }`}
            disabled={Service_ImageUploading}
          >
            Add Service
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddService;
