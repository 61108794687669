import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../../../components/Layout/Layout";
import { contractAPI } from "../../../../constants/API";
import Cookies from "universal-cookie";
import axios from "axios";
import { Location } from "../../../../assets/images";
import {
  fetchProposals,
  updateProposalStatusToSeen,
} from "../../../../constants/Request";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Loading from "../../../../components/Elements/Loading";
import DashboardContractCard from "../../../../components/Corporation/Dashboard/Home/DashboardContractCard";
import DashboardContractCardSkeleton from "../../../../components/Corporation/Dashboard/Home/DashboardContractCardSkeleton";
import Pagination3 from "../../../../components/Elements/Pagination3";
import DashboardProposalCard from "../../../../components/Corporation/Dashboard/Home/DashboardProposalCard";
import DashboardProposalCardSkeleton from "../../../../components/Corporation/Dashboard/Home/DashboardProposalCardSkeleton";
import { Icon } from "@iconify/react";

function DashboardHome() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [contracts, setContracts] = useState([]);
  const [proposals, setProposals] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const [screenLoad, setScreenLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const accessName = cookies.get("access");
  const [loadSubmitedProposals, setLoadSubmitedProposals] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // console.log("fetchData");
  }, []);

  const fetchData = useCallback(async () => {
    // console.log("fetchData");

    try {
      setLoad(true);
      const apiUrl = `${contractAPI}/corporationdashboardcontracts`;
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "corporation",
        },
        params: {
          // page: page,
          page: searchParams.get("page") || 1,
          pageSize: 5,
        },
      });
      setContracts(getContractCardImage(response?.data?.data?.data));
      setTotalPages(response?.data?.data?.totalPages);
      setCurrentPage(response?.data?.data?.currentPage);
      setSearchParams(`?page=${response?.data?.data?.currentPage}`);
    } catch (error) {
      console.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  }, [page]);

  useEffect(() => {
    fetchData();
    handleCloseModel();
  }, [fetchData]);

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const [sampleApprovalProposalPdf, setSampleApprovalProposalPdf] =
    useState(null);

  const handleCloseModel = () => {
    setSampleApprovalProposalPdf(null);
    setIsModelOpen(false);
  };

  const getProposalsHandler = async (id) => {
    try {
      setLoadSubmitedProposals(true);
      const res = await fetchProposals(id);
      let data = res?.data?.data;

      if (!Array.isArray(data)) {
        setProposals([]);
      } else {
        setProposals(data);
      }
    } catch (error) {
      setProposals([]);
      toast.error(error.response.data.message);
    } finally {
      setLoadSubmitedProposals(false);
    }
  };

  const viewProposalsHandler = async (ID, status, proposal) => {
    try {
      if (status === 1) {
        navigate(`/corporations/dashboard/home/proposal/${ID}`, {
          state: proposal,
        });
      } else {
        const res = await updateProposalStatusToSeen(ID);
        if (res.status === 200) {
          navigate(`/corporations/dashboard/home/proposal/${ID}`);
        }
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const proposalapprovalHandler = async (sampleapprovalproposalpdf) => {
    setSampleApprovalProposalPdf(sampleapprovalproposalpdf);
    handleOpenModel();
  };

  const getContractCardImage = (data) => {
    data.map((item, index) => {
      for (let i = 1; i <= 10; i++) {
        // data[index]["ContractCard_Image"] = "https://via.placeholder.com/150";
        // break;
        if (item[`Project_Photos_${i}`]) {
          data[index]["ContractCard_Image"] = item[`Project_Photos_${i}`];
          break;
        }
      }
    });

    return data;
  };

  return (
    <div className="min-h-full">
      <Loading load={screenLoad} />
      <div className="w-full rounded-2xl px-4 m-auto h-full p-6  flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Dashboard
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-4 relative">
          {/* First Column */}

          <div
            className="w-full h-fit justify-between  rounded flex md:flex-col flex-wrap gap-2 p-3 bg-[#FFFFFF80] "
            style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
          >
            <h2 className="text-2xl euclidbold text-secondaryl   font-bold">
              Current Listing
            </h2>

            {load ? (
              <div className="w-full">
                <DashboardContractCardSkeleton />
              </div>
            ) : (
              contracts.length > 0 && (
                <div className="w-full">
                  {contracts.map((item, index) => (
                    <DashboardContractCard
                      key={index}
                      contract={item}
                      getProposalsHandler={getProposalsHandler}
                    />
                  ))}
                </div>
              )
            )}

            {contracts &&
              !load &&
              !(contracts.length > 0) &&
              accessName === "property manager" && (
                <div className="h-64 flex flex-col justify-center items-center">
                  <div className="text-center text-lg text-infromation   w-[80%]">
                    Current Listing are not available at the moment ! Please add
                    new listing
                  </div>

                  <div className="pt-2">
                    <Link to="/corporations/dashboard/contract">
                      <span className="font-bold flex items-center justify-center text-secondaryl hover:text-opacity-90 euclidbold gap-2">
                        <Icon
                          icon="ic:round-create-new-folder"
                          className="text-2xl"
                        />{" "}
                        Create New Listing
                      </span>
                    </Link>
                  </div>
                </div>
              )}

            {contracts &&
              !load &&
              !(contracts.length > 0) &&
              accessName == "board member" && (
                <div className="h-64 flex  justify-center items-center">
                  <div className="text-center text-lg text-infromation   w-[80%]">
                    Current Listing are not available at the moment ! Please add
                    new listing
                  </div>
                </div>
              )}

            {!load && (
              <div className="my-3">
                {totalPages > 1 && (
                  // <Pagination page={page} setPage={setPage} totalPages={totalPages} />
                  <Pagination3
                    totalPages={totalPages} // Total number of pages
                    currentPage={currentPage} // Current active page
                    setPage={setPage}
                    setContracts={setContracts}
                    setLoad={setLoad}
                  />
                )}
              </div>
            )}
          </div>

          {/* Second Column */}
          <div
            className="w-full h-full   rounded flex md:flex-col flex-wrap gap-2 p-3 bg-[#FFFFFF80] "
            style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
          >
            <h2 className="text-2xl euclidbold text-secondaryl   font-bold">
              Submitted Proposals
            </h2>

            {loadSubmitedProposals ? (
              <div className="w-full">
                <DashboardProposalCardSkeleton />
              </div>
            ) : proposals.length > 0 ? (
              <div className="w-full">
                {proposals.map((proposal) => (
                  <DashboardProposalCard
                    key={proposal.ID}
                    proposal={proposal}
                    viewProposalsHandler={viewProposalsHandler}
                    proposalapprovalHandler={proposalapprovalHandler}
                    handleCloseModel={handleCloseModel}
                    isModelOpen={isModelOpen}
                    setLoad={setScreenLoad}
                    load={screenLoad}
                    sampleApprovalProposalPdf={sampleApprovalProposalPdf}
                    setSampleApprovalProposalPdf={setSampleApprovalProposalPdf}
                  />
                ))}
              </div>
            ) : (
              <p className="text-infromation"> No Proposal Yet. </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHome;
