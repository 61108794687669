import axios from "axios";
import React, { useRef, useState } from "react";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
import { InputFileIcon, ApprovedIcon, Spinner } from "../../../assets/images";
import { IoImageSharp } from "react-icons/io5";
import { PiSignatureBold } from "react-icons/pi";
import { MdCancel } from "react-icons/md";

const SignatureModel = ({
  isOpen,
  onClose,
  signature,
  setSignature,
  setValue,
  errors,
  uploadingFile,
  signatureError,
  displaySignature,
  handleChangeSignature,
  uploadingSignature,
  signatureUploded,
  setDisplaySignature,
  setSignatureError,
  signatureDrawError,
  setSignatureDrawError,
}) => {
  const [activeTab, setActiveTab] = useState("Image");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const signatureCanvasRef = useRef(null);

  const isCanvasBlank = (canvas) => {
    return !canvas
      .getTrimmedCanvas()
      .getContext("2d")
      .getImageData(
        0,
        0,
        canvas.getTrimmedCanvas().width,
        canvas.getTrimmedCanvas().height
      )
      .data.some((channel) => channel !== 0);
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignature(null); // Clear the stored image as well
    setDisplaySignature(null);
    setSignatureDrawError("");
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const saveSignature = () => {
    if (isCanvasBlank(signatureCanvasRef.current)) {
      setSignatureDrawError("Please provide a signature before saving.");
      return;
    }
    const signatureDataUrl = signatureCanvasRef.current.toDataURL();
    const signatureFile = dataURLtoFile(signatureDataUrl, "signature.png");
    setSignature(signatureFile);

    setValue("Signature", signatureFile);
    setDisplaySignature(URL.createObjectURL(signatureFile));
    setSignatureError("");
    setSignatureDrawError("");
    onClose();
    // Save the signature image to your server or database if needed
  };

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
      ${isOpen ? "block" : "hidden"}
      `}
    >
      {isOpen && <style>{`body { overflow: hidden; }`}</style>}
      <div className="flex items-center justify-center  text-center mt-10 ">
        <div
          className="inline-block w-[90vw] border-[1px]  shadow-lg md:w-[50vw] align-bottom  rounded-2xl  text-left overflow-hidden bg-white  transform transition-all  sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(40,42,115,0.15)_0%,_rgba(0,148,191,0.15)_100%)]">
            <div
              className="transition-opacity  flex justify-end right-3"
              aria-hidden="true"
              onClick={onClose}
            >
              <span className="text-sky-500    rounded-sm">
                <MdCancel className="text-4xl cursor-pointer text-secondaryl hover:text-opacity-95" />
              </span>
            </div>

            <div className="h-fit ">
              <h1 className="text-secondaryl font-extrabold text-2xl euclidbold text-center underline">
                Signature Upload / Draw
              </h1>

              <div className="flex flex-row justify-center gap-2 my-3">
                <div
                  className={`px-4 py-2 rounded cursor-pointer items-center justify-center flex gap-1 hover:bg-secondaryl hover:text-white ring-[0.5px] ring-secondaryl ${
                    activeTab === "Image"
                      ? "bg-secondaryl text-white"
                      : "bg-[#FFFFFF80] text-secondaryl"
                  } `}
                  onClick={() => handleTabClick("Image")}
                >
                  <IoImageSharp /> Upload
                </div>
                <div
                  className={`px-4 py-2 rounded cursor-pointer items-center justify-center flex gap-1 hover:bg-secondaryl hover:text-white ring-[0.5px] ring-secondaryl ${
                    activeTab === "Draw"
                      ? "bg-secondaryl text-white"
                      : "bg-[#FFFFFF80] text-secondaryl"
                  } `}
                  onClick={() => handleTabClick("Draw")}
                >
                  <PiSignatureBold /> Draw
                </div>
              </div>

              <div className="w-full flex flex-wrap  justify-start  items-center">
                {activeTab === "Image" && (
                  <div className="flex justify-center w-full">
                    <div className="">
                      <input
                        type="file"
                        id="SignatureUpload"
                        name="SignatureUpload"
                        accept=".jpg,.jpeg,.png"
                        className={`hidden ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }
                      } `}
                        disabled={uploadingFile}
                        onChange={(e) => handleChangeSignature(e)}
                      />

                      {/* Label For style is input tag and use in File input */}
                      <label
                        htmlFor="SignatureUpload"
                        className={`block bg-[#FFFFFF80] h-64 w-64 rounded-sm text-black focus:outline-none  ${
                          signatureError
                            ? "ring-1 ring-red-500"
                            : "ring-1 ring-secondaryl "
                        }  ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer bg-transparent"
                        } 
                       ${displaySignature ? "ps-0" : "ps-1"}
                      `}
                        disabled={uploadingFile}
                      >
                        <div className="flex items-center h-full space-x-1  overflow-hidden">
                          {displaySignature ? (
                            <>
                              <img src={displaySignature} alt="Signature" />
                            </>
                          ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                              <InputFileIcon />
                              <span className="text-infromation text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                .jpg, .png, .jpeg files only
                              </span>
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                    {/* Error */}
                    <div className="h-[3vh] pt-0">
                      {uploadingSignature && <Spinner />}

                      {signatureError && (
                        <p className="text-red-500 text-xs leading-none">
                          {signatureError}
                        </p>
                      )}

                      {/* {errors.Signature && (
                        <p className="text-red-500 text-xs leading-none">
                          {errors.Signature.message}
                        </p>
                      )} */}

                      {/* {signatureUploded && (
                      <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                        <ApprovedIcon /> Signature uploaded Successfully!
                      </p>
                    )} */}
                    </div>
                  </div>
                )}

                {activeTab === "Draw" && (
                  <div className="flex flex-col items-center w-full justify-center space-y-1 mb-2">
                    <div className="ring-1 ring-secondaryl rounded-md w-64 h-64">
                      <SignatureCanvas
                        ref={signatureCanvasRef}
                        canvasProps={{
                          className:
                            "signature-canvas w-full h-full pen-cursor",
                        }}
                      />
                    </div>
                    <div className="h-[3vh] pt-0">
                      {signatureDrawError && (
                        <p className="text-red-500 text-xs leading-none">
                          {signatureDrawError}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-2 justify-center">
                      <div
                        onClick={clearSignature}
                        className="bg-red-500 cursor-pointer text-white px-4 py-2 rounded-md hover:bg-opacity-95"
                      >
                        Clear Signature
                      </div>
                      <div
                        onClick={saveSignature}
                        className="bg-secondaryl cursor-pointer text-white px-4 py-2 rounded-md hover:bg-opacity-95"
                      >
                        Save Signature
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Close Button */}
            <div className="px-3 pb-3 sm:flex sm:flex-row-reverse">
              <div
                onClick={onClose}
                type="button"
                className="w-full inline-flex cursor-pointer justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondaryl text-base font-medium text-white hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureModel;
