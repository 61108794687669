import React, { useCallback, useEffect, useState } from "react";
import { ContractorProfileLeftImage, Spinner } from "../../../../assets/images";
import axios from "axios";
import { InputFileIcon, PlusSquare } from "../../../../assets/images";
import { Link, NavLink, useNavigate } from "react-router-dom";
import InputBox from "../../../../components/Elements/InputBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get, set, useForm } from "react-hook-form";
import {
  baseUrl,
  corporationCertificateAPI,
  corporationProfile,
  propertyManager,
} from "../../../../constants/API";

import InputBoxLive from "../../../../components/Elements/InputBoxLive";
import toast from "react-hot-toast";
import {
  getCorporationProfileData,
  viewFileHandler,
} from "../../../../constants/Request";
import ManageBoardMembers from "../../../../components/Corporation/Dashboard/Profile/ManageBoardMembers";
import Cookies from "universal-cookie";
import Loading from "../../../../components/Elements/Loading";
import { ImNewTab } from "react-icons/im";
import { Icon } from "@iconify/react";
import ManageSecurityMembers from "../../../../components/Corporation/Dashboard/Profile/ManageSecurityMembers";

const MyProfile = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(true);
  const [employedByCondoState, setEmployedByCondoState] = useState(false);
  const [onSiteHoursSameWorkingHours, setOnSiteHoursSameWorkingHours] =
    useState(false);
  const [propertyManagerCount, setPropertyManagerCount] = useState(0);
  const [boardMembersData, setBoardMembersData] = useState([]);
  const [securityMembersData, setSecurityMembersData] = useState([]);
  const [manageOnsiteDaysAndTimeState, setManageOnsiteDaysAndTimeState] =
    useState([
      {
        Day: "Monday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Tuesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Wednesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Thursday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Friday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Saturday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Sunday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
    ]);

  const [manageWorkingDaysAndTimeState, setManageWorkingDaysAndTimeState] =
    useState([
      {
        Day: "Monday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Tuesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Wednesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Thursday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Friday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Saturday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Sunday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
    ]);

  const schema = yup.object().shape({
    Corporation_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Corporation name can only contain letters.")
      .required("Corporation Name is required"),
    Corporation_Address: yup
      .string()
      .required("Corpora  tion Address is required"),
    Corporation_Property_Manager_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Property Manager name can only contain letters.")
      .required("Property Manager Name is required"),
    Corporation_Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(/^[0-9]{10}$/, "Phone No must be exactly 10 digits"),
    Property_Manager_Email: yup
      .string()
      .required("Property Manager Email is required")
      .matches(
        /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
        "Invalid email"
      ),
    Property_Manager_Phone_No: yup
      .string()
      .required("Property Manager Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    propertyManagers: yup.array().of(
      yup.object().shape({
        Property_Manager_Name: yup
          .string()
          .matches(/^[A-Za-z\s]+$/, "Property Manager name can only contain letters.")
          .required("Property Manager Name is required"),
        Property_Manager_Email: yup
          .string()
          .required("Property Manager Email is required")
          .test("valid-Email", "Invalid Property Manager Email id", (value) => {
            if (!value) return true; // Skip validation if the field is empty
            // Check if the value matches the pattern of an email
            return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(
              value
            );
          })
          .test(
            "is-unique",
            "Property Manager Email Must Be Unique",
            function (value) {
              if (!value) return true; // Skip validation if the field is empty
              const propertyManagersArray = getValues("propertyManagers");
              if (propertyManagersArray) {
                const allEmails = propertyManagersArray?.map(
                  (manager) => manager.Property_Manager_Email
                );
                const mainPropertyManagerEmail = getValues(
                  "Property_Manager_Email"
                );
                if (mainPropertyManagerEmail) {
                  allEmails.push(mainPropertyManagerEmail);
                }
                return allEmails.filter((email) => email === value).length <= 1;
              }
              return true;
            }
          )
          .test(
            "is-unique2",
            "Property Manager Email Must Be Unique",
            function (value) {
              if (!value) return true;
              uniqueEmails(value);
              return true;
            }
          ),
        Property_Manager_Phone_No: yup
          .string()
          .required("Phone No is required")
          .matches(
            /^[0-9]{10}$/, // Regular expression for 10-digit phone number
            "Phone No must be exactly 10 digits"
          ),
      })
    ),
    Employed_By_Condo: yup.boolean().default(false),
    Management_Company_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Management Company name can only contain letters.")
      .test(
        "is-required",
        "Management Company Name is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Address: yup
      .string()
      .test(
        "is-required",
        "Management Company Address is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Phone_No: yup
      .string()
      .test(
        "is-required",
        "Management Company Phone No is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Email: yup
      .string()
      .test(
        "is-required",
        "Management Company Email is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      )
      .test("valid-Email", "Invalid Email id", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      }),
    manageOnsiteDaysAndTime: yup
      .array()
      .of(
        yup.object().shape({
          Day: yup.string(),
          Checked: yup.boolean(),
          Working_Hours_From: yup.string(),
          Working_Hours_To: yup.string(),
        })
      )
      .default([
        {
          Day: "Monday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Tuesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Wednesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Thursday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Friday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Saturday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Sunday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
      ]),
    manageWorkingDaysAndTime: yup
      .array()
      .of(
        yup.object().shape({
          Day: yup.string(),
          Checked: yup.boolean(),
          Working_Hours_From: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_From !==
          //       ""
          //     );
          //   }
          //   return true;
          // }),
          Working_Hours_To: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_To !== ""
          //     );
          //   }
          //   return true;
          // }),
        })
      )
      .default([
        {
          Day: "Monday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Tuesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Wednesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Thursday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Friday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Saturday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Sunday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
      ]),
    Public_Certificate_URL: yup.string().nullable(),
    Onsite_Same_Working_Hours: yup.boolean().default(false),
    // Term_And_Policy: yup.boolean().oneOf([true], "This field must be Checked"),
  });

  const uniqueEmails = () => {
    const propertyManagersArray = getValues("propertyManagers");

    // Extract all email addresses from the property managers array
    if (propertyManagersArray.length > 0) {
      const allEmails = propertyManagersArray?.map(
        (manager) => manager.Property_Manager_Email
      );

      // Create an object to store the count of each email
      const emailCounts = {};
      const errorArray = [];
      const mainPropertyManagerEmail = getValues("Property_Manager_Email");
      allEmails.forEach((email) => {
        emailCounts[email] = (emailCounts[email] || 0) + 1;
        if (email === mainPropertyManagerEmail) {
          emailCounts[email] = emailCounts[email] + 1;
        }

        errorArray.push(emailCounts[email]);
      });

      // Check for duplicates
      const duplicateEmails = errorArray?.map((email, index) => {
        if (email > 1) {
          setError(`propertyManagers[${index}].Property_Manager_Email`, {
            type: "manual",
            message: "Property Manager Email Must Be Unique",
          });
        } else {
          const e =
            errors["propertyManagers"] && errors["propertyManagers"][index]
              ? errors["propertyManagers"][index].Property_Manager_Email
              : null;

          if (e?.message === "Property Manager Email Must Be Unique")
            clearErrors(`propertyManagers[${index}].Property_Manager_Email`);
        }
      });
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is not a number
    if (
      !(
        (
          (event.key >= "0" && event.key <= "9") ||
          event.key === "Tab" ||
          event.key === "F5" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "Delete" ||
          event.keyCode === 8 || // backspace
          (event.ctrlKey && event.keyCode === 65) ||
          (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
          (event.ctrlKey && event.keyCode === 67)
        ) // Ctrl + C
      )
    ) {
      // Prevent the default behavior (don't write the character)
      event.preventDefault();
    }
  };

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    async function getcorporatiodata() {
      try {
        const data = await getCorporationProfileData(setLoad);
        if (data) {
          setLoad(true);
          Object.keys(data.data).forEach((key) => {
            setValue(key, data.data[key]);
          });

          if (data.propertyManager) {
            setValue(
              "Corporation_Property_Manager_Name",
              data.propertyManager.Corporation_Property_Manager_Name
            );
            setValue(
              "Property_Manager_Email",
              data.propertyManager.Property_Manager_Email
            );
            setValue(
              "Property_Manager_Phone_No",
              data.propertyManager.Property_Manager_Phone_No
            );
            // console.log(data.propertyManager);
            setValue(
              "Property_Manager_ID",
              data.propertyManager.Property_Manager_ID
            );
          }

          if (data.data.cp_onsiteworkinghours) {
            setManageOnsiteDaysAndTimeState(data.data.cp_onsiteworkinghours);
            setValue(
              "manageOnsiteDaysAndTime",
              data.data.cp_onsiteworkinghours
            );
          }

          if (data.data.cp_workingHours) {
            setManageWorkingDaysAndTimeState(data.data.cp_workingHours);
            setValue("manageWorkingDaysAndTime", data.data.cp_workingHours);
          }

          if (data.propertyManagers) {
            setPropertyManagerCount(data.propertyManagers.length);
            setValue("propertyManagers", data.propertyManagers);
          }

          if (data.data.cp_managementCompany) {
            setEmployedByCondoState(false);
            setValue(
              "Management_Company_Name",
              data.data.cp_managementCompany.Management_Company_Name
            );
            setValue(
              "Management_Company_Email",
              data.data.cp_managementCompany.Management_Company_Email
            );
            setValue(
              "Management_Company_Phone_No",
              data.data.cp_managementCompany.Management_Company_Phone_No
            );
            setValue(
              "Management_Company_Address",
              data.data.cp_managementCompany.Management_Company_Address
            );
          }

          if (data.data.Public_Certificate_URL) {
            const imgurl = await viewFileHandler(
              data.data.Public_Certificate_URL,
              "corporation"
            );

            setPiCertificateURL(imgurl);
          }

          if (data.data.Employed_By_Condo) {
            setEmployedByCondoState(data.data.Employed_By_Condo);
          }

          if (data.data.Onsite_Same_Working_Hours) {
            setOnSiteHoursSameWorkingHours(data.data.Onsite_Same_Working_Hours);
          }

          if (data.data.BoardMembers) {
            setBoardMembersData(data.data.BoardMembers);
          }

          if (data.data.SecurityMembers) {
            setSecurityMembersData(data.data.SecurityMembers);
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setLoad(false);
      }
    }

    getcorporatiodata();
  }, [register]);

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; //  MB
  const SUPPORTED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/jpg",
  ];
  const [piCertificate, setPiCertificate] = useState(null);
  const [piCertificateError, setPiCertificateError] = useState("");
  const [piCertificateUploded, setPiCertificateUploded] = useState("");
  const [piCertificateURL, setPiCertificateURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleAddPropertyManager = () => {
    let propertyManagersArray = getValues("propertyManagers");
    if (!propertyManagersArray) {
      propertyManagersArray = [];
    }
    propertyManagersArray.push({
      Property_Manager_Name: "",
      Property_Manager_Email: "",
      Property_Manager_Phone_No: "",
    });
    const length = propertyManagersArray.length;
    setPropertyManagerCount(length);
    setValue("propertyManagers", propertyManagersArray);
  };

  const handleDeletePropertyManagerId = async (indexToDelete) => {
    let propertyManagersArray = getValues("propertyManagers");
    try {
      setLoad(true);
      const propertyManagersDeleteID = propertyManagersArray[indexToDelete].ID;
      const apiUrl = `${propertyManager}/${propertyManagersDeleteID}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "corporation",
        },
      });
      toast.success("Property Manager Deleted Successfully");
      handleDeletePropertyManager(indexToDelete);
    } catch (error) {
      toast.error("Error deleting property manager ! Please try again.");
    } finally {
      setLoad(false);
    }
  };

  const handleDeletePropertyManager = (indexToDelete) => {
    const propertyManagersArray = getValues("propertyManagers");
    const updatedManagers = propertyManagersArray.filter(
      (_, index) => index !== indexToDelete
    );
    const length = updatedManagers.length;
    setPropertyManagerCount(length);
    setValue("propertyManagers", updatedManagers);
  };

  const handleManageOnsiteDaysAndTime = (index, field, value) => {
    setManageOnsiteDaysAndTimeState((prevDaysAndTime) => {
      const updateDaysAndTime = [...prevDaysAndTime];
      updateDaysAndTime[index][field] = value;
      if (field === "Checked" && value === false) {
        updateDaysAndTime[index].Working_Hours_From = "";
        updateDaysAndTime[index].Working_Hours_To = "";
        setValue(`manageOnsiteDaysAndTime[${index}].Working_Hours_From`, "");
        setValue(`manageOnsiteDaysAndTime[${index}].Working_Hours_To`, "");
      }
      return updateDaysAndTime;
    });
    setValue(`manageOnsiteDaysAndTime[${index}].${field}`, value);
  };

  const handleManageWorkingDaysAndTime = (index, field, value) => {
    setManageWorkingDaysAndTimeState((prevDaysAndTime) => {
      const updateDaysAndTime = [...prevDaysAndTime];
      updateDaysAndTime[index][field] = value;
      if (field === "Checked" && value === false) {
        updateDaysAndTime[index].Working_Hours_From = "";
        updateDaysAndTime[index].Working_Hours_To = "";
        setValue(`manageWorkingDaysAndTime[${index}].Working_Hours_From`, "");
        setValue(`manageWorkingDaysAndTime[${index}].Working_Hours_To`, "");
        // clearErrors(`manageWorkingDaysAndTime[${index}].Working_Hours_From`);
        // clearErrors(`manageWorkingDaysAndTime[${index}].Working_Hours_To`);
      }
      return updateDaysAndTime;
    });

    setValue(`manageWorkingDaysAndTime[${index}].${field}`, value);
    // trigger(`manageWorkingDaysAndTime[${index}].Working_Hours_From`);
    // trigger(`manageWorkingDaysAndTime[${index}].Working_Hours_To`);
  };

  const handleEmployedByCondoChange = useCallback(
    (value) => {
      setEmployedByCondoState(value);
      setValue("employedByCondo", value);
    },
    [employedByCondoState]
  );

  const handleOnsiteHoursSameWorkingHoursChange = useCallback(
    (value) => {
      setOnSiteHoursSameWorkingHours(value);
      setValue("Onsite_Same_Working_Hours", value);

      if (getValues("Onsite_Same_Working_Hours") === true) {
        // Assuming manageOnsiteDaysAndTimeState is an object, create a deep copy
        const newManageOnsiteDaysAndTimeState = JSON.parse(
          JSON.stringify(manageOnsiteDaysAndTimeState)
        );

        setManageWorkingDaysAndTimeState(() => {
          return newManageOnsiteDaysAndTimeState;
        });
        setValue("manageWorkingDaysAndTime", newManageOnsiteDaysAndTimeState);
      }
    },
    [manageOnsiteDaysAndTimeState]
  );

  const onProfileSave = async (data, e) => {
    e.preventDefault();
    if (cookies.get("status") === 2) {
      return toast.error("profile paused");
    }
    if (piCertificateURL) {
      data.publicInformationCertificate = piCertificateURL;
    }

    if (onSiteHoursSameWorkingHours === true) {
      data.manageWorkingDaysAndTime = manageOnsiteDaysAndTimeState;
    } else {
      if (manageWorkingDaysAndTimeState) {
        data.manageWorkingDaysAndTime = manageWorkingDaysAndTimeState;
      }
    }
    setValue("Employed_By_Condo", employedByCondoState);

    try {
      setLoad(true);
      const response = await axios.put(corporationProfile, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "corporation",
        },
      });
      toast.success("Profile Updated Successfully");
      //
      setTimeout(() => window.location.reload(), 1000);
      // reset();
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Failed to update profile data ! Please try again."
      );
    } finally {
      setLoad(false);
    }
  };

  const handleChangePiCertificate = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setPiCertificate(null);
      setPiCertificateError("");
      setPiCertificateUploded("");
      setPiCertificateURL("");
      clearErrors("publicInformationCertificate");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setPiCertificate(file);
        setPiCertificateError("");
        setPiCertificateURL("");
        setPiCertificateUploded("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setPiCertificate(null);
        setPiCertificateError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setPiCertificateUploded("");
        setPiCertificateURL("");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setPiCertificate(null);
        setPiCertificateError("File size exceeds limit (5 MB)");
        setPiCertificateUploded("");
        setPiCertificateURL("");
      } else {
        setPiCertificate(null);
        setPiCertificateError("Something went wrong! Please try again.");
        setPiCertificateUploded("");
        setPiCertificateURL("");
      }
    }
  };

  const handleUploadPiCertificate = async () => {
    if (cookies.get("status") === 2) {
      return toast.error("profile paused");
    }

    if (!piCertificate) {
      setPiCertificateError("Please Upload a Public information certificate");
      setPiCertificateUploded("");
      return;
    }
    const formData = new FormData();
    formData.append("companyCertificate", piCertificate);
    try {
      setUploadingFile(true);
      const response = await axios.post(corporationCertificateAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPiCertificateURL(response.data.data.url);
      setUploadingFile(false);
      setPiCertificateError("");
      setPiCertificateUploded("Uploaded Successfully");
    } catch (error) {
      setUploadingFile(false);
      console.error("Error uploading file ! Please Try Again");
      setPiCertificateError("Failed to upload image");
      setPiCertificateUploded("");
      setPiCertificateURL("");
    }
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    // Check if the pasted data contains only numbers
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  return (
    //  <div className="w-[70%] hidden md:block ">
    //       <img
    //         src={ContractorProfileLeftImage}
    //         className="absolute top-0 bottom-0 w-[40%] h-full object-cover"
    //         alt="Signup background"
    //       />
    //     </div>
    <div>
      <Loading load={load} />
      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          My Profile
        </h1>

        {/* SignUp Form Start Here  */}
        <form
          onSubmit={handleSubmit(onProfileSave)}
          className="mt-8"
          encType="multipart/form-data"
        >
          {/* Corporation Name  && Corporation Address*/}
          <div className="grid grid-cols-1">
            {/* Corporation Name */}
            <InputBox
              title={"Corporation Name"}
              register={register}
              filedName={"Corporation_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />

            {/* Corporation Address */}
            <InputBox
              title={"Corporation Address"}
              register={register}
              filedName={"Corporation_Address"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/* Corporation Property Manager and Phone no */}
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
            {/* Property Manager Name */}
            <InputBox
              title={"Corporation Property Manager Name"}
              register={register}
              filedName={"Corporation_Property_Manager_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
            {/* Phone No */}
            <InputBoxLive
              title={"Phone No"}
              register={register}
              filedName={"Corporation_Phone_No"}
              must={true}
              type="tel"
              errors={errors}
              className={"md:text-sm lg:text-base"}
              maxLength="10"
              schema={schema}
              setError={setError}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
            {/* Property Manager Email  */}
            <InputBoxLive
              title={"Property Manager Email"}
              register={register}
              filedName={"Property_Manager_Email"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
            />
            {/* Property Manager Phone No */}
            <InputBoxLive
              title={"Property Manager Phone No"}
              register={register}
              filedName={"Property_Manager_Phone_No"}
              must={true}
              type="tel"
              errors={errors}
              className={"md:text-sm lg:text-base"}
              maxLength="10"
              schema={schema}
              setError={setError}
            />
          </div>

          {/* Employed by Condo */}
          <div className="grid grid-cols-1 w-full pt-2 pb-4">
            <div className="flex items-center gap-5">
              <label
                htmlFor="Employed_By_Condo"
                className="pt-2 text-secondaryl"
              >
                Employed by Condo Corporation ?
              </label>
              <div className="flex gap-1 items-center">
                <input
                  type="checkbox"
                  id="Employed_By_Condo_Yes"
                  name="Employed_By_Condo"
                  value="Yes"
                  checked={employedByCondoState}
                  onChange={() => handleEmployedByCondoChange(true)}
                  className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                />
                <label htmlFor="Employed_By_Condo_Yes" className="pt-2">
                  Yes
                </label>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  id="Employed_By_Condo_No"
                  name="Employed_By_Condo"
                  value="No"
                  checked={!employedByCondoState}
                  onChange={() => handleEmployedByCondoChange(false)}
                  className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                />
                <label htmlFor="Employed_By_Condo_No" className="pt-2">
                  No
                </label>
              </div>
            </div>
          </div>

          {/* Management Company Fields */}
          {!employedByCondoState && (
            <div className=" bg-gradient-to-b  from-[#282a73]/15 to-[#0094bf]/15 p-4   rounded-sm ">
              <div className="grid grid-cols-1 md:mr-4 lg:mr-20">
                {" "}
                <InputBox
                  title={"Management Company Name"}
                  register={register}
                  filedName={"Management_Company_Name"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base w-full"}
                />
                <InputBox
                  title={"Management Company Address"}
                  register={register}
                  filedName={"Management_Company_Address"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                <InputBoxLive
                  title={"Management Company Email"}
                  register={register}
                  filedName={"Management_Company_Email"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  schema={schema}
                  setError={setError}
                />

                <InputBoxLive
                  title={"Management Company Phone No"}
                  register={register}
                  filedName={"Management_Company_Phone_No"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  type="tel"
                  maxLength="10"
                  schema={schema}
                  setError={setError}
                />
              </div>
            </div>
          )}

          {/* Working Hourse Or Not*/}
          <div className="grid grid-cols-1 w-full mt-4">
            <div className="flex items-center gap-5">
              <label
                htmlFor="Employed_By_Condo"
                className="pt-2 text-secondaryl"
              >
                Onsite Hours Same as Working Hours ?
              </label>
              <div className="flex gap-1 items-center">
                <input
                  type="checkbox"
                  id="Onsite_Hours_Same_As_Working_Hours_Yes"
                  name="Onsite_Hours_Same_As_Working_Hours_Yes"
                  value="Yes"
                  checked={onSiteHoursSameWorkingHours}
                  onChange={() => handleOnsiteHoursSameWorkingHoursChange(true)}
                  className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                />
                <label
                  htmlFor="Onsite_Hours_Same_As_Working_Hours_Yes"
                  className="pt-2"
                >
                  Yes
                </label>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  id="Onsite_Hours_Same_As_Working_Hours_No"
                  name="Onsite_Hours_Same_As_Working_Hours_No"
                  value="No"
                  checked={!onSiteHoursSameWorkingHours}
                  onChange={() =>
                    handleOnsiteHoursSameWorkingHoursChange(false)
                  }
                  className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                />
                <label
                  htmlFor="Onsite_Hours_Same_As_Working_Hours_No"
                  className="pt-2"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {/* OnSite Working Hours */}
          <div className="mb-3 my-4">
            <label className="block font-normal mb-3 text-secondaryl">
              Manager Working Days & Time
              <span className="text-red-500 text-base">*</span>
            </label>
            <div className="flex flex-row flex-wrap gap-2">
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((Day, index) => (
                <div
                  key={index}
                  className="w-full flex flex-wrap flex-row items-center gap-5"
                >
                  {/* {manageOnsiteDaysAndTimeState[index]} */}
                  <div className="flex items-center  ">
                    <label
                      htmlFor={`${Day.toLowerCase()}`}
                      className="w-12 text-secondaryl"
                    >
                      {Day.slice(0, 3).toUpperCase()}
                    </label>

                    <input
                      type="checkbox"
                      id={`${Day.toLowerCase()}`}
                      name={`${Day.toLowerCase()}`}
                      checked={manageOnsiteDaysAndTimeState[index].Checked}
                      onChange={(e) =>
                        handleManageOnsiteDaysAndTime(
                          index,
                          "Checked",
                          e.target.checked
                        )
                      }
                      className="bg-transparent outline-none text-black h-11 w-7 accent-secondaryl"
                    />
                  </div>
                  {/* From Input */}
                  <div className="md:flex md:flex-wrap gap-5 space-y-2 justify-end ">
                    <div className="flex gap-2 items-center ">
                      <label
                        htmlFor={`${Day.toLowerCase()}From`}
                        className="block font-normal text-secondaryl"
                      >
                        From
                      </label>
                      <input
                        type="time"
                        id={`${Day.toLowerCase()}From`}
                        name={`${Day.toLowerCase()}From`}
                        value={
                          manageOnsiteDaysAndTimeState[index].Working_Hours_From
                        }
                        onChange={(e) =>
                          handleManageOnsiteDaysAndTime(
                            index,
                            "Working_Hours_From",
                            e.target.value
                          )
                        }
                        className={`block h-11 w-full min-w-16 md:max-w-full  rounded-sm text-black px-2 ring-gray-400 ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] ${
                          errors[`${Day.toLowerCase()}From`]
                            ? "ring-[0.5px] ring-red-500"
                            : ""
                        } ${
                          manageOnsiteDaysAndTimeState[index].Checked
                            ? "bg-[#FFFFFF80]"
                            : "cursor-not-allowed bg-primaryl/[10%] "
                        }`}
                        placeholder="hrs:mins"
                        disabled={!manageOnsiteDaysAndTimeState[index].Checked}
                      />
                    </div>

                    {/* To Input */}
                    <div className="flex gap-2 items-center">
                      <label
                        htmlFor={`${Day.toLowerCase()}To`}
                        className="block font-normal mt-1 text-secondaryl"
                      >
                        To
                      </label>
                      <input
                        type="time"
                        id={`${Day.toLowerCase()}To`}
                        name={`${Day.toLowerCase()}To`}
                        value={
                          manageOnsiteDaysAndTimeState[index].Working_Hours_To
                        }
                        onChange={(e) =>
                          handleManageOnsiteDaysAndTime(
                            index,
                            "Working_Hours_To",
                            e.target.value
                          )
                        }
                        className={` block h-11 min-w-16  md:max-w-full rounded-sm text-black px-2  ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] ${
                          errors[`${Day.toLowerCase()}To`]
                            ? "ring-[0.5px] ring-red-500"
                            : ""
                        }  ${
                          manageOnsiteDaysAndTimeState[index].Checked
                            ? "bg-[#FFFFFF80]"
                            : "cursor-not-allowed bg-primaryl/[10%]  "
                        }`}
                        placeholder="hrs:mins"
                        disabled={!manageOnsiteDaysAndTimeState[index].Checked}
                      />
                    </div>
                    {/* <div className="h-[3vh] pt-1 max-w-18">
                              {errors[`${Day.toLowerCase()}To`] && (
                                <p className="text-red-500 text-xs leading-none">
                                  {errors[`${Day.toLowerCase()}To`].message}
                                </p>
                              )}
                            </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Working Hours */}
          {!onSiteHoursSameWorkingHours && (
            <div className="mb-3 my-4 ">
              <label className="block font-normal mb-3 text-secondaryl">
                Manager Onsite Days & Time
                <span className="text-red-500 text-base">*</span>
              </label>
              <div className="flex flex-row flex-wrap gap-2">
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((Day, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-wrap flex-row items-center gap-5"
                  >
                    <div className="flex items-center">
                      <label
                        htmlFor={`${Day.toLowerCase()}`}
                        className="w-12 text-secondaryl"
                      >
                        {Day.slice(0, 3).toUpperCase()}
                      </label>

                      <input
                        type="checkbox"
                        id={`${Day.toLowerCase()}`}
                        name={`${Day.toLowerCase()}`}
                        onChange={(e) =>
                          handleManageWorkingDaysAndTime(
                            index,
                            "Checked",
                            e.target.checked
                          )
                        }
                        className="bg-transparent text-black h-11 w-7 accent-secondaryl"
                        checked={manageWorkingDaysAndTimeState[index].Checked}
                      />
                    </div>
                    {/* From Input */}

                    <div className="md:flex md:flex-wrap gap-5 space-y-2 justify-end ">
                      <div className="flex gap-2 items-center ">
                        <label
                          htmlFor={`${Day.toLowerCase()}From`}
                          className="block font-normal "
                        >
                          From
                        </label>
                        <input
                          type="time"
                          id={`${Day.toLowerCase()}From`}
                          name={`${Day.toLowerCase()}From`}
                          value={
                            manageWorkingDaysAndTimeState[index]
                              .Working_Hours_From
                          }
                          onChange={(e) =>
                            handleManageWorkingDaysAndTime(
                              index,
                              "Working_Hours_From",
                              e.target.value
                            )
                          }
                          className={` block h-11 w-full min-w-16 md:max-w-full  rounded-sm text-black  px-2  ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] 
                                 ${
                                   errors[`${Day.toLowerCase()}From`]
                                     ? "ring-[0.5px] ring-red-500"
                                     : ""
                                 } ${
                            manageWorkingDaysAndTimeState[index].Checked
                              ? "bg-[#FFFFFF80]"
                              : "cursor-not-allowed bg-primaryl/[10%] "
                          }`}
                          placeholder="hrs:mins"
                          disabled={
                            !manageWorkingDaysAndTimeState[index].Checked
                          }
                          // {...register(
                          //   `manageWorkingDaysAndTimeState[${index}].Working_Hours_From`
                          // )}
                        />
                        <div className="h-[2vh] pt-1">
                          {errors["manageWorkingDaysAndTime"] &&
                            errors["manageWorkingDaysAndTime"][index] &&
                            errors["manageWorkingDaysAndTime"][index][
                              "Working_Hours_From"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["manageWorkingDaysAndTime"][index][
                                    "Working_Hours_From"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>

                      {/* To Input */}
                      <div className="flex gap-2  justify-end items-center ">
                        <label
                          htmlFor={`${Day.toLowerCase()}To`}
                          className="block text-secondaryl font-normal mt-1"
                        >
                          To
                        </label>
                        <input
                          type="time"
                          id={`${Day.toLowerCase()}To`}
                          name={`${Day.toLowerCase()}To`}
                          value={
                            manageWorkingDaysAndTimeState[index]
                              .Working_Hours_To
                          }
                          onChange={(e) =>
                            handleManageWorkingDaysAndTime(
                              index,
                              "Working_Hours_To",
                              e.target.value
                            )
                          }
                          className={` block h-11 min-w-16  md:max-w-full rounded-sm text-black  px-2 ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px]  ${
                            errors[`${Day.toLowerCase()}To`]
                              ? "ring-[0.5px] ring-red-500"
                              : ""
                          }  ${
                            manageWorkingDaysAndTimeState[index].Checked
                              ? "bg-[#FFFFFF80]"
                              : "cursor-not-allowed bg-primaryl/[10%] "
                          }`}
                          placeholder="hrs:mins"
                          disabled={
                            !manageWorkingDaysAndTimeState[index].Checked
                          }
                        />
                        <div className="h-[2vh] pt-1">
                          {errors["manageWorkingDaysAndTime"] &&
                            errors["manageWorkingDaysAndTime"][index] &&
                            errors["manageWorkingDaysAndTime"][index][
                              "Working_Hours_To"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["manageWorkingDaysAndTime"][index][
                                    "Working_Hours_To"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>
                      {/* <div className="h-[3vh] pt-1 max-w-18">
                              {errors[`${Day.toLowerCase()}To`] && (
                                <p className="text-red-500 text-xs leading-none">
                                  {errors[`${Day.toLowerCase()}To`].message}
                                </p>
                              )}
                            </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Add Property Manager */}
          <div className="flex gap-3 items-center mb-3 ">
            <div
              id="addPropertyManager"
              className="cursor-pointer flex gap-3 hover:opacity-80"
              onClick={handleAddPropertyManager}
            >
              <Icon
                icon="bi:plus-square"
                className="text-secondaryl text-2xl"
              />
              <label className="cursor-pointer text-secondaryl">
                Add more Property Managers
              </label>
            </div>
          </div>
          {/* Property Managers structure */}
          <div className="">
            {/*  */}
            {Array.from({ length: propertyManagerCount }).map((_, index) => (
              <div key={index}>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
                  <div className="mb-3 w-full">
                    <label
                      htmlFor={`propertyManagerName-${index}`}
                      className="block font-normal text-secondaryl"
                    >
                      Property Manager {index + 2} Name
                      <sup className="text-red-500 text-base">*</sup>
                    </label>
                    <input
                      id={`propertyManagerName-${index}`}
                      name={`propertyManagers[${index}].Property_Manager_Name`}
                      type="text"
                      // value={manager.propertyManagerName}
                      {...register(
                        `propertyManagers[${index}].Property_Manager_Name`
                      )}
                      // onChange={(e) =>
                      //   handlePropertyManagerChange(
                      //     index,
                      //     "propertyManagerName",
                      //     e.target.value
                      //   )
                      // }
                      className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none bg-[#FFFFFF80]`}
                    />
                    <div className="h-[2vh] pt-1">
                      {errors["propertyManagers"] &&
                        errors["propertyManagers"][index] &&
                        errors["propertyManagers"][index][
                          "Property_Manager_Name"
                        ] && (
                          <p className="text-red-500 text-xs leading-none">
                            {
                              errors["propertyManagers"][index][
                                "Property_Manager_Name"
                              ].message
                            }
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="mb-3 w-full">
                    <label
                      htmlFor={`propertyManagerEmail-${index}`}
                      className="block font-normal text-secondaryl"
                    >
                      Property Manager {index + 2} Email
                      <sup className="text-red-500 text-base">*</sup>
                    </label>
                    <input
                      id={`propertyManagerEmail-${index}`}
                      name={`propertyManagers[${index}].Property_Manager_Email`}
                      type="text"
                      {...register(
                        `propertyManagers[${index}].Property_Manager_Email`
                      )}
                      className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none bg-[#FFFFFF80]`}
                    />
                    <div className="h-[2vh] pt-1">
                      {errors["propertyManagers"] &&
                        errors["propertyManagers"][index] &&
                        errors["propertyManagers"][index][
                          "Property_Manager_Email"
                        ] && (
                          <p className="text-red-500 text-xs leading-none">
                            {
                              errors["propertyManagers"][index][
                                "Property_Manager_Email"
                              ].message
                            }
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
                  <div className="mb-3 w-full">
                    <label
                      htmlFor={`propertyManagerPhoneNo-${index}`}
                      className="block font-normal text-secondaryl"
                    >
                      Property Manager {index + 2} Phone No
                      <sup className="text-red-500 text-base">*</sup>
                    </label>
                    <input
                      id={`propertyManagerPhoneNo-${index}`}
                      name={`propertyManagers[${index}].Property_Manager_Phone_No`}
                      type="tel"
                      {...register(
                        `propertyManagers[${index}].Property_Manager_Phone_No`
                      )}
                      maxLength="10"
                      min={0}
                      max={10}
                      onKeyDown={handleKeyDown}
                      className={`w-full  block  h-11 rounded-sm text-black px-2 focus:ring-[0.5px] ps-10 focus:ring-secondaryl focus:outline-none bg-[#FFFFFF80]`}
                      onPaste={handlePaste}
                    />
                    <div className="h-[2vh] pt-1">
                      {errors["propertyManagers"] &&
                        errors["propertyManagers"][index] &&
                        errors["propertyManagers"][index][
                          "Property_Manager_Phone_No"
                        ] && (
                          <p className="text-red-500 text-xs leading-none">
                            {
                              errors["propertyManagers"][index][
                                "Property_Manager_Phone_No"
                              ].message
                            }
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="flex justify-end ">
                    <div
                      className={`h-11 w-fit flex justify-end   text-sm md:mt-8 px-6 py-2.5 euclidbold  ring-1 ring-secondaryl items-center text-secondaryl sm:text-xl tracking-widest hover:bg-secondaryl rounded-md hover:text-white transition duration-300 ease-in-out m cursor-pointer`}
                      onClick={() => {
                        const DeleteByPMID = getValues(
                          `propertyManagers[${index}].ID`
                        )
                          ? true
                          : false;

                        if (DeleteByPMID) {
                          const confirmation = window.confirm(
                            `Are you sure you want to delete this Property Manager ?`
                          );
                          if (confirmation) {
                            handleDeletePropertyManagerId(index);
                          }
                        } else {
                          handleDeletePropertyManager(index);
                        }
                      }}
                    >
                      Delete Property Manager {index + 2}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <ManageBoardMembers
            boardMemberData={boardMembersData}
            setLoad={setLoad}
          />
          <ManageSecurityMembers
            securityMembersData={securityMembersData}
            setLoad={setLoad}
          />

          {/* Upload Public Information Certificate */}

          <div className="">
            <label className={`block font-normal text-base text-secondaryl`}>
              The public information Certificate
            </label>
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-5  md:gap-16 md:mr-4 lg:mr-20">
              <div className="col-span-2 sm:col-span-2 md:col-span-1">
                <input
                  type="file"
                  id="Public_Certificate_URL"
                  name="Public_Certificate_URL"
                  className={`hidden  ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={handleChangePiCertificate}
                />
                <label
                  htmlFor="Public_Certificate_URL"
                  className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none
                                ${
                                  piCertificateError
                                    ? "ring-[ring-0.5px] ring-red-500"
                                    : ""
                                }  ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  }
                              `}
                  disabled={uploadingFile}
                >
                  <div className="flex items-center h-full space-x-1 overflow-hidden">
                    <InputFileIcon />

                    {piCertificate && !piCertificateURL ? (
                      <span className="line-clamp-1 w-full">
                        {piCertificate.name}
                      </span>
                    ) : (
                      ""
                    )}

                    {!piCertificate && !piCertificateURL ? (
                      <span className="text-infromation text-opacity-50  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                        Upload Certificate
                      </span>
                    ) : (
                      ""
                    )}
                    {piCertificateURL && (
                      <>
                        <NavLink
                          to={piCertificateURL}
                          className="text-white p-2 bg-secondaryl relative z-10 hover:text-secondaryd truncate flex justify-end rounded-sm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Click Here
                        </NavLink>
                      </>
                    )}
                  </div>
                </label>
              </div>

              <div className="">
                {piCertificateURL ? (
                  <div className="h-11 w-fit text-sm px-6 py-2.5   euclidbold   cursor-not-allowed ring-[0.5px]  ring-secondaryl rounded-md flex  justify-center items-center text-secondaryl sm:text-xl    tracking-widest   md:mt-0 ">
                    UPLOADED
                  </div>
                ) : (
                  <div
                    onClick={handleUploadPiCertificate}
                    className={`h-11 w-fit max-w-32 text-sm px-6 py-2.5 euclidbold    ring-[0.5px]  ring-secondaryl rounded-md flex justify-center items-center text-secondaryl sm:text-xl hover:bg-secondaryl  tracking-widest hover:text-white transition duration-300 ease-in-out md:mt-0  ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    } `}
                    disabled={uploadingFile}
                  >
                    UPLOAD
                  </div>
                )}
              </div>
            </div>

            <div className="h-[5vh] pt-0">
              {uploadingFile && <Spinner />}
              {piCertificateError && (
                <p className="text-red-500 text-xs leading-none">
                  {piCertificateError}
                </p>
              )}
              {piCertificateUploded && (
                <p className="text-green-600 text-xs leading-none">
                  {piCertificateUploded}
                </p>
              )}
            </div>
          </div>

          {/* Reset Password Link */}
          <div className="">
            <div className="flex gap-0 items-center">
              <Link to="/corporations/propertymanager/reset">
                <span className="text-secondaryl hover:text-opacity-95   euclidbold  flex items-center gap-2">
                  Reset Password <ImNewTab />
                </span>
              </Link>
            </div>
          </div>

          {/* Submit Button */}
          <div className="my-3 pt-4">
            <button
              type="submit"
              className={`h-11 w-full max-w-32 text-sm px-6 py-2.5 euclidbold  ring-1 ring-secondaryl flex justify-center items-center text-secondaryl sm:text-xl tracking-widest hover:bg-secondaryl rounded-md hover:text-white transition duration-300 ease-in-out  ${
                uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              disabled={uploadingFile}
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyProfile;
