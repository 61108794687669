import React, { useState, useEffect } from "react";
import {
  CountDay,
  Location,
  ApprovedIcon,
  LogoWithText1,
} from "../../assets/images";
import eye from "../../assets/eye.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { Icon } from "@iconify/react";

const getDeadlineStatus = (deadline) => {
  const currentDate = new Date();
  const proposalDeadline = new Date(deadline).setHours(23, 59, 59, 999);
  if (proposalDeadline < currentDate) {
    return { status: "Expired", daysLeft: 0 };
  } else {
    const daysLeft = Math.ceil(
      (proposalDeadline - currentDate) / (1000 * 60 * 60 * 24)
    );
    return { status: `${daysLeft} days left!`, daysLeft: daysLeft };
  }
};

function ContractCard({ contract, buttonName, contractsPageType }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState(true);

  const currentDate = new Date();
  const proposalDeadline = new Date(contract.Proposal_Deadline).setHours(
    23,
    59,
    59,
    999
  );
  const endDate = new Date(contract.End_Date).setHours(23, 59, 59, 999);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const companyType = cookies.get("companyType");
  const [daysLeft, setDaysLeft] = useState(0);
  const [deadlineStatus, setDeadlineStatus] = useState({
    status: "",
    className: "",
  });

  const [contractCardImage, setContractCardImage] = useState("");

  useEffect(() => {
    setLoad(true);
    const deadlineAns = getDeadlineStatus(contract.Proposal_Deadline);
    setDeadlineStatus((prev) => ({ ...prev, status: deadlineAns.status }));
    for (let i = 1; i <= 10; i++) {
      if (contract[`Project_Photos_${i}`]) {
        setContractCardImage(contract[`Project_Photos_${i}`] || "");
        break;
      }
    }
    setDaysLeft(deadlineAns.daysLeft);
    setLoad(false);
  }, [contract.Proposal_Deadline]);

  return (
    <div
      className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      {/* Card Images  */}
      <div
        className={`h-full relative lg:w-[20%] flex-none bg-cover rounded-xl text-center flex justify-center ${
          (contractCardImage === "" || contractCardImage === null) &&
          "bg-gray-200"
        }`}
      >
        {contractCardImage !== "" && contractCardImage !== null && !load ? (
          <img
            src={contractCardImage}
            alt={contract.Project_Name}
            className="lg:h-36  rounded-md"
          />
        ) : (
          // <div className="h-32 flex items-center"> No Image </div>
          // Changes by Client
          !load && (
            <img
              src={LogoWithText1}
              alt={"Logo"}
              className="lg:h-36 rounded-md"
            />
          )
        )}
      </div>

      {/* Card Content */}
      <div className="flex flex-col justify-between lg:w-[60%]  leading-normal">
        <div className="flex-col">
          {/* Title */}
          <div className="text-secondaryl euclidbold text-xl line-clamp-1">
            {contract.Project_Name}
          </div>
          {/* Location */}
          <div className="text-base  break-words flex gap-1 items-center line-clamp-1">
            <Icon
              icon="carbon:location-filled"
              className="text-secondaryl text-lg"
            />
            <p className="text-infromation">{contract.Project_Location}</p>
          </div>
          {/* Description */}
          <div
            style={{ overflow: "hidden !important" }}
            className="text-left text-infromation text-base  break-words line-clamp-3 pb-[4px]"
          >
            {contract.Project_Description}
          </div>
        </div>

        <div className="flex flex-wrap gap-5 text-base">
          {companyType === "contractor" && (
            <div className="gap-2 flex items-center">
              <CountDay color={daysLeft >= 1 && daysLeft <= 3 ? "red" : ""} />
              <span
                className={`truncate text-base ${
                  deadlineStatus.status === "Expired"
                    ? "text-[#66666680]"
                    : "text-infromation"
                }`}
              >
                {deadlineStatus.status}
              </span>
            </div>
          )}

          {companyType === "contractor" &&
            contractsPageType &&
            (contract.proposal.Status === 2 ? (
              <div className="gap-2 flex items-center">
                <Icon
                  icon="duo-icons:approved"
                  className="text-lg text-[#00A701]"
                />
                <p className="text-sm text-infromation">Approved</p>
              </div>
            ) : contract.proposal.Status === 1 ? (
              <div className="gap-2 flex items-center">
                <Icon
                  icon="iconamoon:eye"
                  className="text-base text-secondaryl"
                />
                <p className="text-infromation text-sm">Viewed By Management</p>
              </div>
            ) : (
              ""
            ))}
        </div>
      </div>

      {/* Card Buttons */}
      <div className="flex-col justify-center my-auto relative w-full lg:w-[20%] space-y-3">
        {companyType === "contractor" && (
          <div
            onClick={() => {
              navigate(
                `/contractors/dashboard/detailscontract/${contract.ID}`,
                {
                  state: contract,
                }
              );
            }}
            className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            {buttonName}
          </div>
        )}

        {companyType === "corporation" &&
        contract.Status === 0 &&
        contractsPageType === "archivescontract" ? (
          <>
            <div className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap ">
              0 NEW MESSAGES
            </div>

            {/* If Requried Edit Option is Open Then Board Member Access Leval Is only view */}
            {/* {cookies.get("access") === "board member" ? (
              ""
              // VIEW Option
            ) 
            : (
              ""
              // Edit Option
            )} */}

            <div
              onClick={() => {
                navigate(
                  `/corporations/dashboard/archivescontract/detailscontract/${contract.ID}`,
                  {
                    state: contract,
                  }
                );
              }}
              className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              {buttonName}
            </div>
          </>
        ) : // If the contract in Proposal Approval stage
        companyType === "corporation" &&
          contract.Status === 1 &&
          contractsPageType === "archivescontract" ? (
          // If the proposal deadline has passed
          endDate < currentDate ? (
            <>
              {cookies.get("access") === "property manager" && (
                <div
                  onClick={() =>
                    navigate(
                      "/corporations/dashboard/message?id=" +
                        contract.proposal.ID
                    )
                  }
                  className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  MESSAGE
                </div>
              )}

              <div
                onClick={
                  () => {
                    cookies.get("access") === "board member"
                      ? navigate(
                          `/corporations/dashboard/archivescontract/detailscontract/${contract.ID}`,
                          {
                            state: contract,
                          }
                        )
                      : navigate(
                          `/corporations/dashboard/reviews/${contract.ID}`
                        );
                  }
                  // toast.success("section under process")
                }
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                {cookies.get("access") === "board member" ? "VIEW" : "REVIEW"}
              </div>
            </>
          ) : (
            // If the proposal deadline has not passed there is not options to review
            <>
              {cookies.get("access") === "property manager" && (
                <div
                  onClick={() =>
                    navigate(
                      "/corporations/dashboard/message?id=" +
                        contract.proposal.ID
                    )
                  }
                  className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap"
                >
                  MESSAGE
                </div>
              )}

              {/* Here Change Page for the view sepcific proposal there if i am selected */}

              <div
                onClick={() => {
                  navigate(
                    `/corporations/dashboard/archivescontract/detailscontract/${contract.ID}`,
                    {
                      state: contract,
                    }
                  );
                }}
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                {buttonName}
              </div>
            </>
          )
        ) : companyType === "corporation" &&
          contract.Status === 2 &&
          contractsPageType === "archivescontract" ? (
          <div
            onClick={() => {
              navigate(
                `/corporations/dashboard/archivescontract/detailscontract/${contract.ID}`,
                {
                  state: contract,
                }
              );
            }}
            className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            {buttonName}
          </div>
        ) : (
          ""
        )}

        {companyType === "corporation" &&
          contract.Is_Request &&
          contractsPageType === "residentrequest" && (
            <div
              onClick={() => {
                if (cookies.get("status") === 2) {
                  return toast.error("profile paused");
                } else {
                  return navigate(
                    `/corporations/dashboard/requestlisting/contract/${contract.ID}`,
                    {
                      state: {
                        page: searchParams.get("page"),
                        // contract: contract,
                      },
                    }
                  );
                }
              }}
              className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              {buttonName}
            </div>
          )}
      </div>
    </div>
  );
}
export default ContractCard;
