import React, { useState } from "react";
import ContactForm from "./ContactForm";
import Cookies from "universal-cookie";
import Layout from "../../components/Layout/Layout";

function Contact() {
  const cookies = new Cookies();
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));

  return isLogin ? (
    <ContactForm />
  ) : (
    <div className="overflow-hidden ">
      <Layout>
        <div className="px-[2.5%] md:px-[8%]">
          <ContactForm />
        </div>
      </Layout>
    </div>
  );
}

export default Contact;
