import React, { useState } from "react";

import {
  BuildingWithMan,
  OwnersLoginImg,
} from "../../../assets/images/index.jsx";
import Loading from "../../../components/Elements/Loading.jsx";

import Layout from "../../../components/Layout/Layout.jsx";
import CreateOwnersForm from "../../../components/Corporation/Dashboard/Resident/CreateOwnersForm.jsx";

function CreateOwners() {
  return (
    <Layout>
      <div className="relative">
        <div className="overflow-hidden   overflow-x-hidden md:p-6 md:px-10  w-full">
          <div className="min-h-screen w-full flex  gap-10 ">
            <div className="w-[40%] hidden md:block">
              <img
                src={OwnersLoginImg}
                className="min-h-screen object-contain"
                alt="Singup background"
              />
            </div>

            <div className="w-full md:w-[60%] ">
              <CreateOwnersForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateOwners;
