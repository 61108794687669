import React from "react";
import { AboutUsPage } from "../../assets/images";

function About() {
  return (
    <div>
      <div className="w-full px-4 m-auto  p-6 rounded-2xl  justify-center h-full">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          About Us
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-8 gap-10 pt-2 h-full">
          {/* About Us Section */}

          <div className="lg:col-span-5  overflow-auto scrollable-list border-none">
            <div className="pt-3">
              <p className="mb-4 text-infromation">
                At our core, we are a digital platform that revolutionizes how
                condominium corporations and vendors handle contracting and
                project management. Our mission is simple: to provide
                condominium communities with a transparent, efficient, and
                cost-effective solution to streamline their contracting
                processes.
              </p>
              <p className="mb-4 text-infromation">
                With a user-friendly interface, our platform simplifies the
                complex tasks of managing bids, contracts, and communication
                between condominium boards, property managers, and vendors. By
                centralizing these processes, we ensure transparency,
                accountability, and efficiency for all stakeholders involved.
              </p>
              <h2 className="mb-4 text-infromation font-bold">
                Why Choose Us?
              </h2>
              <p className="mb-4 text-infromation">
                We offer condominium corporations and vendors a seamless way to
                connect, collaborate, and manage their projects through an
                all-in-one digital solution.
              </p>
              <ul className="list-disc pl-5 mb-4 text-infromation">
                <li>
                  Effortless Contracting: From posting contracts to selecting
                  vendors, our platform makes the entire contracting process
                  smooth and straightforward for condominium corporations and
                  property managers.
                </li>
                <li>
                  Fair and Competitive Bidding: Vendors can easily submit bids,
                  fostering healthy competition that results in better pricing
                  and services for condominium corporations.
                </li>
                <li>
                  Transparency at Every Step: With real-time project tracking
                  and digital signatures, every stage of a contract is
                  documented, keeping everyone informed and ensuring full
                  accountability.
                </li>
                <li>
                  Free to Use: Our platform offers free sign-up for both
                  condominium corporations and vendors, making it accessible to
                  any community looking to streamline their contracting
                  processes.
                </li>
                <li>
                  Concierge Services: We also offer complimentary concierge
                  services for condominium corporations, giving tenants and
                  owners easy access to important information such as meeting
                  minutes, rules, announcements, and project updates.
                </li>
              </ul>
              <h2 className="mb-4 text-infromation font-bold">
                Our Platform’s Benefits:
              </h2>
              <ul className="list-disc pl-5 mb-4 text-infromation">
                <li>
                  Simplified Contract Management: Property managers can easily
                  post contracts, review bids, and approve them with digital
                  signatures.
                </li>
                <li>
                  Cost Savings: By creating a competitive environment for vendor
                  bids, condominium corporations can secure better pricing for
                  their projects.
                </li>
                <li>
                  Enhanced Communication: Our platform centralizes communication
                  between property managers, contractors, and residents,
                  reducing miscommunication and delays.
                </li>
                <li>
                  Vendor Services: Vendors benefit from an equal playing field
                  where they can compete fairly for contracts and grow their
                  business with access to condominium projects.
                </li>
              </ul>
              <h2 className="mb-4 text-infromation font-bold">
                How It Works:
              </h2>
              <ul className="list-disc pl-5 mb-4 text-infromation">
                <li>
                  Post a Project: Property managers list contracts or projects,
                  specifying the work needed.
                </li>
                <li>
                  Receive Vendor Bids: Verified vendors submit proposals based
                  on the project’s requirements, creating a competitive
                  environment.
                </li>
                <li>
                  Review & Approve: Board members and property managers can
                  review proposals, make informed decisions, and approve the
                  best bid using secure digital signatures.
                </li>
                <li>
                  Track Progress: Easily monitor project milestones, ensuring
                  timely completion and full transparency throughout the
                  process.
                </li>
              </ul>
              <p className="text-infromation">
                Our platform is designed to enhance the way condominium
                corporations and vendors work together. By leveraging digital
                tools, we aim to provide a more transparent, accountable, and
                efficient system for managing projects. Whether you’re a
                property manager seeking the best vendor for your next project
                or a contractor looking for fair opportunities, our platform
                empowers you to achieve your goals.
              </p>
            </div>
          </div>
          {/* Image Section */}
          <div className="lg:col-span-3 h-full">
            <div className="w-full">
              <img
                src={AboutUsPage}
                alt="About Us"
                className="w-full ml-auto h-[70vh] object-cover lg:object-fill"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
