const AdminNavLinks = [
  {
    name: "Home",
    path: "home",
  },
  {
    name: "Manage Corporations",
    path: "managecorporations",
  },
  {
    name: "Manage Contractors ",
    path: "managecontractors ",
  },
  {
    name: "Manage Enquiry",
    path: "manageenquiry",
  },
  {
    name: "Manage Services",
    path: "manageservices",
  },
  {
    name: "Manage Working Area",
    path: "manageworkingarea",
  },
  {
    name: "Manage Blog",
    path: "manageblog",
  },
];

module.exports = { AdminNavLinks };
