import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../assets/images";

const CheckBoxDropDown1 = ({
  title,
  options,
  onSelect,
  selectOption,
  setValue,
  filedName,
  clearErrors,
  errors,
  dbfield,
  setError,
  schema,
  must = false,
  isOther = false,
  otherOptionId = "",
  isOpenOther = "",
  setIsOpenOther = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelecteItems = (optionId, e) => {
    e.stopPropagation();

    const isSelected = selectOption.includes(optionId);

    if (isSelected) {
      onSelect(selectOption.filter((ID) => ID !== optionId));
    } else {
      onSelect([...selectOption, optionId]);
    }

    if (must) {
      const value = e.target.value; // Assuming e.target.value is the value of the service field

      // Convert the value to an array if it's not already an array
      const arrayValue = Array.isArray(value) ? value : [value];

      schema
        .validateAt(filedName, { [filedName]: arrayValue }) // Validate the field
        .then((valid) => {
          // If the field is valid, remove the error message
          if (errors[filedName]) {
            clearErrors(filedName);
          }
        })
        .catch((err) => {
          // If the field is invalid, add the error message
          setError(filedName, { type: "manual", message: err.errors[0] });
        })
        .finally(() => {
          // Any cleanup or additional actions you want to perform
        });
    }

    if (
      isOther &&
      optionId === otherOptionId &&
      !selectOption.includes(otherOptionId)
    ) {
      setIsOpenOther(true);
      setIsOpen(false);
    }

    if (
      isOther &&
      optionId === otherOptionId &&
      selectOption.includes(otherOptionId)
    ) {
      setIsOpenOther(false);
    }
  };

  // const handleSelectOption = () => {
  //     onSelect(selectOption);
  //     setValue(filedName, selectOption);
  //     if (errors[filedName]) {
  //         clearErrors(filedName);
  //     }
  //     toggleDropdown();
  // };

  // Close dropdown when clicked outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setValue(`${filedName}`, selectOption);
  }, [selectOption]);

  // Calculate the average rating for a given array of reviews
  const calculateRating = (reviews) => {
    if (!reviews || reviews.length === 0) {
      return "(NA)"; // No reviews available, return 0 rating
    }

    // Filter out invalid ratings (e.g., undefined, non-number, or NaN values) and parse ratings to numbers
    const validRatings = reviews.map((review) => parseFloat(review.Rating)); // Ensure rating is parsed as a float
    // .filter((rating) => !isNaN(rating)); // Filter only valid numbers
    // console.log(validRatings);
    // console.log(reviews, "reviews");

    if (validRatings.length === 0) {
      return "(NA)"; // No valid ratings, return 0 rating
    }

    // Calculate the average rating
    const totalRating = validRatings.reduce((sum, rating) => sum + rating, 0);
    const averageRating = totalRating / 5;

    return `(${averageRating.toFixed(1)})`; // Return the average as a number rounded to 1 decimal place
  };

  return (
    <div ref={dropdownRef} className="relative inline-block w-full">
      {/* Dropdown button */}
      <button
        className={`text-infromation  bg-[#FFFFFF80] w-full h-11 min-w-60 focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none focus:font-medium rounded-lg text-base py-1.5  text-center inline-flex  items-center 
          ${errors[filedName] ? "ring-[0.5px] ring-red-500" : ""} `}
        onClick={toggleDropdown}
        type="button"
      >
        <span className="grid grid-cols-2 w-full items-center px-2 truncate  justify-between">
          <p className="text-base">{title}</p>
          <p className="justify-end flex ">
            {isOpen ? <IconUp /> : <IconDown />}
          </p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-50 h-auto min-w-44 w-full max-h-44 overflow-y-auto top-full left-0  bg-white divide-gray-100  rounded-lg   ring-1 ring-gray-200">
          <ul className="py-1 text-base text-infromation cursor-pointer">
            {options?.map((item, index) => (
              <li className="" key={index}>
                <div
                  className="space-x-2 pt-2 px-2 flex items-center "
                  htmlFor={index}
                >
                  <input
                    type="checkbox"
                    className="cursor-pointer text-base -mt-2"
                    value={item.ID}
                    checked={selectOption.includes(item.ID)}
                    onChange={(e) => handleSelecteItems(item.ID, e)}
                    id={index}
                  />

                  <label htmlFor={index} className="text-base  cursor-pointer">
                    {item[dbfield]}
                    {item.Reviews ? calculateRating(item.Reviews) : ""}
                  </label>
                </div>
                {index !== options.length - 1 && <hr className="" />}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Error */}
      <div className="h-[3vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckBoxDropDown1;
