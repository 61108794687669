import React, { useEffect, useState } from "react";
import ReadInputBox from "../Elements/ReadInputBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { MdAssignmentInd } from "react-icons/md";
import AssignBoardMember from "../Corporation/Dashboard/Home/AssignBoardMember";
import { TbHandClick } from "react-icons/tb";
import Cookies from "universal-cookie";

function AssignBoardMemberDetails({ contract, load, setLoad }) {
  const cookies = new Cookies();
  const [expriedContractEndDate, setExpriedContractEndDate] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [contractId, setContractId] = useState(null);
  const [boardMembersCount, setBoardMembersCount] = useState(0);

  // console.log(contract);

  useEffect(() => {
    setLoad(true);
    if (contract.CpContractAssignedToBoardMembers !== null) {
      contract.CpContractAssignedToBoardMembers.forEach(
        (boardMember, index) => {
          setValue(
            `BoardMembers[${index}][Board_Member_Name]`,
            boardMember["BoardMember"]["Board_Member_Name"]
          );
          setValue(
            `BoardMembers[${index}][Board_Member_Phone_No]`,
            boardMember["BoardMember"]["Board_Member_Phone_No"]
          );
          setValue(
            `BoardMembers[${index}][Board_Member_Email]`,
            boardMember["BoardMember"]["Board_Member_Email"]
          );
          setValue(
            `BoardMembers[${index}][Designation]`,
            boardMember["BoardMember"]["Designation"]
          );
        }
      );
    }

    const projectEndData = new Date(contract.End_Date);
    const currentDate = new Date();
    setExpriedContractEndDate(projectEndData < currentDate);
    setContractId(contract.ID);
    setBoardMembersCount(contract.CpContractAssignedToBoardMembers.length || 0);
    setLoad(false);
  }, [contract]);

  const schema = yup.object().shape({
    BoardMembers: yup.array().of(
      yup.object().shape({
        Board_Member_Name: yup
          .string()
          .matches(/^[A-Za-z\s]+$/, "Board Member name can only contain letters."),
        Board_Member_Phone_No: yup.string(),
        Board_Member_Email: yup.string(),
        Designation: yup.string(),
      })
    ),
  });

  const { register, setValue } = useForm({ resolver: yupResolver(schema) });

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  return (
    <div className="w-full h-full  lg:w-[80%]">
      <h1 className="text-secondaryl font-extrabold text-2xl euclidbold ">
        Assign Board Member Details :
      </h1>

      <div className="pt-3">
        {load ? (
          "Loading..."
        ) : contract?.proposal?.Mannual_Approval_Proposal ? (
          <div className="text-lg text-infromation mb-3">
            This contract has been signed manualy.
          </div>
        ) : boardMembersCount > 0 ? (
          <div>
            {Array.from({ length: boardMembersCount }).map((_, index) => (
              <div key={index}>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox
                    title={"Board Member Name"}
                    register={register}
                    filedName={`BoardMembers[${index}].Board_Member_Name`}
                  />
                  <ReadInputBox
                    title={"Phone No"}
                    register={register}
                    filedName={`BoardMembers[${index}].Board_Member_Phone_No`}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox
                    title={"Board Member Email"}
                    register={register}
                    filedName={`BoardMembers[${index}].Board_Member_Email`}
                  />
                  <ReadInputBox
                    title={"Board Member Designation"}
                    register={register}
                    filedName={`BoardMembers[${index}].Designation`}
                  />
                </div>
                <div className="border-b-2 mb-4"></div>
              </div>
            ))}
          </div>
        ) : expriedContractEndDate ? (
          <div className="text-lg text-infromation mb-3">
            Project End Date has been expired.
          </div>
        ) : (
          <div className="mb-3">
            <div className="text-lg text-gray-600">
              Corporation have deleted the assigned board member details for
              this contract.
              {cookies.get("access") === "property manager" &&
                "Please assign a board member."}
            </div>

            {cookies.get("access") === "property manager" && (
              <div>
                <span
                  className="font-bold gap-1 flex text-lg w-fit items-center justify-start text-indigo-900 hover:text-indigo-950 euclidbold mt-2 cursor-pointer"
                  onClick={handleOpenModel}
                >
                  <TbHandClick /> Assign A Board Member
                </span>

                <AssignBoardMember
                  isOpen={isModelOpen}
                  onClose={handleCloseModel}
                  proposalId={contractId}
                  setLoad={setLoad}
                  load={load}
                  onlyAssignBoardMember={true}
                  contractStatus={contract.Status}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AssignBoardMemberDetails;
