export const renameKeys = (obj) => {
  const newObject = {};

  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(/^Sub_/, ""); // Remove "Sub_" prefix
    newObject[newKey] = obj[key];
  });

  return newObject;
};

export const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
