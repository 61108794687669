import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import {
  directApproveProposal,
  viewFileHandler,
} from "../../constants/Request.jsx";
import ReadInputBox from "../Elements/ReadInputBox.jsx";
import axios from "axios";
import { proposalAPI } from "../../constants/API.js";
import toast from "react-hot-toast";
import AssignBoardMember from "../Corporation/Dashboard/Home/AssignBoardMember.jsx";
import Loading from "../Elements/Loading.jsx";

function ProposalView() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const params = useParams();
  let proposalId = params.id;
  const [load, setLoad] = useState(false);
  const [proposalFileUrl, setProposalFileUrl] = useState(null);
  const [isRequest, setIsRequest] = useState(false);
  const [proposal, setproposal] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [docs, setDocs] = useState();

  // Form validation schema using yup
  const schema = yup.object().shape({
    Company_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Company name can only contain letters.")
      .required("Company Name is required"),
    Your_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Name can only contain letters.")
      .required("Your Name is required"),
    Designation: yup.string().required("Designation is required"),
    Estimate_Time: yup
      .number()
      .required("Estimate Time  is required")
      .typeError("Estimate Time  is required")
      .min(1, "Estimate time  must be greater than 0"),
    Other_Details: yup.string(),
    Attach_Proposal: yup.string().required("Proposal Upload is required"),
    token: yup.string().default(cookies.get("token")),
  });

  // Form handling using react-hook-form
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const [sampleApprovalProposalPdf, setSampleApprovalProposalPdf] =
    useState(null);

  // Already Send the Details From the before the call then optimize time permomance
  const fetchProposal = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${proposalAPI}/${proposalId}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      setIsRequest(response.data.data.CpContract.Is_Request);
      // console.log(response.data.data.CpContract.Is_Request);
      if (response.data.data.Status === 2) {
        setproposal(true);
      }
      if (response.data) {
        Object.keys(response.data.data).forEach((key) => {
          setValue(key, response.data.data[key]);
        });

        const attachment = response.data.data.Attach_Proposal;
        setSampleApprovalProposalPdf(
          response.data.data.Sample_Approval_Proposal_PDF
        );
        // Check if the attachment is an image
        if (attachment.endsWith(".docx") || attachment.endsWith(".doc")) {
          const fileurl = await viewFileHandler(attachment, "proposal");
          setDocs([{ uri: fileurl }]);
          setFileType("word");
          setProposalFileUrl(fileurl);
        } else if (attachment.endsWith(".pdf")) {
          setFileType("pdf");
          const newUrl = await viewFileHandler(`${attachment}`, "proposal");
          setProposalFileUrl(newUrl);
        } else {
          setFileType("image");
          setProposalFileUrl(attachment);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to Fetch Proposal");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchProposal();
  }, []);

  const proposalapprovalHandler = async () => {
    if (isRequest) {
      const res = await directApproveProposal(proposalId);
      if (res.status === 200) {
        toast.success("proposal approved successfully");
      }
      navigate("/corporations/dashboard/home");
    } else {
      handleOpenModel();
    }
  };

  const handleOpenModel = () => {
    setIsModelOpen(true);
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
  };

  return (
    <div>
      <Loading load={load} />

      <div className="w-full rounded-2xl px-4 m-auto  p-6  flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Proposal
        </h1>

        <div className="my-4">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-16 w-full lg:w-[80%]">
            {/* Company Name */}
            <div className="mb-3">
              <ReadInputBox
                title={"Company Name"}
                register={register}
                filedName={"Company_Name"}
              />
            </div>

            {/*Your Name  */}
            <div className="mb-3">
              <ReadInputBox
                title={"Your Name"}
                register={register}
                filedName={"Your_Name"}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-16 w-full lg:w-[80%]">
            {/* Designations */}
            <div className="mb-3">
              <ReadInputBox
                title={"Designation"}
                register={register}
                filedName={"Designation"}
                errors={errors}
              />
            </div>

            <div className="flex flex-col w-full mb-3">
              <ReadInputBox
                title="Estimate time"
                register={register}
                filedName={"Estimate_Time"}
                errors={errors}
              />
              <p className="text-end -mt-4 text-infromation text-sm">Days </p>
            </div>
          </div>

          <div className="grid grid-cols-1 w-full lg:w-[80%]">
            {/* Other Details */}
            <div className="mb-3">
              {/* Label */}
              <label
                htmlFor="otherDetails"
                className={`block font-normal text-secondaryl `}
              >
                Other Details
              </label>
              {/* Input */}
              <textarea
                type="text"
                id="otherDetails"
                name="otherDetails"
                {...register("Other_Details")}
                className={`block bg-[#FFFFFF80]  h-40 max-h-40 min-h-40 w-full rounded-sm cursor-not-allowed text-infromation   px-2 ps-10 focus:outline-none`}
                placeholder="Type Other Details"
                readOnly
                disabled
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              />
            </div>
          </div>
        </div>

        <div className="w-full h-full  lg:w-[80%]">
          <h1 className="text-secondaryl font-extrabold text-2xl euclidbold ">
            Attach Proposal :
          </h1>
          <div className="pt-3">
            {load ? (
              "Loading..."
            ) : proposalFileUrl ? (
              fileType === "pdf" ? (
                <object
                  data={proposalFileUrl}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  className="w-full h-[90vh]"
                >
                  <p>PDF could not be displayed</p>
                </object>
              ) : fileType === "word" ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                    proposalFileUrl
                  )}`}
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  title="Document Viewer"
                />
              ) : (
                <img
                  src={proposalFileUrl}
                  alt="Corporation Certificate"
                  className="w-full h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                />
              )
            ) : (
              <div className="text-infromation">No Documents Provided</div>
            )}
          </div>
        </div>

        {cookies.get("access") === "board member" ? (
          ""
        ) : (
          <div className="my-8 flex gap-2 flex-row justify-between items-center w-full lg:w-[80%]">
            <button
              onClick={() => {
                if (cookies.get("status") === 2) {
                  return toast.error("profile paused");
                } else {
                  return navigate("/corporations/dashboard/message");
                }
              }}
              className="h-11 w-fit px-2.5 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-base md:text-xl truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              MESSAGE
            </button>
            <button
              onClick={() => {
                if (cookies.get("status") === 2) {
                  return toast.error("profile paused");
                } else {
                  return proposalapprovalHandler();
                }
              }}
              disabled={proposal}
              className="h-11 w-fit px-2.5 py-2  euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-base md:text-xl truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              {!proposal ? "APPROVE" : "APPROVED"}
            </button>
          </div>
        )}
      </div>

      {/* Model */}
      <AssignBoardMember
        isOpen={isModelOpen}
        onClose={handleCloseModel}
        proposalId={proposalId}
        setLoad={setLoad}
        load={load}
        sampleApprovalProposalPdf={sampleApprovalProposalPdf}
        setSampleApprovalProposalPdf={setSampleApprovalProposalPdf}
      />
    </div>
  );
}

export default ProposalView;
