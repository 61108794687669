import { BuildingWithMan } from "../../../assets/images";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";

function ContractorLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Contractor Login with UserID"}
          requiredRegister={true}
          loginImage={BuildingWithMan}
        />
      </Layout>
    </div>
  );
}

export default ContractorLogin;
