import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ReadInputBox from "../../../Elements/ReadInputBox";
import { documentSchema } from "../../../../schema";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { viewFileHandler } from "../../../../constants/Request";
import Cookies from "universal-cookie";

const DocumentReadModal = ({ document, handleViewDocument }) => {
  const cookies = new Cookies();
  const {
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(documentSchema),
  });
  const [Section, setSection] = useState("");
  const path = useLocation().pathname;

  const [modelLoading, setModelLoading] = useState(false);
  const [Documentt, setDocument] = useState(null);
  const [DocumenttType, setDocumentType] = useState(null);

  const assignMoMData = async () => {
    if (document) {
      // console.log(document);
      reset(document);
      if (document.Document_URL?.endsWith(".pdf")) {
        const fileurl = await viewFileHandler(
          document.Document_URL,
          "corporationdocuments"
        );
        setDocumentType("pdf");
        setDocument(fileurl);
      } else {
        setDocumentType("image");
        setDocument(document.Document_URL);
      }
    } else {
      reset();
    }
  };
  // Set the form values when the modal is opened in edit mode
  useEffect(() => {
    assignMoMData();
  }, [document, reset]);

  useEffect(() => {
    if (path.includes("rules")) {
      setSection("Rules");
    } else if (path.includes("bylaws")) {
      setSection("ByLaws");
    } else if (path.includes("declaration")) {
      setSection("Declaration");
    } else {
      setSection("Budget");
    }
  }, [path]);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30`}
    >
      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl    w-[80%] h-[80%] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                {Section} Details
              </h2>
              <button
                onClick={() => handleViewDocument(false)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <form className="mb-3">
              <div className="mb-3">
                <ReadInputBox
                  title={`${Section} Name`}
                  register={register}
                  filedName={"Document_Name"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              {cookies.get("companyType") === "corporation" && (
                <div className="mb-3">
                  {/* Label */}
                  <label
                    htmlFor={"IsActive"}
                    className={`block font-normal text-secondaryl`}
                  >
                    Status <span className="text-red-500 text-base">*</span>
                  </label>
                  {/* Input */}
                  <input
                    type="text"
                    id={"IsActive"}
                    name={"IsActive"}
                    className={`block h-11 bg-[#FFFFFF80] w-full  text-infromation rounded-sm  px-2 ps-10 focus:outline-none`}
                    value={document.IsActive === true ? "Active" : "Inactive"}
                    readOnly
                    disabled
                  />
                </div>
              )}
              <div className="mb-3">
                {document && (
                  <>
                    <label
                      className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                    >
                      Uploaded {Section} Document :
                    </label>
                    <div className="pt-3">
                      {modelLoading ? (
                        "Loading..."
                      ) : Documentt ? (
                        DocumenttType === "pdf" ? (
                          <object
                            data={Documentt}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                            className="w-full h-[90vh]"
                          >
                            <p>PDF could not be displayed</p>
                          </object>
                        ) : (
                          <img
                            src={Documentt}
                            alt="MoM Document"
                            className="w-full h-[50vh] object-cover ring-[0.1px]  ring-infromation"
                          />
                        )
                      ) : (
                        <div className="text-infromation">
                          No Documents Provided
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-wrap gap-5 justify-start ">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={(e) => handleViewDocument(false)}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentReadModal;
