import React from "react";

function ReadTextArea({
  title,
  register,
  filedName,
  must,
  className,
  type = "text",
  width = "w-full",
  value,
  cursorNotAllowed = true,
}) {
  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block font-normal text-secondaryl`}
      >
        {title} {must ? <sup className="text-red-500 text-base">*</sup> : ""}
      </label>
      {/* Input */}
      <textarea
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={`block  h-11   bg-[#FFFFFF80] ${
          cursorNotAllowed ? "cursor-not-allowed" : ""
        }  text-infromation  rounded-sm   ${width}  px-2 ps-10 focus:outline-none  ${className} min-h-fit max-h-fit`}
        value={value}
        readOnly
        disabled
      />
    </div>
  );
}

export default ReadTextArea;
