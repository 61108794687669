import React from "react";

const AssignPropertyManager = ({
  isOpen,
  onClose,
  propertyManagers,
  onSelect,
  setShowModel,
}) => {
  const handleSelect = (managerId) => {
    onSelect(managerId);
    setShowModel();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
      <div className="modal-overlay fixed top-0 left-0 z-50 w-full h-full bg-black opacity-50"></div>
      <div className="modal-container bg-white w-96 p-8 rounded-lg shadow-lg z-50">
        <h2 className="text-xl font-semibold mb-4">
          Select a Property Manager
        </h2>
        <ul>
          {propertyManagers.map((manager) => (
            <li
              key={manager.ID}
              className="cursor-pointer py-2 px-4 border-b border-gray-200 hover:bg-gray-100"
              onClick={() => handleSelect(manager.ID)}
            >
              {manager.Property_Manager_Name}
            </li>
          ))}
        </ul>
        <button
          className="mt-4 px-4 py-2 bg-cyan-500 text-white font-semibold rounded hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AssignPropertyManager;
