import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import NoticeReadModal from "./NoticeReadModal";
import toast from "react-hot-toast";
import axios from "axios";
import { corporationNoticeAPI } from "../../../../../constants/API";

function NoticeCard({
  notice,
  fetchData,
  setSelectedNotice,
  setIsEditNotice,
  setShowModal,
}) {
  const cookies = new Cookies();
  const [noticeStartDate, setNoticeStartDate] = useState("");
  const [noticeEndDate, setNoticeEndDate] = useState("");
  const [viewNotice, setViewNotice] = useState({});
  const [showNoticeViewModel, setShowNoticeViewModel] = useState(false);

  useEffect(() => {
    if (notice.Notice_Start_Date && notice.Notice_End_Date) {
      setNoticeStartDate(
        new Date(notice.Notice_Start_Date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
      setNoticeEndDate(
        new Date(notice.Notice_End_Date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
    }
  }, [notice]);

  const handleDeleteNotice = async (id) => {
    try {
      const response = await axios.delete(`${corporationNoticeAPI}/${id}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      toast.success("Notice Deleted Successfully");
      fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const showNoticeHandler = (isTrue, noticeData) => {
    if (isTrue) {
      setIsEditNotice(true);
      setSelectedNotice(noticeData);
      setShowModal(true);
    } else {
      setViewNotice(noticeData);
      setShowNoticeViewModel(true);
    }
  };

  return (
    <>
      {showNoticeViewModel && viewNotice && (
        <NoticeReadModal
          notice={viewNotice}
          setShowNoticeViewModel={setShowNoticeViewModel}
        />
      )}
      {notice && (
        <div
          id="service-card"
          className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
          style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
          key={notice.ID}
        >
          {/* Card Content */}
          <div className="flex-col lg:w-[80%] justify-center leading-normal">
            <div className="space-y-1.5">
              <p className="text-secondaryl text-2xl euclidbold mb-1">
                {notice.Notice_Title}
              </p>

              {cookies.get("companyType") === "corporation" && (
                <div className="text-secondaryl flex euclidbold text-xl gap-2 items-center line-clamp-1">
                  <Icon
                    icon="solar:eye-bold"
                    className="text-secondaryl text-2xl"
                  />
                  <p className="">
                    {notice.Sharing_Status === 0
                      ? "Both"
                      : notice.Sharing_Status === 1
                      ? "Owner"
                      : notice.Sharing_Status === 2
                      ? "Tenant"
                      : notice.Sharing_Status === 3
                      ? "Specific Owner"
                      : notice.Sharing_Status === 4
                      ? "Specific Tenant"
                      : "Null"}
                  </p>
                </div>
              )}

              <div className="text-secondaryl flex font-normal text-xl gap-2 items-center line-clamp-1">
                <Icon
                  icon="bi:calendar-event-fill"
                  className="text-secondaryl ml-[0.2rem]"
                />
                <p className="text-lg text-infromation !font-normal ml-[0.2rem]">
                  {noticeStartDate} To {noticeEndDate}
                </p>
              </div>
            </div>
          </div>
          {/* Card Buttons */}

          <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
            {cookies.get("access") === "property manager" && (
              <>
                <div
                  onClick={() => showNoticeHandler(true, notice)}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Edit
                </div>
                {/* <div
                  onClick={() => {
                    const confirmation = window.confirm(
                      `Are you sure you want to delete this notice ?`
                    );
                    if (confirmation) {
                      handleDeleteNotice(notice.ID);
                    }
                  }}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Delete
                </div> */}
              </>
            )}
            <div
              onClick={() => showNoticeHandler(false, notice)}
              className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              VIEW
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NoticeCard;
