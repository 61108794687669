import { PropertyManagerLogin } from "../../../assets/images";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";

function CorporationLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Property Manager Login with UserID"}
          requiredRegister={true}
          loginImage={PropertyManagerLogin}
        />
      </Layout>
    </div>
  );
}

export default CorporationLogin;
