import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";

import Blog from "../../components/Blog/Blog";
import Cookies from "universal-cookie";

const BlogLayout = () => {
  const cookies = new Cookies();
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));

  return isLogin ? (
    <Blog />
  ) : (
    <Layout>
      <div className=" ">
        <Blog />
      </div>
    </Layout>
  );
};

export default BlogLayout;
