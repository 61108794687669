import { BoardMemberLoginImg } from "../../../assets/images";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";

function BoardMemberLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Board members Login with Email ID"}
          requiredRegister={false}
          loginFieldName={"Email Id"}
          forgotField={false}
          loginImage={BoardMemberLoginImg}
        />
      </Layout>
    </div>
  );
}

export default BoardMemberLogin;
