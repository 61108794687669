import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

function ProtectedOwners({ children }) {
  const cookies = new Cookies();
  let companyType = cookies.get("companyType");
  let token = cookies.get("token");

  if (token && companyType === "owners") {
    return <>{children}</>;
  } else {
    return <Navigate to="/"></Navigate>;
  }
}

export default ProtectedOwners;
