import React, { useState } from "react";
import {
  BuildingWithMan,
  ContractorProfileLeftImage,
} from "../../../assets/images";
import ContractorsSignupForm from "./ContractorSignupForm";
import Loading from "../../Elements/Loading";
import Test from "../../../pages/Test";

function Signup() {
  const [load, setLoad] = useState(false);

  return (
    <div className="overflow-hidden overflow-x-hidden  p-6 ">
      <Loading load={load} />
      <div className="h-full w-full flex relative ">
        {/* First div : images */}
        <div className="w-[40%] hidden md:block">
          <img
            src={BuildingWithMan}
            className="object-contain h-[60vh]"
            alt="Singup background"
          />
        </div>

        {/* Secound Div */}
        <div className="w-full md:w-[60%]">
          <ContractorsSignupForm setLoad={setLoad} />
        </div>
      </div>
    </div>
  );
}

export default Signup;
