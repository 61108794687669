import React, { useEffect, useState } from "react";

import ContractCard from "./ContractCard.jsx";
import ContractCardSkeleton from "../ContractCardSkeleton.jsx";

function DisplayContracts({ title, contracts, load, loading, containerRef }) {
  const [dynamicHeight, setDynamicHeight] = useState("60px");
  useEffect(() => {
    if (contracts.length < 2) {
      setDynamicHeight("11rem");
    } else if (contracts.length < 3) {
      setDynamicHeight("22rem");
    } else if (contracts.length < 4) {
      setDynamicHeight("33rem");
    } else if (contracts.length < 5) {
      setDynamicHeight("44rem");
    } else {
      setDynamicHeight("100%");
    }
  }, [contracts]);
  return (
    <div>
      <div
        ref={containerRef}
        id="infinite-scroll"
        style={{ height: dynamicHeight }}
        className="contracts_listing"
      >
        {load ? (
          <ContractCardSkeleton />
        ) : contracts?.length !== 0 ? (
          contracts.map((contract, index) => (
            <ContractCard key={index} contract={contract} />
          ))
        ) : (
          !loading && (
            <div className="text-center text-2xl text-infromation mt-10">
              {title} are not available
            </div>
          )
        )}
        {/* Loading for infinite scroll */}
        {loading && <div className="size-14 mx-auto spin-loader my-16"></div>}
      </div>
    </div>
  );
}

export default DisplayContracts;
