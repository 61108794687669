import React, { useState } from "react";
import LoginPageLeft from "../../assets/images/BuildingWithMan.png";
import LoginForm from "./LoginForm";
import "./Login.css";
import { useSelector } from "react-redux";
import Loading from "../Elements/Loading";

function Login({
  loginRoleText,
  requiredRegister = true,
  loginFieldName = "User Id",
  forgotField = true,
  loginImage, // This is a default value
}) {
  const loading = useSelector((state) => state.auth.loading);
  const [load, setLoad] = useState(false);

  return (
    <div className="flex relative login_section">
      <Loading load={loading} />
      <Loading load={load} />
      {/* First div : images */}
      <div className="relative w-[50%] hidden md:block ">
        <img
          src={loginImage}
          className="w-full -bottom-10 h-full absolute object-contain "
          alt="Login Page Left"
        />
      </div>
      {/* Second div : Login form */}

      <div className="w-full md:w-[50%] h-[86vh]">
        <LoginForm
          loginRoleText={loginRoleText}
          requiredRegister={requiredRegister}
          loginFieldName={loginFieldName}
          forgotField={forgotField}
          setLoad={setLoad}
        />
      </div>
    </div>
  );
}

export default Login;
