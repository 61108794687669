import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../../assets/images";

const DropDown = ({ title, options, onSelect, selectOption, isClearable }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (option) => {
    onSelect(option);
    toggleDropdown(); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {/* Dropdown button */}

      <button
        className={`text-infromation bg-[#FFFFFF80] w-full h-11 focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none focus:font-medium rounded-sm text-base px-auto py-1 text-center inline-flex items-center 
       `}
        onClick={toggleDropdown}
        type="button"
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        <span className="grid grid-cols-2 w-full items-center px-2 truncate  justify-between">
          <p className="text-infromation">
            {selectOption ? selectOption.label : title}
          </p>
          <p className="justify-end flex ">
            {isOpen ? <IconUp /> : <IconDown />}
          </p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        // <div className="absolute z-50 top-full left-0 shadow-xl bg-white divide-y divide-gray-100 rounded-sm   sm:w-44 md:w-48">
        <div className="flex ">
          {isClearable && (
            <button
              onClick={() => handleSelectOption(null)}
              className="font-medium absolute text-secondaryl text-xs ml-auto focus:outline-none w-fit justify-self-end mt-1"
            >
              Clear fillter
            </button>
          )}
          <div
            className={`absolute z-50 ${
              isClearable ? "my-6" : "my-2"
            } max-h-44 overflow-auto top-full left-0  bg-white  rounded-lg min-w-40 w-full text-infromation ring-1 ring-gray-200 `}
          >
            <ul className="py-1 text-base text-infromation cursor-pointer">
              {options.map((item, index) => (
                <li key={index} onClick={() => handleSelectOption(item)}>
                  <div className="block px-4 py-2">{item.label}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
