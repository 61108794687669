import axios from "axios";
import React, { useEffect, useState } from "react";
import { ownerAPI, tenantAPI } from "../../../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../components/Elements/Loading";
import ContractCardSkeleton from "../../../../components/Contracts/ContractCardSkeleton";
import OwnersTenantCard from "../../../../components/Corporation/Dashboard/Resident/OwnersTenantCard";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReadInputBox1 from "../../../../components/Elements/ReadInputBox1";
import { viewFileHandler } from "../../../../constants/Request";

function ManageOwnersTenant() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [isOwner, setIsOwner] = useState(true);

  const [load, setLoad] = useState(true);

  const [data, setData] = useState([]);
  const [selectStatusType, setSelectStatusType] = useState("All");
  const [filterData, setFilterData] = useState([...data]);
  const [requestData, setRequestData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [rejectData, setRejectData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [user, setUser] = useState({});
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [rejectionId, setRejectionId] = useState("");
  const [modelLoading, setModelLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [document1Url, setDocument1Url] = useState(null);
  const [document2Url, setDocument2Url] = useState(null);
  const [document1Type, setDocument1Type] = useState(null);
  const [document2Type, setDocument2Type] = useState(null);

  const handleSelectStatusType = (status, selectedStatusData) => {
    setSelectStatusType(status);
    setFilterData(selectedStatusData);
  };

  const location = useLocation();
  // console.log(location);

  const fetchOwnersRequests = async () => {
    try {
      const response = await axios.get(ownerAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      // console.log(response.data.data);
      setData(response.data.data);
      setFilterData(response.data.data);
      setApproveData(response.data.data.filter((user) => user.Status === 1));
      setRequestData(response.data.data.filter((user) => user.Status === 0));
      setRejectData(response.data.data.filter((user) => user.Status === 2));
      setSelectStatusType("All");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const fetchTanentsRequests = async () => {
    try {
      const response = await axios.get(tenantAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      // console.log(response.data);
      setData(response.data.data);
      setFilterData(response.data.data);
      setApproveData(response.data.data.filter((user) => user.Status === 1));
      setRequestData(response.data.data.filter((user) => user.Status === 0));
      setRejectData(response.data.data.filter((user) => user.Status === 2));
      setSelectStatusType("All");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoad(true);
    try {
      if (location.pathname.includes("owners")) {
        setIsOwner(true);
        await fetchOwnersRequests();
      } else {
        // console.log("Tenant");
        setIsOwner(false);
        await fetchTanentsRequests();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  const schema = yup.object().shape({
    First_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "First name can only contain letters."),
    Last_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Last name can only contain letters."),
    Email: yup.string(),
    Phone_No: yup.string(),
    Date_Of_Birth: yup.string(),
    Residential_Address: yup.string(),
    Parking: yup.string(),
    Locker: yup.string(),
    Id_Proof_Document: yup.string(),

    Property_Type: yup.string(),
    Proof_Of_Ownership: yup.string(),
    Rejection_Reason: yup.string(),
    Self_Register: yup.string(),
  });

  const { register, setValue } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    fetchData();
  }, [location]);

  const showUserHandler = async (user) => {
    setUser(user);
    setShowUser(!showUser);
    Object.keys(user).forEach((key) => {
      setValue(key, user[key]);
    });

    // console.log("User", user);

    if (user["Id_Proof_Document"]) {
      if (user["Id_Proof_Document"]?.endsWith("pdf")) {
        setDocument1Type("pdf");
        const newUrl = await viewFileHandler(
          user["Id_Proof_Document"],
          isOwner ? "owners" : "tenant"
        );
        setDocument1Url(newUrl);
      } else if (
        user["Id_Proof_Document"]?.endsWith("jpeg") ||
        user["Id_Proof_Document"]?.endsWith("jpg") ||
        user["Id_Proof_Document"]?.endsWith("png")
      ) {
        const newUrl = await viewFileHandler(
          user["Id_Proof_Document"],
          isOwner ? "owners" : "tenant"
        );
        setDocument1Type("image");
        setDocument1Url(newUrl);
      }
    }

    const docs2 = isOwner
      ? user["Proof_Of_Ownership"]
      : user["Lease_Agreement_Document"];

    if (docs2) {
      if (docs2.endsWith("pdf")) {
        setDocument2Type("pdf");

        const newUrl = await viewFileHandler(
          docs2,
          isOwner ? "owners" : "tenant"
        );
        setDocument2Url(newUrl);
      } else if (
        docs2.endsWith("jpeg") ||
        docs2.endsWith("jpg") ||
        docs2.endsWith("png")
      ) {
        const newUrl = await viewFileHandler(
          docs2,
          isOwner ? "owners" : "tenant"
        );
        setDocument2Type("image");
        setDocument2Url(newUrl);
      }
    }
  };

  const showRejectionModalHandler = () => {
    setShowRejectionModal(!showRejectionModal);
  };

  const updateOwners = async (ID, status) => {
    // console.log("updateOwners", ID, status);
    // console.log("Rejection Reason", rejectionReason);

    let data;
    if (status === 2) {
      data = {
        Status: status,
        Rejection_Reason: rejectionReason,
      };
      setModelLoading(true);
    } else {
      data = {
        Status: status,
      };
      setPageLoading(true);
    }

    try {
      await axios.put(`${ownerAPI}/${ID}`, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      if (status === 2) {
        setRejectionReason(null);
        setShowRejectionModal(false);
      }

      fetchOwnersRequests();
      toast.success("Owner Status Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setModelLoading(false);
      setPageLoading(false);
    }
  };

  const deleteOwners = async (ID) => {
    setPageLoading(true);
    try {
      await axios.delete(`${ownerAPI}/${ID}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      const newData = data.filter((doc) => doc.ID !== ID);
      setData(newData);
      fetchOwnersRequests();
      toast.success("Owner Deleted Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setPageLoading(false);
    }
  };

  const deleteTanents = async (ID) => {
    setPageLoading(true);
    try {
      await axios.delete(`${tenantAPI}/${ID}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      const newData = data.filter((doc) => doc.ID !== ID);
      setData(newData);
      fetchTanentsRequests();
      toast.success("Tenant Deleted Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setPageLoading(false);
    }
  };

  const updateTanents = async (ID, status) => {
    let data;
    if (status === 2) {
      data = {
        Status: status,
        Rejection_Reason: rejectionReason,
      };
      setModelLoading(true);
    } else {
      data = {
        Status: status,
      };
      setPageLoading(true);
    }
    try {
      await axios.put(`${tenantAPI}/${ID}`, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      if (status === 2) {
        setRejectionReason(null);
        setShowRejectionModal(false);
      }

      fetchTanentsRequests();

      toast.success("Tenant Status Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setModelLoading(false);
      setPageLoading(false);
    }
  };

  return (
    <div>
      <Loading load={pageLoading} />
      <div className="w-full  rounded-2xl px-4 m-auto p-6  flex flex-col justify-center">
        <div className="flex justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            {isOwner ? "Owners" : "Tenants"}
          </h1>

          {cookies.get("access") === "property manager" && (
            <div
              className="h-11 w-fit px-2.5 py-1.5 items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center bg-secondaryl text-[#ffffff] text-sm truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2"
              onClick={() => {
                isOwner
                  ? navigate("/corporations/dashboard/owners/createowners")
                  : navigate("/corporations/dashboard/tenant/createtenant");
              }}
            >
              <Icon
                icon="fluent-mdl2:calculator-addition"
                className="font-bold"
              />{" "}
              Add {isOwner ? " Owner" : " Tenant"}
            </div>
          )}
        </div>

        <div className="flex flex-wrap gap-5 justify-between items-center py-3">
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "All"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => {
              handleSelectStatusType("All", data);
            }}
          >
            All
          </div>
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "Request"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Request", requestData)}
          >
            Request
          </div>
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "Approve"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Approve", approveData)}
          >
            Approve
          </div>
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "Reject"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Reject", rejectData)}
          >
            Reject
          </div>
        </div>

        <div className="contracts_listing">
          {load ? (
            <ContractCardSkeleton />
          ) : filterData.length !== 0 ? (
            filterData.map((userData, index) => (
              <OwnersTenantCard
                key={index}
                user={userData}
                showUserHandler={showUserHandler}
                setRejectionId={setRejectionId}
                setShowRejectionModal={setShowRejectionModal}
                isOwner={isOwner}
                updateOwners={updateOwners}
                updateTanents={updateTanents}
                deleteOwners={deleteOwners}
                deleteTanents={deleteTanents}
              />
            ))
          ) : (
            <div className="text-center text-2xl text-infromation mt-10">
              {/* {title}  */}
              {isOwner ? "Owners" : "Tenant"} {selectStatusType} are not
              available
            </div>
          )}
        </div>
      </div>

      {showUser && (
        <div
          className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
      `}
        >
          <Loading load={modelLoading} />
          <style>{`body { overflow: hidden; }`}</style>

          <div className="flex justify-center items-center h-full ">
            <div className="bg-white p-8 rounded-lg w-[80%] h-[80%] overflow-auto relative">
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
                  {isOwner ? "Owner" : "Tenant"} Details
                </h2>
                <button
                  onClick={() => setShowUser(false)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  ✖
                </button>
              </div>

              <div className="mb-3">
                <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-3 gap-2  flex">
                  <Icon icon="material-symbols:person" />
                  Personal information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox1
                    title={"First Name"}
                    register={register}
                    filedName={`First_Name`}
                    cursorNotAllowed={false}
                  />
                  <ReadInputBox1
                    title={"Last Name"}
                    register={register}
                    filedName={`Last_Name`}
                    cursorNotAllowed={false}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox1
                    title={"Email"}
                    register={register}
                    filedName={`Email`}
                    cursorNotAllowed={false}
                  />
                  <ReadInputBox1
                    title={"Phone No"}
                    register={register}
                    filedName={`Phone_No`}
                    cursorNotAllowed={false}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox1
                    title={"Date Of Birth"}
                    register={register}
                    filedName={`Date_Of_Birth`}
                    cursorNotAllowed={false}
                  />
                </div>

                <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-3  gap-2  flex">
                  <Icon icon="fluent:mountain-location-bottom-24-filled" />
                  Address information
                </h2>

                {/* Property Address  */}
                <div className="grid grid-cols-1 w-full ">
                  <ReadInputBox1
                    title={"Property Address"}
                    register={register}
                    filedName={`Residential_Address`}
                    cursorNotAllowed={false}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                  <ReadInputBox1
                    title={"Parking"}
                    register={register}
                    filedName={`Parking`}
                    cursorNotAllowed={false}
                  />
                  <ReadInputBox1
                    title={"Locker"}
                    register={register}
                    filedName={`Locker`}
                    cursorNotAllowed={false}
                  />
                </div>

                <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-3  gap-2  flex">
                  <Icon icon="fluent:person-home-32-filled" />
                  Property Details
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
                  <ReadInputBox1
                    title={"Property Type"}
                    register={register}
                    filedName={"Property_Type"}
                    cursorNotAllowed={false}
                  />
                </div>

                <div className="w-full h-full">
                  <h2 className="text-secondaryl font-extrabold text-xl euclidbold  my-3 ">
                    {isOwner
                      ? "Proof Of Ownership : "
                      : "Lease_Agreement_Document : "}
                  </h2>

                  <div className="pt-3">
                    {load ? (
                      "Loading..."
                    ) : document2Url ? (
                      document2Type === "pdf" ? (
                        <object
                          data={document2Url}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                          className="w-full h-[90vh]"
                        >
                          <p>PDF could not be displayed</p>
                        </object>
                      ) : (
                        <img
                          src={document2Url}
                          alt="Property Document"
                          className="w-full h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                        />
                      )
                    ) : (
                      <div className="text-infromation">
                        No Documents Provided
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full h-full">
                  <h2 className="text-secondaryl font-extrabold text-xl euclidbold  items-center justify-start my-3 gap-2  flex">
                    <Icon icon="heroicons:identification-solid" />
                    Identification Document
                  </h2>
                  <div className="p">
                    {load ? (
                      "Loading..."
                    ) : document1Url ? (
                      document1Type === "pdf" ? (
                        <object
                          data={document1Url}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                          className="w-full h-[90vh]"
                        >
                          <p>PDF could not be displayed</p>
                        </object>
                      ) : (
                        <img
                          src={document1Url}
                          alt="Identification Document"
                          className="w-full h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                        />
                      )
                    ) : (
                      <div className="text-infromation">
                        No Documents Provided
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center space-x-2">
                <button
                  onClick={() => setShowUser(false)}
                  className="bg-secondaryl text-white px-4 py-2 rounded hover:bg-opacity-90"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showRejectionModal && (
        <div
          className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
      `}
        >
          <Loading load={modelLoading} />
          <style>{`body { overflow: hidden; }`}</style>

          <div className="flex h-full justify-center items-center">
            {" "}
            <div className="bg-white p-6 md:p-10 rounded-lg top-[25%] w-[80%] md:w-[60%] lg:w-[75%] ">
              <div className="mb-4">
                <h3 className="text-2xl euclidbold text-secondaryl pb-3">
                  Rejection_Reason
                </h3>

                <textarea
                  type="text"
                  id="rejectionReason"
                  name="rejectionReason"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  className={`bg-transparent block h-28 max-h-40 w-full rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-secondaryl p-2 focus:outline-none focus:border-rose-600 `}
                  placeholder="Type Rejection reason here"
                />
              </div>

              <div className="flex justify-end space-x-4">
                <div
                  onClick={() => {
                    setRejectionId("");
                    setShowRejectionModal(false);
                  }}
                  className=" text-secondaryl px-4 py-2 border-[1px] border-secondaryl rounded  cursor-pointer"
                >
                  Close
                </div>

                <div
                  onClick={() =>
                    isOwner
                      ? updateOwners(rejectionId, 2)
                      : updateTanents(rejectionId, 2)
                  }
                  className="bg-secondaryl  px-4 py-2 border-[1px] border-secondaryl rounded text-white cursor-pointer"
                >
                  Reject
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageOwnersTenant;

/*  */
