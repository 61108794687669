// import React from "react";

// function LogoutIcon({ color = "#3E5CAA" }) {
//   return (
//     <div>
//       <svg
//         width="22"
//         height="22"
//         viewBox="0 0 22 22"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1Z"
//           stroke={color}
//           stroke-width="1.5"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//         />
//         <path
//           d="M6.99976 11H14.9998H6.99976ZM6.99976 11C6.99976 11.84 8.99376 13.4096 9.49976 14L6.99976 11ZM6.99976 11C6.99976 10.16 8.99376 8.5904 9.49976 8L6.99976 11Z"
//           fill={color}
//           fill-opacity="0.1"
//         />
//         <path
//           d="M6.99976 11H14.9998M6.99976 11C6.99976 11.84 8.99376 13.4096 9.49976 14M6.99976 11C6.99976 10.16 8.99376 8.5904 9.49976 8"
//           stroke={color}
//           stroke-width="1.5"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//         />
//       </svg>
//     </div>
//   );
// }

// export default LogoutIcon;

import React from "react";

function LogoutIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99976 11H14.9998H6.99976ZM6.99976 11C6.99976 11.84 8.99376 13.4096 9.49976 14L6.99976 11ZM6.99976 11C6.99976 10.16 8.99376 8.5904 9.49976 8L6.99976 11Z"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        d="M6.99976 11H14.9998M6.99976 11C6.99976 11.84 8.99376 13.4096 9.49976 14M6.99976 11C6.99976 10.16 8.99376 8.5904 9.49976 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon;
