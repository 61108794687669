import React from "react";
import ClientReviewCard from "./ClientReviewCard";
import Skeleton from "react-loading-skeleton";
import ClientReviewCardSkeleton from "./ClientReviewCardSkeleton";

function ClientReview({ data, load }) {
  return (
    <div className="lg:w-4/6 bg-primarybg rounded py-3 px-2 ">
      {load ? (
        <ClientReviewCardSkeleton cards={3} />
      ) : data.length > 0 ? (
        <div className="space-y-3">
          {data.map((item, index) => (
            <ClientReviewCard data={item} key={index} />
          ))}
        </div>
      ) : (
        <label className="text-center w-full my-3 text-infromation">
          No Reviews Available at the moment
        </label>
      )}
    </div>
  );
}

export default ClientReview;
