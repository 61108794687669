import Reviews from "../../../../components/Contractor/Reviews/Reviews";

function ContractorReviews() {
  return (
    <div>
      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Client Reviews
        </h1>
        <Reviews />
      </div>
    </div>
  );
}

export default ContractorReviews;
