import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

function ProtectedAccessPropertyManager({ children }) {
  const cookies = new Cookies();
  let access = cookies.get("access");
  let token = cookies.get("token");

  if (token && access === "property manager") {
    return <>{children}</>;
  } else if (token && access === "board member") {
    return <Navigate to="/corporations/dashboard"></Navigate>;
  } else {
    return <Navigate to="/"></Navigate>;
  }
}

export default ProtectedAccessPropertyManager;
