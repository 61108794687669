import React from "react";

function IconDropDownDown({color="#313144"}) {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 23 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04575 0.830089C2.42366 0.208004 1.41507 0.208004 0.79298 0.830089C0.170895 1.45217 0.170895 2.46077 0.79298 3.08286L10.3507 12.6406C10.9728 13.2626 11.9814 13.2626 12.6034 12.6406L22.1611 3.08286C22.7832 2.46077 22.7832 1.45217 22.1611 0.830089C21.539 0.208004 20.5304 0.208004 19.9084 0.830089L11.4771 9.2614L3.04575 0.830089Z"
        fill={color}
      />
    </svg>
  );
}

export default IconDropDownDown;
