import React from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";

function MoMCard({ mom, onView, onEdit }) {
  // console.log(mom);
  const cookies = new Cookies();
  return (
    <div
      className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      <div className="flex-col lg:w-[80%] justify-center leading-normal">
        <div className="space-y-1.5">
          {/* Title */}
          <div className="text-secondaryl euclidbold text-2xl break-words">
            {mom.Meeting_Topic}
          </div>
          {/* Date */}
          <div className="text-base flex gap-1 items-center">
            <Icon icon="uis:calender" className="text-secondaryl text-lg" />
            <p className="text-infromation break-words">{mom.Meeting_Date}</p>
          </div>
          <div className="text-base flex gap-1 items-center">
            <Icon
              icon="mage:dashboard-3-fill"
              className="text-secondaryl text-lg"
            />
            <p className="text-infromation break-words line-clamp-3">
              {mom.Meeting_Details}
            </p>
          </div>

          <div className="text-base flex gap-1 items-center">
            <Icon
              icon="streamline:group-mom-call-solid"
              className="text-secondaryl text-lg"
            />

            <p className="text-infromation hover:text-gray-700 line-clamp-2 break-all">
              {mom.Meeting_Outcome}
            </p>
          </div>
        </div>
      </div>
      {/* Card Buttons */}

      <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
        {cookies.get("access") === "property manager" && (
          <button
            className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            onClick={onEdit}
          >
            Edit
          </button>
        )}

        <button
          className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          onClick={onView}
        >
          VIEW
        </button>
      </div>
    </div>
  );
}

export default MoMCard;
