import React, { useState } from "react";

import AboutConent from "./AboutConent";
import ContactForm from "../Contact/ContactForm";
import Layout from "../../components/Layout/Layout";
import Cookies from "universal-cookie";

function About() {
  const cookies = new Cookies();
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));
  return isLogin ? (
    <AboutConent />
  ) : (
    <div className="overflow-hidden ">
      <Layout>
        <div className="py-4 md:py-16 px-[2.5%] md:px-[8%]">
          <AboutConent />
        </div>
        {/* <ContactForm /> */}
      </Layout>
    </div>
  );
}
export default About;
