import React from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

import { Icon } from "@iconify/react";
function Hero() {
  const navigate = useNavigate();

  return (
    <div className="w-full  h-[89vh] relative landing_page ">
      <div className="absolute left-1/2 -translate-x-1/2 md:left-auto md:translate-x-0 md:right-[4%] top-auto bottom-[45%] md:bottom-[40%] lg:top-8 lg:bottom-auto border-dashed border-[2px] border-spacing-10 border-white p-1 rounded-xl w-[90%] md:w-[40%] lg:w-[30%]">
        <div className=" flex  bg-white text-secondaryl  euclidbold  text-base md:text-xl  tracking-wide items-center text-center p-2 md:pl-4 md:pr-2 md:py-1 gap-2 md:gap-5 rounded-xl justify-center ">
          <span>
            <Icon icon="gridicons:chat" className="text-2xl md:text-7xl" />{" "}
          </span>{" "}
          Transparent Communication
        </div>
      </div>

      <div className="absolute left-1/2 -translate-x-1/2 md:right-auto md:translate-x-0 md:left-[4%] lg:left-[7%] bottom-[38%] md:bottom-[40%] border-dashed border-[2px] border-spacing-10 border-white p-1 rounded-xl w-[90%] md:w-[40%] lg:w-[20%]">
        <div className=" flex  bg-white text-primaryl  euclidbold  text-base md:text-xl  tracking-wide items-center text-center p-2 md:pl-4 md:pr-2 md:py-1 gap-2 md:gap-5 rounded-xl justify-center ">
          <span>
            <Icon icon="ri:team-fill" className="text-2xl md:text-6xl" />
          </span>
          Expert Team
        </div>
      </div>

      <div className="flex flex-col items-center py-8 md:py-16 space-y-2 md:space-y-4 w-full">
        <h3 className="text-white text-center text-xl md:text-3xl lg:text-4xl  font-semibold w-[85%] md:w-[60%] lg:w-[33%]  tracking-wide">
          We Eliminate Property Management Issues And Hassles
        </h3>
        <p className="text-white text-base md:text-lg text-center font-normal w-[90%] md:w-[75%] lg:w-[44%] capitalize">
          At Condominium Portal Inc., we specialize in eliminating property
          management issues and hassles, ensuring a smooth and stress-free
          experience for property owners. Our comprehensive services address all
          aspects of property management, from maintenance and tenant relations
          to financial oversight and legal compliance. With our expertise and
          dedication, you can enjoy peace of mind knowing your property is in
          capable hands
        </p>
      </div>
    </div>
  );
}

export default Hero;
