import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VerifyBoardMemberImg } from "../../../assets/images";
import axios from "axios";
import { securityMember } from "../../../constants/API";
import toast from "react-hot-toast";
import InputBoxLive from "../../../components/Elements/InputBoxLive";
import InputBox from "../../../components/Elements/InputBox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../components/Elements/Loading";
import Layout from "../../../components/Layout/Layout";
import ReadInputBox1 from "../../../components/Elements/ReadInputBox1";
import { securityMemberSchema } from "../../../schema";

const VerifySecurityMember = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  if (!queryParams.get("token")) {
    navigate("/");
  }
  const token = queryParams.get("token");
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [boardMemberNotFound, setBoardMemberNotFound] = useState(false);
  const [findError, setFindError] = useState(false);

  const [load, setLoad] = useState(false);

  const {
    register,
    formState: { errors },
    setValue,
    setError,
    reset,
    handleSubmit,
  } = useForm({ resolver: yupResolver(securityMemberSchema) });

  const getBoardMembersData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${securityMember}/verify`, {
        headers: {
          authorization: token,
          companyType: "securitymember",
        },
      });
      const data = response.data.data;

      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      setAlreadyVerified(false);
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log("error", error.response.data.message);

      if (error.response.data.data === false) {
        setBoardMemberNotFound(true);
      }
      setFindError(true);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getBoardMembersData();
  }, []);

  const handleSaveBoardMembers = async (data) => {
    const formdata = new FormData();
    Object.keys(data).forEach((key) => {
      formdata.append(key, data[key]);
    });

    formdata.keys().forEach((key) => {
      console.log(key, formdata.get(key));
    });

    try {
      setLoad(true);
      await axios.put(`${securityMember}/verify`, formdata, {
        headers: {
          authorization: token,
          companyType: "securitymember",
        },
      });
      toast.success("Security Member verified successfully");
      reset();
      navigate("/corporations/securitymember/login");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  return (
    // ContractorProfileLeftImage
    <Layout>
      <Loading load={load} />
      <div className="w-full  px-3">
        <div className="w-full flex  gap-10 min-h-[85vh]">
          <div className="relative w-[40%] hidden md:block  ">
            <img
              src={VerifyBoardMemberImg}
              className="w-full h-full  absolute object-contain "
              alt="Verify Security Member"
            />
          </div>

          <div className="w-full md:w-[60%]">
            <div className="mx-auto">
              <h1 className="text-secondaryl mt-1 mb-3 font-extrabold text-4xl euclidbold flex justify-center  md:justify-start  ">
                Verify Security Member
              </h1>

              {!load &&
                !alreadyVerified &&
                !findError &&
                !boardMemberNotFound && (
                  <form onSubmit={handleSubmit(handleSaveBoardMembers)}>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
                      <InputBox
                        title={"Security_Member_Name"}
                        register={register}
                        filedName={"Security_Member_Name"}
                        must={true}
                        errors={errors}
                        className={"md:text-sm lg:text-base"}
                      />

                      <InputBoxLive
                        title={"Security_Member_Phone_No"}
                        register={register}
                        filedName={"Security_Member_Phone_No"}
                        must={true}
                        type="tel"
                        errors={errors}
                        className={"md:text-sm lg:text-base"}
                        maxLength="10"
                        schema={securityMemberSchema}
                        setError={setError}
                      />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16">
                      <ReadInputBox1
                        title={"Security_Member_Email"}
                        register={register}
                        filedName={"Security_Member_Email"}
                        must={true}
                        errors={errors}
                        className={"md:text-sm lg:text-base"}
                      />
                    </div>

                    <div className="mb-3">
                      <div className="flex gap-3 items-center">
                        <input
                          type="checkbox"
                          id="Term_And_Policy"
                          name="Term_And_Policy"
                          {...register("Term_And_Policy")}
                          className="bg-transparent  text-black min-h-7 min-w-7 accent-secondaryl"
                        />
                        <label htmlFor="Term_And_Policy" className="pt-2">
                          I agree to all the{" "}
                          <Link
                            href="/"
                            className="text-blue-500 hover:text-blue-600"
                          >
                            Terms
                          </Link>{" "}
                          and
                          <Link
                            href="/"
                            className="text-blue-500 hover:text-blue-600"
                          >
                            {" "}
                            Privacy policy
                          </Link>
                        </label>
                      </div>
                      <div className="h-[2vh]">
                        {errors.Term_And_Policy && (
                          <p className="text-red-500 text-xs  leading-none">
                            {errors.Term_And_Policy.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className=" mb-3">
                      <button
                        type="submit"
                        className={`h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl hover:ring-secondaryl   tracking-widest hover:bg-secondaryl   hover:text-white transition duration-300 ease-in-out `}
                      >
                        SAVE
                      </button>
                    </div>
                  </form>
                )}

              {!load && alreadyVerified && (
                <div className="mt-10">
                  <h1 className="text-xl text-infromation">
                    Security Member already verified successfully !
                  </h1>
                  <Link to="/corporations/boardmember/login">
                    <span className="text-secondaryl hover:text-opacity-95">
                      Login Click Here
                    </span>
                  </Link>
                </div>
              )}

              {findError && boardMemberNotFound && (
                <div className="mt-10">
                  <h1 className="text-xl text-secondaryl">
                    Security Member not found!
                  </h1>
                </div>
              )}

              {findError && !boardMemberNotFound && (
                <div className="mt-10">
                  <h1 className="text-xl text-secondaryl">
                    Something went wrong! Please try again.
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VerifySecurityMember;
