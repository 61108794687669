import React from "react";

function InputBox({
  title,
  register,
  filedName,
  must,
  errors,
  placeHolder = "",
  className,
  type = "text",
  width = "w-full",
  min,
  max,
  maxLength = "",
  schema,
  requiredError = true,
}) {
  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block font-normal text-secondaryl 
  }`}
      >
        <p>
          {title}{" "}
          {must ? <span className="text-red-500 text-base">*</span> : ""}
        </p>
      </label>
      {/* Input */}
      <input
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={`block h-11 ${width} rounded-sm text-black px-2 ps-10 bg-[#FFFFFF80] focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none  ${
          errors[filedName] ? "ring-[0.5px] ring-red-500" : ""
        } ${className}`}
        {...(placeHolder
          ? { placeholder: placeHolder }
          : { placeholder: `Type ${title}` })}
        min={min}
        max={max}
        maxLength={maxLength}
        // style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      />

      {/* Error */}
      {requiredError && (
        <div className="h-[2vh] pt-1">
          {errors[filedName] && (
            <p className="text-red-500 text-xs leading-none">
              {errors[filedName]?.message}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default InputBox;
