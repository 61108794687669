import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import uimg from "../../assets/image/user.png";
import "../../styles.css";
import { InputFileIcon } from "../../assets/images";
import Cookies from "universal-cookie";
import { fetchChats, fetchCorporationMessages } from "../../constants/Request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LogoWithoutText } from "../../assets";
import toast from "react-hot-toast";
import io from "socket.io-client";
import { baseUrl, proposalFileAPI } from "../../constants/API";
import axios from "axios";

const socket = io(baseUrl);

function Messages() {
  const navigate = useNavigate();
  const location = useLocation();
  // Parse query parameters using URLSearchParams
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id"); // Get the 'id' query parameter
  const [activeTab, setActiveTab] = useState("inbox");
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const cookies = new Cookies();
  let token = cookies.get("token");
  const messageInput = useRef(null);

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];

  const [proposalFile, setProposalFile] = useState(null);
  const [proposalFileError, setProposalFileError] = useState("");
  const [proposalFileUploded, setProposalFileUploded] = useState("");
  const [proposalFileUrl, setProposalFileUrl] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleChangeProposalFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setProposalFile(null);
      setProposalFileError("Please Attach a Proposal");
      setProposalFileUploded("");
      setProposalFileUrl("");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setProposalFile(file);
        setProposalFileError("");
        setProposalFileUploded("");
        setProposalFileUrl("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setProposalFile(null);
        setProposalFileError(
          "Unsupported file type file type must be jpg , jpeg, png, pdf"
        );
        setProposalFileUploded("");
        setProposalFileUrl("");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setProposalFile(null);
        setProposalFileError("File size exceeds limit (5 MB)");
        setProposalFileUploded("");
        setProposalFileUrl("");
      } else {
        setProposalFile(null);
        setProposalFileError("Something went wrong please try again later");
        setProposalFileUploded("");
        setProposalFileUrl("");
      }
    }
  };

  // Handle Proposal File Upload API
  const handleUploadProposalFile = async () => {
    if (!proposalFile) {
      if (!proposalFileError) {
        setProposalFileError("Please Attach a Proposal");
        setProposalFileUploded("");
      }
      return;
    }

    const formData = new FormData();
    formData.append("file", proposalFile);

    try {
      setProposalFileError("");
      setUploadingFile(true);
      const response = await axios.post(proposalFileAPI, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "contractor",
        },
      });
      setProposalFileError("");

      setProposalFileUrl(response.data.data.url);
      setUploadingFile(false);
      setProposalFileUploded("Uploaded Successfully");
    } catch (error) {
      setUploadingFile(false);
      console.error("Error uploading Proposal :", error);
      setProposalFileError("Failed to upload Proposal");
      setProposalFileUrl("");
      setProposalFileUploded("");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSelectedUser(null);
  };

  const handleUserClick = async (user) => {
    try {
      const res = await fetchChats(user.ID);
      // console.log(res);
      setMessages(res.data);
    } catch (error) {
      toast.error("Error fetching messages");
    } finally {
      setSelectedUser(user);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${formattedHours}:${formattedMinutes} ${meridiem}`;
    return [`${day} ${monthNames[monthIndex]} ${year}`, time];
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const res = await fetchCorporationMessages(token);
        const formattedUsers = res.map((user) => ({
          ...user,
          createdAt: formatDate(user.contract.CreatedDate),
        }));
        console.log(formattedUsers);
        setUsers(formattedUsers);
      } catch (error) {
        toast.error("failed to get users");
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, [token]);

  useEffect(() => {
    socket.on("receiveMessage", (data) => {
      console.log(data);
      if (selectedUser && data.chat.Proposal_ID === selectedUser.ID) {
        setMessages((prevMessages) => [...prevMessages, data.chat]);
      }
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, [selectedUser]);

  const sendMessage = () => {
    const content = messageInput.current.value;
    if (content.trim() && selectedUser) {
      const message = {
        Proposal_ID: selectedUser.ID,
        Sender: "corporation",
        Content: content,
      };
      messageInput.current.value = "";
      socket.emit("createMessage", message, (response) => {
        messageInput.current.value = "";
      });
    }
  };

  useEffect(() => {
    if (id !== "" && id !== null && id !== undefined) {
      const user = users.find((user) => user.ID == id);
      // console.log(user);
      if (user) {
        handleUserClick(user);
      }
    }
  }, [id, users]);

  return (
    <div className="min-h-[60vh]">
      {loading && (
        <div className="absolute z-50 min-h-full flex items-center justify-center h-full w-full bg-black opacity-55">
          <div className="loginLoader"></div>
          <img
            src={LogoWithoutText}
            className="lazy_image absolute"
            height={100}
            width={100}
            alt=""
          />
        </div>
      )}
      <div className="text-center px-[10%] pt-6">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Messages
        </h1>
        <hr className="my-4" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-[10%] m-auto">
        {/* First Column */}
        <div className="font-medium col-span-1 md:shadow-md h-fit w-[78vw] justify-between md:w-auto flex md:flex-col flex-wrap md:gap-2">
          <div
            className={`cursor-pointer text-start rounded p-2 ${
              activeTab === "inbox"
                ? "bg-blue-900 font-semibold text-white"
                : ""
            } ${activeTab !== "inbox" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("inbox")}
          >
            Inbox
          </div>
          <div
            className={`cursor-pointer text-start rounded p-2 ${
              activeTab === "send"
                ? "bg-blue-900 font-semibold  text-white"
                : ""
            } ${activeTab !== "send" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("send")}
          >
            Send
          </div>
          <div
            className={`cursor-pointer text-start rounded p-2 ${
              activeTab === "important"
                ? "bg-blue-900 font-semibold  text-white"
                : ""
            } ${activeTab !== "important" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("important")}
          >
            Important
          </div>
          <div
            className={`cursor-pointer text-start rounded p-2 ${
              activeTab === "drafts"
                ? "bg-blue-900 font-semibold  text-white"
                : ""
            } ${activeTab !== "drafts" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("drafts")}
          >
            Drafts
          </div>
          <div
            className={`cursor-pointer text-start rounded p-2 ${
              activeTab === "compose"
                ? "bg-blue-900 font-semibold text-white"
                : ""
            } ${activeTab !== "compose" ? "hover:bg-gray-100" : ""}`}
            onClick={() => handleTabClick("compose")}
          >
            Compose
          </div>
        </div>
        {/* Second Column */}
        <div className="col-span-2">
          <div className="flex justify-between mb-3">
            <input
              type="text"
              placeholder="Search"
              className="w-3/5 h-11 rounded px-2 border-b border-gray-300 focus:outline-none"
            />
            <select className="w-2/5 h-11 rounded px-2 mx-1 border-b border-gray- focus:outline-none">
              <option value="default">All Projects</option>
              <option value="ongoing">Ongoing Projects</option>
              <option value="expired">Expired Projects</option>
            </select>
          </div>
          <div className="flex justify-between mb-4 text-secondaryl">
            <button>All</button>
            <button>Unread</button>
            <button>Marked</button>
            <button>Oldest</button>
            <button>Newest</button>
          </div>
          {/* List of people who sent messages */}
          <div className="border-[1px] rounded-sm border-gray-300 mb-2 scrollable-list">
            <div className="scrollable-list-inner">
              {users.map((user) => (
                <div
                  key={user.ID}
                  className={`scrollable-list-item cursor-pointer rounded-md ${
                    selectedUser?.ID === user.ID
                      ? "bg-secondaryl text-white p-4"
                      : "p-4"
                  } ${selectedUser?.ID !== user.ID ? "hover:bg-gray-100" : ""}`}
                  onClick={() => handleUserClick(user)}
                >
                  <div className="flex gap-6 items-center justify-between w-full">
                    <div className="flex">
                      <img
                        src={uimg}
                        alt="User Logo"
                        className="w-14 rounded-full"
                      />
                      <div className="ms-2 text-[14px]">
                        <h3
                          className={` ${
                            selectedUser?.ID === user.ID
                              ? "text-white"
                              : "text-secondaryl font-semibold"
                          }`}
                        >
                          {user.contractor.First_Name}{" "}
                          {user.contractor.Last_Name}
                        </h3>
                        <p>{user.contract.Project_Name}</p>
                        <p
                          className={`text-[10px] italic 
                              ${
                                selectedUser?.ID === user.ID
                                  ? "text-white line-clamp-2"
                                  : "text-gray-500 line-clamp-2"
                              }
                            `}
                        >
                          {user.contract.Project_Description}
                        </p>
                        {/* <p className="">
                          {user.createdAt[0]}
                          <br />
                          {user.createdAt[1]}
                        </p> */}
                      </div>
                    </div>
                    <div className="text-[10px]">
                      <h3
                        className={`${
                          selectedUser?.ID === user.ID
                            ? "text-white"
                            : "text-gray-500"
                        }`}
                      >
                        {formatDate(user.contract.CreatedDate)}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {selectedUser && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative rounded-lg w-11/12 md:w-3/5 max-h-[90vh] bg-white overflow-hidden scrollable-list">
            <div className="bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(40,42,115,0.15)_0%,_rgba(0,148,191,0.15)_100%)] p-4 relative overflow-y-auto scrollable-listt">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img src={uimg} alt="" className="w-14 rounded-full" />
                  <h3 className="text-secondaryl italic">
                    {selectedUser.contractor.First_Name}
                    {selectedUser.contractor.Last_Name}
                  </h3>
                </div>

                <p>{formatDate(selectedUser.contract.CreatedDate)}</p>
              </div>
              <button
                onClick={() => {
                  setSelectedUser(null);
                  if (id !== null && id !== "" && id !== undefined) {
                    navigate(`/corporations/dashboard/message`);
                  }
                }}
                className="text-gray-500 absolute right-[5%] top-[2%] hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
              <div
                key={selectedUser.ID}
                className="my-4 p-3 mt-0 border-gray-300 border-[1px]"
              >
                <div className="flex items-center">
                  <p className="text-secondaryl text-xl">
                    {selectedUser.contract.Project_Name}
                  </p>
                </div>
                <p className="">To: Me</p>
                {/* <p className="py-2 text-gray-500">
                <span className="line-clamp-2">
                  {selectedUser.Other_Details}
                </span>
              </p> */}
                <hr />
                <div className="flex justify-between flex-col">
                  <div>Attachments:</div>
                  <div className="flex justify-between">
                    <span className="mr-2 text-gray-500">Download</span>
                    <NavLink
                      to={selectedUser.Attach_Proposal}
                      target="_blank"
                      download
                      className="mr-2"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </NavLink>
                  </div>
                </div>
                <hr className="mb-3" />
                <span className="text-secondaryl ">Messages</span> <br />
                <p className=" text-gray-500 h-[35vh] scrollable-list p-2">
                  {messages?.map((message, index) => (
                    <span
                      key={index}
                      className={`${
                        message.Sender === "corporation"
                          ? "text-right leading-[4px] block bg-gray-100 p-2 pb-0"
                          : "text-left leading-[4px] block text-black p-2 pb-0"
                      }`}
                    >
                      {message.Content}
                    </span>
                  ))}
                </p>
                <input
                  type="text"
                  placeholder="Type your message..."
                  className="w-full px-4 py-2 border border-gray-300 focus:outline-none"
                  ref={messageInput}
                />
                <div className="mt-4">
                  <div>
                    <button className="text-blue-900">Attach File(s)</button>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="mb-2">
                        <label className="block font-normal text-[12px] sm:text-[12px] md:text-base"></label>
                        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-4 md:gap-16 md:mr-4 lg:mr-20">
                          <div className="col-span-1 sm:col-span-1 md:col-span-1">
                            <input
                              type="file"
                              id="companyContractDocs"
                              name="companyContractDocs"
                              className="hidden"
                            />
                            <label
                              htmlFor="companyContractDocs"
                              className="block bg-transparent cursor-pointer h-10 w-full rounded-sm text-black ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none"
                            >
                              <div className="flex items-center h-full space-x-1 overflow-hidden">
                                <InputFileIcon />
                              </div>
                            </label>
                          </div>
                          <div className="text-sm px-2 py-1 h-fit euclidbold cursor-pointer ring-1 ring-cyan-500 flex justify-center items-center text-cyan-500 sm:text-xl hover:ring-1 hover:ring-cyan-600 tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out mt-[1px]">
                            UPLOAD
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="px-6 py-2.5 items-center ring-1 euclidbold ring-cyan-500 text-cyan-500 text-xl tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out"
                      onClick={sendMessage}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Messages;
