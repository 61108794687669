import React from "react";
import LogoWhite from "../../assets/image/footer.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="relative w-full bg-[#0094BF] overflow-x-hidden">
      <div className="mx-auto my-3 md:w-fit w-full">
        <div className="mx-auto flex gap-2 md:gap-7 md:items-start  items-center md:flex-nowrap md:flex-row flex-col ">
          {/* logo */}

          <div className="">
            <img
              src={LogoWhite}
              onClick={() => navigate("/")}
              className="block w-[12rem] cursor-pointer"
              alt="Condominium White Logo"
            />
          </div>

          <div className="md:h-[7rem] md:w-[1px] w-full h-[1px] bg-gradient-to-b from-white to-[#999999] "></div>

          {/* Links */}

          <div className="w-fit">
            <div className="text-white euclidbold text-base tracking-wide flex flex-col items-start justify-start gap-1">
              <NavLink className={"text-nowrap"} to="/about">
                ABOUT US
              </NavLink>
              <NavLink className={"text-nowrap"} to="/contact">
                CONTACT US
              </NavLink>
              <NavLink className={"text-nowrap"} to="/blog">
                BLOG
              </NavLink>
              <NavLink className={"text-nowrap"} to="/privacypolicy">
                PRIVACY POLICY
              </NavLink>
              <NavLink className={"text-nowrap"} to="/terms">
                TERMS & CONDITIONS
              </NavLink>
            </div>
          </div>

          <div className="md:h-[7rem] md:w-[1px] w-full h-[1px] bg-gradient-to-b from-white to-[#999999] "></div>

          {/* Follow icons */}

          <div className="flex flex-col md:items-start justify-center">
            <div className="text-nowrap">
              <h4 className="text-white font-bold w-full text-base euclidbold tracking-wide">
                FOLLOW US
              </h4>
            </div>
            <div className="flex mt-2 md:w-full w-fit mx-auto gap-3">
              <NavLink
                to="https://www.facebook.com/share/mypoXz4tCZbT9p1X/?mibextid=LQQJ4d"
                target="_blank"
                className={
                  "text-white border rounded-full  w-10 h-10 flex justify-center items-center"
                }
              >
                <FaFacebookF className="text-xl" />
              </NavLink>
              <NavLink
                to="https://www.instagram.com/condominiumportal?igsh=MXVmN2hiaHV0OTU3ZQ=="
                target="_blank"
                className={
                  "text-white border rounded-full  w-10 h-10 flex justify-center items-center"
                }
              >
                <div className="">
                  <FaInstagram className="text-xl" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="text-white pb-1 text-center">
        Technology Partner - Tiny Script Soft Tech Pvt. Ltd.
      </div>
      <div className="text-white pb-2 text-center">
        Copyright © 2024 Condominium Portal Inc.
      </div>
    </div>
  );
}

export default Footer;
