import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useLoading } from "../../../../context/LoadingContext";
import { corporationAPI, ownerAPI, tenantAPI } from "../../../../constants/API";
import axios from "axios";
import CountUp from "react-countup";

function ServiceDashboard() {
  const cookies = new Cookies();
  const { load } = useLoading();
  const [isOwner, setIsOwner] = useState(false);
  const navigate = useNavigate();

  const [serviceDashboard, setServiceDashboard] = useState({
    "Total Request": { value: 0 },
    "Pending Request": { value: 0 },
    "Approve Request": { value: 0 },
    "Rejected Request": { value: 0 },
  });

  const [maintainanceDashboard, setMaintainanceDashboard] = useState({
    "Total Request": { value: 0 },
    "Pending Request": { value: 0 },
    "Approve Request": { value: 0 },
    "Rejected Request": { value: 0 },
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${corporationAPI}/requestdashboard`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      const data = response.data.data;

      setServiceDashboard(data.service);
      setMaintainanceDashboard(data.maintainance);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Manage Request
          </h1>
          <div className="flex gap-4 sm:w-fit w-full flex-wrap">
            <button
              className="h-fit w-fit px-4 py-2 items-center flex-grow cursor-pointer rounded-md euclidsemibold text-2xl bg-[#FFFFFF80] text-secondaryl  truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2 hover:bg-secondaryl hover:text-[#ffffff]"
              onClick={() => {
                navigate("/corporations/dashboard/details");
              }}
            >
              Details
            </button>
            <button
              className="h-fit w-fit px-3 py-2 items-center flex-grow cursor-pointer rounded-md euclidsemibold text-2xl bg-[#FFFFFF80] text-secondaryl  truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2 hover:bg-secondaryl hover:text-[#ffffff]"
              onClick={() => {
                navigate("/corporations/dashboard/managerequest/bookedservice");
              }}
            >
              Booked Service
            </button>
          </div>
        </div>

        <div className="w-full py-3">
          <p htmlFor="" className="text-lg text-secondaryl font-medium pb-2">
            Service
          </p>
          <div className="flex flex-wrap gap-5 w-full justify-center md:justify-start">
            {Object.entries(serviceDashboard).map(([key, request], index) => (
              <div
                key={index}
                className="bg-[#FFFFFF80] h-40 w-56 flex flex-col flex-grow justify-around items-center rounded"
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              >
                <label
                  htmlFor=""
                  className="text-2xl text-secondaryl euclidsemibold"
                >
                  {key}
                </label>
                <div className="text-2xl text-secondaryl  euclidsemibold">
                  <CountUp
                    start={0}
                    end={request.value}
                    duration={2.5}
                    separator=","
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full py-3">
          <label
            htmlFor=""
            className="text-lg text-secondaryl font-medium pb-2"
          >
            Maintainance
          </label>
          <div className="flex flex-wrap gap-5 w-full justify-center md:justify-start">
            {Object.entries(maintainanceDashboard).map(
              ([key, request], index) => (
                <div
                  key={index}
                  className="bg-[#FFFFFF80] h-40 w-56 flex flex-col flex-grow  justify-around items-center rounded"
                  style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                >
                  <label
                    htmlFor=""
                    className="text-2xl text-secondaryl euclidsemibold"
                  >
                    {key}
                  </label>
                  <div className="text-2xl text-secondaryl  euclidsemibold">
                    <CountUp
                      start={0}
                      end={request.value}
                      duration={2.5}
                      separator=","
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDashboard;
