import React, { useEffect, useState } from "react";
import { FaCalendarDays } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { blogAPI } from "../../constants/API";
import axios from "axios";
import BlogDetailsSkeleton from "./BlogDetailsSkeleton";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import Cookies from "universal-cookie";

const BlogDetails = () => {
  const location = useLocation();
  const cookies = new Cookies();
  const [blogSlug, setBlogSlug] = useState(
    location.pathname.split("/blog/")[1]
  );
  const [isLogin, setIsLogin] = useState(cookies.get("companyType"));

  const [blogData, setBlogData] = useState(null);
  const [load, setLoad] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const fetchBlogData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${blogAPI}/slug/${blogSlug}`);

      const rawContent = response.data.data.Blog_Description;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);

      setBlogData(response.data.data);
    } catch (error) {
      // console.log("Error:", error.response);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setBlogSlug(location.pathname.split("/blog/")[1]);
    fetchBlogData();
  }, [blogSlug]);

  const dateFormate = (date) => {
    const originalDate = new Date(date);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <div className={!isLogin && `m-3`}>
      <div
        className={
          !isLogin &&
          `w-full mx-auto min-w-[65%] sm:max-w-[80%] md:max-w-[70%] lg:max-w-[65%] ring-1 ring-gray-200 rounded-2xl `
        }
      >
        {load ? (
          <BlogDetailsSkeleton />
        ) : (
          <div
            className={`w-full m-auto p-6 rounded-2xl flex flex-col justify-center `}
          >
            <div className="w-full md:w-[90%] lg:w-[85%]  overflow-hidden">
              {blogData ? (
                <div className="py-3 space-y-2">
                  <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
                    {blogData.Blog_Title}
                  </h1>
                  <p className="text-infromation text-base flex items-center gap-2 ">
                    <FaCalendarDays />
                    {dateFormate(blogData.Blog_Publish_Date)}
                  </p>

                  <div className="py-2">
                    <img
                      className="w-full rounded h-96 object-fill"
                      src={blogData.Blog_Image}
                      alt="Sunset in the mountains"
                    />
                  </div>

                  <div className="text-justify text-infromation">
                    <div>
                      <Editor
                        editorState={editorState}
                        readOnly
                        toolbarHidden
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h1 className="text-3xl euclidbold text-gray-500 text-center">
                    Blog Not Found
                  </h1>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDetails;
