import React from "react";
import Skeleton from "react-loading-skeleton";

function ReviewPortfolioSkeleton() {
  return (
    <div className="w-[100%] lg:w-2/6 h-[100%] py-4 px-2 bg-primarybg rounded text-center">
      <div className="">
        <div className="flex justify-center">
          <Skeleton circle={true} height={230} width={230} />
        </div>
        <div className="space-y-2 my-4">
          <div className="text-4xl w-[90%] mx-auto ">
            <Skeleton />
          </div>
          <div className="text-2xl w-4/5 mx-auto">
            <Skeleton />
          </div>

          <div className="text-base w-3/5 mx-auto">
            <Skeleton />
          </div>

          <div className="mt-4 text-2xl w-[80%] mx-auto">
            <Skeleton />
          </div>

          <div className="text-2xl w-[70%] mx-auto">
            <Skeleton />
          </div>

          <div className="text-4xl w-[75%] mx-auto">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPortfolioSkeleton;
