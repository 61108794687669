import React from "react";
import { Location, Star, HalfStar } from "../../../assets/images";
import { ClientImage } from "../../../assets/images";

function ClientReviewCard({ data }) {
  // Star Color Change
  const renderRatingStars = () => {
    const stars = [];
    const fullStars = Math.floor(4);
    const hasHalfStar = 4 % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={i} className={"h-6 w-7"} />);
    }

    if (hasHalfStar) {
      stars.push(<HalfStar key={stars.length} className={"h-6 w-7 ml-0"} />);
    }

    return stars;
  };
  return (
    <div className="flex flex-col  lg:flex-row  px-1.5 md:px-3 py-3 bg-[#3E5CAA0D] justify-center rounded-2xl gap-5">
      <div className="p-2">
        <div className="lg:w-24 flex items-center flex-col gap-2 ">
          <img
            className="h-24 w-24 rounded-full ring-[0.5px] ring-secondaryl object-cover "
            src={ClientImage}
          />

          <div className="space-y-2 euclidbold text-center ">
            <h1 className="text-2xl flex justify-center break-words truncate text-secondaryl">
              {data.Name}
            </h1>
            <div className="flex items-center  justify-center text-base gap-2">
              <Location />
              <p> {data.projectLocation}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[0.5px] border-x-[0.01px] border-secondaryl hidden lg:block"></div>

      {/* Card Content */}
      <div className="w-full flex-col justify-start mx-auto leading-normal">
        <div className="flex space-x-1">{renderRatingStars()}</div>

        {/* Description */}
        <p className="text-base text-justify  font-normal text-infromation mt-3">
          {data.Review_Content}
        </p>
      </div>
    </div>
  );
}

export default ClientReviewCard;
