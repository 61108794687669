import React, { memo } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination3.css";
import { useSearchParams } from "react-router-dom";
function Pagination3({
  totalPages,
  currentPage,
  setPage,
  setLoad,
  setContracts,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const onPageChange = ({ selected }) => {
    setSearchParams({ page: selected + 1 });
    setPage(selected + 1);
    setContracts([]);
    setLoad(true);
  };

  return (
    <ReactPaginate
      pageCount={totalPages}
      onPageChange={onPageChange}
      forcePage={currentPage - 1}
      previousLabel={"Prev"}
      nextLabel={"Next"}
      breakLabel={"..."}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      containerClassName={"pagination"}
      activeClassName={"active"}
      previousLinkClassName={"previous"}
      nextLinkClassName={"next"}
      disabledClassName={"disabled"}
    />
  );
}

export default memo(Pagination3);
