import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, contractAPI } from "../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { BarChart } from "@mui/x-charts";
import { viewFileHandler } from "../../constants/Request";
import ApproveForm from "./ApproveForm";

const CorporationDetailsModal = ({ isOpen, onClose, corporation }) => {
  const cookies = new Cookies();
  const [contracts, setContracts] = useState("");
  const [imageurl, setimageurl] = useState(null);
  const [corporationapprovedcontracts, setCorporationapprovedContracts] =
    useState("");
  const [showApproveForm, setShowApproveForm] = useState(false);

  /*
  const getAllContracts = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${contractAPI}/admin/${corporation?.ID}`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      setContracts(response.data.contracts);
      let approvedcontracts = response.data.contracts;
      const approvedcontract = approvedcontracts.filter(
        (item) => item.Status === 1
      );
      setCorporationapprovedContracts(approvedcontract);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };*/

  useEffect(() => {
    async function getimg() {
      if (!corporation?.Public_Certificate_URL) {
        setimageurl(null);
      } else {
        const imgres = await viewFileHandler(
          corporation?.Public_Certificate_URL,
          "corporation"
        );
        setimageurl(imgres);
      }
    }
    getimg();

    // getAllContracts();
    window.scrollTo(0, 0);
  }, [corporation]);

  /*
  const handleAction = async (newStatus) => {
    setLoad(true);
    try {
      const res = await axios.put(
        `${baseUrl}/admin/corporation/${corporation?.ID}`,
        {
          Verification_Status: newStatus,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      onClose();
      fetchCorporationData();
      toast.success("Application Status Updated", {
        duration: 4000,
      });
    } catch (error) {
      toast.error("Failed to update application status");
    } finally {
      setLoad(false);
    }
  };*/

  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto pb-36 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center  justify-center min-h-screen  min-w-[90vw] pt-4 px-4 pb-36 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 z-[51] bg-gray-500 opacity-75 text-red-600 font-bold cursor-pointer"></div>
        </div>

        <div
          className="inline-block z-50  w-[90vw] md:w-[80vw] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ width: "100vw" }}
        >
          {/* <button
            className={`bg-red-500 right-[1%] top-[1%] absolute text-white px-3 py-1 rounded-full mr-2`}
            onClick={() => handleDelete()}
          >
            Delete
          </button> */}
          <div className="  px-4 pb-4 sm:p-6 sm:pb-4 w-full">
            <div className="sm:flex sm:items-start w-full">
              <div className="mt-3 flex flex-wrap gap-x-4 sm:text-left mr-4">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Corporation Details
                </h3>
                <div className="w-full flex flex-wrap gap-x-4 mt-4">
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Corporation Name:</span>{" "}
                    {corporation?.Corporation_Name}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Corporation Address:</span>{" "}
                    {corporation?.Corporation_Address}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Employed By Condo:</span>{" "}
                    {corporation?.Employed_By_Condo ? "Yes" : "No"}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Corporation Phone No:</span>{" "}
                    {corporation?.Corporation_Phone_No}
                  </p>

                  <h2 className="font-bold mt-4">Management Company Details</h2>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Name:</span>
                    {corporation?.cp_managementCompany?.Management_Company_Name}
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Email:</span>
                    {
                      corporation?.cp_managementCompany
                        ?.Management_Company_Email
                    }
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Address:</span>
                    {
                      corporation?.cp_managementCompany
                        ?.Management_Company_Address
                    }
                  </p>
                  <p className="text-sm text-gray-500">
                    <span className="font-bold">Phone:</span>
                    {
                      corporation?.cp_managementCompany
                        ?.Management_Company_Phone_No
                    }
                  </p>

                  <h2 className="font-bold mt-4 text-center w-full">
                    Property Managers
                  </h2>
                  {corporation?.cp_propertymanagers?.map((manager, index) => (
                    <div key={index}>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Name:</span>{" "}
                        {manager.Property_Manager_Name}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Email:</span>{" "}
                        {manager.Property_Manager_Email}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Phone:</span>{" "}
                        {manager.Property_Manager_Phone_No}
                      </p>
                    </div>
                  ))}
                  <h2 className="font-bold mt-4 text-center w-full">
                    Board Members
                  </h2>
                  {corporation?.BoardMembers?.map((member, index) => (
                    <div key={index}>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Name:</span>
                        {member.Board_Member_Name}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Email:</span>
                        {member.Board_Member_Email}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-bold">Phone:</span>
                        {member.Board_Member_Phone_No}
                      </p>
                    </div>
                  ))}

                  <h2 className="font-bold mt-4 text-center w-full">
                    Working Hours
                  </h2>
                  {corporation?.cp_workingHours?.map((hour, index) => (
                    <p key={index} className="text-sm text-gray-500">
                      <span className="font-bold">{hour.Day}:</span>{" "}
                      {hour.Checked
                        ? `${hour.Working_Hours_From}-${hour.Working_Hours_To}`
                        : "Holiday"}
                    </p>
                  ))}

                  <div className="w-full flex flex-col items-center gap-4 justify-between">
                    <div className="w-full">
                      <h2 className="font-bold mt-5">
                        Corporation Certificate
                      </h2>
                      {corporation?.Public_Certificate_URL ? (
                        corporation?.Public_Certificate_URL.endsWith(".pdf") ? (
                          <object
                            data={imageurl}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                          >
                            <p>PDF could not be displayed</p>
                          </object>
                        ) : (
                          <img
                            src={corporation?.Public_Certificate_URL}
                            alt="Corporation Certificate"
                            className="w-[420px] h-[240px]"
                          />
                        )
                      ) : (
                        <p className="text-sm text-gray-500">
                          No Certificate Provided
                        </p>
                      )}
                    </div>

                    {/* {corporation?.Verification_Status === null && (
                      <div className="flex flex-row h-2/5 gap-2 justify-between">
                        <button
                          className={`bg-green-500 text-white px-3 py-1 rounded-full mr-2`}
                          onClick={() => setShowApproveForm(true)}
                        >
                          Approve
                        </button>
                        <button
                          className={`bg-red-500 text-white px-3 py-1 rounded-full mr-2`}
                          onClick={() => handleAction(0)}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                    {corporation?.Verification_Status === 1 && (
                      <div>
                        <button
                          className={`bg-orange-500 text-white px-3 py-1 rounded-full mr-2`}
                          onClick={() => handleAction(2)}
                        >
                          Pause
                        </button>
                        <button
                          className={`bg-red-500 text-white px-3 py-1 rounded-full mr-2`}
                          onClick={() => handleAction(0)}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                    {(corporation?.Verification_Status === 0 ||
                      corporation?.Verification_Status === 2) && (
                      <>
                        <button
                          className={`bg-green-500 text-white px-3 py-1 rounded-full mr-2`}
                          onClick={() => setShowApproveForm(true)}
                        >
                          Approve Application
                        </button>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Statistics Panel */}
          <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 w-[80vw] lg:w-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Statistics
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h4 className="text-lg font-medium text-gray-900">
                  Total Contracts
                </h4>
                <p className="text-xl font-semibold">{contracts?.length}</p>
              </div>
              <div className="bg-green-100 p-4 rounded-lg">
                <h4 className="text-lg font-medium text-green-900">
                  Approved Contracts
                </h4>
                <p className="text-xl font-semibold">
                  {corporationapprovedcontracts?.length}
                </p>
              </div>
              <div className="bg-red-100 p-4 rounded-lg">
                <h4 className="text-lg font-medium text-red-900">
                  Not Approved Contracts
                </h4>
                <p className="text-xl font-semibold">
                  {contracts?.length - corporationapprovedcontracts?.length}
                </p>
              </div>
            </div>
          </div>
          {/* <BarChart
            series={[
              { data: [35, 44, 24, 34] },
              { data: [51, 6, 49, 30] },
              { data: [15, 25, 30, 50] },
              { data: [60, 50, 15, 25] },
            ]}
            height={290}
            xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          /> */}

          <div className="bg-gray-50 mb-10 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={onClose}
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-cyan-500 text-base font-medium text-white hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporationDetailsModal;
