import React from "react";
import Skeleton from "react-loading-skeleton";

const ComplaintSuggestionSkeleton = ({ cards = 5 }) => {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="w-full flex items-center lg:flex-row flex-col  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        <div className="flex-col lg:w-[75%] w-full  justify-center leading-normal">
          {/* Title */}
          <div className="text-base">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
        {/* Card Buttons */}
        <div className="flex-col gap-2 justify-center my-auto relative w-full lg:w-[20%] space-y-3">
          <Skeleton height={40} />
        </div>
      </div>
    ));
};

export default ComplaintSuggestionSkeleton;
