import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import {
  meetingDocumentRequestOwnersAPI,
  meetingDocumentRequestTenantAPI,
} from "../../../constants/API";
import Loading from "../../Elements/Loading";
import toast from "react-hot-toast";
import { viewFileHandler } from "../../../constants/Request";
import ReadInputBox from "../../Elements/ReadInputBox";
import ReadTextArea from "../../Elements/ReadTextArea";
import { momSchema } from "../../../schema";

function MoMReadModal({ mom, onClose, isOwner }) {
  const cookies = new Cookies();
  const [modelLoading, setModelLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);

  const { register, reset } = useForm({
    resolver: yupResolver(momSchema),
  });

  const [MoMDocumnet, setMoMDocument] = useState(null);
  const [MoMDocumnetType, setMoMDocumentType] = useState(null);

  const assignMoMData = async () => {
    reset(mom);
    // console.log(mom.Meeting_Document?.endsWith(".pdf"));
    if (mom?.Meeting_Document?.endsWith(".pdf")) {
      const fileurl = await viewFileHandler(mom.Meeting_Document, "meetings");
      setMoMDocumentType("pdf");
      setMoMDocument(fileurl);
    } else {
      setMoMDocumentType("image");
      setMoMDocument(mom.Meeting_Document);
    }
  };

  const requestStatusHandler = () => {
    if (isOwner) {
      if (mom?.MeetingDocumentRequestOwners?.length === 0) {
        setRequestStatus(null);
      } else if (
        mom?.MeetingDocumentRequestOwners?.length > 0 &&
        mom?.MeetingDocumentRequestOwners[0]?.Status === 0
      ) {
        setRequestStatus(0);
      } else if (
        mom?.MeetingDocumentRequestOwners?.length > 0 &&
        mom?.MeetingDocumentRequestOwners[0]?.Status === 1
      ) {
        setRequestStatus(1);
      } else if (
        mom?.MeetingDocumentRequestOwners?.length > 0 &&
        mom?.MeetingDocumentRequestOwners[0]?.Status === 2
      ) {
        setRequestStatus(2);
      }
    } else {
      if (mom?.MeetingDocumentRequestTenants?.length === 0) {
        setRequestStatus(null);
      } else if (
        mom?.MeetingDocumentRequestTenants?.length > 0 &&
        mom?.MeetingDocumentRequestTenants[0]?.Status === 0
      ) {
        setRequestStatus(0);
      } else if (
        mom?.MeetingDocumentRequestTenants?.length > 0 &&
        mom?.MeetingDocumentRequestTenants[0]?.Status === 1
      ) {
        setRequestStatus(1);
      } else if (
        mom?.MeetingDocumentRequestTenants?.length > 0 &&
        mom?.MeetingDocumentRequestTenants[0]?.Status === 2
      ) {
        setRequestStatus(2);
      }
    }
  };

  useEffect(() => {
    setModelLoading(true);
    assignMoMData();
    requestStatusHandler();
    setModelLoading(false);
  }, []);

  const onRequestDownload = async () => {
    setModelLoading(true);
    try {
      const apiUrl = isOwner
        ? `${meetingDocumentRequestOwnersAPI}`
        : `${meetingDocumentRequestTenantAPI}`;

      const response = await axios.post(
        apiUrl,
        {
          Meeting_ID: mom.ID,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      toast.success(response.data.message);
      setRequestStatus(0);
    } catch (error) {
      // console.log(error);
      toast.error(
        error.response.data.message || "Failed to Send MoMDocument Request"
      );
    } finally {
      setModelLoading(false);
    }
  };
  useEffect(() => {
    const disableShortcuts = (e) => {
      // Disable Ctrl+S (Save) or Cmd+S on macOS
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
        toast.error("Save function disabled!");
      }

      // Disable Ctrl+P (Print) or Cmd+P on macOS
      if ((e.ctrlKey || e.metaKey) && e.key === "p") {
        e.preventDefault();
        toast.error("Print function disabled!");
      }
    };

    // Disable right-click globally
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    // Add event listeners
    document.addEventListener("keydown", disableShortcuts);
    document.addEventListener("contextmenu", disableContextMenu);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", disableShortcuts);
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30 h-`}
    >
      <style>{`body { overflow: hidden; }`}</style>
      <Loading load={modelLoading} />
      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl w-[80%] h-[80vh] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-3xl euclidbold">
                {mom.Meeting_Topic}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <div className="my-3">
              <div className="mb-3">
                <ReadInputBox
                  title={"MoM Date"}
                  register={register}
                  filedName={"Meeting_Date"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <ReadTextArea
                  title={"MoM Details "}
                  register={register}
                  filedName={"Meeting_Details"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <ReadTextArea
                  title={"MoM Outcome "}
                  register={register}
                  filedName={"Meeting_Outcome"}
                  cursorNotAllowed={false}
                />
              </div>

              {cookies.get("companyType") === "corporation" && (
                <div className="mb-3">
                  <ReadTextArea
                    title={"Who Can Show "}
                    register={register}
                    filedName={"Meeting_Outcome"}
                    cursorNotAllowed={false}
                  />
                </div>
              )}

              <div className="mb-3">
                {mom && (
                  <>
                    <label
                      className={`block font-normal  text-secondaryl text-base `}
                    >
                      Uploaded MoM Document :
                    </label>

                    <div className="my-2">
                      {requestStatus === null && (
                        <button
                          className="h-fit w-fit px-4 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap "
                          onClick={onRequestDownload}
                        >
                          Request Document Download
                        </button>
                      )}

                      {requestStatus === 0 && (
                        <label
                          className={`block font-normal  text-yellow-600 text-sm   `}
                        >
                          Request Sent, Awaiting Corporation's Response
                        </label>
                      )}

                      {requestStatus === 1 && (
                        <label
                          className={`block font-normal  text-green-500  text-sm `}
                        >
                          Request Approved by Corporation. You can now download
                          the document
                        </label>
                      )}

                      {requestStatus === 2 && (
                        <label
                          className={`block font-normal text-red-500 text-sm `}
                        >
                          Request Rejected by Corporation
                        </label>
                      )}
                    </div>

                    <div className="pt-3">
                      {modelLoading ? (
                        "Loading..."
                      ) : MoMDocumnet ? (
                        MoMDocumnetType === "pdf" ? (
                          <div className="relative">
                            <object
                              // data={`${MoMDocumnet}${
                              //   requestStatus === 1
                              //     ? "#toolbar=1"
                              //     : "#toolbar=0"
                              // }`}
                              data={`${MoMDocumnet}${"#toolbar=0"}`}
                              type="application/pdf"
                              width="100%"
                              height="600px"
                              className="w-full h-[90vh] select-none"
                              onContextMenu={(e) => e.preventDefault()} // Prevent right-click
                            >
                              <p>PDF could not be displayed</p>
                            </object>
                            {/* Only apply the overlay when the toolbar should not be shown */}
                            <div
                              className="absolute top-0 left-0 w-full h-full"
                              style={{ background: "transparent", zIndex: 1 }}
                              onContextMenu={(e) => e.preventDefault()} // Disable right-click on overlay
                            ></div>
                          </div>
                        ) : (
                          <img
                            src={MoMDocumnet}
                            alt="MoM Document"
                            className="w-full h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                          />
                        )
                      ) : (
                        <div className="text-infromation">
                          No Documents Provided
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-wrap gap-5 justify-start ">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoMReadModal;
