import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBox from "../../../../components/Elements/InputBox";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../assets/images";
import axios from "axios";
import Cookies from "universal-cookie";
import { meetingAPI } from "../../../../constants/API";
import Loading from "../../../../components/Elements/Loading";
import toast from "react-hot-toast";
import { viewFileHandler } from "../../../../constants/Request";
import { momSchema } from "../../../../schema";
import DropDownStyle2 from "../../../Elements/DropDownStyle2";

const whoCanShowOption = [
  { ID: 0, Sharing_Status: "Both" },
  { ID: 1, Sharing_Status: "Owner" },
  { ID: 2, Sharing_Status: "Tenant" },
];

function MoMModal({ mom, onClose, fetchMoMs, isEditModal }) {
  const cookies = new Cookies();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [modelLoading, setModelLoading] = useState(false);
  const [selectWhoCanShow, setSelectWhoCanShow] = useState(null);
  // console.log(mom);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(momSchema),
  });

  const [MoMDocumnet, setMoMDocument] = useState(null);
  const [MoMDocumnetType, setMoMDocumentType] = useState(null);

  const assignMoMData = async () => {
    if (mom) {
      setSelectWhoCanShow(mom.Sharing_Status);
      reset(mom);
      if (mom?.Meeting_Document?.endsWith(".pdf")) {
        const fileurl = await viewFileHandler(mom.Meeting_Document, "meetings");
        setMoMDocumentType("pdf");
        setMoMDocument(fileurl);
      } else {
        setMoMDocumentType("image");
        setMoMDocument(mom.Meeting_Document);
      }
    } else {
      reset();
    }
  };
  // Set the form values when the modal is opened in edit mode
  useEffect(() => {
    assignMoMData();
  }, [mom, reset]);

  const onSubmit = async (data) => {
    setModelLoading(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "Meeting_Document") {
        formData.append(key, MoMDocs);
      } else {
        formData.append(key, data[key]);
      }
    });

    try {
      if (mom) {
        const response = await axios.put(`${meetingAPI}/${mom.ID}`, formData, {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
            // Don't manually set Content-Type here
          },
        });

        toast.success(response.data.message); // Display success message
      } else {
        const response = await axios.post(meetingAPI, formData, {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        });

        toast.success(response.data.message); // Display success message
      }
      fetchMoMs(); // Fetch meetings after adding/updating
      reset();
      onClose();
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message || "Failed to save meeting");
    } finally {
      setModelLoading(false);
    }
  };

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];

  const [MoMDocs, setMoMDocs] = useState(null);
  const [MoMDocsError, setMoMDocsError] = useState("");
  const [MoMDocsUploded, setMoMDocsUploded] = useState("");
  const [MoMDocsURL, setMoMDocsURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingMoMDocs, setUploadingMoMDocs] = useState(false);

  const handleChangeMoMDocs = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMoMDocs(null);
      setMoMDocsError("");
      setMoMDocsUploded("");
      setMoMDocsURL("");
      setValue("Meeting_Document", "");
      clearErrors("Meeting_Document");
    }

    if (!file) {
      setMoMDocs(null);
      setMoMDocsError("");
      setMoMDocsUploded("");
      setMoMDocsURL("");
      setValue("Meeting_Document", "");
      clearErrors("Meeting_Document");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setMoMDocs(file);
        setMoMDocsUploded("");
        setMoMDocsError("");
        setMoMDocsURL("");
        setValue("Meeting_Document", file);
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setMoMDocs(null);
        setMoMDocsError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setMoMDocsUploded("");
        setMoMDocsURL("");
        setValue("Meeting_Document", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setMoMDocs(null);
        setMoMDocsError("File size exceeds limit (5 MB)");
        setMoMDocsUploded("");
        setMoMDocsURL("");
        setValue("Meeting_Document", "");
      } else {
        setMoMDocs(null);
        setMoMDocsError("Something went wrong! Please try again.");
        setMoMDocsUploded("");
        setMoMDocsURL("");
        setValue("Meeting_Document", "");
      }
    }
  }; // Empty dependency array means the function will not change after the initial render

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto   
      bg-black bg-opacity-30`}
    >
      <Loading load={modelLoading} />
      <style>{`body { overflow: hidden; }`}</style>

      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl    w-[80%] h-[80%] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                {mom ? "Edit MoM" : "Add MoM"}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
              <div className="mb-3">
                <InputBox
                  title={"MoM Topic"}
                  register={register}
                  filedName={"Meeting_Topic"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  type="date"
                  title={"MoM Date "}
                  register={register}
                  filedName={"Meeting_Date"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  title={"MoM Details "}
                  register={register}
                  filedName={"Meeting_Details"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  title={"MoM Outcome "}
                  register={register}
                  filedName={"Meeting_Outcome"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                />
              </div>

              {/* who can show */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full">
                <div>
                  <label
                    htmlFor="Sharing_Status"
                    className="block font-normal text-secondaryl"
                  >
                    <p>
                      Who Can Show
                      <span className="text-red-500 text-base ml-1">*</span>
                    </p>
                  </label>

                  <div className="">
                    <DropDownStyle2
                      title="Who Can Show"
                      options={whoCanShowOption}
                      onSelect={setSelectWhoCanShow}
                      selectOption={selectWhoCanShow}
                      setValue={setValue}
                      filedName={"Sharing_Status"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"Sharing_Status"}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label
                    className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                  >
                    {isEditModal && "Replace "} MoM Document Upload
                    <span className="text-red-500 text-base">*</span>
                  </label>

                  <div>
                    <div className="">
                      {/* Business Card File Input */}
                      <div className="col-span-2 sm:col-span-2 md:col-span-1">
                        <input
                          type="file"
                          id="Meeting_Document"
                          name="Meeting_Document"
                          accept=".pdf"
                          className="hidden"
                          onChange={handleChangeMoMDocs}
                          disabled={uploadingFile}
                        />

                        {/* Label For style is input tag and use in File input */}
                        <label
                          htmlFor="Meeting_Document"
                          className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                            uploadingFile
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                        >
                          <div className="flex items-center h-full space-x-1 overflow-hidden">
                            <span>
                              <InputFileIcon />
                            </span>
                            {MoMDocs ? (
                              <span className="line-clamp-1">
                                <span>{MoMDocs.name}</span>
                              </span>
                            ) : (
                              <span className="text-infromation text-opacity-50   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                                Upload MoM Document
                              </span>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>

                    {/* Error */}
                    <div className="">
                      {uploadingMoMDocs && <Spinner />}
                      {MoMDocsError && (
                        <p className="text-red-500 text-xs leading-none">
                          {MoMDocsError}
                        </p>
                      )}
                      {errors.Meeting_Document && ( // Error message from yup validation
                        <p className="text-red-500 text-xs leading-none">
                          {errors.Meeting_Document.message}
                        </p>
                      )}
                      {MoMDocsUploded && (
                        <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                          {/* {contractDocsUploded}  */}
                          <ApprovedIcon /> Attach MoM Document Successfully!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {mom && (
                <div className="mb-6">
                  <label
                    className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                  >
                    Uploaded MoM Document :
                  </label>
                  <div className="pt-3">
                    {modelLoading ? (
                      "Loading..."
                    ) : MoMDocumnet ? (
                      MoMDocumnetType === "pdf" ? (
                        <object
                          data={MoMDocumnet}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                          className="w-full h-[90vh]"
                        >
                          <p>PDF could not be displayed</p>
                        </object>
                      ) : (
                        <img
                          src={MoMDocumnet}
                          alt="Meeting Document"
                          className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                        />
                      )
                    ) : (
                      <div className="text-infromation">
                        No Documents Provided
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="flex flex-wrap gap-5 justify-start ">
                {/* {onDelete && (
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                )} */}
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-secondaryl hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded"
                >
                  {mom ? "Save Changes" : "Add MoM"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoMModal;
