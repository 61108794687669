import { PasswordIcon, UserIdIcon } from "../../assets/images/index";

import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  contractorLoginAsync,
  corporationLoginAsync,
} from "../../store/Auth/Auth";

import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  boardMemberLoginAPI,
  ownerLoginAPI,
  propertyManagerLoginAPI,
  securityMemberLoginAPI,
  tenantLoginAPI,
} from "../../constants/API";
import AssignPropertyManager from "./AssignPropertyManager";
import axios from "axios";

const notifyfailure = (props) => toast.error(props);

function LoginForm({
  loginRoleText,
  requiredRegister,
  loginFieldName,
  forgotField,
  setLoad,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [propertyManagersdata, setpropertyManagerData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const cookies = new Cookies();

  const schema = yup.object().shape({
    userId: yup
      .string()
      .required(
        location.pathname.includes("owners") ||
          location.pathname.includes("tenant") ||
          location.pathname.includes("boardmember") ||
          location.pathname.includes("securitymember")
          ? "Email Id is required"
          : "User Id is required"
      ),
    password: yup.string().required("Password is required"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // Handle form submission
  const onSubmit = async (data) => {
    let api;
    if (location.pathname.includes("boardmember")) {
      api = boardMemberLoginAPI;
    } else if (location.pathname.includes("securitymember")) {
      api = securityMemberLoginAPI;
    } else {
      api = propertyManagerLoginAPI;
    }

    try {
      let response;
      if (location.pathname.includes("corporation")) {
        response = await dispatch(
          corporationLoginAsync({ data: data, api: api })
        );
      } else if (location.pathname.includes("contractor")) {
        response = await dispatch(contractorLoginAsync(data));
      } else if (location.pathname.includes("owners")) {
        setLoad(true);
        response = await axios.post(ownerLoginAPI, data);
      } else if (location.pathname.includes("tenant")) {
        setLoad(true);
        response = await axios.post(tenantLoginAPI, data);
      }

      // console.log(response);
      const oneHour = 60 * 60 * 4000; // 1 hour in milliseconds
      const expirationDate = new Date(Date.now() + oneHour); // Current time + 1 hour
      const expirationTime = 60 * 60 * 4000;
      const options = { expires: new Date(Date.now() + expirationTime) };

      if (response.payload) {
        cookies.set("token", response.payload.token, options);
        // console.log(response.payload.access);
        if (location.pathname.includes("corporation")) {
          if (location.pathname.includes("boardmember")) {
            cookies.set("companyType", "corporation", {
              expires: expirationDate,
            });
            cookies.set("access", response.payload.access, options);
            cookies.set("status", response.payload.status, options);

            toast.success("Login Successfully", { duration: 1500 });
            navigate("/corporations/dashboard");
          } else if (location.pathname.includes("securitymember")) {
            cookies.set("companyType", "corporation", {
              expires: expirationDate,
            });
            cookies.set("access", response.payload.access, options);
            cookies.set("status", response.payload.status, options);
            cookies.set("name", response.payload.data.name, options);

            toast.success("Login Successfully", { duration: 1500 });
            navigate("/corporations/dashboard/archivescontract?page=1");
          } else {
            if (response.payload.propertyManagers.length > 1) {
              setpropertyManagerData(response.payload.propertyManagers);
              setShowModel(true);

              cookies.set("companyType", "corporation", {
                expires: expirationDate,
              });
              setpropertyManagerData(response.payload.propertyManagers);
              cookies.set("access", response.payload.access, options);
              cookies.set("status", response.payload.status, options);
              toast.success("Login Successfully", { duration: 1500 });
            } else {
              cookies.set("companyType", "corporation", {
                expires: expirationDate,
              });
              cookies.set("access", response.payload.access, options);
              cookies.set("status", response.payload.status, options);

              toast.success("Login Successfully", { duration: 1500 });
              navigate("/corporations/dashboard/overview");
            }
          }
        } else if (location.pathname.includes("contractor")) {
          cookies.set("companyType", "contractor", {
            expires: expirationDate,
          });
          cookies.set("Profile_Photo", response.payload.profileimg, options);

          cookies.set("status", response.payload.status, options);

          toast.success("Login Successfully", { duration: 1500 });
          navigate("/contractors/dashboard/currentlisting", {});
        }
      }

      if (response.data) {
        if (location.pathname.includes("owners")) {
          // console.log("Owner Login");

          cookies.set("companyType", "owners", {
            expires: expirationDate,
          });

          cookies.set("token", response.data.token, options);

          cookies.set("Profile_Photo", null, options);

          cookies.set("status", response.data.status, options);

          toast.success("Login Successfully", { duration: 4000 });
          navigate("/owners/dashboard", { replace: true }); // Redirect to the dashboard and replace the current location
        } else if (location.pathname.includes("tenant")) {
          cookies.set("companyType", "tenant", {
            expires: expirationDate,
          });

          cookies.set("token", response.data.token, options);

          cookies.set("Profile_Photo", null, options);

          cookies.set("status", response.data.status, options);

          toast.success("Login Successfully", { duration: 4000 });
          navigate("/tenant/dashboard", { replace: true }); // Redirect to the dashboard and replace the current location
        }
      }
    } catch (error) {
      // console.log(error);
      if (error.response) {
        notifyfailure(error.response.data.message);
      } else {
        notifyfailure("Something went wrong ! Please try again later");
      }
    } finally {
      setLoad(false);
    }
  };

  const handlePropertyManagerSelect = (selectedManagerId) => {
    cookies.set("selectedPropertyManagerId", selectedManagerId, { path: "/" });
    toast.success("Property Manager assigned successfully");
    navigate("/corporations/dashboard");
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = async () => {
    setShowModel(false);
    cookies.remove("access");
    cookies.remove("token");
    cookies.remove("companyType");
    cookies.remove("status");
    window.location.reload();
  };

  return (
    <div className="relative w-full h-[100%] flex flex-col justify-center items-center ">
      {showModel && (
        <AssignPropertyManager
          isOpen={showModel}
          onClose={handleClose}
          setShowModel={() => setShowModel(false)}
          propertyManagers={propertyManagersdata}
          onSelect={handlePropertyManagerSelect}
        />
      )}

      <div className="mx-auto w-[90%] md:w-[70%] my-4">
        <div className="items-center justify-center">
          <div className="text-center space-y-1">
            {/* Remove Bugs Reports 10 Nov Bug*/}
            {/* <h1 className="text-3xl md:text-4xl font-bold text-[#3E5CAA] euclidbold">
              Condominium Portal
            </h1>
            <p className="text-black text-base md:text-lg">
              New Contracting job opportunities everyday!
            </p> */}
            <h2 className="text-4xl md:text-5xl leading-[4rem] pt-2 euclidbold text-[#0893bd] ">
              Welcome
            </h2>
            <p className="text-black text-opacity-50 text-sm">
              {loginRoleText}
            </p>
          </div>

          {/* Login Form Start Here  */}
          <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <div className="">
              <div className="relative my-1">
                <input
                  placeholder={
                    location.pathname.includes("owners") ||
                    location.pathname.includes("tenant") ||
                    location.pathname.includes("boardmember") ||
                    location.pathname.includes("securitymember")
                      ? "Enter Email Id"
                      : "Enter UserId"
                  }
                  type="text"
                  id="userId"
                  name="userId"
                  {...register("userId")}
                  className={`bg-[#FFFFFF80] pl-5 h-11 w-full rounded text-black px-2 ps-10 focus:outline-none focus:ring-[0.5px] focus:ring-secondaryl ${
                    errors.userId ? "ring-[0.5px] ring-red-500" : ""
                  }`}
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <UserIdIcon />
                </div>
                <label
                  htmlFor="userId"
                  className={`absolute left-0 -top-6 cursor-text euclidbold text-label uppercase text-xs bg-inherit ${
                    errors.userId ? "text-red-500" : ""
                  }`}
                >
                  {loginFieldName}
                  <span className="text-red-500 text-base">*</span>
                </label>
              </div>
              {/* Error Message and Forgot User ID */}
              <div className="flex justify-between mb-4 mt-1">
                <div className="h-[2vh] ">
                  {errors.userId && (
                    <p className="text-red-500 text-xs  leading-none">
                      {errors.userId.message}
                    </p>
                  )}
                </div>
                {/* <div className="h-[2vh] ">
                  {forgotField && (
                    <Link to="/">
                      <p className=" text-right text-xs text-opacity-50 text-[#666666] euclidbold leading-none">
                        Forgot ID?
                      </p>
                    </Link>
                  )}
                </div> */}
              </div>
            </div>
            <div className="mt-[35px]">
              <div className="relative my-1">
                <input
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  {...register("password")}
                  className={`bg-[#FFFFFF80] pl-5 h-11 w-full rounded text-black px-2 ps-10 focus:outline-none focus:ring-[0.5px] focus:ring-secondaryl password"
                   ${errors.password ? "ring-[0.5px] ring-red-500" : ""}`}
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <PasswordIcon />
                </div>
                <label
                  htmlFor="password"
                  className={`absolute cursor-text left-0 -top-6 euclidbold text-label uppercase text-xs bg-inherit ${
                    errors.password ? "text-red-500" : ""
                  }`}
                >
                  Password
                  <span className="text-red-500 text-base">*</span>
                </label>

                <div
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="text-lg mr-2 transition-colors hover:text-gray-500"
                  />
                </div>
              </div>

              {/* Error Message and Forgot Password Link for Password */}
              <div className="flex justify-between mb-4 mt-1">
                <div className="h-[2vh] ">
                  {errors.password && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="h-[2vh]">
                  {(location.pathname.includes("corporation") ||
                    location.pathname.includes("contractor") ||
                    location.pathname.includes("owner") ||
                    location.pathname.includes("tenant")) && (
                    <>
                      {location.pathname.includes("corporation") ? (
                        loginRoleText.includes("Property Manager") ? (
                          <Link
                            to={{
                              pathname: "/corporations/reset",
                              state: {
                                userType: loginRoleText,
                              },
                            }}
                          >
                            <p className="text-right text-xs text-[#666666] leading-none">
                              Forgot password?
                            </p>
                          </Link>
                        ) : loginRoleText.includes("Security members") ? (
                          <Link
                            to={{
                              pathname: "/corporations/securitymember/reset",
                              state: {
                                userType: loginRoleText,
                              },
                            }}
                          >
                            <p className="text-right text-xs text-[#666666] leading-none">
                              Forgot password?
                            </p>
                          </Link>
                        ) : (
                          <Link to="/corporations/boardmember/reset">
                            <p className="text-right text-xs text-[#666666] leading-none">
                              Forgot your password?
                            </p>
                          </Link>
                        )
                      ) : location.pathname.includes("contractor") ? (
                        <Link
                          to={{
                            pathname: "/contractors/reset",
                            state: {
                              userType: "contractor",
                            },
                          }}
                        >
                          <p className="text-right text-xs text-[#666666] leading-none">
                            Forgot password?
                          </p>
                        </Link>
                      ) : location.pathname.includes("owner") ? (
                        <Link
                          to={{
                            pathname: "/owners/reset",
                            state: {
                              userType: "owner",
                            },
                          }}
                        >
                          <p className="text-right text-xs text-[#666666] leading-none">
                            Forgot password?
                          </p>
                        </Link>
                      ) : location.pathname.includes("tenant") ? (
                        <Link
                          to={{
                            pathname: "/tenants/reset",
                            state: {
                              userType: "tenant",
                            },
                          }}
                        >
                          <p className="text-right text-xs text-[#666666] leading-none">
                            Forgot password?
                          </p>
                        </Link>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Submit Button */}
            <div className="">
              <button
                type="submit"
                className="btn px-3 py-2 mx-auto ring-1 ring-secondaryl w-fit flex justify-center text-[#3e5caa] text-xl euclidbold tracking-widest hover:bg-secondaryl  focus:outline-none hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 outline-none"
              >
                LOGIN
              </button>
            </div>
          </form>

          {/* Go For Signup  */}
          {(location.pathname.includes("corporation") ||
            location.pathname.includes("contractor") ||
            location.pathname.includes("owners") ||
            location.pathname.includes("tenant")) &&
            // Reson of the Reaming Logic of the Tenats and Owner
            requiredRegister && (
              <div>
                {/* OR Divider */}
                <div className="flex items-center justify-center mt-3">
                  <div className="w-1/4 border-t border-gray-400"></div>
                  <span className="px-1 text-[#3e5caa] text-sm font-medium leading-none">
                    OR
                  </span>
                  <div className="w-1/4 h-px border-t border-gray-400"></div>
                </div>
                <div className="text-center z-10 mb-3 text-black text-base mt-3">
                  <span className="font-medium">Don’t have an account?</span>

                  {location.pathname.includes("corporation") && (
                    <Link to="/corporations/signup">
                      <span className="font-bold text-[#3e5caa] hover:text-label euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  )}

                  {location.pathname.includes("contractor") && (
                    <Link to="/contractors/signup">
                      <span className="font-bold text-[#3e5caa] hover:text-label euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  )}

                  {location.pathname.includes("owners") && (
                    <Link to="/owners/signup">
                      <span className="font-bold text-[#3e5caa] hover:text-label euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  )}

                  {location.pathname.includes("tenant") && (
                    <Link to="/tenant/signup">
                      <span className="font-bold text-[#3e5caa] hover:text-label euclidbold">
                        {" "}
                        Register Now
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
