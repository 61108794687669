import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Loading from "../../../../components/Elements/Loading";
import MeetingCardSkeleton from "../../../../components/Resident/Meetings/MoMCardSkeleton";
import MeetingRequestCard from "../../../../components/Corporation/Dashboard/Meetings/MeetingRequestCard";
import toast from "react-hot-toast";
import axios from "axios";
import {
  meetingAPI,
  meetingDocumentRequestOwnersAPI,
  meetingDocumentRequestTenantAPI,
} from "../../../../constants/API";
import MeetingRequestView from "../../../../components/Corporation/Dashboard/Meetings/MeetingRequestView";

function CorporationMeetingsRequest() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [selectStatusType, setSelectStatusType] = useState("All");
  const [filterData, setFilterData] = useState([...data]);
  const [requestData, setRequestData] = useState([]);
  const [approveData, setApproveData] = useState([]);
  const [rejectData, setRejectData] = useState([]);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const handleSelectStatusType = (status, selectedStatusData) => {
    setSelectStatusType(status);
    setFilterData(selectedStatusData);
  };

  const fetchData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${meetingAPI}/residentdocumentrequest`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      setData(response.data.data);
      setFilterData(response.data.data);
      setApproveData(
        response.data.data.filter((meeting) => meeting.Status === 1)
      );
      setRequestData(
        response.data.data.filter((meeting) => meeting.Status === 0)
      );
      setRejectData(
        response.data.data.filter((meeting) => meeting.Status === 2)
      );
      setSelectStatusType("All");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showRejectionModalHandler = () => {
    setShowRejectionModal(!showRejectionModal);
  };

  const updateRequestStatus = async (ID, status, isOwner) => {
    setPageLoading(true);
    try {
      // console.log("ID", ID);
      // console.log("status", status);

      const url = isOwner
        ? `${meetingDocumentRequestOwnersAPI}/${ID}`
        : `${meetingDocumentRequestTenantAPI}/${ID}`;

      let response = await axios.put(
        url,
        { Status: status },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      fetchData();
      toast.success(response.data.message);
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setPageLoading(false);
    }

    // console.log("update request status");
  };

  const handleViewMeeting = (meeting) => {
    setSelectedMeeting(meeting);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMeeting(null);
  };

  return (
    <div className="">
      <Loading load={pageLoading} />
      <div className="w-full  rounded-2xl px-4 m-auto p-6  flex flex-col justify-center">
        <div className="flex justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Manage MoM Request
          </h1>
        </div>
        <div className="flex flex-wrap gap-5 justify-between items-center py-3">
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "All"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => {
              handleSelectStatusType("All", data);
            }}
          >
            All
          </div>
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectStatusType === "Request"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Request", requestData)}
          >
            Request
          </div>
          <div
            className={`text-secondaryl font-medium text-2xl cursor-pointer ${
              selectStatusType === "Approve"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Approve", approveData)}
          >
            Approve
          </div>
          <div
            className={`text-secondaryl font-medium text-2xl cursor-pointer ${
              selectStatusType === "Reject"
                ? "text-opacity-100"
                : "text-opacity-50"
            }`}
            onClick={() => handleSelectStatusType("Reject", rejectData)}
          >
            Reject
          </div>
        </div>
        <div className="contracts_listing">
          {load ? (
            <MeetingCardSkeleton />
          ) : filterData?.length !== 0 ? (
            filterData?.map((meetingData, index) => (
              <MeetingRequestCard
                key={index}
                meeting={meetingData}
                onView={() => handleViewMeeting(meetingData)}
                updateRequestStatus={updateRequestStatus}
              />
            ))
          ) : (
            <div className="text-center text-2xl text-infromation mt-10">
              MoM Document {selectStatusType !== "All" ? selectStatusType : ""}{" "}
              Request are not available
            </div>
          )}
        </div>
      </div>
      {/* Add/Edit Meeting Modal */}
      {showModal && (
        <MeetingRequestView
          meeting={selectedMeeting}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default CorporationMeetingsRequest;
