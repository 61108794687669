import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { complaintSuggestionSchema } from "../../../schema";
import InputBox from "../../Elements/InputBox";
import axios from "axios";
import {
  ownersComplaintSuggestionAPI,
  tenantComplaintSuggestionAPI,
} from "../../../constants/API";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";
import Loading from "../../Elements/Loading";

const ComplaintSuggestionModal = ({ onClose, fetchData }) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(complaintSuggestionSchema) });
  const [load, setLoad] = useState(false);

  const cookies = new Cookies();

  // console.log(errors);

  const onSubmit = async (data) => {
    // console.log(data);

    try {
      setLoad(true);
      const api =
        cookies.get("companyType") === "owners"
          ? ownersComplaintSuggestionAPI
          : tenantComplaintSuggestionAPI;
      const response = await axios.post(api, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      fetchData();
      // console.log(response.data);
      toast.success(`PIC added successfully`);
      onClose();
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      {load && <Loading load={load} />}
      <style>{`body { overflow: hidden; }`}</style>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            Add Complaint/Suggestion
          </h2>
          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✖
          </button>
        </div>

        <div className=" mb-3">
          {/* Label */}
          <label
            htmlFor="Description"
            className={`block font-normal text-secondaryl`}
          >
            Write your Complaint/Suggestion
          </label>
          {/* Input */}
          <textarea
            type="text"
            id="Description"
            name="Description"
            {...register("Description")}
            className={`bg-[#FFFFFF80] focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none block h-40 max-h-40 min-h-40 w-full rounded-sm text-black px-2 ps-10 focus:border-rose-600 ${
              errors.Description ? "ring-1 ring-red-500" : ""
            }`}
            placeholder="Type Complaint/Suggestion"
          />

          {/* Error */}
          <div className="h-[2vh] pt-1">
            {errors.Description && (
              <p className="text-red-500 text-xs  leading-none">
                {errors.Description.message}
              </p>
            )}
          </div>
        </div>
        {/* Save Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
          h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  `}
          >
            Add Complaint/Suggestion
          </button>
        </div>
      </form>
    </div>
  );
};

export default ComplaintSuggestionModal;
