import { createSlice } from "@reduxjs/toolkit";

const contractorSlice = createSlice({
  name: "corporationformslice",
  initialState: {},
  reducers: {},
});

export const contractorSliceAtctions = contractorSlice.actions;

export default contractorSlice;
