import React from "react";
import Skeleton from "react-loading-skeleton";

function ClientReviewCardSkeleton({ cards = 5 }) {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div
        key={i}
        className="flex flex-col  lg:flex-row  px-1.5 md:px-3 py-3 bg-[#3E5CAA0D] justify-center rounded-2xl gap-5"
      >
        <div className="p-2">
          <div className="lg:w-24 flex items-center flex-col gap-2 ">
            <Skeleton circle={true} height={120} width={120} />
          </div>
          <div className="space-y-2 text-center ">
            <h1 className="text-2xl  justify-center truncate ">
              <Skeleton />
            </h1>
            <div className="mx-auto text-base space-x-2 w-2/3">
              <Skeleton />
            </div>
          </div>
        </div>

        <Skeleton width={5} height="100%" className="hidden lg:block" />

        <div className="w-full flex-col justify-start mx-auto ">
          <div className="w-full flex-col justify-start ">
            <div className="w-1/2 text-4xl">
              <Skeleton />
            </div>

            {/* Description */}
            <p className="text-base mt-3">
              <Skeleton count={3} />
            </p>
          </div>
        </div>
      </div>
    ));
}

export default ClientReviewCardSkeleton;
