import React, { useState } from "react";
import axios from "axios";
import { adminAPI, baseUrl } from "../../constants/API";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../components/Elements/Loading";
import Layout from "../../components/Layout/Layout";

const AdminLogin = () => {
  const [error, setError] = useState(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    try {
      setLoad(true);
      const response = await axios.post(`${adminAPI}/login`, data);
      if (response.data.data) {
        cookies.set("token", response.data.data);
        cookies.set("companyType", "admin");
        navigate("/admin");
      }
      toast.success(response.data.message);
      setError(null);
    } catch (error) {
      console.error("Error:", error.response.data);
      toast.error(error.response.data.message);
      setError(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      // console.log(resetEmail);
      setLoad(true);
      const response = await axios.post(`${baseUrl}/sendresetpasswordmail`, {
        email: resetEmail,
        userType: "admin",
      });
      // console.log(response);
      // toast.success(response.data.message);
      setShowResetModal(false);
      setResetEmail("");
    } catch (error) {
      console.error("Error:", error.response);
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  return (
    <Layout>
      <div>
        <Toaster />
        <Loading load={load} />
        <div className="pt-10">
          <div className="max-w-md mx-auto  p-6 bg-white rounded-md shadow-md">
            <h1 className="text-3xl font-bold mb-6 euclidbold text-primaryd">
              Login
            </h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="border focus:ring-1 focus:ring-cyan-500 border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="border border-gray-300 focus:ring-1 focus:ring-cyan-500 rounded-md px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div
                className="w-full flex items-center justify-end mb-2 cursor-pointer"
                onClick={() => setShowResetModal(true)}
              >
                Reset Your Password
              </div>
              <button
                type="submit"
                className="bg-primaryl text-white font-bold py-2 px-4 rounded focus:outline-none hover:bg-cyan-600"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>

      {showResetModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-black opacity-50 absolute inset-0"></div>
          <div className="bg-white p-6 rounded-md shadow-md z-10 max-w-sm w-full">
            <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
            <form onSubmit={handlePasswordReset}>
              <div className="mb-4">
                <label
                  htmlFor="resetEmail"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="resetEmail"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="border focus:ring-1 focus:ring-cyan-500 border-gray-300 rounded-md px-3 py-2 w-full focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-primaryl text-white font-bold py-2 px-4 rounded focus:outline-none hover:bg-cyan-600"
              >
                Submit
              </button>
              <button
                type="button"
                className="ml-2 bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none hover:bg-gray-600"
                onClick={() => setShowResetModal(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdminLogin;
