import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { useLoading } from "../../../../context/LoadingContext";
import { Icon } from "@iconify/react";
import Loading from "../../../../components/Elements/Loading";
import NoticeCard from "../../../../components/Corporation/Dashboard/Documents/Notice/NoticeCard";
import NoticeCardSkeleton from "../../../../components/Corporation/Dashboard/Documents/Notice/NoticeCardSkeleton";
import NoticeModal from "../../../../components/Corporation/Dashboard/Documents/Notice/NoticeModal";
import NoticeReadModal from "../../../../components/Corporation/Dashboard/Documents/Notice/NoticeReadModal";
import ContractCardSkeleton from "../../../../components/Contracts/ContractCardSkeleton";
import { corporationNoticeAPI } from "../../../../constants/API";
import axios from "axios";
import { set } from "react-hook-form";

const CorporationNotices = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const path = useLocation().pathname;
  const [notices, setNotices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [isEditNotice, setIsEditNotice] = useState(false);

  const fetchNotice = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${corporationNoticeAPI}/corporation`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      setNotices(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotice(null);
    if (isEditNotice) setIsEditNotice(false);
  };

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full  rounded-2xl px-4 m-auto  p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Notice
          </h1>

          {cookies.get("access") === "property manager" && (
            <div
              className="h-11 w-fit px-2.5 py-1.5 items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center bg-secondaryl text-[#ffffff] text-sm truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2"
              onClick={() => setShowModal(true)}
            >
              <Icon
                icon="fluent-mdl2:calculator-addition"
                className="font-bold"
              />
              Add Notice
            </div>
          )}
        </div>

        <div className="">
          {load ? (
            <ContractCardSkeleton />
          ) : notices.length > 0 ? (
            notices.map((notice, index) => (
              <div key={index}>
                <NoticeCard
                  notice={notice}
                  setSelectedNotice={setSelectedNotice}
                  setIsEditNotice={setIsEditNotice}
                  setShowModal={setShowModal}
                  fetchData={fetchNotice}
                />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4 text-center">
              No Notices found
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className={`fixed inset-0 z-30 overflow-y-auto bg-[#231F20B2] `}>
          <Loading load={load} />

          <div className="flex justify-center items-center h-full">
            <div className="bg-white max-h-fit rounded-lg md:w-[60%] w-4/5 h-[80%] overflow-y-auto relative">
              <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                {showModal && cookies.get("access") === "property manager" && (
                  <NoticeModal
                    onClose={handleCloseModal}
                    fetchNotice={fetchNotice}
                    isEditNotice={isEditNotice}
                    setIsEditNotice={setIsEditNotice}
                    selectedNotice={selectedNotice}
                    setLoad={setLoad}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorporationNotices;
