import React from "react";

function Star({className}) {
  return (
    <svg
     className={className}
      width="45"
      height="43"
      viewBox="0 0 45 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2797 2.27788C20.8584 0.979425 21.1479 0.3302 21.5509 0.130275C21.9009 -0.043425 22.3119 -0.043425 22.6619 0.130275C23.0649 0.3302 23.3544 0.979425 23.9332 2.27788L28.5432 12.6202C28.7144 13.0041 28.7999 13.196 28.9324 13.343C29.0494 13.473 29.1927 13.577 29.3524 13.6482C29.5332 13.7287 29.7422 13.7508 30.1602 13.7949L41.4209 14.9834C42.8347 15.1326 43.5414 15.2072 43.8562 15.5287C44.1294 15.8079 44.2564 16.1989 44.1994 16.5855C44.1339 17.0305 43.6059 17.5062 42.5499 18.458L34.1382 26.0385C33.8262 26.3197 33.6699 26.4605 33.5712 26.6318C33.4837 26.7835 33.4289 26.9517 33.4107 27.1257C33.3899 27.3225 33.4334 27.528 33.5207 27.9393L35.8702 39.016C36.1652 40.4067 36.3127 41.102 36.1042 41.5005C35.9229 41.847 35.5904 42.0885 35.2052 42.1537C34.7617 42.2287 34.1459 41.8738 32.9144 41.1635L23.1057 35.506C22.7417 35.296 22.5597 35.1912 22.3662 35.15C22.1949 35.1138 22.0179 35.1138 21.8467 35.15C21.6532 35.1912 21.4712 35.296 21.1072 35.506L11.2985 41.1635C10.067 41.8738 9.45131 42.2287 9.00779 42.1537C8.62251 42.0885 8.28991 41.847 8.10881 41.5005C7.90034 41.102 8.04781 40.4067 8.34278 39.016L10.6922 27.9393C10.7794 27.528 10.823 27.3225 10.8023 27.1257C10.784 26.9517 10.7293 26.7835 10.6418 26.6318C10.5429 26.4605 10.3868 26.3197 10.0746 26.0385L1.66308 18.458C0.60706 17.5062 0.0790349 17.0305 0.0133849 16.5855C-0.0436151 16.1989 0.0834101 15.8079 0.35676 15.5287C0.67146 15.2072 1.37833 15.1326 2.79208 14.9834L14.0528 13.7949C14.4708 13.7508 14.6797 13.7287 14.8604 13.6482C15.0203 13.577 15.1634 13.473 15.2806 13.343C15.413 13.196 15.4985 13.0041 15.6696 12.6202L20.2797 2.27788Z"
        fill="#FFC107"
      />
    </svg>
  );
}

export default Star;
