import {
  UserIdIcon,
  LoginVectorLeft,
  LoginVectorRight,
  LogoWithoutText,
} from "../../assets/images/index";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { resetPasswordMailAPI } from "../../constants/API";
import { useEffect, useState } from "react";
import Loading from "../../components/Elements/Loading";

function SendMail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [userType, setUserType] = useState(""); // Refactored to handle multiple user types

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("User ID or email is required")
      .test("valid-userIdOrEmail", "Invalid user ID or email", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of either a user ID or an email
        return (
          /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value) ||
          /^[a-zA-Z0-9]+$/.test(value)
        );
      }),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    // Determine the user type based on the path
    if (location.pathname.includes("corporations")) {
      setUserType("corporation");
      if (location.pathname.includes("propertymanager")) {
        setUserType("propertymanager");
      } else if (location.pathname.includes("boardmember")) {
        setUserType("boardmember");
      } else if (location.pathname.includes("securitymember")) {
        setUserType("securitymember");
      }
    } else if (location.pathname.includes("contractor")) {
      setUserType("contractor");
    } else if (location.pathname.includes("owner")) {
      setUserType("owner");
    } else if (location.pathname.includes("tenant")) {
      setUserType("tenant");
    }
  }, [location.pathname]);

  const onSubmit = async (data) => {
    setLoad(true);
    try {
      if (userType) {
        const res = await axios.post(resetPasswordMailAPI, {
          email: data.email,
          userType: userType,
        });
        if (res.status === 200) {
          toast.success("Reset Password Mail Sent Successfully", {
            duration: 4000,
          });
          navigate("/"); // Redirect to home
        }
        // console.log(res);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.response?.data?.message || "Error sending reset mail");
    } finally {
      setLoad(false);
    }
  };

  return (
    <Layout>
      <Loading load={load} />
      <div className="relative w-full h-[80vh] flex justify-center items-center">
        <div className="flex px-3 flex-col justify-center items-center space-y-1">
          <h1 className="text-5xl euclidbold text-[#0893bd] text-center">
            Welcome
          </h1>
          <p className="text-black text-opacity-50 text-sm text-center">
            Reset Your Password
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-5 w-full">
            <div className="relative">
              <input
                type={userType === "contractor" ? "text" : "email"}
                id="email"
                name="email"
                {...register("email")}
                className={`bg-[#FFFFFF80] pl-5 h-11 w-full rounded text-black px-2 ps-10 focus:outline-none focus:ring-[0.5px] focus:ring-secondaryl  ${
                  errors.email ? "ring-[0.5px] ring-red-500" : ""
                }`}
              />
              <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <UserIdIcon />
              </div>
              <label
                htmlFor="email"
                className={`absolute cursor-text left-0 -top-5 euclidbold text-label uppercase text-xs bg-inherit ${
                  errors.email ? "text-red-500" : ""
                }`}
              >
                EMAIL ID Or USER ID
              </label>
            </div>

            {/* Submit Button */}
            <div className="my-3 pt-3">
              <button
                type="submit"
                className="btn px-3 py-2 mx-auto w-fit flex justify-center text-secondaryl text-xl euclidbold tracking-widest hover:bg-secondaryl  focus:outline-none hover:text-white"
              >
                Reset Password
              </button>
            </div>
          </form>

          {/* Go For Signup */}
          <div>
            <div className="flex items-center justify-center my-3">
              <div className="w-1/4 border-t border-gray-400"></div>
              <span className="px-1 text-[#3e5caa] text-sm font-medium leading-none">
                OR
              </span>
              <div className="w-1/4 h-px border-t border-gray-400"></div>
            </div>

            <div className="text-center z-10 text-black text-base">
              <span className="font-medium">Don’t have an account?</span>

              {userType === "corporation" || userType === "propertymanager" ? (
                <Link to="/corporations/signup">
                  <span className="font-bold text-secondaryl hover:text-opacity-95 euclidbold">
                    {" "}
                    Register Now
                  </span>
                </Link>
              ) : (
                <Link to="/contractors/signup">
                  <span className="font-bold text-secondaryl hover:text-opacity-95 euclidbold">
                    {" "}
                    Register Now
                  </span>
                </Link>
              )}

              {/* Add links for Owners and Tenants */}
              {userType === "owner" && (
                <Link to="/owners/signup">
                  <span className="font-bold text-secondaryl hover:text-opacity-95 euclidbold">
                    {" "}
                    Register Now
                  </span>
                </Link>
              )}

              {userType === "tenant" && (
                <Link to="/tenants/signup">
                  <span className="font-bold text-secondaryl hover:text-opacity-95 euclidbold">
                    {" "}
                    Register Now
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SendMail;
