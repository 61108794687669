import React, { useEffect, useState } from "react";

function InputBoxTimeLive({
  title,
  register,
  filedName,
  errors,
  placeHolder = "",
  className,
  type = "time",
  width = "w-full",
  min,
  max,
  maxLength = "",
  schema,
  setError,
}) {
  return (
    <div>
      <div className="flex gap-2">
        {/* Label */}
        <label
          htmlFor={filedName}
          className="block font-normal mt-1 text-secondaryl"
        >
          {title}
        </label>
        {/* Input */}
        <input
          type={type}
          id={filedName}
          name={filedName}
          {...register(filedName)}
          className={` block h-11  ${width} rounded-sm text-black   px-2  ps-10 bg-[#FFFFFF80]  focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
            errors[filedName] ? "ring-[0.5px] ring-red-500" : ""
          } ${className}`}
          placeholder="hrs:mins"
          // style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}

          // Conditionally attach the event listener
        />
      </div>
      {/* Error */}
      <div className="h-[3vh] pt-1 max-w-18">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
}

export default InputBoxTimeLive;
