import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { picSchema } from "../../../../schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "universal-cookie";
import ReadTextArea from "../../../Elements/ReadTextArea";
import axios from "axios";
import toast from "react-hot-toast";
import Loading from "../../../Elements/Loading";

const IncidentReadModal = ({ data, handleView, fetchData, isViewModal }) => {
  const {
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(picSchema),
  });
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [secuirtyMemberData, setSecuirtyMemberData] = useState({});

  const assignData = async () => {
    if (data) {
      reset(data);
      if (
        cookies.get("access") === "property manager" ||
        cookies.get("access") === "board member"
      ) {
        setSecuirtyMemberData(data.SecurityMember);
      }
    } else {
      reset();
    }
  };

  useEffect(() => {
    assignData();
  }, [data, reset]);

  return (
    <>
      <div
        className={`fixed inset-0 z-30 overflow-y-auto pt-32 bg-black bg-opacity-30`}
      >
        <style>{`body { overflow: hidden; }`}</style>
        <Loading load={load} />
        <div className="flex  justify-center items-center  bg-transparent">
          <div className="bg-white rounded-2xl w-[80%] overflow-auto ">
            <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                  Details Incident
                </h2>
                <button
                  onClick={() => handleView(false)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <Icon
                    icon="carbon:close-filled"
                    className="text-secondaryl hover:text-opacity-95 text-3xl"
                  />
                </button>
              </div>

              <form className="my-3">
                {(cookies.get("access") === "property manager" ||
                  cookies.get("access") === "board member") && (
                  <div className="mt-4">
                    <h2 className="text-secondaryl font-extrabold euclidbold  items-center justify-start  mb-3 underline  gap-2  flex text-2xl">
                      <Icon
                        icon="material-symbols:person"
                        className="text-2xl"
                      />
                      Security Member Details
                    </h2>

                    <div className="space-y-1">
                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Security Member Name :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {`${secuirtyMemberData?.Security_Member_Name} `}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Security Member Email Id :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {secuirtyMemberData?.Security_Member_Email}{" "}
                        </span>
                      </div>

                      <div>
                        {" "}
                        <label htmlFor="" className="text-secondaryl">
                          Security Member Contact No :{" "}
                        </label>{" "}
                        <span className="text-infromation">
                          {" "}
                          {secuirtyMemberData?.Security_Member_Phone_No}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-secondaryl mt-3 euclidbold text-xl break-words line-clamp-3 mb-1">
                  Incident Name : {data?.Incident?.Name}
                </div>

                <div className="mb-3 mt-3">
                  <ReadTextArea
                    title={`Description`}
                    register={register}
                    filedName={"Description"}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    cursorNotAllowed={false}
                  />
                </div>

                <div className="flex flex-wrap gap-5 justify-between ">
                  <button
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                    onClick={(e) => handleView(false)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentReadModal;
