import React, { useEffect, useState } from "react";
import Maintenance from "../../components/Resident/Details/Maintenance";
import Service from "../../components/Resident/Details/Service";
import DropDown from "../../components/Resident/Details/DropDown";
import { getDaysInMonth, format, addMonths, set } from "date-fns";
import { getServiceCorporationByResident } from "../../constants/Request";
import toast from "react-hot-toast";

const generateDays = (numDays) => {
  return Array.from({ length: numDays }, (_, i) => {
    const day = i + 1;
    return { label: day.toString(), value: day };
  });
};

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - 2000 }, (_, i) => {
    const year = 2001 + i;
    return { label: year.toString(), value: year };
  });
};

const generateMonths = () => {
  const months = [];
  const currentYear = new Date().getFullYear();
  const baseDate = new Date(currentYear, 0); // January 2024
  for (let i = 0; i < 12; i++) {
    const monthDate = addMonths(baseDate, i);
    months.push({
      label: format(monthDate, "MMMM"), // Full month name
      value: i + 1, // Month number (1-12)
    });
  }
  return months;
};

const generateDaysInMonth = (month, year) => {
  if (!month) {
    month = new Date().getMonth() + 1;
  } else {
    month = month - 1;
  }

  if (!year) {
    year = new Date().getFullYear();
  }

  const numDays = getDaysInMonth(new Date(year, month));

  const day = Array.from({ length: numDays }, (_, i) => {
    const day = i + 1;
    return { label: day.toString(), value: day };
  });

  return day;
};

function Details() {
  const [selectTab, setSelectTab] = useState("service");
  const [load, setLoad] = useState(false);

  const [selectStatus, setSelectStatus] = useState("");
  const [selectService, setSelectService] = useState("");
  const [selectMonth, setSelectMonth] = useState("");
  const [selectDay, setSelectDay] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const yearOptions = generateYears();
  const [dayOptions, setDayOptions] = useState(generateDays(31));
  const monthOptions = generateMonths();
  const [serviceOptions, setServiceOptions] = useState([]);

  const statusOption = [
    { label: "All Request", value: "" },
    { label: "Approve Request", value: 1 },
    { label: "Reject Request", value: 2 },
    { label: "Pending Request", value: 0 },
  ];

  const getService = async () => {
    setLoad(true);
    try {
      let data = await getServiceCorporationByResident();
      data = data.map((item) => {
        return { label: item.Service_Name, value: item.ID };
      });
      setServiceOptions(data);
    } catch (error) {
      toast.error("Something went wrong. Please try again later");
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    const days = generateDaysInMonth(selectMonth?.value, selectYear?.value);
    setDayOptions(days);

    if (selectDay && days.length) {
      const lastDay = days[days.length - 1].value;
      if (selectDay > lastDay) {
        setSelectDay(lastDay);
      }
    }
  }, [selectMonth, selectYear]);

  const handleSelectTab = (tab) => {
    setSelectTab(tab);
  };

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <div className="flex pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Details
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5 w-full mt-3 mb-2">
          {/* Dropdown Sort  */}
          <div className="flex flex-col">
            <span className="block w-full font-normal text-secondaryl mb-1">
              Status
            </span>
            <DropDown
              title={"Select Status"}
              options={statusOption}
              onSelect={setSelectStatus}
              selectOption={selectStatus}
            />
          </div>

          {selectTab === "service" && (
            <div className="flex flex-col">
              <span className="block w-full font-normal text-secondaryl mb-1">
                Service Type
              </span>
              <DropDown
                title={"Select Service"}
                options={serviceOptions}
                onSelect={setSelectService}
                selectOption={selectService}
              />
            </div>
          )}

          <div className="flex flex-col">
            <span className="block w-full font-normal text-secondaryl mb-1">
              Date
            </span>
            <DropDown
              title={"Select Date"}
              options={dayOptions}
              onSelect={setSelectDay}
              selectOption={selectDay}
            />
          </div>

          <div className="flex flex-col">
            <span className="block w-full font-normal text-secondaryl mb-1">
              Month
            </span>
            <DropDown
              title={"Select Month"}
              options={monthOptions}
              onSelect={setSelectMonth}
              selectOption={selectMonth}
            />
          </div>

          <div className="flex flex-col">
            <span className="block w-full font-normal text-secondaryl mb-1">
              Year
            </span>
            <DropDown
              title={"Select Year"}
              options={yearOptions}
              onSelect={setSelectYear}
              selectOption={selectYear}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-5 justify-around  items-center py-3">
          <div
            className={`text-secondaryl font-medium euclidbold	 text-2xl cursor-pointer ${
              selectTab === "service"
                ? "text-opacity-100 euclidbold"
                : "text-opacity-50 euclidsemibold"
            }`}
            onClick={() => {
              handleSelectTab("service");
            }}
          >
            Service
          </div>
          <div
            className={`text-secondaryl font-medium	 text-2xl cursor-pointer ${
              selectTab === "maintenance"
                ? "text-opacity-100 euclidbold"
                : "text-opacity-50 euclidsemibold"
            }`}
            onClick={() => handleSelectTab("maintenance")}
          >
            Maintenance
          </div>
        </div>

        <div className="w-full">
          {selectTab === "service" && (
            <Service
              selectMonth={selectMonth}
              selectDay={selectDay}
              selectYear={selectYear}
              selectStatus={selectStatus}
              selectService={selectService}
            />
          )}

          {selectTab === "maintenance" && (
            <Maintenance
              selectMonth={selectMonth}
              selectDay={selectDay}
              selectYear={selectYear}
              selectStatus={selectStatus}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Details;
