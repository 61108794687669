import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import ComplaintSuggestionReadModal from "./ComplaintSuggestionReadModal";
import axios from "axios";
import {
  ownersComplaintSuggestionAPI,
  tenantComplaintSuggestionAPI,
} from "../../../constants/API";
import toast from "react-hot-toast";
import ProcessIcon from "../../../assets/images/ProcessIcon";

function ComplaintSuggestionCard({ data, onDelete, fetchData, setLoad }) {
  const cookies = new Cookies();

  const { Description, ID } = data;

  const [complaintSuggestion, setComplaintSuggestionCard] = useState({});
  const [showViewModel, setShowViewModel] = useState(false);

  const [isViewModal, setIsViewModal] = useState(false);

  const handleView = (isTrue, data, IsViewModal) => {
    if (isTrue) {
      setComplaintSuggestionCard(data);
      setShowViewModel(true);
    } else {
      setComplaintSuggestionCard({});
      setShowViewModel(false);
    }

    if (IsViewModal) {
      setIsViewModal(true);
    } else {
      setIsViewModal(false);
    }
  };

  return (
    <>
      {showViewModel && complaintSuggestion && (
        <ComplaintSuggestionReadModal
          data={complaintSuggestion}
          handleView={handleView}
          fetchData={fetchData}
          isViewModal={isViewModal}
          setLoad={setLoad}
        />
      )}

      <div
        className="w-full flex items-center lg:flex-row flex-col  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
        key={ID}
      >
        <div className="flex-col w-full  justify-center leading-normal">
          {cookies.get("companyType") === "corporation" && (
            <div className="text-secondaryl euclidbold text-2xl break-words line-clamp-3 mb-1">
              {data.Owners_ID ? "Owner" : "Tenant"}
            </div>
          )}
          <div className="text-secondaryl  text-base break-words line-clamp-3 mb-1">
            {Description}
          </div>
          {data.Status === 1 && (
            <div className="text-sm flex gap-1 items-center text-[#00A701]">
              <Icon icon="duo-icons:approved" className=" text-lg" />
              <p className="">Resolved</p>
            </div>
          )}

          {data.Status === 2 && (
            <div className="text-sm flex gap-1 text-secondaryl items-center">
              <ProcessIcon />
              <p className="">Process</p>
            </div>
          )}

          {data.Status === 0 && (
            <div className="text-sm flex gap-1 items-center text-[#FFA640]">
              <Icon icon="lsicon:minus-filled" className=" text-lg" />
              <p className="">Pending</p>
            </div>
          )}
        </div>
        {/* Card Buttons */}
        <div className="flex-col justify-center my-auto relative w-full lg:w-[20%] space-y-3">
          {cookies.get("access") === "property manager" &&
            data &&
            data?.Status !== 1 && (
              <div
                onClick={() => handleView(true, data, false)}
                className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                Add Comment
              </div>
            )}
          <div
            onClick={() => handleView(true, data, true)}
            className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            VIEW
          </div>
          {cookies.get("companyType") === "tenant" ||
            (cookies.get("companyType") === "owners" && (
              <div
                onClick={() => {
                  const confirmation = window.confirm(
                    `Are you sure you want to delete this Complaint / Suggestion ?`
                  );
                  if (confirmation) {
                    onDelete();
                  }
                }}
                className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                Delete
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ComplaintSuggestionCard;
