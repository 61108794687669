import React, { useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import CorporationSignupForm from "../../../components/Corporation/Signup/CorporationSignupForm";
import { BuildingWithManAndWomen } from "../../../assets/images";

function CorporationSignup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="overflow-hidden   overflow-x-hidden md:p-6  md:px-10">
        <div className="min-h-screen w-full flex relative gap-10 ">
          <div className="w-full md:w-[60%]">
            <CorporationSignupForm />
          </div>
          <div className="w-[40%] hidden md:block">
            <img
              src={BuildingWithManAndWomen}
              className="min-h-screen object-contain"
              alt="Singup background"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CorporationSignup;
