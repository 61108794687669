import React, { useEffect, useState } from "react";
import { ownerAPI, tenantAPI } from "../../../../constants/API";
import { NavLink, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { LogoWithText1 } from "../../../../assets/images";
import { Icon } from "@iconify/react";
import { AdminNavLinks } from "../../../../pages/AdminTest/constants/constants";

function DashboardSidebar() {
  const cookies = new Cookies();

  const [showProjectsSublist, setShowProjectsSublist] = useState(true);
  const [showResidentsSublist, setShowResidentsSublist] = useState(true);
  const [showMeetingSublist, setShowMeetingSublist] = useState(true);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  // console.log(isCollapsed);
  useEffect(() => {
    if (showProjectsSublist) {
      setShowResidentsSublist(false);
      setShowMeetingSublist(false);
      setIsCollapsed(false);
    }
  }, [showProjectsSublist]);

  // Effect to ensure when showResidentsSublist is true, showProjectsSublist becomes false
  useEffect(() => {
    if (showResidentsSublist) {
      setShowProjectsSublist(false);
      setShowMeetingSublist(false);
      setIsCollapsed(false);
    }
  }, [showResidentsSublist]);

  useEffect(() => {
    if (showMeetingSublist) {
      setShowProjectsSublist(false);
      setShowResidentsSublist(false);
      setIsCollapsed(false);
    }
  }, [showMeetingSublist]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 768) {
  //       setIsCollapsed(true); // Default collapsed on small screens
  //     } else {
  //       setIsCollapsed(false); // Default expanded on larger screens
  //     }
  //   };

  //   // Set initial state based on window width
  //   if (showProjectsSublist || showResidentsSublist) {
  //     handleResize();
  //   }
  // }, [showProjectsSublist, showResidentsSublist]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true); // Default collapsed on small screens
      } else {
        setIsCollapsed(false); // Default expanded on larger screens
      }
    };

    handleResize();
  }, []);

  // Effect to ensure when showProjectsSublist is true, showResidentsSublist becomes false

  useEffect(() => {
    if (
      location.pathname.includes("owners") ||
      location.pathname.includes("tenant")
    ) {
      setShowResidentsSublist(true);
    } else {
      setShowResidentsSublist(false);
    }

    if (
      location.pathname.includes("archivescontract") ||
      location.pathname.includes("contract")
    ) {
      setShowProjectsSublist(true);
    } else {
      setShowProjectsSublist(false);
    }

    if (
      location.pathname.includes("meetings") ||
      location.pathname.includes("tenantrequestmeetings") ||
      location.pathname.includes("ownersrequestmeetings")
    ) {
      setShowMeetingSublist(true);
    } else {
      setShowMeetingSublist(false);
    }
  }, [location]);

  return (
    <aside
      className={`my-3 ml-3 bg-gradient-to-b from-[rgba(40,42,115,0.15)] to-[rgba(0,148,191,0.15)]  rounded-2xl transition-all duration-300  ${
        isCollapsed ? "w-14" : "w-72"
      } 
    `}
    >
      <div className="flex justify-center py-2 md:py-0">
        <NavLink to="/corporations/dashboard/home">
          <img
            src={LogoWithText1} // Replace with your logo
            alt="Condominium Portal"
            className={`${
              isCollapsed ? "h-14 w-14 " : "h-40 w-40 "
            } transition-all duration-300 `}
          />
        </NavLink>
      </div>

      {/* Toggle Button */}
      <div className="flex justify-end px-2 py-1">
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-2 bg-primaryl text-white rounded-full cursor-pointer hover:bg-opacity-80"
        >
          {isCollapsed ? (
            <Icon icon="ion:chevron-forward-outline" className="text-xl" />
          ) : (
            <Icon icon="ion:chevron-back-outline" className="text-xl" />
          )}
        </div>
      </div>

      <nav className="mb-2">
        <ul className="space-y-1.5 euclidbold text-primaryl text-lg">
          {AdminNavLinks.map((link) => (
            <NavLink
              to={link.path}
              className={({ isActive }) =>
                `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                  isActive
                    ? "bg-primaryl text-white hover:bg-opacity-80"
                    : "bg-[#ffffff] bg-opacity-70"
                }`
              }
            >
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                {link.name}
              </span>
            </NavLink>
          ))}
        </ul>
      </nav>
    </aside>
  );
}

export default DashboardSidebar;
