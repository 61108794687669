import React from "react";
import Skeleton from "react-loading-skeleton";

function ContractCardSkeleton({ cards = 5 }) {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="w-full   lg:flex   rounded-md p-4  bg-[#FFFFFF80]  lg:space-x-5 mx-auto my-3 "
      >
        {/* Card Images  */}
        <div className="h-full relative lg:w-[20%] flex-none bg-cover rounded-xl text-center flex justify-center">
          <Skeleton width={190} height={130} />
        </div>

        {/* Card Content */}
        <div className="w-full  flex-col justify-start leading-normal lg:pr-10">
          <div className="space-y-1">
            {/* Title */}
            <div className="  mb-2  sm:pt-3 md:pt-3 lg:pt-0">
              <Skeleton />
            </div>
            {/* Location */}
            <p className="text-sm  flex space-x-2 ">
              <span className="text-gray-500">
                <Skeleton />
              </span>
            </p>

            {/* Description */}
            <p className=" text-base text-justify  overflow-hidden">
              <Skeleton count={3} />
            </p>
          </div>
        </div>

        <div className="py-2">
          <p
            className={`space-x-2 sm:mt-3 md:mt-auto lg:mt-auto text-sm  flex lg:ml-0`}
          >
            <Skeleton count={1} />
          </p>

          <p className="lg:ml-8 space-x-2 mt-2  text-sm  flex ite">
            <Skeleton count={1} />
          </p>
        </div>
      </div>
    ));
}
export default ContractCardSkeleton;
