import { configureStore } from "@reduxjs/toolkit";
import corporationSlice from "./Corporation/corporationSlice";
import contractorSlice from "./Contractor/contractorSlice";
import authSlice from "./Auth/Auth";

const store = configureStore({
  reducer: {
    corporation: corporationSlice.reducer,
    contractor: contractorSlice.reducer,
    auth: authSlice.reducer,
  },
});

export default store;
