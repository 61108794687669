import React, { useEffect, useState } from "react";
import ContractCardSkeleton from "../Contracts/ContractCardSkeleton.jsx";
import { Toaster } from "react-hot-toast";
import Pagination3 from "../Elements/Pagination3.jsx";
import ServiceCard from "./ServiceCard.jsx";
import SubServiceCard from "./SubServiceCard.jsx";

function DisplayService({
  title,
  services,
  load,
  fetchData,
  loading,
  containerRef,
  setServices,
  setLoad,
}) {
  const [dynamicHeight, setDynamicHeight] = useState("60px");
  useEffect(() => {
    if (services.length < 2) {
      setDynamicHeight("12rem");
    } else if (services.length < 3) {
      setDynamicHeight("24rem");
    } else if (services.length < 4) {
      setDynamicHeight("36rem");
    } else if (services.length < 5) {
      setDynamicHeight("48rem");
    } else {
      setDynamicHeight("100%");
    }
  }, [services]);

  return (
    <div className="mt-3 ">
      <div
        ref={containerRef}
        id="infinite-scroll"
        className={`contracts_listing `}
        style={{ height: dynamicHeight }}
      >
        {load ? (
          <ContractCardSkeleton />
        ) : services.length !== 0 ? (
          services.map((service, index) =>
            service.Sub_Service_ID === null ? (
              <ServiceCard
                fetchData={fetchData}
                key={index}
                service={service}
                setServices={setServices}
                setLoad={setLoad}
              />
            ) : (
              <SubServiceCard
                fetchData={fetchData}
                key={index}
                service={service}
                setServices={setServices}
                setLoad={setLoad}
              />
            )
          )
        ) : (
          !loading && (
            <div className="text-center text-2xl text-infromation mt-10">
              {title} are not available
            </div>
          )
        )}
        {/* Loading for infinite scroll */}
        {loading && <div className="size-14 mx-auto spin-loader my-16"></div>}
      </div>
    </div>
  );
}

export default DisplayService;
