import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const ServiceViewModel = ({ service, setShowServiceViewModel }) => {
  return (
    <>
      {service ? (
        <div
          className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
                `}
        >
          <style>{`body { overflow: hidden; }`}</style>

          <div className="flex justify-center items-center h-full ">
            <div className="bg-white  rounded-lg md:w-[40%] w-4/5 h-[80%] max-h-fit overflow-y-auto relative">
              <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                <div className="flex justify-between items-center mb-3">
                  <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
                    View Service
                  </h2>
                  <button
                    onClick={() => setShowServiceViewModel(false)}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    ✖
                  </button>
                </div>
                <div>
                  {service.isResident && (
                    <div className="mt-4">
                      <div className="text-secondaryl text-xl euclidbold  items-center justify-start  mb-3 underline gap-2  flex">
                        <Icon
                          icon="material-symbols:person"
                          className="text-2xl"
                        />
                        <h3 className="!font-light">Resident Details :</h3>
                      </div>

                      <div className="space-y-1">
                        <div>
                          <label htmlFor="" className="text-secondaryl mr-1">
                            Resident Type :{" "}
                          </label>
                          <span className="text-infromation">
                            {service.Resident_Type}
                          </span>
                        </div>

                        <div>
                          <label htmlFor="" className="text-secondaryl mr-1">
                            Resident Name :{" "}
                          </label>
                          <span className="text-infromation">
                            {`${service.Resident_Data?.First_Name}  ${service.Resident_Data?.Last_Name}`}
                          </span>
                        </div>

                        <div>
                          <label htmlFor="" className="text-secondaryl mr-1">
                            Resident Address :{" "}
                          </label>
                          <span className="text-infromation">
                            {service.Resident_Data?.Residential_Address}
                          </span>
                        </div>

                        <div>
                          <label htmlFor="" className="text-secondaryl mr-1">
                            Resident Contact No :{" "}
                          </label>
                          <span className="text-infromation">
                            {service.Resident_Data?.Phone_No}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-4">
                    <div className="mb-3">
                      {/* Label */}
                      <label
                        htmlFor={service.Service_Name}
                        className={`block font-normal text-secondaryl`}
                      >
                        Service Name
                      </label>
                      {/* Input */}
                      <input
                        type="text"
                        id={service.Service_Name}
                        name={service.Service_Name}
                        className={`block  h-11 w-full bg-[#3F5CA91A]  text-infromation rounded-sm px-2 ps-10 focus:outline-none`}
                        value={service.Service_Name}
                        readOnly
                        disabled
                        style={{
                          boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                        }}
                      />
                    </div>
                    <label
                      htmlFor={"Service_Description"}
                      className={`block font-normal text-secondaryl`}
                    >
                      Service Description
                    </label>
                    <textarea
                      disabled={true}
                      className={
                        "block mb-3 h-32 py-2 bg-[#3F5CA91A] resize-none  text-infromation rounded-sm  w-full px-2 ps-10 focus:outline-none"
                      }
                      title={"Service Description"}
                      value={service.Service_Description}
                    />
                    {service.Book_From_Date && service.Book_To_Date && (
                      <>
                        <label
                          htmlFor={"Service_Description"}
                          className={`block font-normal text-secondaryl`}
                        >
                          Book Date
                        </label>
                        <input
                          type="text"
                          id={service.Book_To_Date}
                          name={service.Book_To_Date}
                          className={`block mb-3 h-11 w-full bg-[#3F5CA91A]  text-infromation rounded-sm px-2 ps-10 focus:outline-none`}
                          value={
                            service.Book_From_Date +
                            " To " +
                            service.Book_To_Date
                          }
                          readOnly
                          disabled
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                        />
                      </>
                    )}
                    {service.Book_From_Time && service.Book_To_Time && (
                      <>
                        <label
                          htmlFor={"Service_Description"}
                          className={`block font-normal text-secondaryl`}
                        >
                          Book Time
                        </label>
                        <input
                          type="text"
                          id={service.Book_To_Time}
                          name={service.Book_To_Time}
                          className={`block mb-3 h-11 w-full bg-[#3F5CA91A]  text-infromation rounded-sm px-2 ps-10 focus:outline-none`}
                          value={
                            service.Book_From_Time +
                            " To " +
                            service.Book_To_Time
                          }
                          readOnly
                          disabled
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                        />
                      </>
                    )}
                    {service && service?.Service_Image && (
                      <>
                        <label
                          htmlFor={"Service_Image"}
                          className={`block font-normal text-secondaryl`}
                        >
                          Service Image
                        </label>
                        <div>
                          <img
                            src={service?.Service_Image}
                            id="Service_Image"
                            name="Service_Image"
                            alt={""}
                            className="lg:w-1/2 w-3/4 rounded-md"
                          />
                        </div>
                      </>
                    )}
                    <div className="my-4">
                      {service.Status === 1 && (
                        <div className="text-sm flex gap-1 items-center text-[#00A701]">
                          <Icon
                            icon="duo-icons:approved"
                            className=" text-lg"
                          />
                          <p className="">Approved</p>
                        </div>
                      )}

                      {service.Status === 2 && (
                        <div className="text-sm flex gap-1 items-center text-[#EB3333]">
                          <Icon icon="tabler:cancel" className=" text-lg" />
                          <p className="">Reject</p>
                        </div>
                      )}

                      {service.Status === 0 && (
                        <div className="text-sm flex gap-1 items-center text-[#FFA640]">
                          <Icon
                            icon="lsicon:minus-filled"
                            className=" text-lg"
                          />
                          <p className="">Pending</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {service &&
                    service?.SubServiceCorporations?.length > 0 &&
                    service?.SubServiceCorporations.map((subService, index) => (
                      <div className="mb-3 mt-8" key={index}>
                        <div className="mb-3">
                          {/* Label */}
                          <label
                            htmlFor={subService.Sub_Service_Name}
                            className={`block font-normal text-secondaryl`}
                          >
                            Sub Service Name {index + 2}
                          </label>
                          {/* Input */}
                          <input
                            type="text"
                            id={subService.Sub_Service_Name}
                            name={subService.Sub_Service_Name}
                            className={`block  h-11 w-full bg-[#3F5CA91A]  text-infromation rounded-sm px-2 ps-10 focus:outline-none`}
                            value={subService.Sub_Service_Name}
                            readOnly
                            disabled
                            style={{
                              boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                            }}
                          />
                        </div>
                        <label
                          htmlFor={"Service_Description"}
                          className={`block font-normal text-secondaryl`}
                        >
                          Service Description {index + 2}
                        </label>
                        <textarea
                          disabled={true}
                          className={
                            "block mb-3 h-32 py-2 bg-[#3F5CA91A] resize-none  text-infromation rounded-sm  w-full px-2 ps-10 focus:outline-none"
                          }
                          title={"Service Description"}
                          value={subService.Sub_Service_Description}
                        />
                        {subService && subService?.Sub_Service_Image && (
                          <>
                            <label
                              htmlFor={"Service_Image"}
                              className={`block font-normal text-secondaryl`}
                            >
                              Service Image {index + 2}
                            </label>
                            <div>
                              <img
                                src={subService?.Sub_Service_Image}
                                id="Service_Image"
                                name="Service_Image"
                                alt={""}
                                className="lg:w-1/2 w-3/4 rounded-md"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </div>

                <div className="flex justify-center space-x-2">
                  <button
                    onClick={() => setShowServiceViewModel(false)}
                    className="bg-secondaryl text-white px-4 py-2 rounded hover:bg-opacity-90"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`fixed inset-0 z-30 overflow-y-auto  bg-[#231F20B2] 
                `}
        >
          <style>{`body { overflow: hidden; }`}</style>

          <div className="flex justify-center items-center h-full ">
            <div className="bg-white  rounded-lg md:w-[40%] w-4/5 h-[80%] max-h-fit overflow-y-auto relative">
              <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                <div className="flex justify-between items-center mb-3">
                  No Service Selected
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceViewModel;
