import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./API";
// Async thunk for login for corporation
export const corporationLoginAsync = createAsyncThunk(
  "auth/corporationLogin",
  async (credentials) => {
    const response = await authService.corporationLogin(credentials);
    return response.data; // Assuming the API returns the user data upon successful login
  }
);

// Async thunk for login for contractor
export const contractorLoginAsync = createAsyncThunk(
  "auth/contractorLogin",
  async (credentials) => {
    const response = await authService.contractorLogin(credentials);
    return response.data; // Assuming the API returns the user data upon successful login
  }
);

//Async thunk for signup for corporation
export const corporationSignupAsync = createAsyncThunk(
  "auth/corporationSignup",
  async (data) => {
    const response = await authService.corporationSignup(data);
    return response.data;
  }
);

// Async thunk for signup for contractor
export const contractorSignupAsync = createAsyncThunk(
  "auth/contractorSignup",
  async (data) => {
    const response = await authService.contractorSignup(data);
    return response.data;
  }
);

// Slice for authentication
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder //   CORPORATION LOGIN EXTRAREDUCERS
      .addCase(corporationLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(corporationLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload;
      })
      .addCase(corporationLoginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //  CONTRACTOR LOGIN EXTRAREDUCERS
      .addCase(contractorLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(contractorLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.user = action.payload;
      })
      .addCase(contractorLoginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //    CORPORATION SIGNUP EXTRAREDUCERS
      .addCase(corporationSignupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(corporationSignupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(corporationSignupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //    CONTRACTOR SIGNUP EXTRAREDUCERS
      .addCase(contractorSignupAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(contractorSignupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(contractorSignupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice;
