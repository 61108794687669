import axios from "axios";
import {
  contractorAPI,
  contractorLoginAPI,
  corporationAPI,
} from "../../constants/API";
import toast from "react-hot-toast";

const authService = {
  corporationLogin: async (credentials) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(credentials.api, credentials.data);
        // console.log(response);
        resolve(response);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Failed to Login Property Manager. Please check your input and try again later."
          );
        }
        reject(error);
      }
    });
  },

  corporationSignup: async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(corporationAPI, data);
        toast.success(response.data.message);
        resolve(response);
      } catch (error) {
        if (error.response) {
          // If the error has a response from the server, display the server error message
          toast.error(error.response.data.message);
        } else {
          // If the error is not from the server, display a generic error message
          toast.error(
            "Failed to register corporation. Please check your input and try again later."
          );
        }
        reject(error);
      }
    });
  },
  contractorLogin: async (credentials) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(contractorLoginAPI, credentials);
        resolve(response);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Failed to Login Contractor. Please check your input and try again later."
          );
        }
        reject(error);
      }
    });
  },
  contractorSignup: async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(contractorAPI, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log(response);
        toast.success(response.data.message);
        resolve(response);
      } catch (error) {
        if (error.response) {
          // If the error has a response from the server, display the server error message
          toast.error(error.response.data.message);
        } else {
          // If the error is not from the server, display a generic error message
          toast.error(
            "Failed to register contractor. Please check your input and try again later."
          );
        }
        reject(error);
      }
    });
  },
};

export default authService;
