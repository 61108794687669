import React from 'react'

function FileDownloadIcon() {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.6882 10.4653C15.9041 10.2464 15.9041 9.90162 15.6882 9.68269C15.4795 9.46368 15.1322 9.46368 14.924 9.68269L11.0094 13.6438V0.555545C11.0094 0.250262 10.77 -0.000244141 10.4689 -0.000244141C10.1678 -0.000244141 9.92074 0.250262 9.92074 0.555545V13.6438L6.01387 9.68269C5.79792 9.46368 5.45007 9.46368 5.24193 9.68269C5.02588 9.90162 5.02588 10.2464 5.24193 10.4653L10.0832 15.3734C10.2918 15.5849 10.6392 15.5849 10.8552 15.3734L15.6882 10.4653ZM19.5254 14.0586C19.5254 13.7533 19.7648 13.5106 20.0659 13.5106C20.367 13.5106 20.6141 13.7533 20.6141 14.0586V19.444C20.6141 19.7492 20.367 19.9998 20.0659 19.9998H0.863224C0.562115 19.9998 0.322754 19.7492 0.322754 19.444V14.0586C0.322754 13.7533 0.562115 13.5106 0.863224 13.5106C1.16431 13.5106 1.4115 13.7533 1.4115 14.0586V18.8961H19.5254V14.0586Z" fill="#6A707E" />
        </svg>
    )
}

export default FileDownloadIcon
