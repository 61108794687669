import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import DocumentReadModal from "./DocumentReadModal";

function DocumentCard({ data, onDelete, onToggleActive, section }) {
  const cookies = new Cookies();
  const { Document_Name, Document_URL, IsActive, ID } = data;

  const [viewDocument, setViewDocument] = useState({});
  const [showDocumentViewModel, setShowDocumentViewModel] = useState(false);

  const handleViewDocument = (isTrue, data) => {
    if (isTrue) {
      setViewDocument(data);
      setShowDocumentViewModel(true);
    } else {
      setViewDocument({});
      setShowDocumentViewModel(false);
    }
  };

  return (
    <>
      {showDocumentViewModel && viewDocument && (
        <DocumentReadModal
          document={viewDocument}
          handleViewDocument={handleViewDocument}
        />
      )}

      <div
        className="w-full flex items-center lg:flex-row flex-col  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
        key={ID}
      >
        <div className="h-auto relative lg:w-[12%] w-full flex-none bg-cover rounded-xl text-center flex justify-center">
          {Document_URL &&
          Document_URL.length > 0 &&
          !String(Document_URL).includes(".pdf") ? (
            <img
              src={Document_URL}
              alt={Document_Name}
              className="lg:h-[5.75rem] lg:w-[7.3rem] w-full object-cover rounded-md "
            />
          ) : (
            <div className="lg:h-[5.75rem] lg:w-[7.3rem] w-full flex items-center">
              No Image
            </div>
          )}
        </div>
        <div className="flex-col lg:w-[75%] w-full  justify-center leading-normal">
          <div className="space-y-2">
            {/* Title */}
            <div className="text-secondaryl euclidbold text-2xl break-words">
              {Document_Name}
            </div>
          </div>
        </div>
        {/* Card Buttons */}

        <div className="flex-col justify-center my-auto relative w-full lg:w-[20%] space-y-3">
          {cookies.get("access") === "property manager" && (
            <>
              <div className="flex items-center space-x-2 justify-between">
                <p className="text-secondaryl font-medium">
                  {IsActive ? "Deactivate" : "Activate"} :
                </p>
                <label
                  htmlFor={`toggle-${ID}`}
                  className="relative cursor-pointer !mb-0"
                >
                  <input
                    onChange={() => onToggleActive(!IsActive)}
                    checked={IsActive}
                    type="checkbox"
                    id={`toggle-${ID}`}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 peer-focus:outline-none ring-2 ring-secondaryl rounded-full peer  peer-checked:bg-secondaryl"></div>
                  <div className="absolute left-1 top-1 bg-secondaryl peer-checked:bg-white w-4 h-4 rounded-full transition-all peer-checked:translate-x-5"></div>
                </label>
              </div>

              <div>
                <button
                  className="h-11 w-full px-2 py-2.5 euclidbold focus:outline-none items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                  onClick={() => {
                    const confirmation = window.confirm(
                      `Are you sure you want to delete this ${section}`
                    );
                    if (confirmation) {
                      onDelete(ID);
                    }
                  }}
                >
                  Delete
                </button>
              </div>
            </>
          )}

          <div>
            <button
              className="h-11 w-full px-2 py-2.5 euclidbold focus:outline-none items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              onClick={() => handleViewDocument(true, data)}
            >
              VIEW
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentCard;
