import React, { useEffect, useState } from "react";
import { ContractorProfileLeftImage } from "../../assets/images";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Loading from "../../components/Elements/Loading";
import Layout from "../../components/Layout/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { adminAPI } from "../../constants/API";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import InputBox from "../../components/Elements/InputBox";
import ReadInputBox from "../../components/Elements/ReadInputBox";

const AdminProfile = () => {
  const navigate = useNavigate();

  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const schema = yup.object().shape({
    Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Last name can only contain letters.")
      .required("Admin Name is required"),
    Email: yup.string().required("Email is required").email("Email is invalid"),
    ...(changePassword && {
      OldPassword: yup.string().required("Old Password is required"),
      Password: yup.string().required("New Password is required"),
    }),
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getData = async () => {
    try {
      // console.log("cookies.get('token')", cookies.get("token"));
      const response = await axios.get(adminAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      const data = response.data.data;

      if (data) {
        Object.keys(data).map((key) => {
          setValue(key, data[key]);
          if (key === "Password") {
            setValue("Password", "");
          }
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || "Error fetching data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSaveProfile = async (data) => {
    try {
      setLoad(true);
      const requestData = changePassword
        ? {
            Name: data.Name,
            OldPassword: data.OldPassword,
            Password: data.Password,
          }
        : { Name: data.Name };
      const response = await axios.put(`${adminAPI}`, requestData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      toast.success(response.data.message);
      navigate("/admin");
    } catch (error) {
      toast.error(error.response.data.message || "Error saving data");
    } finally {
      setLoad(false);
    }
  };

  return (
    <Layout>
      <div>
        <Loading load={load} />
        <div className="h-full w-full flex relative ">
          {/* First div : images */}
          <div className="w-[70%] hidden md:block h-[78vh] ">
            <img
              src={ContractorProfileLeftImage}
              className="absolute top-0 bottom-0 w-[40%] h-full object-cover"
              alt="Signup background"
            />
          </div>
          <div className="absolute hidden bottom-[-1%] left-0 w-full md:w-[40%] h-[40%] bg-gradient-to-b from-transparent to-[#0893bd]  md:block"></div>
          {/* Second Div */}
          <div className="w-full h-full pb-[3%] md:pl-[5%] md:pr-[2%] mx-3 sm:mx-3 md:mx-0">
            <div className="">
              <div className=" mx-auto ">
                <div className="container sm:mx-auto mx-auto ">
                  <h1 className="text-5xl md:pl-[3%] euclidbold text-[#0893bd] flex justify-center  sm:justify-center lg:justify-start">
                    Manage account
                  </h1>

                  <form onSubmit={handleSubmit(onSaveProfile)}>
                    <div className="bg-white rounded-sm mt-[5%]">
                      <div className="grid grid-cols-1 md:mr-4 lg:mr-20 md:pl-[3%]">
                        <InputBox
                          title={"Admin Name"}
                          register={register}
                          filedName={"Name"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                        <ReadInputBox
                          title={"Admin Email"}
                          register={register}
                          filedName={"Email"}
                          must={true}
                          errors={errors}
                          className={"md:text-sm lg:text-base"}
                        />
                        {changePassword ? (
                          <>
                            <InputBox
                              type="password"
                              title={"Old Password"}
                              register={register}
                              filedName={"OldPassword"}
                              must={true}
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                            />
                            <InputBox
                              type="password"
                              title={"New Password"}
                              register={register}
                              filedName={"Password"}
                              must={true}
                              errors={errors}
                              className={"md:text-sm lg:text-base"}
                            />
                            <button
                              type="button"
                              onClick={() => setChangePassword(false)}
                              className="absolute top-[43%] right-[5%] p-2 bg-cyan-600 text-white"
                            >
                              X
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setChangePassword(true)}
                            className="text-blue-500 hover:underline focus:outline-none"
                          >
                            Change Password
                          </button>
                        )}
                      </div>

                      {/* Submit Button */}
                      <div className="mb-3 md:pl-[3%] flex gap-4">
                        <button
                          type="submit"
                          className={`h-11 px-6 py-2.5 items-center ring-1 euclidbold ring-cyan-500 text-cyan-500 text-xl tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 focus:outline-none hover:text-white transition duration-300-in-out mt-4 md:mt-0 flex`}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminProfile;
