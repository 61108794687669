import React from "react";
import Skeleton from "react-loading-skeleton";
function DashboardContractCardSkeleton({ cards = 5 }) {
  return Array(cards)
    .fill(0)
    .map((item, i) => (
      <div
        key={i}
        className="w-full flex flex-col lg:flex-row  bg-[#FFFFFF] rounded p-3 gap-3 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        {/* Card Images  */}
        <div className="h-full w-full lg:w-[25%] flex-none bg-cover rounded-md flex justify-center">
          <div className="w-full">
            <Skeleton height={100} />
          </div>
        </div>

        {/* Card Content */}
        <div className="flex-col w-full lg:w-[45%] my-auto overflow-hidden">
          <div className="space-y-1">
            {/* Title */}

            {/* Description */}
            <p className=" text-base text-justify  overflow-hidden">
              <Skeleton count={2} />
            </p>
          </div>
        </div>

        <div className="flex-col justify-center my-auto relative w-auto lg:w-[30%] space-y-3">
          <Skeleton height={40} />
          <Skeleton height={40} />
        </div>
      </div>
    ));
}

export default DashboardContractCardSkeleton;
