import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../../components/Elements/Loading";
import { useLoading } from "../../../../context/LoadingContext";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import ContractCardSkeleton from "../../../../components/Contracts/ContractCardSkeleton";
import ServiceCard from "../../../../components/Corporation/Dashboard/Service/ServiceCard/ServiceCard";
import { serviceCorporationApi } from "../../../../constants/API";
import axios from "axios";
import toast from "react-hot-toast";
import ReadInputBox1 from "../../../../components/Elements/ReadInputBox1";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddService from "../../../../components/Corporation/Dashboard/Service/AddService";
import { serviceSchema } from "../../../../schema";
import EditService from "../../../../components/Corporation/Dashboard/Service/EditService";
import { useSearchParams } from "react-router-dom";
import { debounce } from "../../../../utils/helper";

const ManageService = () => {
  const [load, setLoad] = useState(false);
  const cookies = new Cookies();

  const { setValue } = useForm({ resolver: yupResolver(serviceSchema) });
  const [data, setData] = useState([]);
  const [service, setService] = useState({});
  const [filterData, setFilterData] = useState([...data]);
  const [modelLoading, setModelLoading] = useState(false);
  const [showAddService, setShowAddService] = useState(false);
  const [showEditService, setShowEditService] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);

    if (!data.length > 0) {
      setLoad(true);
    }
    try {
      const respponse = await axios.get(
        `${serviceCorporationApi}/corporation`,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
          params: {
            page,
            limit: 5,
          },
        }
      );

      Object.keys(respponse.data.data).forEach((key) => {
        setValue(key, respponse.data.data[key]);
      });
      setData((prevData) => [...prevData, ...respponse.data.data]);
      setFilterData((prevData) => [...prevData, ...respponse.data.data]); // Append filtered data
      setTotalPages(respponse.data.totalPages);
    } catch (error) {
    } finally {
      setLoad(false);
      setTimeout(() => {
        setLoading(false); // Stop loading spinner
      }, 1000);
    }
  };

  useEffect(() => {
    debounce(fetchData(), 500);
  }, [page]);

  // Function to handle scroll event in the container
  const handleContainerScroll = debounce(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        // Load next page when the container reaches the bottom
        if (page < totalPages) {
          setPage(page + 1);
        }
      }
    }
  }, 200);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleContainerScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleContainerScroll);
      }
    };
  }, [page, totalPages]);

  return (
    <>
      <div>
        <div className="w-full px-4 m-auto h-full p-6 rounded-2xl flex flex-col justify-center">
          <div className="flex justify-between items-center pb-2">
            <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
              Manage Services
            </h1>

            {cookies.get("access") === "property manager" && (
              <button
                onClick={() => setShowAddService(!showAddService)}
                className="h-11 focus:outline-none focus:border-none w-fit px-2.5 py-1.5 items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center bg-secondaryl text-[#ffffff] text-sm truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2"
              >
                <Icon
                  icon="fluent-mdl2:calculator-addition"
                  className="font-bold"
                />{" "}
                Add Service
              </button>
            )}
          </div>

          <div
            ref={containerRef}
            id="infinite-scroll"
            className="contracts_listing"
          >
            {load ? (
              <ContractCardSkeleton />
            ) : filterData.length > 0 ? (
              filterData.map((service, index) => (
                <ServiceCard
                  key={index}
                  service={service}
                  loading={loading}
                  setShowEditService={setShowEditService}
                  setService={setService}
                  fetchServices={fetchData}
                  setData={setData}
                  setFilterData={setFilterData}
                  setLoad={setLoad}
                />
              ))
            ) : (
              !loading && (
                <div className="text-center text-2xl text-infromation mt-10">
                  Services are not available
                </div>
              )
            )}
          </div>

          {/* Loading for infinite scroll */}
          {loading && <div className="size-14 mx-auto spin-loader my-16"></div>}
        </div>
      </div>

      {(showAddService || showEditService) && (
        <div className={`fixed inset-0 z-30 overflow-y-auto bg-[#231F20B2] `}>
          <Loading load={modelLoading} />

          <div className="flex justify-center items-center h-full">
            <div className="bg-white  rounded-lg md:w-[40%] w-4/5 h-[80%] overflow-y-auto relative">
              <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 relative">
                {/* Add Service */}
                {showAddService && (
                  <AddService
                    setShowAddService={setShowAddService}
                    fetchServices={fetchData}
                    setLoad={setModelLoading}
                  />
                )}

                {/* Edit Service */}
                {showEditService && (
                  <EditService
                    setShowEditService={setShowEditService}
                    service={service}
                    setService={setService}
                    fetchServices={fetchData}
                    setLoad={setModelLoading}
                    setFilterData={setFilterData}
                    setData={setData}
                    filterData={filterData}
                    data={data}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageService;
