import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ContractDetailsModal from "./ContractDetailsModel";
import { LogoWithText1 } from "../../../assets/images";

function ContractCard({ contract }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(true);

  const [contractCardImage, setContractCardImage] = useState("");

  useEffect(() => {
    setLoad(true);
    for (let i = 1; i <= 10; i++) {
      if (contract[`Project_Photos_${i}`]) {
        setContractCardImage(contract[`Project_Photos_${i}`] || "");
        break;
      }
    }
    setLoad(false);
  }, []);

  const handleViewClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        <div
          className={`h-full relative lg:w-[20%] flex-none bg-cover rounded-xl text-center flex justify-center ${
            (contractCardImage === "" || contractCardImage === null) &&
            "bg-gray-200"
          }`}
        >
          {contractCardImage !== "" && contractCardImage !== null && !load ? (
            <img
              src={contractCardImage}
              alt={contract.Project_Name}
              className="lg:h-36  rounded-md"
            />
          ) : (
            // <div className="h-32 flex items-center"> No Image </div>
            // Changes by Client
            !load && (
              <img
                src={LogoWithText1}
                alt={"Logo"}
                className="lg:h-36 rounded-md"
              />
            )
          )}
        </div>
        {/* Contract Info */}
        <div className="flex-col lg:w-[60%]  justify-center leading-normal">
          <div className="space-y-1.5">
            <div className="text-secondaryl euclidbold text-2xl">
              {contract.Project_Name}
            </div>

            <p className="text-left text-infromation break-words line-clamp-3">
              {contract.Project_Description}
            </p>

            {contract.Request_Status === 1 && (
              <div className="text-sm flex gap-1 items-center text-[#00A701]">
                <Icon icon="duo-icons:approved" className=" text-lg" />
                <p className="">Approved</p>
              </div>
            )}

            {contract.Request_Status === 2 && (
              <div className="text-sm flex gap-1 items-center text-[#EB3333]">
                <Icon icon="tabler:cancel" className=" text-lg" />
                <p className="">Reject</p>
              </div>
            )}

            {contract.Request_Status === 0 && (
              <div className="text-sm flex gap-1 items-center text-[#FFA640]">
                <Icon icon="lsicon:minus-filled" className=" text-lg" />
                <p className="">Pending</p>
              </div>
            )}
          </div>
        </div>

        {/* VIEW Button */}
        <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
          <div
            onClick={handleViewClick}
            className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl hover:text-white transition duration-300 ease-in-out"
          >
            VIEW
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ContractDetailsModal contract={contract} onClose={handleCloseModal} />
      )}
    </>
  );
}

export default ContractCard;
