import React, { useEffect, useState } from "react";
// import { Downloads, Location } from "../../assets/images/index.jsx";
import ContractImage from "./ContractImage.jsx";
import { NavLink, useLocation } from "react-router-dom";
import { getWorkingHoursByCorporationId } from "../../constants/Request.jsx";
import { Icon } from "@iconify/react";

function ContractDetailsCard() {
  const location = useLocation();
  const [displayImage, setDisplayImage] = useState("");

  const setDefaultDisplayImage = () => {
    for (let i = 1; i <= 10; i++) {
      const photoKey = `Project_Photos_${i}`;
      if (
        location.state[photoKey] !== null &&
        location.state[photoKey] !== ""
      ) {
        setDisplayImage(location.state[photoKey]);
        break;
      }
    }
  };

  const [projectPhotos, setProjectPhotos] = useState([]);
  const [workingHours, setWorkingHours] = useState([]); // New state for working hours

  useEffect(() => {
    ContractImageManage();
    setDefaultDisplayImage();
  }, [location.state]);

  const ContractImageManage = async () => {
    for (let i = 1; i <= 10; i++) {
      const photoKey = `Project_Photos_${i}`;
      // console.log(`photo key ${i} : location.state[photoKey]`);

      if (
        location.state[photoKey] !== null &&
        location.state[photoKey] !== ""
      ) {
        // console.log(`photo key ${i} true : ${location.state[photoKey]}`);

        setProjectPhotos((prev) => [...prev, location.state[photoKey]]);
      }
    }
  };

  const workinghours = async (corporationId) => {
    try {
      const res = await getWorkingHoursByCorporationId(corporationId);
      setWorkingHours(res); // Store the working hours in state
    } catch (error) {
      console.error("Error fetching working hours:", error);
    }
  };

  useEffect(() => {
    if (location.state?.Corporation_ID) {
      workinghours(location.state.Corporation_ID);
    }
  }, [location.state?.Corporation_ID]);

  return (
    <div className="">
      <div className="flex flex-col md:flex-row w-full lg:w-[90%] gap-10">
        <div className="md:flex-1 w-full lg:w-[45%]"></div>
        <div className="md:flex-1 w-full lg:w-[55%]">
          <p className="text-lg font-bold text-infromation">Details</p>
          <div className="border-b-[1px] border-infromation/50 mb-1" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row  gap-5 md:gap-10 w-full lg:w-[90%] ">
        <div className="md:flex-1  w-full lg:w-[45%]">
          <div className="h-[460px] w-full  ring-[0.2px] ring-infromation bg-cover rounded-lg text-center flex justify-center">
            {displayImage !== "" ? (
              <img
                src={displayImage}
                alt={location.state.Project_Name}
                className="h-full w-full rounded-lg object-cover"
              />
            ) : (
              <div className="h-[460px] flex items-center"> No Image </div>
            )}
          </div>

          <div className="w-full pt-2 flex-wrap hidden md:flex px-1">
            {projectPhotos.map((photo, index) => (
              <ContractImage
                src={photo}
                key={index}
                setDisplayImage={setDisplayImage}
                displayImage={displayImage}
              />
            ))}
          </div>
        </div>

        <div className="md:flex-1 w-full lg:w-[55%] my-2 ">
          <div className="space-y-2">
            <div className="text-secondaryl euclidbold text-2xl">
              {location.state.Project_Name}
            </div>
            <div className="text-base flex gap-2 items-center">
              <Icon
                icon="carbon:location-filled"
                className="text-secondaryl text-lg"
              />
              <p className="text-infromation">
                {location.state.Project_Location}
              </p>
            </div>

            <div className="space-y-2 py-2 text-sm text-secondaryl">
              {location.state.Is_Specific_Contractor ? (
                <p className="text-red-500">
                  Special Contract for :
                  <span className="font-bold euclidbold">
                    {" "}
                    {location.state.totalSpecificContractors}{" "}
                  </span>
                  Contractors
                </p>
              ) : (
                ""
              )}

              <p>
                Project Starting Date:
                <span className="font-bold euclidbold">
                  {" "}
                  {location.state.Start_Date}
                </span>
              </p>
              <p>
                Project Proposal Deadline Date:
                <span className="font-bold euclidbold">
                  {" "}
                  {location.state.Proposal_Deadline}
                </span>
              </p>
              <div className="flex flex-col lg:flex-row gap-3 ">
                <p>Property manager On site Schedule: </p>
                <div>
                  {workingHours.length > 0 ? (
                    <ul className="list-disc pl-3">
                      {workingHours
                        .slice(0, Math.ceil(workingHours.length))
                        .map(
                          (wh) =>
                            wh.Checked && (
                              <li key={wh.ID} className="text-sm">
                                <strong>{wh.Day}:</strong>{" "}
                                {wh.Checked &&
                                wh.Working_Hours_From &&
                                wh.Working_Hours_To
                                  ? `${wh.Working_Hours_From} - ${wh.Working_Hours_To}`
                                  : "Not Working"}
                              </li>
                            )
                        )}
                    </ul>
                  ) : (
                    <p>No working hours available.</p>
                  )}
                </div>
              </div>
            </div>

            <p className="text-infromation text-base text-justify font-normal overflow-hidden">
              {location.state.Project_Description}
            </p>

            {location?.state?.Project_Photos && (
              <NavLink
                to={location.state.Project_Photos}
                className="h-fit w-fit px-2 py-1.5  items-center cursor-pointer ring-1 ring-secondaryl rounded flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold  hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out gap-2"
              >
                Download
                <Icon
                  icon="ic:round-download-for-offline"
                  className="text-2xl"
                />
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractDetailsCard;
