import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { noticeSchema } from "../../../../../schema";
import InputBox from "../../../../Elements/InputBox";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../../assets/images";
import toast from "react-hot-toast";
import InputBoxLiveDate from "../../../../Elements/InputBoxLiveDate";
import DropDownStyle2 from "../../../../Elements/DropDownStyle2";
import Cookies from "universal-cookie";
import axios from "axios";
import {
  corporationNoticeAPI,
  ownerAPI,
  tenantAPI,
} from "../../../../../constants/API";
import { viewFileHandler } from "../../../../../constants/Request";
import CheckBoxDropDown1 from "../../../../Elements/CheckBoxDropDown1";
import { Icon } from "@iconify/react";
import CheckBoxDropDown1WithName from "../../../../Elements/CheckBoxDropDown1WithName";

const whoCanShowOption = [
  { ID: 0, Sharing_Status: "Both" },
  { ID: 1, Sharing_Status: "Owner" },
  { ID: 2, Sharing_Status: "Tenant" },
];

const NoticeModel = ({
  selectedNotice,
  onClose,
  fetchNotice,
  isEditNotice,
  setLoad,
  setIsEditNotice,
}) => {
  // console.log("selectedNotice", selectedNotice);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(noticeSchema),
  });
  // console.log(errors);
  // console.log(selectedNotice);
  const cookies = new Cookies();

  const watchIsSpecificResident = watch("Is_Specific_Resident");

  // console.log("watch : ", watchIsSpecificResident);

  const [selectDate, setSelectDate] = useState(true);
  const [residentList, setResidentList] = useState([]);
  const [minStartDate, setMinStartDate] = useState(
    // new Date().toISOString().split("T")[0]
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );

  const [minEndDate, setMinEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [maxProposalDeadline, setMaxProposalDeadline] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [modelLoading, setModelLoading] = useState(true);
  const [selectWhoCanShow, setSelectWhoCanShow] = useState(null);
  const [selectResidents, setSelectResidents] = useState([]);
  const [IsDocument, setIsDocument] = useState(false);
  const watchSharingStatus = watch("Sharing_Status");
  const watchIsUploadingDocument = watch("Is_Uploading_Document");

  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/pdf",
  ];
  const [Document, setDocument] = useState(null);
  const [DocumentError, setDocumentError] = useState("");
  const [DocumentUploded, setDocumentUploded] = useState("");
  const [DocumentURL, setDocumentURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);

  // for Edit
  const [noticeDocument, setnoticeDocument] = useState(null);
  const [noticeDocumentType, setnoticeDocumentType] = useState(null);
  const [orginalOwnerIDList, setOrginalOwnerIDList] = useState([]);
  const [orginalTenantIDList, setOrginalTenantIDList] = useState([]);

  const handleChangeDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocument(null);
      setDocumentError("");
      setDocumentUploded("");
      setDocumentURL("");
      setValue("Notice_Document", "");
      clearErrors("Notice_Document");
    }

    if (!file) {
      setDocument(null);
      setDocumentError("");
      setDocumentUploded("");
      setDocumentURL("");
      setValue("Notice_Document", "");
      clearErrors("Notice_Document");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setDocument(file);
        setDocumentUploded("");
        setDocumentError("");
        setDocumentURL("");
        setValue("Notice_Document", file);
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setDocument(null);
        setDocumentError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setDocument(null);
        setDocumentError("File size exceeds limit (5 MB)");
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      } else {
        setDocument(null);
        setDocumentError("Something went wrong! Please try again.");
        setDocumentUploded("");
        setDocumentURL("");
        setValue("Notice_Document", "");
      }
    }
  };

  const assignNoticeData = async () => {
    reset(selectedNotice);

    if (selectedNotice.Sharing_Status === 0) {
      setSelectWhoCanShow(0);
      setValue("Sharing_Status", 0);
      setValue("Is_Specific_Resident", false);
      setSelectResidents([]);
    } else if (selectedNotice.Sharing_Status === 1) {
      setSelectWhoCanShow(1);
      setValue("Sharing_Status", 1);
      setValue("Is_Specific_Resident", false);
      setSelectResidents([]);
    } else if (selectedNotice.Sharing_Status === 2) {
      setSelectWhoCanShow(2);
      setValue("Sharing_Status", 2);
      setValue("Is_Specific_Resident", false);
      setSelectResidents([]);
    } else if (selectedNotice.Sharing_Status === 3) {
      setSelectWhoCanShow(1);
      setValue("Sharing_Status", 1);
      setValue("Is_Specific_Resident", true);
      const ownersID = selectedNotice.CpNoticeToSpecificOwners.map(
        (r) => r.Owners_ID
      );
      setSelectResidents(ownersID);
      setOrginalOwnerIDList(ownersID);
    } else if (selectedNotice.Sharing_Status === 4) {
      // console.log(
      //   "selectedNotice.Sharing_Status : ",
      //   selectedNotice.Sharing_Status
      // );
      setSelectWhoCanShow(2);
      setValue("Sharing_Status", 2);
      setValue("Is_Specific_Resident", true);
      const tenantsID = selectedNotice.CpNoticeToSpecificTenants.map(
        (r) => r.Tenant_ID
      );

      // console.log("tenantsID", tenantsID);

      setSelectResidents(tenantsID);
      setOrginalTenantIDList(tenantsID);
    }

    // console.log("selectedNotice.Notice_Document", selectedNotice);

    if (selectedNotice?.Notice_Document?.endsWith(".pdf")) {
      const fileurl = await viewFileHandler(
        selectedNotice.Notice_Document,
        "notice"
      );
      setValue("Is_Uploading_Document", true);
      setnoticeDocumentType("pdf");
      setnoticeDocument(fileurl);
    } else if (
      selectedNotice?.Notice_Document?.endsWith(".png") ||
      selectedNotice?.Notice_Document?.endsWith(".jpg") ||
      selectedNotice?.Notice_Document?.endsWith(".jpeg")
    ) {
      setValue("Is_Uploading_Document", true);
      setnoticeDocumentType("image");
      setnoticeDocument(selectedNotice.Notice_Document);
    } else {
      setValue("Is_Uploading_Document", false);
      setnoticeDocument(null);
      setnoticeDocumentType(null);
    }
  };

  useEffect(() => {
    if (selectedNotice) {
      setModelLoading(true);
      assignNoticeData();
    }
    setModelLoading(false);
  }, [isEditNotice, selectedNotice]);

  // Edit Time
  const onEditSubmit = async (data) => {
    setLoad(true);
    // console.log("data", data);

    const formData = new FormData();

    formData.append("Notice_Title", data.Notice_Title);
    formData.append("Is_Specific_Resident", data.Is_Specific_Resident);
    formData.append("Notice_Description", data.Notice_Description);
    formData.append("Notice_Start_Date", data.Notice_Start_Date);
    formData.append("Notice_End_Date", data.Notice_End_Date);
    formData.append("Is_Uploading_Document", data.Is_Uploading_Document);

    let deleteOwnersID = [];
    let createOwnersID = [];
    let deleteTenantsID = [];
    let createTenantsID = [];

    // console.log("watchIsSpecificResident : ", watchIsSpecificResident);

    if (watchIsSpecificResident) {
      if (getValues("Sharing_Status") === 1) {
        formData.append("Sharing_Status", 3);
        data.Resident_ID.forEach((item) => {
          if (!orginalOwnerIDList.includes(item)) {
            createOwnersID.push(item);
          }
        });

        orginalOwnerIDList.forEach((item) => {
          if (!data.Resident_ID.includes(item)) {
            deleteOwnersID.push(item);
          }
        });
        if (createOwnersID.length > 0) {
          formData.append("createOwnersID", createOwnersID);
        }

        if (deleteOwnersID.length > 0) {
          formData.append("deleteOwnersID", deleteOwnersID);
        }

        if (orginalTenantIDList.length > 0) {
          formData.append("deleteTenantsID", orginalTenantIDList);
        }
      } else if (getValues("Sharing_Status") === 2) {
        formData.append("Sharing_Status", 4);
        data.Resident_ID.forEach((item) => {
          if (!orginalTenantIDList.includes(item)) {
            createTenantsID.push(item);
          }
        });

        orginalTenantIDList.forEach((item) => {
          if (!data.Resident_ID.includes(item)) {
            deleteTenantsID.push(item);
          }
        });
        if (createTenantsID.length > 0) {
          formData.append("createTenantsID", createTenantsID);
        }

        if (deleteTenantsID.length > 0) {
          formData.append("deleteTenantsID", deleteTenantsID);
        }

        if (orginalOwnerIDList.length > 0) {
          formData.append("deleteOwnersID", orginalOwnerIDList);
        }
      }
    } else {
      formData.append("Sharing_Status", selectWhoCanShow);
      formData.delete("Resident_ID");

      if (orginalOwnerIDList.length > 0) {
        formData.append("deleteOwnersID", orginalOwnerIDList);
      }

      if (orginalTenantIDList.length > 0) {
        formData.append("deleteTenantsID", orginalTenantIDList);
      }
    }

    if (Document && data.Is_Uploading_Document) {
      formData.append("Notice_Document", Document);
    }
    try {
      await axios.put(
        `${corporationNoticeAPI}/${selectedNotice.ID}`,
        formData,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      toast.success("Notice updated successfully");
      fetchNotice();
      setIsEditNotice(false);
      onClose(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    } finally {
      setLoad(false);
    }
  };

  // Create Time

  const onCreateSubmit = async (data) => {
    // console.log("Form click submit");
    setLoad(true);
    const formData = new FormData();

    formData.delete("Is_Specific_Resident");
    formData.delete("Is_Owner");
    formData.append("Notice_Title", data.Notice_Title);
    formData.append("Notice_Description", data.Notice_Description);
    formData.append("Notice_Start_Date", data.Notice_Start_Date);
    formData.append("Notice_End_Date", data.Notice_End_Date);
    formData.append("Is_Uploading_Document", data.Is_Uploading_Document);

    if (watchIsSpecificResident) {
      if (getValues("Sharing_Status") === 1) {
        formData.append("Sharing_Status", 3);
        formData.append("Residents", selectResidents);
      } else if (getValues("Sharing_Status") === 2) {
        formData.append("Sharing_Status", 4);
        formData.append("Residents", selectResidents);
      }
    } else {
      formData.append("Sharing_Status", selectWhoCanShow);
    }

    if (Document && data.Is_Uploading_Document) {
      formData.append("Notice_Document", Document);
    }
    try {
      await axios.post(corporationNoticeAPI, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      toast.success("Notice added successfully");
      fetchNotice();
      setIsEditNotice(false);
      onClose(false);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchResidentList = async (status) => {
    // console.log("fetchResidentList", status);

    try {
      setDataLoading(true);
      const url = status === 1 ? ownerAPI : tenantAPI;
      const response = await axios.get(url, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      setResidentList(response.data.data);

      // console.log("fetchResidentList", response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    // console.log("watchSharingStatus", watchSharingStatus);

    if (watchSharingStatus == 1) {
      setShowCheckBox(true);
      // console.log("watchin 1");

      if (watchIsSpecificResident) {
        // console.log("fetching 1");
        fetchResidentList(1);
        setSelectResidents(orginalOwnerIDList);
      }
    } else if (watchSharingStatus == 2) {
      setShowCheckBox(true);
      // console.log("watchin 2");

      if (watchIsSpecificResident) {
        // console.log("fetching 2");
        fetchResidentList(2);
      }
      setSelectResidents(orginalTenantIDList);
    } else if (watchSharingStatus == 0) {
      setShowCheckBox(false);
      setValue("Is_Specific_Resident", false);
    }
  }, [watchSharingStatus, watchIsSpecificResident]);

  return (
    <div>
      <style>{`body { overflow: hidden; }`}</style>
      <form
        onSubmit={handleSubmit(isEditNotice ? onEditSubmit : onCreateSubmit)}
      >
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            {isEditNotice ? "Edit Notice" : "Add Notice"}
          </h2>

          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <Icon
              icon="carbon:close-filled"
              className="text-secondaryl hover:text-opacity-95 text-3xl"
            />
          </button>
        </div>
        <div className="mb-3">
          {/* notice title */}
          <InputBox
            title={"Notice Title"}
            register={register}
            filedName={"Notice_Title"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />
          {/* Start date */}
          <InputBoxLiveDate
            title={"Start Date"}
            register={register}
            filedName={"Notice_Start_Date"}
            must={true}
            type="date"
            setValue={setValue}
            min={minStartDate}
            errors={errors}
            className={"md:text-sm lg:text-base"}
            schema={noticeSchema}
            setError={setError}
            setMinEndDate={setMinEndDate}
            setMaxProposalDeadline={setMaxProposalDeadline}
            setSelectDate={setSelectDate}
            otherDependencies={true}
          />
          {/* End date */}
          <InputBoxLiveDate
            title={"End Date"}
            register={register}
            filedName={"Notice_End_Date"}
            must={true}
            type="date"
            errors={errors}
            min={minEndDate}
            className={"md:text-sm lg:text-base"}
            schema={noticeSchema}
            setError={setError}
            disabled={selectDate}
          />
          {/* Who can show */}
          <div className="mb-3">
            <label
              htmlFor="Sharing_Status"
              className="block font-normal text-secondaryl"
            >
              <p>
                Who Can Show
                <span className="text-red-500 text-base ml-1">*</span>
              </p>
            </label>

            <div className="">
              <DropDownStyle2
                title="Who Can Show"
                options={whoCanShowOption}
                onSelect={setSelectWhoCanShow}
                selectOption={selectWhoCanShow}
                setValue={setValue}
                filedName={"Sharing_Status"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"Sharing_Status"}
              />
            </div>
          </div>
          {showCheckBox && (
            <div className="flex gap-3 items-center mb-3">
              <input
                type="checkbox"
                id="Is_Specific_Resident"
                name="Is_Specific_Resident"
                // checked={isSpecificResident}
                // onChange={() => setIsSpecificResident(!isSpecificResident)}
                {...register("Is_Specific_Resident")}
                className="bg-[#FFFFFF80] accent-secondaryl cursor-pointer text-black size-5"
              />
              <label
                htmlFor="isSpecificResident"
                className="pt-2 text-secondaryl"
              >
                This Notice is for Specific{" "}
                {getValues("Sharing_Status") === 1 ? "Owner" : "Tenant"}
              </label>
            </div>
          )}
          <div
            className={`transition-all duration-500 ease-in-out  ${
              showCheckBox && watchIsSpecificResident
                ? "opacity-100"
                : "opacity-0"
            }`}
          >
            {watchIsSpecificResident &&
              (residentList.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-12 mb-3">
                  <div className="">
                    <CheckBoxDropDown1WithName
                      title={`Select ${
                        getValues("Sharing_Status") === 1 ? "Owner" : "Tenant"
                      }`}
                      options={residentList}
                      onSelect={setSelectResidents}
                      selectOption={selectResidents}
                      setValue={setValue}
                      filedName={"Resident_ID"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"First_Name"}
                      setError={setError}
                      schema={noticeSchema}
                      must={true}
                      className="w-full"
                    />
                  </div>
                  <div className="mb-3 col-span-2">
                    <input
                      type="text"
                      id="specificResidentDisplay"
                      name="specificResidentDisplay"
                      className={` block h-11  min-w-20 w-full cursor-default rounded-sm text-black   px-2  ps-10 bg-[#FFFFFF80]  focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none`}
                      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                      placeholder="Selected Contractor Type Display Here"
                      value={
                        residentList
                          ?.filter((item) => selectResidents?.includes(item.ID))
                          .map((item) => item.First_Name)
                          .join(", ") || ""
                      }
                      readOnly
                    />
                  </div>
                </div>
              ) : dataLoading ? (
                <Spinner />
              ) : (
                "Resident Data Not Found. Please try again later"
              ))}
          </div>
          {/* Comment */}
          <div className=" mb-3">
            <label
              htmlFor="Comment"
              className={`block font-normal text-secondaryl`}
            >
              Comment
            </label>
            {/* Input */}
            <textarea
              type="text"
              id="Notice_Description"
              name="Notice_Description"
              {...register("Notice_Description")}
              className={`bg-[#FFFFFF80] focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none block h-40 max-h-40 min-h-40 w-full rounded-sm text-black px-2 ps-10 focus:border-rose-600 ${
                errors.Notice_Description ? "ring-1 ring-red-500" : ""
              }`}
              placeholder="Comment"
            />

            {/* Error */}
            <div className="h-[2vh] pt-1">
              {errors.Notice_Description && (
                <p className="text-red-500 text-xs  leading-none">
                  {errors.Notice_Description.message}
                </p>
              )}
            </div>
          </div>

          <div className="flex gap-3 items-center mb-3">
            <input
              type="checkbox"
              id="Is_Uploading_Document"
              name="Is_Uploading_Document"
              {...register("Is_Uploading_Document")}
              className="bg-[#FFFFFF80] accent-secondaryl cursor-pointer text-black size-5"
            />
            <label
              htmlFor="isSpecificResident"
              className="pt-2 text-secondaryl"
            >
              Upload Document
            </label>
          </div>

          {(selectedNotice?.Notice_Document || null) &&
            watchIsUploadingDocument && (
              <div className="mb-6">
                <label
                  className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                >
                  Uploaded Notice Document :
                </label>
                <div className="">
                  {modelLoading ? (
                    "Loading..."
                  ) : noticeDocument ? (
                    noticeDocumentType === "pdf" ? (
                      <object
                        data={noticeDocument}
                        type="application/pdf"
                        width="100%"
                        height="600px"
                        className="w-full h-[90vh]"
                      >
                        <p>PDF could not be displayed</p>
                      </object>
                    ) : (
                      <img
                        src={noticeDocument}
                        alt="Meeting Document"
                        className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                      />
                    )
                  ) : (
                    <div className="text-infromation">
                      No Documents Provided
                    </div>
                  )}
                </div>
              </div>
            )}

          {watchIsUploadingDocument && (
            <div className="mb-2 lg:mb-0 mt-2">
              {/* Input */}
              <label
                className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base`}
              >
                Document Upload :
              </label>

              <div>
                <div className="mb-6">
                  {/* Business Card File Input */}
                  <div className="col-span-2 sm:col-span-2 md:col-span-3">
                    <input
                      type="file"
                      id="Document"
                      name="Document"
                      accept={SUPPORTED_FILE_TYPES.join(",")}
                      className="hidden"
                      onChange={handleChangeDocument}
                      disabled={uploadingFile}
                    />
                    <label
                      htmlFor="Document"
                      className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none ${
                        uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                    >
                      <div className="flex items-center h-full space-x-1 overflow-hidden">
                        <span>
                          <InputFileIcon />
                        </span>
                        {Document ? (
                          <span className="line-clamp-1">
                            <span>{Document.name}</span>
                          </span>
                        ) : (
                          <span className="text-infromation text-opacity-50 text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                            No File Chosen
                          </span>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {uploadingDocument && <Spinner />}
                  {DocumentError && (
                    <p className="text-red-500 text-xs leading-none">
                      {DocumentError}
                    </p>
                  )}
                  {errors.Notice_Document && ( // Error message from yup validation
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Notice_Document.message}
                    </p>
                  )}
                  {DocumentUploded && (
                    <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                      {/* {DocumentUploded}  */}
                      <ApprovedIcon />
                      Uploaded Successfully!
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Save Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
        h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  ${
          uploadingDocument ? "cursor-not-allowed" : "cursor-pointer"
        }`}
            disabled={uploadingDocument}
          >
            {isEditNotice ? "Edit" : "Add"} Notice
          </button>
        </div>
      </form>
    </div>
  );
};

export default NoticeModel;
