import React from "react";
import { Toaster } from "react-hot-toast";
import DashboardSidebar from "../../../components/Contractor/Dashboard/Layout/DashboardSidebar";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../../components/Layout/DashboardHeader";

const ContractorDashboardLayout = () => {
  return (
    <div className="flex flex-row ">
      <Toaster />
      {/* Sidebar  */}
      <DashboardSidebar />
      {/* Main Content */}
      <main className="flex-1 p-3">
        {/* Header */}
        <DashboardHeader />

        <div className="relative">
          <div className="bg-gradient-to-b h-full from-[#282a73]/15 to-[#0094bf]/15 rounded-2xl relative min-h-[81vh]">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContractorDashboardLayout;
