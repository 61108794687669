import { PasswordIcon } from "../../assets/images/index";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { setPasswordAPI } from "../../constants/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Loading from "../../components/Elements/Loading";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userType = queryParams.get("userType");
  const [load, setLoad] = useState(false);
  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    setLoad(true);
    // console.log(data);
    try {
      const res = await axios.post(setPasswordAPI, {
        password: data.password,
        userType: userType,
        token: token,
      });
      // console.log(res);

      if (res.status === 200) {
        navigate("/");
      }
      toast.success("Password changed successfully. Please login.");
    } catch (error) {
      // console.log(error);
      toast.error("Failed to change password. Please try again later.");
    } finally {
      setLoad(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <Loading load={load} />

      <div className="relative w-full h-[80vh] flex  justify-center items-center">
        <div className="flex px-3  flex-col justify-center items-center space-y-1">
          <h1 className="text-5xl euclidbold text-[#0893bd] text-center ">
            Welcome
          </h1>
          <p className="text-black text-opacity-50 text-sm  text-center">
            Reset You Password
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-5 w-full">
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                {...register("password")}
                className={`bg-[#FFFFFF80] pl-5 h-11 w-full rounded text-black px-2 ps-10 focus:outline-none focus:ring-[0.5px] focus:ring-secondaryl ${
                  errors.password ? "ring-[0.5px] ring-red-500" : ""
                }`}
              />
              <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <PasswordIcon />
              </div>
              <label
                htmlFor="password"
                className={`absolute cursor-text left-0 -top-5 euclidbold text-label uppercase text-xs bg-inherit  ${
                  errors.password ? "text-red-500" : ""
                }`}
              >
                Password
              </label>

              <div
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="text-lg mr-2 transition-colors hover:text-gray-500"
                />
              </div>
            </div>
            <div className="flex justify-between mt-1">
              <div className="">
                {errors.password ? (
                  <ul className="text-red-500 text-xs leading-none list-inside">
                    {errors.password?.message.split(",").map((err) => (
                      <li className="list-disc" key={err}>
                        {err}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
            {/* Submit Button */}
            <div className="pt-3">
              <button
                type="submit"
                className="btn px-3 py-2 mx-auto w-fit flex justify-center text-secondaryl text-xl euclidbold tracking-widest hover:bg-secondaryl  focus:outline-none hover:text-white"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
