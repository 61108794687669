import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout.jsx";
import ContractCard from "./ContractCard.jsx";
import DropDown from "./DropDown.jsx";
import Pagination2 from "../Elements/Pagination2.jsx";
import ContractCardSkeleton from "./ContractCardSkeleton.jsx";
import { Toaster } from "react-hot-toast";
import Pagination3 from "../Elements/Pagination3.jsx";

function DisplayContracts({
  title,
  contracts,
  setContracts,
  totalPages,
  currentPage,
  setPage,
  buttonName,
  contractsPageType = "",
  load,
  setLoad,
  selectSortOpportunity,
  setSelectSortOpportunity,
  selectFilterOpportunity,
  setSelectFilterOpportunity,
  sortOpportunities,
  filterOpportunities,
}) {
  return (
    <div>
      <div className=" mt-3 mb-4">
        {/* Heading  */}

        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          {title}
        </h1>

        {/* Dropdown */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full md:w-[80%] lg:w-[50%]  my-5">
          {/* Dropdown Sort  */}
          <div className="flex flex-col">
            <span className="block w-full font-normal text-secondaryl mb-1">
              Sort
            </span>
            <DropDown
              options={sortOpportunities}
              onSelect={setSelectSortOpportunity}
              selectOption={selectSortOpportunity}
            />
          </div>
          {/* Dropdown Filter  */}

          {contractsPageType !== "residentrequest" && (
            <div className="flex flex-col">
              <span className="block w-full font-normal text-secondaryl mb-1">
                Filter
              </span>
              <DropDown
                options={filterOpportunities}
                onSelect={setSelectFilterOpportunity}
                selectOption={selectFilterOpportunity}
              />
            </div>
          )}
        </div>
      </div>

      <div className="contracts_listing">
        {load ? (
          <ContractCardSkeleton />
        ) : contracts?.length !== 0 ? (
          contracts.map((contract, index) => (
            <ContractCard
              key={index}
              contract={contract}
              buttonName={buttonName}
              contractsPageType={contractsPageType}
            />
          ))
        ) : (
          <div className="text-center text-2xl text-infromation mt-10">
            {title} are not available
          </div>
        )}
      </div>

      {!load && (
        <div className="my-5 ">
          {totalPages > 1 && (
            // <Pagination page={page} setPage={setPage} totalPages={totalPages} />
            <Pagination3
              totalPages={totalPages} // Total number of pages
              currentPage={currentPage} // Current active page
              setPage={setPage}
              setContracts={setContracts}
              setLoad={setLoad}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DisplayContracts;
