import React from "react";

import { Toaster } from "react-hot-toast";
import DashboardSidebar from "../../../components/AdminTest/Dashboard/Layout/DashboardSidebar";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../../components/Layout/DashboardHeader";
import Loading from "../../../components/Elements/Loading";
import { useLoading } from "../../../context/LoadingContext";

const AdminDashboardLayout = () => {
  const { load } = useLoading();
  return (
    <div className="flex flex-row ">
      <Toaster />
      {/* <Loading load={load} /> */}
      {/* Sidebar  */}
      <DashboardSidebar />
      {/* Main Content */}
      <main className="flex-1 p-3">
        {/* Header */}
        <DashboardHeader />

        <div className="relative">
          <div className="bg-gradient-to-b min-h-[80vh] from-[#282a73]/15 to-[#0094bf]/15 rounded-2xl h-full relative">
            <Loading load={load} />
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboardLayout;
