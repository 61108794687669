import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { viewFileHandler } from "../../constants/Request.jsx";
import ReadInputBox from "../Elements/ReadInputBox.jsx";

import { IoDocumentAttach } from "react-icons/io5";
import { HiDocumentCheck } from "react-icons/hi2";
import AssignBoardMemberDetails from "./AssignBoardMemberDetails.jsx";

function ContractorProposalView({ contract }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const params = useParams();
  let proposalId = params.id;
  const [load, setLoad] = useState(false);
  const [proposalFileUrl, setProposalFileUrl] = useState(null);
  const [proposalFileType, setProposalproposalFileType] = useState(null);
  const [approveProposalFileUrl, setApproveProposalFileUrl] = useState(null);
  const [approveProposalFileType, setApproveProposalFileType] = useState(null);
  const [companyType, setCompanyType] = useState(cookies.get("companyType"));
  const [access, setAccess] = useState(cookies.get("access"));
  const [docs, setDocs] = useState();

  // Form validation schema using yup
  const schema = yup.object().shape({
    Company_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Company name can only contain letters.")
      .required("Company Name is required"),
    Your_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Name can only contain letters.")
      .required("Your Name is required"),
    Designation: yup.string().required("Designation is required"),
    Estimate_Time: yup
      .number()
      .required("Estimate Time  is required")
      .typeError("Estimate Time  is required")
      .min(1, "Estimate time  must be greater than 0"),
    Other_Details: yup.string(),
    Attach_Proposal: yup.string().required("Proposal Upload is required"),
    // token: yup.string().default(cookies.get("token")),
  });

  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const setDataHandler = async () => {
      setLoad(true);
      try {
        if (contract) {
          Object.keys(contract.proposal).forEach((key) => {
            setValue(key, contract.proposal[key]);
          });

          if (contract.proposal.Attach_Proposal.endsWith(".pdf")) {
            const fileurl = await viewFileHandler(
              contract.proposal.Attach_Proposal,
              "proposal"
            );
            setProposalproposalFileType("pdf");
            setProposalFileUrl(fileurl);
          } else if (
            contract.proposal.Attach_Proposal.endsWith(".docx") ||
            contract.proposal.Attach_Proposal.endsWith(".doc")
          ) {
            const fileurl = await viewFileHandler(
              contract.proposal.Attach_Proposal,
              "proposal"
            );
            // console.log("fileurl docs", fileurl);
            setDocs([{ uri: fileurl }]);
            setProposalproposalFileType("word");
            setProposalFileUrl(fileurl);
          } else {
            setProposalproposalFileType("image");
            setProposalFileUrl(contract.proposal.Attach_Proposal);
          }

          if (contract.proposal.Status === 2) {
            if (contract.proposal.Approval_Proposal_PDF.endsWith(".pdf")) {
              const fileurl = await viewFileHandler(
                contract.proposal.Approval_Proposal_PDF,
                "approvalproposalpdf"
              );
              setApproveProposalFileType("pdf");
              setApproveProposalFileUrl(fileurl);
            } else {
              setApproveProposalFileType("image");
              setApproveProposalFileUrl(
                contract.proposal.Approval_Proposal_PDF
              );
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setLoad(false);
      }
    };

    setDataHandler();
  }, [contract]);

  return (
    <>
      <div className="mt-5">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Proposal
        </h1>

        <div className="my-4">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-16 w-full lg:w-[80%]">
            {/* Company Name */}
            <div className="mb-3">
              <ReadInputBox
                title={"Company Name"}
                register={register}
                filedName={"Company_Name"}
              />
            </div>

            {/*Your Name  */}
            <div className="mb-3">
              <ReadInputBox
                title={"Your Name"}
                register={register}
                filedName={"Your_Name"}
                errors={errors}
              />
            </div>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-16 w-full lg:w-[80%]">
            {/* Designations */}
            <div className="mb-3">
              <ReadInputBox
                title={"Designation"}
                register={register}
                filedName={"Designation"}
                errors={errors}
              />
            </div>

            <div className="flex flex-col w-full mb-3">
              <ReadInputBox
                title="Estimate time"
                register={register}
                filedName={"Estimate_Time"}
                errors={errors}
              />
              <p className="text-end -mt-4 text-infromation text-sm">Days </p>
            </div>
          </div>

          <div className="grid grid-cols-1 w-full lg:w-[80%]">
            {/* Other Details */}
            <div className="mb-3">
              {/* Label */}
              <label
                htmlFor="otherDetails"
                className={`block font-normal text-secondaryl `}
              >
                Other Details
              </label>
              {/* Input */}
              <textarea
                type="text"
                id="otherDetails"
                name="otherDetails"
                {...register("Other_Details")}
                className={`block bg-[#FFFFFF80]  h-40 max-h-40 min-h-40 w-full rounded-sm cursor-not-allowed text-infromation   px-2 ps-10 focus:outline-none`}
                placeholder="Type Other Details"
                readOnly
                disabled
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              />
            </div>
          </div>
        </div>

        {(access === "property manager" ||
          access === "board member" ||
          companyType === "contractor") && (
          <div className="w-full h-full lg:w-[80%]">
            <h1 className="text-secondaryl font-extrabold text-2xl euclidbold ">
              Attach Proposal :
            </h1>
            <div className="pt-3">
              {load ? (
                "Loading..."
              ) : proposalFileUrl ? (
                proposalFileType === "pdf" ? (
                  <object
                    data={proposalFileUrl}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                    className="w-full  h-[90vh]"
                  >
                    <p>PDF could not be displayed</p>
                  </object>
                ) : proposalFileType === "word" ? (
                  <iframe
                    src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                      proposalFileUrl
                    )}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    title="Document Viewer"
                  />
                ) : (
                  // <DocxToHtml docxUrl={proposalFileUrl} />
                  <img
                    src={proposalFileUrl}
                    alt="Proposal Document"
                    className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                  />
                )
              ) : (
                <div className="text-infromation">No Documents Provided</div>
              )}
            </div>
          </div>
        )}
        {contract.proposal.Status === 2 && (
          <div className="h-full my-10  w-full lg:w-[80%] ">
            <h1 className="text-secondaryl font-extrabold text-2xl euclidbold ">
              Approved Proposal Document :
            </h1>

            <div className="pt-3">
              {load ? (
                "Loading..."
              ) : approveProposalFileUrl ? (
                approveProposalFileType === "pdf" ? (
                  <object
                    data={approveProposalFileUrl}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                    className="w-full h-[90vh]"
                  >
                    <p>PDF could not be displayed</p>
                  </object>
                ) : (
                  <img
                    src={approveProposalFileUrl}
                    alt="Approved Proposal Document"
                    className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                  />
                )
              ) : (
                <div className="text-infromation">No Documents Provided</div>
              )}
            </div>
          </div>
        )}

        {(access === "property manager" || access === "board member") &&
          contract.proposal.Status === 2 && (
            <AssignBoardMemberDetails
              contract={contract}
              load={load}
              setLoad={setLoad}
            />
          )}
      </div>
    </>
  );
}

export default ContractorProposalView;
