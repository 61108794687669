import React, { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { contractAPI } from "../../../../constants/API";
import axios from "axios";
import toast from "react-hot-toast";
import DisplayContracts from "../../../../components/Contracts/DisplayContracts";
import { useSearchParams } from "react-router-dom";

function RequestContract() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  const cookies = new Cookies();
  const [contracts, setContracts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [selectSortOpportunity, setSelectSortOpportunity] =
    useState("Select Type");

  const sortOpportunities = [
    // { id: 1, label: "Popularity" },
    { id: 2, label: "Newest First" },
    { id: 3, label: "Oldest First" },
    // { id: 4, label: "Closing soon" },
    // { id: 5, label: "Job Closed" },
  ];

  const token = cookies.get("token");

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);

      const apiUrl = `${contractAPI}/corporationresidentrequestcontracts`;
      const response = await axios.get(apiUrl, {
        headers: {
          authorization: token,
          companyType: "corporation",
        },
        params: {
          page: searchParams.get("page") || 1,
          pageSize: 5,
          status: 0,
          sort: selectSortOpportunity,
        },
      });

      setContracts(response.data.data.contracts);
      setTotalPages(response.data.data.totalPages);
      setCurrentPage(response.data.data.currentPage);
      setSearchParams(`?page=${response.data.data.currentPage}`);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  }, [page, selectSortOpportunity, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // console.log("page", page, "currentPage", currentPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Loading load={load} /> */}
      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <DisplayContracts
          title="Request Listing"
          contracts={contracts}
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={setPage}
          buttonName={"VIEW"}
          contractsPageType={"residentrequest"}
          load={load}
          setLoad={setLoad}
          setContracts={setContracts}
          selectSortOpportunity={selectSortOpportunity}
          setSelectSortOpportunity={setSelectSortOpportunity}
          sortOpportunities={sortOpportunities}
        />
      </div>
    </div>
  );
}

export default RequestContract;
