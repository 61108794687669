import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import axios from "axios";
import { serviceCorporationApi } from "../../../../constants/API";
import ContractCardSkeleton from "../../../../components/Contracts/ContractCardSkeleton";
import ServiceCard from "../../../../components/Service/ServiceCard";
import { getDaysInMonth, set } from "date-fns";
import DisplayService from "../../../../components/Service/DisplayService";
import { debounce } from "../../../../utils/helper";

function BookedService() {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [bookedData, setBookedData] = useState([]);
  const [displayBookedData, setDisplayBookedData] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);

    if (!bookedData.length > 0) {
      setLoad(true);
    }
    try {
      const response = await axios.get(`${serviceCorporationApi}/requests`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
        params: {
          page,
          limit: 5,
          status: 1,
        },
      });
      setTotalPages(response.data.totalPages);
      setBookedData((prevData) => [...prevData, ...response.data.data]);
      // setDisplayBookedData((prevData) => [...prevData, ...response.data.data]);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
      setTimeout(() => {
        setLoading(false); // Stop loading spinner
      }, 1000);
    }
  };

  const filterData = () => {
    const filteredServices = bookedData.filter((data) => {
  
      console.log("select Date", selectDate);
      console.log("Booked From", data);

      // Parse and normalize the selected date (if available)
      const selectedDate = selectDate
        ? new Date(
            Date.UTC(
              new Date(selectDate).getFullYear(),
              new Date(selectDate).getMonth(),
              new Date(selectDate).getDate()
            )
          )
        : null;

      // If no selected date, return all services
      if (!selectedDate) {
        return true;
      }

      console.log("selected Date (UTC-normalized)", selectedDate);

      // Parse and normalize the service date
      const serviceDate = new Date(
        Date.UTC(
          new Date(data.Book_From).getFullYear(),
          new Date(data.Book_From).getMonth(),
          new Date(data.Book_From).getDate()
        )
      );

      console.log("Booked From", data.Book_From);
      console.log("Service Date (UTC-normalized)", serviceDate);

      // Compare the full date (year, month, day) in UTC
      const isSameDate =
        selectedDate.getUTCFullYear() === serviceDate.getUTCFullYear() &&
        selectedDate.getUTCMonth() === serviceDate.getUTCMonth() &&
        selectedDate.getUTCDate() === serviceDate.getUTCDate();

      console.log("Is Same Date (UTC comparison):", isSameDate);

      return isSameDate;
    });

    setDisplayBookedData(filteredServices);
  };

  useEffect(() => {
    filterData();
  }, [selectDate, bookedData]);

  useEffect(() => {
    const fetchDebouncedData = debounce(fetchData, 500);
    fetchDebouncedData();
  }, [page]);

  // Function to handle scroll event in the container
  const handleContainerScroll = debounce(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        // Load next page when the container reaches the bottom
        if (page < totalPages) {
          setPage(page + 1);
        }
      }
    }
  }, 200);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleContainerScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleContainerScroll);
      }
    };
  }, [page, totalPages]);

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Booked Service
          </h1>
        </div>

        <div className="my-3">
          <label
            htmlFor="bookdate"
            className="block font-normal text-secondaryl"
          >
            <p>Select Date</p>
          </label>

          <div className="relative">
            <input
              type={"date"}
              id={"bookdate"}
              name={"bookdate"}
              onChange={(e) => {
                setSelectDate(e.target.value);
              }}
              onFocus={(e) => {
                e.target.showPicker();
              }}
              value={selectDate}
              className={`block h-11 w-1/4 bg-[#FFFFFF80] rounded-sm text-black pl-9 cursor-pointer focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none`}
            />

            <div
              className="absolute inset-y-0 left-2 top-3 opacity-50 cursor-pointer"
              onClick={() => {
                document.getElementById("bookdate").showPicker(); // Only trigger if not disabled
              }}
            >
              <Icon icon="uis:calender" className="text-xl" />
            </div>
          </div>
        </div>

        <DisplayService
          title={"Booked Service"}
          services={displayBookedData}
          load={load}
          loading={loading}
          fetchData={fetchData}
          containerRef={containerRef}
        />
      </div>
    </div>
  );
}

export default BookedService;
