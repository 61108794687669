import DisplayContracts from "../../../../components/Contracts/DisplayContracts.jsx";
import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { contractAPI } from "../../../../constants/API.js";
import Cookies from "universal-cookie";
import { useSearchParams } from "react-router-dom";

function ApprovedContracts() {
  const cookies = new Cookies();
  const [searchParams, setSearchParams] = useSearchParams();

  const [contracts, setContracts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectSortOpportunity, setSelectSortOpportunity] =
    useState("Select Type");
  const [selectFilterOpportunity, setSelectFilterOpportunity] =
    useState("Select Type");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sortOpportunities = [
    { id: 1, label: "Popularity" },
    { id: 2, label: "Newest First" },
    { id: 3, label: "Oldest First" },
    { id: 4, label: "Closing soon" },
    { id: 5, label: "Job Closed" },
  ];

  const filterOpportunities = [
    { id: 1, label: "All" },
    { id: 2, label: "Open" },
    { id: 3, label: "Closed" },
  ];

  const token = cookies.get("token");
  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      const token = cookies.get("token");
      const response = await axios.get(`${contractAPI}/approved`, {
        headers: {
          authorization: token,
          companyType: "contractor",
        },
        params: {
          page: searchParams.get("page") || 1,
          pageSize: 5,
          sort: selectSortOpportunity,
          filter: selectFilterOpportunity,
        },
      });
      // console.log("response", response.data.data);
      let serverContracts = response.data.data.contracts.map((contract) => {
        const { CpProposals, ...rest } = contract; // Destructure CpProposals and rest of the properties
        return {
          ...rest, // Spread the rest of the properties
          proposal: { ...CpProposals[0] }, // Assign proposal or null if CpProposals is empty
        };
      });

      setContracts(serverContracts);
      setTotalPages(response.data.data.totalPages);
      setCurrentPage(response.data.data.currentPage);
      setSearchParams(`?page=${response.data.data.currentPage}`);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to fetch approved contracts.");
    } finally {
      setLoad(false);
    }
  }, [page, selectSortOpportunity, selectFilterOpportunity, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
      <DisplayContracts
        title="Approved Opportunities"
        contracts={contracts}
        setContracts={setContracts}
        totalPages={totalPages}
        currentPage={currentPage}
        setPage={setPage}
        buttonName={"VIEW"}
        contractsPageType={"approved"}
        load={load}
        setLoad={setLoad}
        selectSortOpportunity={selectSortOpportunity}
        setSelectSortOpportunity={setSelectSortOpportunity}
        selectFilterOpportunity={selectFilterOpportunity}
        setSelectFilterOpportunity={setSelectFilterOpportunity}
        sortOpportunities={sortOpportunities}
        filterOpportunities={filterOpportunities}
      />
    </div>
  );
}

export default ApprovedContracts;
