import React from "react";
import { Header, Footer } from "../index";
import { Toaster } from "react-hot-toast";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden bg-[radial-gradient(50%_50%_at_50%_50%,_rgba(40,42,115,0.15)_0%,_rgba(0,148,191,0.15)_100%)]">
      {/* Toaster For Pop Of Notified */}
      <div className="absolute z-[99999]">
        <Toaster />
      </div>
      {/* Header */}

      <Header />

      {/* Main Content */}
      <main className="flex-1 w-full h-full relative">{children}</main>
      {/* Footer */}

      <Footer />
    </div>
  );
}

export default Layout;
