import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import { useLoading } from "../../context/LoadingContext";
import axios from "axios";
import { serviceCorporationApi } from "../../constants/API";
import toast from "react-hot-toast";
import ServiceViewModel from "./ServiceViewModel";

function ServiceCard({ service, fetchData, setServices, setLoad }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const companyType = cookies.get("companyType");

  const [viewService, setviewService] = useState({});
  const [showServiceViewModel, setShowServiceViewModel] = useState(false);
  const notifysuccess = (props) => toast.success(props, { duration: 4000 });
  const notifyfailure = (props) => toast.error(props, { duration: 2000 });

  const [serviceBookFromDate, setServiceBookFromDate] = useState("");
  const [serviceBookToDate, setServiceBookToDate] = useState("");
  const [serviceBookFromTime, setServiceBookFromTime] = useState("");
  const [serviceBookToTime, setServiceBookToTime] = useState("");

  useEffect(() => {
    if (service.Book_From) {
      const date = new Date(service.Book_From);
      setServiceBookFromDate(date.toLocaleDateString());
      setServiceBookFromTime(
        date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }
    if (service.Book_To) {
      const date = new Date(service.Book_To);
      setServiceBookToDate(date.toLocaleDateString());
      setServiceBookToTime(
        date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
    }
  }, [service.ID]);

  const handleUpdateRequest = async (status, id) => {
    setLoad(true);
    try {
      const response = await axios.put(
        `${serviceCorporationApi}/request/${id}`,
        { status },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: cookies.get("companyType"),
          },
        }
      );
      if (response.data.isUpdated) {
        setServices((prevServices) => {
          const updatedServices = prevServices.map((service) => {
            if (service.ID === id) {
              return { ...service, Status: status };
            }
            return service;
          });
          return updatedServices;
        });
      }
      notifysuccess("Service status updated Successfully", {
        duration: 4000,
      });
    } catch (error) {
      // console.log(error);
      notifyfailure(
        error.message || "Failed to approve service. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const showServiceHandler = (from, serviceData) => {
    if (from) {
      setShowServiceViewModel(true);
      setviewService(serviceData);
    } else {
      setShowServiceViewModel(true);
      // console.log(serviceData.Tenant);
      setviewService({
        Service_Name: serviceData.ServiceCorporation.Service_Name,
        Service_Description: serviceData.ServiceCorporation.Service_Description,
        Service_Image: serviceData?.ServiceCorporation?.Service_Image,
        isResident: serviceData.Owner || serviceData.Tenant ? true : false,
        Resident_Type: serviceData.Owner ? "Owner" : "Tenant",
        Resident_Data: serviceData.Owner
          ? serviceData.Owner
          : serviceData.Tenant,
        Book_From_Date: serviceBookFromDate,
        Book_From_Time: serviceBookFromTime,
        Book_To_Date: serviceBookToDate,
        Book_To_Time: serviceBookToTime,
        ID: serviceData.ID,
        Status: serviceData.Status,
      });
    }
  };

  return (
    <>
      {showServiceViewModel && viewService && (
        <ServiceViewModel
          service={viewService}
          setShowServiceViewModel={setShowServiceViewModel}
        />
      )}
      <div
        id="service-card"
        className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      >
        {/* Card Images  */}
        <div className="h-full relative lg:w-[20%] flex-none bg-cover rounded-xl text-center flex justify-center">
          {service?.ServiceCorporation?.Service_Image !== "" &&
          service?.ServiceCorporation?.Service_Image !== null ? (
            <img
              src={service.ServiceCorporation.Service_Image}
              alt={service.ServiceCorporation.Service_Name}
              className="lg:h-36 lg:w-52  rounded-md "
            />
          ) : (
            <div className="h-32 flex items-center"> No Image </div>
          )}
        </div>
        {/* Card Content */}

        <div className="flex-col lg:w-[60%] justify-center leading-normal">
          <div className="space-y-1.5">
            <div className="text-secondaryl euclidbold text-2xl line-clamp-1">
              {service.ServiceCorporation.Service_Name}
            </div>
            <div className="py-1 space-y-1.5">
              <div className="text-xl flex gap-1  font-bold items-center line-clamp-1">
                <Icon
                  icon="uis:calender"
                  className="text-secondaryl  text-lg"
                />
                <p className="text-secondaryl">
                  {serviceBookFromDate + " To " + serviceBookToDate}
                </p>
              </div>
              <div className="text-base flex gap-1  font-bold  items-center line-clamp-1">
                <Icon
                  icon="tdesign:time-filled"
                  className="text-secondaryl text-lg"
                />
                <p className="text-secondaryl">
                  {" "}
                  {serviceBookFromTime + " To " + serviceBookToTime}
                </p>
              </div>
            </div>
          </div>
          {service.Status === 1 && (
            <div className="text-sm flex gap-1 items-center text-[#00A701]">
              <Icon icon="duo-icons:approved" className=" text-lg" />
              <p className="">Approved</p>
            </div>
          )}

          {service.Status === 2 && (
            <div className="text-sm flex gap-1 items-center text-[#EB3333]">
              <Icon icon="tabler:cancel" className=" text-lg" />
              <p className="">Reject</p>
            </div>
          )}

          {service.Status === 0 && (
            <div className="text-sm flex gap-1 items-center text-[#FFA640]">
              <Icon icon="lsicon:minus-filled" className=" text-lg" />
              <p className="">Pending</p>
            </div>
          )}
        </div>
        {/* Card Buttons */}

        <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
          {cookies.get("access") === "property manager" &&
            service.Status === 0 && (
              <>
                <div
                  onClick={() => handleUpdateRequest(1, service.ID)}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Approve
                </div>
                <div
                  onClick={() => handleUpdateRequest(2, service.ID)}
                  className="h-11 w-full px-2 uppercase py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
                >
                  Reject
                </div>
              </>
            )}
          <div
            onClick={() => showServiceHandler(false, service)}
            className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            VIEW
          </div>
        </div>
      </div>
    </>
  );
}
export default ServiceCard;
