import { SecurityMemberLoginImg } from "../../../assets/images";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";

function SecurityMemberLogin() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Security members Login with Email ID"}
          requiredRegister={false}
          loginFieldName={"Email Id"}
          forgotField={false}
          loginImage={SecurityMemberLoginImg}
        />
      </Layout>
    </div>
  );
}

export default SecurityMemberLogin;
