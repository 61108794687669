import React from "react";

function ContractImage({ src, alt, setDisplayImage, displayImage }) {
  const handleDisplayImage = () => {
    setDisplayImage(src);
  };
  return (
    <div className="w-1/2 p-1 sm:w-1/5 " onClick={handleDisplayImage}>
      <div
        className={`block cursor-pointer ring-[1px] rounded-md ${
          displayImage === src ? "ring-secondaryl" : "ring-infromation"
        }`}
      >
        <img
          src={src}
          alt={alt || ""}
          className=" w-36 rounded-md lg:w-32 lg:h-20"
        />
      </div>
    </div>
  );
}

export default ContractImage;
