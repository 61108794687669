import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { adminAPI, baseUrl, corporationAPI } from "../../constants/API";
import { BsEyeFill } from "react-icons/bs";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { GoClockFill } from "react-icons/go";
import CorporationDetailsModal from "./CorporationModel";
import toast, { Toaster } from "react-hot-toast";
import ApproveForm from "./ApproveForm";
import { set } from "react-hook-form";

const ManageCorporations = ({ setLoad }) => {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [allApplicationsCount, setAllApplicationsCount] = useState(0);
  const [newApplicationsCount, setNewApplicationsCount] = useState(0);
  const [approvedApplicationsCount, setApprovedApplicationsCount] = useState(0);
  const [rejectedApplicationsCount, setRejectedApplicationsCount] = useState(0);
  const [selectedCorporation, setSelectedCorporation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [id, setid] = useState(null);
  const cookies = new Cookies();

  useEffect(() => {
    setLoad(true);
    setNewApplicationsCount(
      originalData.filter((item) => item.Verification_Status === null).length
    );
    setApprovedApplicationsCount(
      originalData.filter((item) => item.Verification_Status === 1).length
    );
    setRejectedApplicationsCount(
      originalData.filter(
        (item) =>
          item.Verification_Status === 0 || item.Verification_Status === 2
      ).length
    );
    setAllApplicationsCount(originalData.length);
    filterData(activeTab, searchTerm);
    setLoad(false);
  }, [originalData, activeTab, searchTerm]);

  const fetchCorporationData = async () => {
    setLoad(true);
    try {
      const response = await axios.get(corporationAPI, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });
      setOriginalData(response.data.corporations.reverse());
    } catch (error) {
      console.error("Error fetching corporation data:", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchCorporationData();
  }, []);

  const filterData = (tab, term) => {
    setLoad(true);
    let filtered = originalData;
    if (tab !== "All") {
      if (tab === "New") {
        filtered = originalData.filter(
          (corporation) => corporation.Verification_Status === null
        );
      } else if (tab === "Approved") {
        filtered = originalData.filter(
          (corporation) => corporation.Verification_Status === 1
        );
      } else if (tab === "Rejected") {
        filtered = originalData.filter(
          (corporation) =>
            corporation.Verification_Status === 0 ||
            corporation.Verification_Status === 2
        );
      }
    }

    if (term) {
      filtered = filtered.filter((item) =>
        item.Corporation_Name.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setLoad(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    filterData(tab, searchTerm);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterData(activeTab, e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoad(true);
    try {
      e.preventDefault();
      // Your search logic here
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  const renderStatus = (status) => {
    if (status === null) {
      return (
        <div className="flex items-center">
          <GoClockFill className="text-blue-500 mr-1" />
          <span>Pending</span>
        </div>
      );
    } else if (status === 1) {
      return (
        <div className="flex items-center whitespace-nowrap text-green-500">
          <RiCheckLine className="mr-1" />
          Approved
        </div>
      );
    } else if (status === 0) {
      return (
        <div className="flex items-center whitespace-nowrap text-red-500">
          <RiCloseLine className="mr-1" />
          Rejected
        </div>
      );
    } else if (status === 2) {
      return (
        <div className="flex items-center whitespace-nowrap text-yellow-500">
          <GoClockFill className="mr-1" />
          Paused
        </div>
      );
    }
  };

  const handleViewDetails = (corporation) => {
    setSelectedCorporation(corporation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const tabCounts = {
    All: allApplicationsCount,
    New: newApplicationsCount,
    Approved: approvedApplicationsCount,
    Rejected: rejectedApplicationsCount,
  };

  const handleAction = async (id, action) => {
    setLoad(true);
    try {
      await axios.put(
        `${adminAPI}/corporation/${id}`,
        {
          Verification_Status: action,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      fetchCorporationData();
      toast.success(
        `Corporation ${
          action === 1 ? "approved" : action === 0 ? "rejected" : "paused"
        } successfully.`
      );
      // console.log("Action successful");
    } catch (error) {
      // console.log("errros : ", error);
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  const handleApprove = async () => {
    setLoad(true);
    // console.log(userId, password);
    try {
      await axios.put(
        `${adminAPI}/corporation/${id}`,
        {
          Verification_Status: 1,
          UserID: userId,
          Password: password,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      toast.success("Application Approved", { duration: 4000 });
    } catch (error) {
      // console.log("errros : ", error);

      toast.error(error.response.data.message, { duration: 4000 });
    } finally {
      setLoad(false);
      setFormModal(false);
      fetchCorporationData();
    }
  };

  const handleForm = async (id) => {
    setid(id);
    setFormModal(true);
  };

  return (
    <div>
      <div className="flex justify-left items-center mb-4">
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "All" ? "bg-cyan-500 text-white" : "bg-gray-300"
          }`}
          onClick={() => handleTabClick("All")}
        >
          All ({tabCounts.All})
        </button>
        <button
          className={`px-4   ml-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "New" ? "bg-cyan-500 text-white" : "bg-gray-300"
          } `}
          onClick={() => handleTabClick("New")}
        >
          New Applications ({tabCounts.New})
        </button>
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "Approved" ? "bg-cyan-500 text-white" : "bg-gray-300"
          } ml-4`}
          onClick={() => handleTabClick("Approved")}
        >
          Approved Applications ({tabCounts.Approved})
        </button>
        <button
          className={`px-4 py-2 rounded whitespace-nowrap hover:bg-cyan-600 hover:text-white ${
            activeTab === "Rejected" ? "bg-cyan-500 text-white" : "bg-gray-300"
          } ml-4`}
          onClick={() => handleTabClick("Rejected")}
        >
          Rejected Applications ({tabCounts.Rejected})
        </button>
        <form
          onSubmit={handleSubmit}
          className="flex justify-center w-full rounded-xl"
        >
          <input
            type="text"
            placeholder="Corporation Name"
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-300 px-4 py-2 rounded-l"
          />
          <button
            type="submit"
            className="bg-cyan-500 text-white px-4 py-2 rounded-r"
          >
            Search
          </button>
        </form>
      </div>

      <div className="flex justify-center">
        <div className="overflow-auto max-h-[60vh] w-full table-container">
          <table className="table-auto w-full relative">
            <thead className="relative">
              <tr>
                <th className="px-4 py-2 text-sm">Corporation Name</th>
                <th className="px-4 py-2 text-sm">EmployedByCondo</th>
                <th className="px-4 py-2 text-sm">Property Managers</th>
                <th className="px-4 py-2 text-sm">Board Members</th>
                <th className="px-4 py-2 text-sm">VIEW</th>
                <th className="px-4 py-2 text-sm">Status</th>
                <th className="px-4 py-2 text-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((item) => (
                <tr key={item.ID}>
                  <td className="border px-4 py-2">{item.Corporation_Name}</td>
                  <td className="border px-4 py-2">
                    {item.Employed_By_Condo ? "Yes" : "No"}
                  </td>
                  <td className="border px-4 py-2">
                    {item.cp_propertymanagers?.length || 0}
                  </td>
                  <td className="border px-4 py-2">
                    {item.BoardMembers?.length || 0}
                  </td>
                  <td className="border px-4 py-2 cursor-pointer">
                    <BsEyeFill
                      onClick={() => handleViewDetails(item)}
                      className="text-blue-500 cursor-pointer  z-50 "
                    />
                  </td>
                  <td className="border px-4 py-2">
                    {renderStatus(item.Verification_Status)}
                  </td>
                  <td className="border px-4 py-2">
                    {item.Verification_Status !== 1 && (
                      <button
                        onClick={() => handleForm(item.ID)}
                        className="text-green-500 hover:text-green-700"
                      >
                        Approve
                      </button>
                    )}
                    {item.Verification_Status !== 0 && (
                      <button
                        onClick={() => handleAction(item.ID, 0)}
                        className="text-red-500 hover:text-red-700 ml-2"
                      >
                        Reject
                      </button>
                    )}
                    {item.Verification_Status !== 2 && (
                      <button
                        onClick={() => handleAction(item.ID, 2)}
                        className="text-yellow-500 hover:text-yellow-700 ml-2"
                      >
                        Pause
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <CorporationDetailsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        corporation={selectedCorporation}
      />
      {formModal && (
        <ApproveForm
          onClose={() => setFormModal(false)}
          onApprove={handleApprove}
          setUserId={setUserId}
          setPassword={setPassword}
          userId={userId}
          password={password}
        />
      )}
    </div>
  );
};

export default ManageCorporations;
