import React, { useCallback, useEffect, useState } from "react";
import { ContractorProfileLeftImage, Spinner } from "../../../assets/images";
import axios from "axios";
import { InputFileIcon, PlusSquare } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import InputBox from "../../Elements/InputBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get, set, useForm } from "react-hook-form";
import { corporationCertificateAPI } from "../../../constants/API";
import { corporationSignupAsync } from "../../../store/Auth/Auth";
import { useDispatch } from "react-redux";
import InputBoxLive from "../../Elements/InputBoxLive";
import toast from "react-hot-toast";
import InputBoxLivePropertyManager from "../../Elements/InputBoxLivePropertyManager";
import Loading from "../../Elements/Loading";
import { Icon } from "@iconify/react";

const CorporationSignupForm = () => {
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch(); //Employed_By_Condo
  const [employedByCondoState, setEmployedByCondoState] = useState(false);
  const [onSiteHoursSameWorkingHours, setOnSiteHoursSameWorkingHours] =
    useState(false);
  const [propertyManagerCount, setPropertyManagerCount] = useState(0);
  const [manageOnsiteDaysAndTimeState, setManageOnsiteDaysAndTimeState] =
    useState([
      {
        Day: "Monday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Tuesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Wednesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Thursday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Friday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Saturday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Sunday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
    ]);
  const [manageWorkingDaysAndTimeState, setManageWorkingDaysAndTimeState] =
    useState([
      {
        Day: "Monday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Tuesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Wednesday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Thursday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Friday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Saturday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
      {
        Day: "Sunday",
        Checked: false,
        Working_Hours_From: "",
        Working_Hours_To: "",
      },
    ]);
  const schema = yup.object().shape({
    Corporation_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Corporation name can only contain letters.")
      .required("Corporation Name is required"),
    Corporation_Address: yup
      .string()
      .required("Corpora  tion Address is required"),
    Corporation_Property_Manager_Name: yup
      .string()
      .matches(
        /^[A-Za-z\s]+$/,
        "Property Manager name can only contain letters."
      )
      .required("Property Manager Name is required"),
    Corporation_Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(/^[0-9]{10}$/, "Phone No must be exactly 10 digits"),
    Property_Manager_Email: yup
      .string()
      .required("Property Manager Email is required")
      .test("valid-Email", "Invalid Property Manager Email id", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      }),
    Property_Manager_Phone_No: yup
      .string()
      .required("Property Manager Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),

    propertyManagers: yup.array().of(
      yup.object().shape({
        Property_Manager_Name: yup
          .string()
          .matches(
            /^[A-Za-z\s]+$/,
            "Property Manager name can only contain letters."
          )
          .required("Property Manager Name is required"),
        Property_Manager_Email: yup
          .string()
          .required("Property Manager Email is required")
          .test("valid-Email", "Invalid Property Manager Email id", (value) => {
            if (!value) return true; // Skip validation if the field is empty
            // Check if the value matches the pattern of an email
            return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(
              value
            );
          })
          .test(
            "is-unique",
            "Property Manager Email Must Be Unique",
            function (value) {
              if (!value) return true; // Skip validation if the field is empty
              const propertyManagersArray = getValues("propertyManagers");
              if (propertyManagersArray) {
                const allEmails = propertyManagersArray?.map(
                  (manager) => manager.Property_Manager_Email
                );
                const mainPropertyManagerEmail = getValues(
                  "Property_Manager_Email"
                );
                if (mainPropertyManagerEmail) {
                  allEmails.push(mainPropertyManagerEmail);
                }
                return allEmails.filter((email) => email === value).length <= 1;
              }
              return true;
            }
          )
          .test(
            "is-unique2",
            "Property Manager Email Must Be Unique",
            function (value) {
              if (!value) return true;
              uniqueEmails(value);
              return true;
            }
          ),
        Property_Manager_Phone_No: yup
          .string()
          .required("Phone No is required")
          .matches(
            /^[0-9]{10}$/, // Regular expression for 10-digit phone number
            "Phone No must be exactly 10 digits"
          ),
      })
    ),
    Employed_By_Condo: yup.boolean().default(false),
    Management_Company_Name: yup
      .string()
      .matches(
        /^[A-Za-z\s]+$/,
        "Management Company name can only contain letters."
      )
      .test(
        "is-required",
        "Management Company Name is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Address: yup
      .string()
      .test(
        "is-required",
        "Management Company Address is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Phone_No: yup
      .string()
      .test(
        "is-required",
        "Management Company Phone No is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      ),
    Management_Company_Email: yup
      .string()
      .test(
        "is-required",
        "Management Company Email is required",
        function (value) {
          if (!this.parent.Employed_By_Condo) {
            return value !== "";
          }
          return true;
        }
      )
      .test("valid-Email", "Invalid Email id", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      }),
    Onsite_Same_Working_Hours: yup.boolean().default(false),
    manageOnsiteDaysAndTime: yup
      .array()
      .of(
        yup.object().shape({
          Day: yup.string(),
          Checked: yup.boolean(),
          Working_Hours_From: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_From !==
          //       ""
          //     );
          //   }
          //   return true;
          // }),
          Working_Hours_To: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_To !== ""
          //     );
          //   }
          //   return true;
          // }),
        })
      )
      .test(
        "at-least-one-day-checked",
        "At least one day must be checked and have Onsite hours filled.",
        (days) =>
          days?.some((day) => {
            if (!day.Working_Hours_From || !day.Working_Hours_To) {
              return false;
            }
            return (
              day.Checked &&
              day.Working_Hours_From?.trim() !== "" &&
              day.Working_Hours_To?.trim() !== ""
            );
          })
      )
      .default([
        {
          Day: "Monday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Tuesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Wednesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Thursday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Friday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Saturday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Sunday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
      ]),
    manageWorkingDaysAndTime: yup
      .array()
      .of(
        yup.object().shape({
          Day: yup.string(),
          Checked: yup.boolean(),
          Working_Hours_From: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_From !==
          //       ""
          //     );
          //   }
          //   return true;
          // }),
          Working_Hours_To: yup.string(),
          // .test("is-required", "Required", function (value) {
          //   if (manageOnsiteDaysAndTimeState[this.key].Checked) {
          //     return (
          //       manageOnsiteDaysAndTimeState[this.key].Working_Hours_To !== ""
          //     );
          //   }
          //   return true;
          // }),
        })
      )
      .test(
        "at-least-one-day-checked",
        "At least one day must be checked and have working hours filled.",
        (days) =>
          days?.some((day) => {
            if (!day.Working_Hours_From || !day.Working_Hours_To) {
              return false;
            }
            return (
              day.Checked &&
              day.Working_Hours_From?.trim() !== "" &&
              day.Working_Hours_To?.trim() !== ""
            );
          })
      )
      .default([
        {
          Day: "Monday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Tuesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Wednesday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Thursday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Friday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Saturday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
        {
          Day: "Sunday",
          Checked: false,
          Working_Hours_From: "",
          Working_Hours_To: "",
        },
      ]),
    Public_Certificate_URL: yup.string(),
    Term_And_Policy: yup.boolean().oneOf([true], "This field must be Checked"),
  });

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    // Check if the pasted data contains only numbers
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is not a number
    if (
      !(
        (
          (event.key >= "0" && event.key <= "9") ||
          event.key === "Tab" ||
          event.key === "F5" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "Delete" ||
          event.keyCode === 8 || // backspace
          (event.ctrlKey && event.keyCode === 65) ||
          (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
          (event.ctrlKey && event.keyCode === 67)
        ) // Ctrl + C
      )
    ) {
      // Prevent the default behavior (don't write the character)
      event.preventDefault();
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues,
    trigger,
  } = useForm({ resolver: yupResolver(schema) });

  // console.log(errors);
  const uniqueEmails = () => {
    const propertyManagersArray = getValues("propertyManagers");

    // Extract all email addresses from the property managers array
    if (propertyManagersArray.length > 0) {
      const allEmails = propertyManagersArray?.map(
        (manager) => manager.Property_Manager_Email
      );

      // Create an object to store the count of each email
      const emailCounts = {};
      const errorArray = [];
      const mainPropertyManagerEmail = getValues("Property_Manager_Email");
      allEmails.forEach((email) => {
        emailCounts[email] = (emailCounts[email] || 0) + 1;
        if (email === mainPropertyManagerEmail) {
          emailCounts[email] = emailCounts[email] + 1;
        }

        errorArray.push(emailCounts[email]);
      });

      // Check for duplicates
      const duplicateEmails = errorArray?.map((email, index) => {
        if (email > 1) {
          setError(`propertyManagers[${index}].Property_Manager_Email`, {
            type: "manual",
            message: "Property Manager Email Must Be Unique",
          });
        } else {
          const e =
            errors["propertyManagers"] && errors["propertyManagers"][index]
              ? errors["propertyManagers"][index].Property_Manager_Email
              : null;

          if (e?.message === "Property Manager Email Must Be Unique")
            clearErrors(`propertyManagers[${index}].Property_Manager_Email`);
        }
      });
    }
  };

  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
  const [piCertificate, setPiCertificate] = useState(null);
  const [piCertificateError, setPiCertificateError] = useState("");
  const [piCertificateUploded, setPiCertificateUploded] = useState("");
  const [piCertificateURL, setPiCertificateURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleAddPropertyManager = () => {
    let propertyManagersArray = getValues("propertyManagers");
    if (!propertyManagersArray) {
      propertyManagersArray = [];
    }
    propertyManagersArray.push({
      Property_Manager_Name: "",
      Property_Manager_Email: "",
      Property_Manager_Phone_No: "",
    });

    const length = propertyManagersArray.length;
    setPropertyManagerCount(length);
    setValue("propertyManagers", propertyManagersArray);
  };
  const handleDeletePropertyManager = (indexToDelete) => {
    let propertyManagersArray = getValues("propertyManagers");
    const updatedManagers = propertyManagersArray.filter(
      (_, index) => index !== indexToDelete
    );
    const length = updatedManagers.length;
    setPropertyManagerCount(length);
    setValue("propertyManagers", updatedManagers);
  };

  const handleManageOnsiteDaysAndTime = (index, field, value) => {
    // let updateDaysAndTime;
    setManageOnsiteDaysAndTimeState((prevDaysAndTime) => {
      const updateDaysAndTime = [...prevDaysAndTime];
      updateDaysAndTime[index][field] = value;
      if (field === "Checked" && value === false) {
        updateDaysAndTime[index].Working_Hours_From = "";
        updateDaysAndTime[index].Working_Hours_To = "";
        setValue(`manageOnsiteDaysAndTime[${index}].Working_Hours_From`, "");
        setValue(`manageOnsiteDaysAndTime[${index}].Working_Hours_To`, "");
        // clearErrors(`manageOnsiteDaysAndTime[${index}].Working_Hours_From`);
        // clearErrors(`manageOnsiteDaysAndTime[${index}].Working_Hours_To`);
      }
      return updateDaysAndTime;
    });

    setValue(`manageOnsiteDaysAndTime[${index}].${field}`, value);
    // trigger(`manageOnsiteDaysAndTime[${index}].Working_Hours_From`);
    // trigger(`manageOnsiteDaysAndTime[${index}].Working_Hours_To`);
  };

  const handleManageWorkingDaysAndTime = (index, field, value) => {
    // let updateDaysAndTime;
    setManageWorkingDaysAndTimeState((prevDaysAndTime) => {
      const updateDaysAndTime = [...prevDaysAndTime];
      updateDaysAndTime[index][field] = value;
      if (field === "Checked" && value === false) {
        updateDaysAndTime[index].Working_Hours_From = "";
        updateDaysAndTime[index].Working_Hours_To = "";
        setValue(`manageWorkingDaysAndTime[${index}].Working_Hours_From`, "");
        setValue(`manageWorkingDaysAndTime[${index}].Working_Hours_To`, "");
        // clearErrors(`manageWorkingDaysAndTime[${index}].Working_Hours_From`);
        // clearErrors(`manageWorkingDaysAndTime[${index}].Working_Hours_To`);
      }
      return updateDaysAndTime;
    });

    setValue(`manageWorkingDaysAndTime[${index}].${field}`, value);
    // trigger(`manageWorkingDaysAndTime[${index}].Working_Hours_From`);
    // trigger(`manageWorkingDaysAndTime[${index}].Working_Hours_To`);
  };

  // console.log(getValues());
  const handleEmployedByCondoChange = useCallback(
    (value) => {
      setEmployedByCondoState(value);
      setValue("Employed_By_Condo", value);
    },
    [employedByCondoState]
  );

  const handleOnsiteHoursSameWorkingHoursChange = useCallback(
    (value) => {
      setOnSiteHoursSameWorkingHours(value);
      setValue("Onsite_Same_Working_Hours", value);
      // console.log("value 1", value);

      if (getValues("Onsite_Same_Working_Hours") === true) {
        // console.log(
        //   "manageWorkingDaysAndTimeState",
        //   manageWorkingDaysAndTimeState
        // );
        // console.log(
        //   "manageOnsiteDaysAndTimeState",
        //   manageOnsiteDaysAndTimeState
        // );

        // Assuming manageOnsiteDaysAndTimeState is an object, create a deep copy
        const newManageOnsiteDaysAndTimeState = JSON.parse(
          JSON.stringify(manageOnsiteDaysAndTimeState)
        );

        setManageWorkingDaysAndTimeState(() => {
          return newManageOnsiteDaysAndTimeState;
        });
        setValue("manageWorkingDaysAndTime", newManageOnsiteDaysAndTimeState);
      }
    },
    [manageOnsiteDaysAndTimeState]
  );

  const onCorporationSignup = async (data, e) => {
    e.preventDefault();
    // notifysuccess("Page is in Development");
    if (piCertificateURL) {
      data.Public_Certificate_URL = piCertificateURL;
    }

    if (manageOnsiteDaysAndTimeState) {
      data.manageOnsiteDaysAndTime = manageOnsiteDaysAndTimeState;
    }

    if (onSiteHoursSameWorkingHours === true) {
      data.manageWorkingDaysAndTime = manageOnsiteDaysAndTimeState;
    } else {
      if (manageWorkingDaysAndTimeState) {
        data.manageWorkingDaysAndTime = manageWorkingDaysAndTimeState;
      }
    }

    let res;
    console.log("data", data);

    try {
      setLoad(true);
      res = await dispatch(corporationSignupAsync(data));
      if (res.meta.requestStatus === "fulfilled") {
        if (res?.payload?.message) {
          toast.success(res.payload.message, { duration: 6000 });
          reset();
          navigate("/corporations/login", { state: res });
        }
      }
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Failed to register corporation. Please try again later."
      );
    } finally {
      setLoad(false);
    }
  };

  const handleChangePiCertificate = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setPiCertificate(null);
      setPiCertificateError("");
      setPiCertificateUploded("");
      setPiCertificateURL("");
      clearErrors("Public_Certificate_URL");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setPiCertificate(file);
        setPiCertificateError("");
        setPiCertificateURL("");
        setPiCertificateUploded("");
        clearErrors("Public_Certificate_URL");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setPiCertificate(null);
        setPiCertificateError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setPiCertificateUploded("");
        setPiCertificateURL("");
        clearErrors("Public_Certificate_URL");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setPiCertificate(null);
        setPiCertificateError("File size exceeds limit (5 MB)");
        setPiCertificateUploded("");
        setPiCertificateURL("");
        clearErrors("Public_Certificate_URL");
      } else {
        setPiCertificate(null);
        setPiCertificateError("Something went wrong! Please try again.");
        setPiCertificateUploded("");
        setPiCertificateURL("");
        clearErrors("Public_Certificate_URL");
      }
    }
  };

  const handleUploadPiCertificate = async () => {
    if (!piCertificate) {
      setPiCertificateError("Please Upload a Public information certificate");
      setPiCertificateUploded("");
      return;
    }
    const formData = new FormData();
    formData.append("companyCertificate", piCertificate);
    try {
      setUploadingFile(true);
      clearErrors("Public_Certificate_URL");
      setPiCertificateError("");
      const response = await axios.post(corporationCertificateAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setPiCertificateURL(response.data.data.url);
      setUploadingFile(false);
      setPiCertificateError("");
      setPiCertificateUploded("Uploaded Successfully");
    } catch (error) {
      setUploadingFile(false);
      console.error("Error uploading image:", error);
      setPiCertificateError("Failed to upload image");
      setPiCertificateUploded("");
      setPiCertificateURL("");
      clearErrors("Public_Certificate_URL");
    }
  };

  return (
    <div>
      <Loading load={load} />

      <div className="w-full px-4 m-auto p-6 flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold flex justify-center  md:justify-start  ">
          Create Account
        </h1>
        <p className="my-1 text-infromation text-base leading-tight flex justify-center  lg:justify-start ">
          Create a corporation account!
        </p>
        {/* SignUp Form Start Here  */}
        <form
          onSubmit={handleSubmit(onCorporationSignup)}
          encType="multipart/form-data"
        >
          <div className="py-4">
            {/* Corporation Name and Address */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
              {/* Corporation Name */}
              <InputBox
                title={"Corporation Name"}
                register={register}
                filedName={"Corporation_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />

              {/* Corporation Address */}
              <InputBox
                title={"Corporation Address"}
                register={register}
                filedName={"Corporation_Address"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            </div>
            {/* Corporation Property Manager and Phone no */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
              {/* Property Manager Name */}
              <InputBox
                title={"Corporation Property Manager 1 Name"}
                register={register}
                filedName={"Corporation_Property_Manager_Name"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
              {/* Phone No */}
              <InputBoxLive
                title={"Phone No"}
                register={register}
                filedName={"Corporation_Phone_No"}
                must={true}
                type="tel"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                maxLength="10"
                schema={schema}
                setError={setError}
              />
            </div>
            {/* Property Manager Email And Ph  */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 w-full">
              <InputBoxLive
                title={"Property Manager 1 Email"}
                register={register}
                filedName={"Property_Manager_Email"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
              />
              {/* Property Manager Phone No */}
              <InputBoxLive
                title={"Property Manager 1 Phone No"}
                register={register}
                filedName={"Property_Manager_Phone_No"}
                must={true}
                type="tel"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                maxLength="10"
                schema={schema}
                setError={setError}
              />
            </div>

            <div className="grid grid-cols-1 w-full pt-2 pb-4">
              <div className="flex items-center gap-5">
                <label
                  htmlFor="Employed_By_Condo"
                  className="pt-2 text-secondaryl"
                >
                  Employed by Condo Corporation ?
                </label>
                <div className="flex gap-1 items-center">
                  <input
                    type="checkbox"
                    id="Employed_By_Condo_Yes"
                    name="Employed_By_Condo"
                    value="Yes"
                    checked={employedByCondoState}
                    onChange={() => handleEmployedByCondoChange(true)}
                    className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                  />
                  <label htmlFor="Employed_By_Condo_Yes" className="pt-2">
                    Yes
                  </label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    id="Employed_By_Condo_No"
                    name="Employed_By_Condo"
                    value="No"
                    checked={!employedByCondoState}
                    onChange={() => handleEmployedByCondoChange(false)}
                    className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                  />
                  <label htmlFor="Employed_By_Condo_No" className="pt-2">
                    No
                  </label>
                </div>
              </div>
            </div>

            {/* Management Company Fields */}
            {!employedByCondoState && (
              <div className=" bg-gradient-to-b  from-[#282a73]/15 to-[#0094bf]/15 p-4   rounded-sm ">
                <div className="grid grid-cols-1 md:mr-4 lg:mr-20">
                  <InputBox
                    title={"Management Company Name"}
                    register={register}
                    filedName={"Management_Company_Name"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base w-full"}
                  />
                  <InputBox
                    title={"Management Company Address"}
                    register={register}
                    filedName={"Management_Company_Address"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                  <InputBoxLive
                    title={"Management Company Email"}
                    register={register}
                    filedName={"Management_Company_Email"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    schema={schema}
                    setError={setError}
                  />

                  <InputBoxLive
                    title={"Management Company Phone No"}
                    register={register}
                    filedName={"Management_Company_Phone_No"}
                    must={true}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    type="tel"
                    maxLength="10"
                    schema={schema}
                    setError={setError}
                  />
                </div>
              </div>
            )}

            {/* Working Hourse Or Not*/}
            <div className="grid grid-cols-1 w-full mt-4">
              <div className="flex items-center gap-5">
                <label
                  htmlFor="Employed_By_Condo"
                  className="pt-2 text-secondaryl"
                >
                  Onsite Hours Same as Working Hours ?
                </label>
                <div className="flex gap-1 items-center">
                  <input
                    type="checkbox"
                    id="Onsite_Hours_Same_As_Working_Hours_Yes"
                    name="Onsite_Hours_Same_As_Working_Hours_Yes"
                    value="Yes"
                    checked={onSiteHoursSameWorkingHours}
                    onChange={() =>
                      handleOnsiteHoursSameWorkingHoursChange(true)
                    }
                    className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                  />
                  <label
                    htmlFor="Onsite_Hours_Same_As_Working_Hours_Yes"
                    className="pt-2"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    id="Onsite_Hours_Same_As_Working_Hours_No"
                    name="Onsite_Hours_Same_As_Working_Hours_No"
                    value="No"
                    checked={!onSiteHoursSameWorkingHours}
                    onChange={() =>
                      handleOnsiteHoursSameWorkingHoursChange(false)
                    }
                    className="bg-transparent text-black h-7 w-7 accent-secondaryl"
                  />
                  <label
                    htmlFor="Onsite_Hours_Same_As_Working_Hours_No"
                    className="pt-2"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>

            {/* Working Hours */}

            <div className="mb-3 my-4">
              <label className="block font-normal mb-3 text-secondaryl">
                Manager Working Days & Time
                <span className="text-red-500 text-base">*</span>
              </label>
              <div className="flex flex-row flex-wrap gap-2">
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((Day, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-wrap flex-row items-center gap-5"
                  >
                    <div className="flex items-center">
                      <label
                        htmlFor={`${Day.toLowerCase()}`}
                        className="w-12 text-secondaryl"
                      >
                        {Day.slice(0, 3).toUpperCase()}
                      </label>

                      <input
                        type="checkbox"
                        id={`${Day.toLowerCase()}`}
                        name={`${Day.toLowerCase()}`}
                        onChange={(e) =>
                          handleManageWorkingDaysAndTime(
                            index,
                            "Checked",
                            e.target.checked
                          )
                        }
                        className="bg-transparent outline-none text-black h-11 w-7 accent-secondaryl"
                        checked={manageWorkingDaysAndTimeState[index].Checked}
                      />
                    </div>
                    {/* From Input */}

                    <div className="md:flex md:flex-wrap gap-5 space-y-2 justify-end ">
                      <div className="flex gap-2 items-center ">
                        <label
                          htmlFor={`${Day.toLowerCase()}From`}
                          className="block font-normal text-secondaryl"
                        >
                          From
                        </label>
                        <input
                          type="time"
                          id={`${Day.toLowerCase()}From`}
                          name={`${Day.toLowerCase()}From`}
                          value={
                            manageWorkingDaysAndTimeState[index]
                              .Working_Hours_From
                          }
                          onChange={(e) =>
                            handleManageWorkingDaysAndTime(
                              index,
                              "Working_Hours_From",
                              e.target.value
                            )
                          }
                          className={`block h-11 w-full min-w-16 md:max-w-full  rounded-sm text-black px-2 ring-gray-400 ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] ${
                            errors[`${Day.toLowerCase()}From`]
                              ? " ring-[0.5px]  ring-red-500"
                              : ""
                          } ${
                            manageWorkingDaysAndTimeState[index].Checked
                              ? "bg-[#FFFFFF80]"
                              : "cursor-not-allowed bg-primaryl/[10%] "
                          }`}
                          placeholder="hrs:mins"
                          disabled={
                            !manageWorkingDaysAndTimeState[index].Checked
                          }
                          // {...register(
                          //   `manageWorkingDaysAndTimeState[${index}].Working_Hours_From`
                          // )}
                        />
                        <div className="h-[2vh] pt-1">
                          {errors["manageWorkingDaysAndTime"] &&
                            errors["manageWorkingDaysAndTime"][index] &&
                            errors["manageWorkingDaysAndTime"][index][
                              "Working_Hours_From"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["manageWorkingDaysAndTime"][index][
                                    "Working_Hours_From"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>

                      {/* To Input */}
                      <div className="flex gap-2  justify-end items-center ">
                        <label
                          htmlFor={`${Day.toLowerCase()}To`}
                          className="block font-normal mt-1 text-secondaryl"
                        >
                          To
                        </label>
                        <input
                          type="time"
                          id={`${Day.toLowerCase()}To`}
                          name={`${Day.toLowerCase()}To`}
                          value={
                            manageWorkingDaysAndTimeState[index]
                              .Working_Hours_To
                          }
                          onChange={(e) =>
                            handleManageWorkingDaysAndTime(
                              index,
                              "Working_Hours_To",
                              e.target.value
                            )
                          }
                          className={` block h-11 min-w-16  md:max-w-full rounded-sm text-black px-2  ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] ${
                            errors[`${Day.toLowerCase()}To`]
                              ? " ring-[0.5px]  ring-red-500"
                              : ""
                          }  ${
                            manageWorkingDaysAndTimeState[index].Checked
                              ? "bg-[#FFFFFF80]"
                              : "cursor-not-allowed bg-primaryl/[10%] "
                          }`}
                          placeholder="hrs:mins"
                          disabled={
                            !manageWorkingDaysAndTimeState[index].Checked
                          }
                        />
                        <div className="h-[2vh] pt-1">
                          {errors["manageWorkingDaysAndTime"] &&
                            errors["manageWorkingDaysAndTime"][index] &&
                            errors["manageWorkingDaysAndTime"][index][
                              "Working_Hours_To"
                            ] && (
                              <p className="text-red-500 text-xs leading-none">
                                {
                                  errors["manageWorkingDaysAndTime"][index][
                                    "Working_Hours_To"
                                  ].message
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="h-[3vh] pt-1 max-w-18">
                  {errors["manageWorkingDaysAndTime"] && (
                    <p className="text-red-500 text-xs leading-none">
                      {errors["manageWorkingDaysAndTime"].message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* OnSite Working Hours */}
            {!onSiteHoursSameWorkingHours && (
              <div className="mb-3 my-4 ">
                <label className="block font-normal mb-3 text-secondaryl">
                  Manager Onsite Days & Time
                  <span className="text-red-500 text-base">*</span>
                </label>
                <div className="flex flex-row flex-wrap gap-2">
                  {[
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((Day, index) => (
                    <div
                      key={index}
                      className="w-full flex flex-wrap flex-row items-center gap-5"
                    >
                      {/* {manageOnsiteDaysAndTimeState[index]} */}
                      <div className="flex items-center  ">
                        <label
                          htmlFor={`${Day.toLowerCase()}`}
                          className="w-12 text-secondaryl"
                        >
                          {Day.slice(0, 3).toUpperCase()}
                        </label>

                        <input
                          type="checkbox"
                          id={`${Day.toLowerCase()}`}
                          name={`${Day.toLowerCase()}`}
                          onChange={(e) =>
                            handleManageOnsiteDaysAndTime(
                              index,
                              "Checked",
                              e.target.checked
                            )
                          }
                          className="bg-transparent text-black h-11 w-7 accent-secondaryl"
                        />
                      </div>
                      {/* From Input */}
                      <div className="md:flex md:flex-wrap gap-5 space-y-2 justify-end ">
                        <div className="flex gap-2 items-center ">
                          <label
                            htmlFor={`${Day.toLowerCase()}From`}
                            className="block font-normal "
                          >
                            From
                          </label>
                          <input
                            type="time"
                            id={`${Day.toLowerCase()}From`}
                            name={`${Day.toLowerCase()}From`}
                            value={
                              manageOnsiteDaysAndTimeState[index]
                                .Working_Hours_From
                            }
                            onChange={(e) =>
                              handleManageOnsiteDaysAndTime(
                                index,
                                "Working_Hours_From",
                                e.target.value
                              )
                            }
                            className={` block h-11 w-full min-w-16 md:max-w-full  rounded-sm text-black  px-2  ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px] 
                                   ${
                                     errors[`${Day.toLowerCase()}From`]
                                       ? " ring-[0.5px]  ring-red-500"
                                       : ""
                                   } ${
                              manageOnsiteDaysAndTimeState[index].Checked
                                ? "bg-[#FFFFFF80]"
                                : "cursor-not-allowed bg-primaryl/[10%] "
                            }`}
                            placeholder="hrs:mins"
                            disabled={
                              !manageOnsiteDaysAndTimeState[index].Checked
                            }
                            // {...register(
                            //   `manageOnsiteDaysAndTime[${index}].Working_Hours_From`
                            // )}
                          />
                          <div className="h-[2vh] pt-1">
                            {errors["manageOnsiteDaysAndTime"] &&
                              errors["manageOnsiteDaysAndTime"][index] &&
                              errors["manageOnsiteDaysAndTime"][index][
                                "Working_Hours_From"
                              ] && (
                                <p className="text-red-500 text-xs leading-none">
                                  {
                                    errors["manageOnsiteDaysAndTime"][index][
                                      "Working_Hours_From"
                                    ].message
                                  }
                                </p>
                              )}
                          </div>
                        </div>

                        {/* To Input */}
                        <div className="flex gap-2  justify-end items-center ">
                          <label
                            htmlFor={`${Day.toLowerCase()}To`}
                            className="block text-secondaryl font-normal mt-1"
                          >
                            To
                          </label>
                          <input
                            type="time"
                            id={`${Day.toLowerCase()}To`}
                            name={`${Day.toLowerCase()}To`}
                            value={
                              manageOnsiteDaysAndTimeState[index]
                                .Working_Hours_To
                            }
                            onChange={(e) =>
                              handleManageOnsiteDaysAndTime(
                                index,
                                "Working_Hours_To",
                                e.target.value
                              )
                            }
                            className={` block h-11 min-w-16  md:max-w-full rounded-sm text-black  px-2 ps-10 focus:ring-secondaryl focus:outline-none focus:ring-[0.5px]  ${
                              errors[`${Day.toLowerCase()}To`]
                                ? " ring-[0.5px]  ring-red-500"
                                : ""
                            }  ${
                              manageOnsiteDaysAndTimeState[index].Checked
                                ? "bg-[#FFFFFF80]"
                                : "cursor-not-allowed bg-primaryl/[10%] "
                            }`}
                            placeholder="hrs:mins"
                            disabled={
                              !manageOnsiteDaysAndTimeState[index].Checked
                            }
                          />
                          <div className="h-[2vh] pt-1">
                            {errors["manageOnsiteDaysAndTime"] &&
                              errors["manageOnsiteDaysAndTime"][index] &&
                              errors["manageOnsiteDaysAndTime"][index][
                                "Working_Hours_To"
                              ] && (
                                <p className="text-red-500 text-xs leading-none">
                                  {
                                    errors["manageOnsiteDaysAndTime"][index][
                                      "Working_Hours_To"
                                    ].message
                                  }
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="h-[3vh] pt-1 max-w-18">
                    {errors["manageOnsiteDaysAndTime"] && (
                      <p className="text-red-500 text-xs leading-none">
                        {errors["manageOnsiteDaysAndTime"].message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Add Property Manager */}
            <div className="flex gap-3 items-center mb-3 ">
              <div
                id="addPropertyManager"
                className="cursor-pointer flex gap-3 hover:opacity-80"
                onClick={handleAddPropertyManager}
              >
                <Icon
                  icon="bi:plus-square"
                  className="text-secondaryl text-2xl"
                />
                <label className="cursor-pointer text-secondaryl ">
                  Add more Property Managers
                </label>
              </div>
            </div>
            {/* Property Managers structure */}
            <div className="">
              {Array.from({ length: propertyManagerCount }).map((_, index) => (
                <div key={index}>
                  {/* <div className="flex justify-end">
                              <div
                                className=" bg-red-500 text-white px-3 py-1 rounded-sm hover:bg-red-600 cursor-pointer mb-2 flex item-center w-auto"
                                onClick={() =>
                                  handleDeletePropertyManager(index)
                                }
                              >
                                Delete Property Manager {index + 1}
                              </div>
                            </div> */}
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                    <div className="mb-3 w-full">
                      <label
                        htmlFor={`propertyManagerName-${index}`}
                        className="block font-normal text-secondaryl"
                      >
                        Property Manager {index + 2} Name
                        <sup className="text-red-500 text-base">*</sup>
                      </label>
                      <input
                        id={`propertyManagerName-${index}`}
                        name={`propertyManagers[${index}].Property_Manager_Name`}
                        type="text"
                        // value={manager.propertyManagerName}
                        {...register(
                          `propertyManagers[${index}].Property_Manager_Name`
                        )}
                        // onChange={(e) =>
                        //   handlePropertyManagerChange(
                        //     index,
                        //     "propertyManagerName",
                        //     e.target.value
                        //   )
                        // }
                        className=" block w-full  h-11 rounded-sm text-black bg-[#FFFFFF80]  px-2  ps-10 focus:ring-[0.5px] focus:ring-secondaryl   focus:outline-none"
                      />
                      <div className="h-[2vh] pt-1">
                        {errors["propertyManagers"] &&
                          errors["propertyManagers"][index] &&
                          errors["propertyManagers"][index][
                            "Property_Manager_Name"
                          ] && (
                            <p className="text-red-500 text-xs leading-none">
                              {
                                errors["propertyManagers"][index][
                                  "Property_Manager_Name"
                                ].message
                              }
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="mb-3 w-full">
                      <label
                        htmlFor={`propertyManagerEmail-${index}`}
                        className="block font-normal text-secondaryl"
                      >
                        Property Manager {index + 2} Email
                        <sup className="text-red-500 text-base">*</sup>
                      </label>
                      <input
                        id={`propertyManagerEmail-${index}`}
                        name={`propertyManagers[${index}].Property_Manager_Email`}
                        type="text"
                        {...register(
                          `propertyManagers[${index}].Property_Manager_Email`
                        )}
                        className=" block w-full  h-11 rounded-sm text-black bg-[#FFFFFF80]  px-2  ps-10 focus:ring-[0.5px] focus:ring-secondaryl   focus:outline-none"
                      />
                      <div className="h-[2vh] pt-1">
                        {errors["propertyManagers"] &&
                          errors["propertyManagers"][index] &&
                          errors["propertyManagers"][index][
                            "Property_Manager_Email"
                          ] && (
                            <p className="text-red-500 text-xs leading-none">
                              {
                                errors["propertyManagers"][index][
                                  "Property_Manager_Email"
                                ].message
                              }
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 md:mr-4 lg:mr-20">
                    <div className="mb-3 w-full ">
                      <label
                        htmlFor={`propertyManagerPhoneNo-${index}`}
                        className="block font-normal text-secondaryl"
                      >
                        Property Manager {index + 2} Phone No
                        <sup className="text-red-500 text-base">*</sup>
                      </label>
                      <input
                        id={`propertyManagerPhoneNo-${index}`}
                        name={`propertyManagers[${index}].Property_Manager_Phone_No`}
                        type="tel"
                        {...register(
                          `propertyManagers[${index}].Property_Manager_Phone_No`
                        )}
                        maxLength="10"
                        min={0}
                        max={10}
                        onKeyDown={handleKeyDown}
                        className=" block w-full  h-11 rounded-sm text-black bg-[#FFFFFF80]  px-2  ps-10 focus:ring-[0.5px] focus:ring-secondaryl   focus:outline-none"
                        onPaste={handlePaste}
                      />
                      <div className="h-[2vh] pt-1">
                        {errors["propertyManagers"] &&
                          errors["propertyManagers"][index] &&
                          errors["propertyManagers"][index][
                            "Property_Manager_Phone_No"
                          ] && (
                            <p className="text-red-500 text-xs leading-none">
                              {
                                errors["propertyManagers"][index][
                                  "Property_Manager_Phone_No"
                                ].message
                              }
                            </p>
                          )}
                      </div>
                    </div>

                    <div
                      className=" bg-red-500 text-white px-3 py-1 h-fit md:mt-8 rounded-sm hover:bg-red-600 cursor-pointer mb-3 flex item-center w-auto"
                      onClick={() => handleDeletePropertyManager(index)}
                    >
                      Delete Property Manager {index + 2}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Upload Public Information Certificate */}

            <div className="mt-4 mb-3">
              <label className={`block font-normal text-base text-secondaryl`}>
                The public information Certificate
              </label>
              <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-5  md:gap-16 md:mr-4 lg:mr-20">
                <div className="col-span-2 sm:col-span-2 md:col-span-1">
                  <input
                    type="file"
                    id="Public_Certificate_URL"
                    name="Public_Certificate_URL"
                    className={`hidden  ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={handleChangePiCertificate}
                  />
                  <label
                    htmlFor="Public_Certificate_URL"
                    className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none
                              ${
                                piCertificateError
                                  ? "ring-[ring-0.5px] ring-red-500"
                                  : ""
                              }  ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    }
                              `}
                    disabled={uploadingFile}
                  >
                    <div className="flex items-center h-full space-x-1 overflow-hidden">
                      <InputFileIcon />
                      {piCertificate ? (
                        <span className="line-clamp-1">
                          {piCertificate.name}
                        </span>
                      ) : (
                        <span className="text-infromation text-opacity-50  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                          Upload Certificate
                        </span>
                      )}
                    </div>
                  </label>
                </div>

                <div className="">
                  {piCertificateURL ? (
                    <div className="h-11 w-full max-w-32 text-sm px-6 py-2.5   euclidbold   cursor-not-allowed  ring-[0.5px]   ring-cyan-500 flex  justify-center items-center text-cyan-500 sm:text-xl    tracking-widest   md:mt-0 ">
                      UPLOADED
                    </div>
                  ) : (
                    <div
                      onClick={handleUploadPiCertificate}
                      className={`h-11 w-full max-w-32 text-sm px-6 py-2.5 euclidbold    ring-[0.5px]  ring-secondaryl rounded-md flex justify-center items-center text-secondaryl sm:text-xl hover:bg-secondaryl  tracking-widest hover:text-white transition duration-300 ease-in-out md:mt-0  ${
                        uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                      } `}
                      disabled={uploadingFile}
                    >
                      UPLOAD
                    </div>
                  )}
                </div>
              </div>

              <div className="h-[3vh] pt-0">
                {uploadingFile && <Spinner />}
                {piCertificateError && (
                  <p className="text-red-500 text-xs leading-none">
                    {piCertificateError}
                  </p>
                )}
                {piCertificateUploded && (
                  <p className="text-green-600 text-xs leading-none">
                    {piCertificateUploded}
                  </p>
                )}
              </div>
            </div>

            {/* Terms And Policy */}
            <div className="">
              <div className="flex gap-3 items-center">
                <input
                  type="checkbox"
                  id="Term_And_Policy"
                  name="Term_And_Policy"
                  {...register("Term_And_Policy")}
                  className="bg-transparent  text-black min-h-7 min-w-7 accent-secondaryl"
                />
                <label htmlFor="Term_And_Policy" className="pt-2">
                  I agree to all the{" "}
                  <Link
                    to="/terms"
                    className="text-blue-500 hover:text-blue-600"
                  >
                    Terms
                  </Link>{" "}
                  and
                  <Link
                    to="/privacypolicy"
                    className="text-blue-500 hover:text-blue-600"
                  >
                    {" "}
                    Privacy policy
                  </Link>
                </label>
              </div>
              <div className="h-[2vh] pt-1">
                {errors.Term_And_Policy && (
                  <p className="text-red-500 text-xs  leading-none">
                    {errors.Term_And_Policy.message}
                  </p>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="my-3">
              <button
                type="submit"
                className={`h-11 w-fit px-3 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-xl truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out    ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                disabled={uploadingFile}
              >
                SIGN UP
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CorporationSignupForm;
