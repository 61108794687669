import React, { useState } from "react";
import { Chart } from "react-google-charts";

const getQuarter = (date) => {
  const month = date.getUTCMonth() + 1;
  if (month <= 3) return "1st";
  if (month <= 6) return "2nd";
  if (month <= 9) return "3rd";
  return "4th";
};

const processContractData = (contracts, selectedQuarter) => {
  const statusCount = {
    Approved: 0,
    "Pending/Expired": 0,
    Total: 0,
  };

  contracts?.forEach((contract) => {
    const createdDate = new Date(contract.CreatedDate);
    if (!isNaN(createdDate)) {
      const quarter = getQuarter(createdDate);
      if (selectedQuarter === "All" || quarter === selectedQuarter) {
        if (contract.Status === 1) {
          statusCount.Approved += 1;
        } else {
          statusCount["Pending/Expired"] += 1;
        }
        statusCount.Total += 1;
      }
    }
  });

  return [
    ["Status", "Count"],
    ["Approved", statusCount.Approved],
    ["Pending/Expired", statusCount["Pending/Expired"]],
    ["Total Contracts", statusCount.Total],
  ];
};

const options = {
  title: "Contract Status",
};

const CustomerSatisfaction = ({ contract }) => {
  const [selectedQuarter, setSelectedQuarter] = useState("All");

  const handleQuarterChange = (e) => {
    setSelectedQuarter(e.target.value);
  };

  const data = processContractData(contract, selectedQuarter);

  return (
    <div className="bg-white shadow-xl flex-col rounded-lg p-2 h-[320px] flex items-center justify-center">
      <h3 className="text-xl font-bold mb-4">Contract Status</h3>
      <div className="">
        <select
          value={selectedQuarter}
          onChange={handleQuarterChange}
          className="border p-1 rounded"
        >
          <option value="All">All Quarters</option>
          <option value="1st">1st Quarter</option>
          <option value="2nd">2nd Quarter</option>
          <option value="3rd">3rd Quarter</option>
          <option value="4th">4th Quarter</option>
        </select>
      </div>
      {data.length > 1 ? (
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width="100%"
          height="220px"
        />
      ) : (
        <p className="text-red-500 font-semibold">
          No data available for the selected quarter
        </p>
      )}
    </div>
  );
};

export default CustomerSatisfaction;
