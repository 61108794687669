import React, { useEffect, useState } from "react";
import { LuChevronsUpDown } from "react-icons/lu";

const Clock = ({
  fromHour,
  setFromHour,
  toHour,
  setToHour,
  fromPeriod,
  setFromPeriod,
  toPeriod,
  setToPeriod,
  fromDate,
  toDate,
}) => {
  const [isFrom, setIsFrom] = useState(true);

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);

  const getRotation = (hour) => {
    return (hour / 12) * 360;
  };

  const handleHourClick = (hour) => {
    if (isFrom) {
      setFromHour(hour);
    } else {
      setToHour(hour);
    }

    setIsFrom(!isFrom);
  };

  // Effect to adjust AM/PM automatically based on dates and time logic
  useEffect(() => {
    if (fromDate === toDate) {
      const fromTime = fromHour + (fromPeriod === "PM" ? 12 : 0);
      const toTime = toHour + (toPeriod === "PM" ? 12 : 0);

      if (fromTime >= toTime) {
        // If from time is greater than or equal to to time, adjust periods
        if (isFrom) {
          setToPeriod(fromPeriod === "AM" ? "PM" : "AM");
        } else {
          setFromPeriod(toPeriod === "AM" ? "PM" : "AM");
        }
      }
    }
  }, [fromHour, toHour, fromPeriod, toPeriod, fromDate, toDate, isFrom]);

  return (
    <div className="flex flex-col items-center justify-center h-[360px]">
      <div className="relative w-80 h-80 rounded-full border-2 border-secondaryl bg-transparent">
        {/* Hour markers */}
        {hours.map((hour) => (
          <div
            key={hour}
            className="absolute flex justify-center items-center"
            style={{
              transform: `rotate(${(hour / 12) * 360}deg)`,
              transformOrigin: "50% 100%",
              top: "-100px",
              left: "50%",
              width: "2px",
              height: "250px",
            }}
          >
            <span
              className={`${
                hour === fromHour || hour === toHour
                  ? "bg-secondaryl text-white rounded-full px-2"
                  : ""
              } text-center cursor-pointer transform translate-x-[-50%] translate-y-[-50%] text-secondaryl`}
              style={{ transform: `rotate(${-(hour / 12) * 360}deg)` }}
              onClick={() => handleHourClick(hour)}
            >
              {hour}
            </span>
          </div>
        ))}

        {/* From Hour Pointer */}
        <div
          className="absolute w-[2px] h-28 bg-secondaryl"
          style={{
            top: "12%",
            left: "50%",
            transform: `rotate(${getRotation(fromHour)}deg) translateX(-50%)`,
            transformOrigin: "bottom",
          }}
        />

        {/* To Hour Pointer */}
        <div
          className="absolute w-[2px] h-28 bg-secondaryl opacity-40"
          style={{
            top: "12%",
            left: "50%",
            transform: `rotate(${getRotation(toHour)}deg) translateX(-50%)`,
            transformOrigin: "bottom",
          }}
        />
      </div>
      {/* Hour buttons to select "From" and "To" */}
      <div className=" flex flex-col items-center justify-center text-secondaryl">
        <div className="mt-4 flex gap-4">
          <p
            className="text-lg font-semibold cursor-pointer flex items-center"
            onClick={() =>
              setFromPeriod((prev) => (prev === "AM" ? "PM" : "AM"))
            }
          >
            From: {fromHour === 0 ? 12 : fromHour}:00 {fromPeriod}{" "}
            <LuChevronsUpDown />
          </p>
          <p
            className="text-lg font-semibold cursor-pointer flex items-center"
            onClick={() => setToPeriod((prev) => (prev === "AM" ? "PM" : "AM"))}
          >
            To: {toHour === 0 ? 12 : toHour}:00 {toPeriod}
            <LuChevronsUpDown />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Clock;
