import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { Icon } from "@iconify/react";
import PICCard from "../../components/Corporation/Dashboard/Documents/PIC/PICCard";
import DocumentCardSkeleton from "../../components/Corporation/Dashboard/Documents/DocumentCardSkeleton";

import { corporationPICAPI } from "../../constants/API";
import axios from "axios";
import Loading from "../../components/Elements/Loading";

const PIC = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${corporationPICAPI}/resident`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });

      setData(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="rounded-2xl">
      <Loading load={pageLoading} />
      <div className="w-full  rounded-2xl px-4 m-auto  p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            Public Information Certificate
          </h1>
        </div>

        <div className="">
          {load ? (
            <DocumentCardSkeleton />
          ) : data.length > 0 ? (
            data.map((data, index) => (
              <div key={index}>
                <PICCard data={data} />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4 text-center">
              No Public Information Certificate Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PIC;
