import React from "react";
import Cookies from "universal-cookie";
import { Navigate } from "react-router-dom";

function Unprotected({ children }) {
  const cookies = new Cookies();
  let token = cookies.get("token");
  let companyType = cookies.get("companyType");
  
  if (token && companyType === "contractor") {
    return <Navigate to="/contractors/contractlisting"></Navigate>;
  }
  if (token && companyType === "corporation") {
    return <Navigate to="/corporations/managecontracts"></Navigate>;
  }
  if (token && companyType === "owners") {
    return <Navigate to="/owners/dashboard"></Navigate>;
  }
  if (token && companyType === "tenant") {
    return <Navigate to="/tenant/dashboard"></Navigate>;
  }

  return <>{children}</>;
}

export default Unprotected;
