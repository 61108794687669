import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ReadInputBox from "../../../../Elements/ReadInputBox";
import { picSchema } from "../../../../../schema";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { viewFileHandler } from "../../../../../constants/Request";
import Cookies from "universal-cookie";

const PICReadModal = ({ data, handleViewPIC }) => {
  const cookies = new Cookies();
  const {
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(picSchema),
  });

  const [modelLoading, setModelLoading] = useState(false);
  const [picc, setPicc] = useState(null);
  const [picType, setPicType] = useState(null);

  const assignPICData = async () => {
    // console.log(data);

    if (data) {
      // console.log(data);
      reset(data);
      if (data.PIC_URL?.endsWith(".pdf")) {
        const fileurl = await viewFileHandler(
          data.PIC_URL,
          "corporationpublicinformationcertificate"
        );
        setPicType("pdf");
        setPicc(fileurl);
      } else {
        setPicType("image");
        setPicc(data.PIC_URL);
      }
    } else {
      reset();
    }
  };
  // Set the form values when the modal is opened in edit mode
  useEffect(() => {
    assignPICData();
  }, [data, reset]);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30`}
    >
      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl    w-[80%] h-[80%] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                Public Information Certificate Details
              </h2>
              <button
                onClick={() => handleViewPIC(false)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <form className="mb-3">
              <div className="mb-3">
                <ReadInputBox
                  title={`Public Information Certificate Name`}
                  register={register}
                  filedName={"PIC_Name"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              {cookies.get("companyType") === "corporation" && (
                <div className="mb-3">
                  {/* Label */}
                  <label
                    htmlFor={"IsActive"}
                    className={`block font-normal text-secondaryl`}
                  >
                    Status <span className="text-red-500 text-base">*</span>
                  </label>
                  {/* Input */}
                  <input
                    type="text"
                    id={"IsActive"}
                    name={"IsActive"}
                    className={`block h-11 bg-[#FFFFFF80] w-full  text-infromation rounded-sm  px-2 ps-10 focus:outline-none`}
                    value={data.IsActive === true ? "Active" : "Inactive"}
                    readOnly
                    disabled
                  />
                </div>
              )}
              <div className="mb-3">
                {data && (
                  <>
                    <label
                      className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                    >
                      Uploaded PIC Document :
                    </label>
                    <div className="pt-3">
                      {modelLoading ? (
                        "Loading..."
                      ) : picc ? (
                        picType === "pdf" ? (
                          <object
                            data={picc}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                            className="w-full h-[90vh]"
                          >
                            <p>PDF could not be displayed</p>
                          </object>
                        ) : (
                          <img
                            src={picc}
                            alt="PIC Document"
                            className="w-full h-[50vh] object-cover ring-[0.1px]  ring-infromation"
                          />
                        )
                      ) : (
                        <div className="text-infromation">
                          No Documents Provided
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-wrap gap-5 justify-start ">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={(e) => handleViewPIC(false)}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PICReadModal;
