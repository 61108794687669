import React from "react";

function IconDropDownUpper({color="#313144"}) {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04575 12.4546C2.42366 13.0767 1.41507 13.0767 0.79298 12.4546C0.170895 11.8325 0.170895 10.8239 0.79298 10.2018L10.3507 0.644117C10.9728 0.0220322 11.9814 0.0220322 12.6034 0.644117L22.1611 10.2018C22.7832 10.8239 22.7832 11.8325 22.1611 12.4546C21.539 13.0767 20.5304 13.0767 19.9084 12.4546L11.4771 4.02327L3.04575 12.4546Z"
        fill={color}
        className="hover:text-sky-600"
      />
    </svg>
  );
}

export default IconDropDownUpper;
