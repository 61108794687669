import React from "react";

function UserIdIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.707 13.8177C16.0727 12.8687 17.0888 11.5312 17.6098 9.99696C18.1308 8.46269 18.13 6.8103 17.6075 5.27649C17.0851 3.74267 16.0677 2.40612 14.7011 1.4583C13.3345 0.510473 11.6889 0 10 0C8.31106 0 6.66546 0.510473 5.2989 1.4583C3.93234 2.40612 2.91494 3.74267 2.39245 5.27649C1.86995 6.8103 1.86917 8.46269 2.3902 9.99696C2.91123 11.5312 3.92735 12.8687 5.293 13.8177C3.74305 14.3504 2.40298 15.3269 1.45569 16.6139C0.508397 17.9009 4.1125e-05 19.4357 0 21.0089V21.9639C0 22.2172 0.105357 22.4601 0.292893 22.6392C0.48043 22.8183 0.734784 22.9189 1 22.9189C1.26522 22.9189 1.51957 22.8183 1.70711 22.6392C1.89464 22.4601 2 22.2172 2 21.9639V21.0089C2 19.4892 2.63214 18.0317 3.75736 16.9571C4.88258 15.8825 6.4087 15.2788 8 15.2788H12C13.5913 15.2788 15.1174 15.8825 16.2426 16.9571C17.3679 18.0317 18 19.4892 18 21.0089V21.9639C18 22.2172 18.1054 22.4601 18.2929 22.6392C18.4804 22.8183 18.7348 22.9189 19 22.9189C19.2652 22.9189 19.5196 22.8183 19.7071 22.6392C19.8946 22.4601 20 22.2172 20 21.9639V21.0089C20 19.4357 19.4916 17.9009 18.5443 16.6139C17.597 15.3269 16.257 14.3504 14.707 13.8177ZM4 7.6388C4 6.1191 4.63214 4.66165 5.75736 3.58706C6.88258 2.51247 8.4087 1.90877 10 1.90877C11.5913 1.90877 13.1174 2.51247 14.2426 3.58706C15.3679 4.66165 16 6.1191 16 7.6388C16 9.1585 15.3679 10.616 14.2426 11.6905C13.1174 12.7651 11.5913 13.3688 10 13.3688C8.4087 13.3688 6.88258 12.7651 5.75736 11.6905C4.63214 10.616 4 9.1585 4 7.6388Z" fill="#3E5CAA" fillOpacity="0.3" />
    </svg>
  );
}

export default UserIdIcon;
