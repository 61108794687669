import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { incidentSchema } from "../../../../schema";
import InputBox from "../../../Elements/InputBox";
import axios from "axios";
import {
  incident,
  incidentReportingAPI,
  ownersComplaintSuggestionAPI,
  tenantComplaintSuggestionAPI,
} from "../../../../constants/API";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";
import Loading from "../../../Elements/Loading";
import { Icon } from "@iconify/react";
import { set } from "date-fns";
import DropDownStyle1 from "../../../Elements/DropDownStyle1";

const CreateIncidentModal = ({ onClose, fetchData }) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(incidentSchema) });
  const [load, setLoad] = useState(false);
  const [selectIncidentType, setSelectIncidentType] = useState("");
  const [incidentOptions, setIncidentOptions] = useState([]);

  const cookies = new Cookies();

  // console.log(errors);

  const fetachIncidentOptions = async () => {
    try {
      setLoad(true);
      const response = await axios.get(incident, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      // console.log(response.data.data);
      setIncidentOptions(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetachIncidentOptions();
  }, []);

  const onSubmit = async (data) => {
    // console.log(data);

    try {
      setLoad(true);

      const response = await axios.post(incidentReportingAPI, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      fetchData();
      // console.log(response.data);
      toast.success("Incident added successfully");
      onClose();
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  // console.log("Select Incident Type", selectIncidentType);

  return (
    <div>
      {load && <Loading load={load} />}
      <style>{`body { overflow: hidden; }`}</style>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            Add Incident
          </h2>

          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <Icon
              icon="carbon:close-filled"
              className="text-secondaryl hover:text-opacity-95 text-3xl"
            />
          </button>
        </div>

        {/* Project Type */}
        <div className="">
          <label
            htmlFor={"Incident_ID"}
            className="block font-normal text-secondaryl"
          >
            <p>
              Incident Type
              <span className="text-red-500 text-base ml-1">*</span>
            </p>
          </label>

          <DropDownStyle1
            title="Select Incident Type"
            options={incidentOptions}
            onSelect={setSelectIncidentType}
            selectOption={selectIncidentType}
            setValue={setValue}
            filedName={"Incident_ID"}
            clearErrors={clearErrors}
            errors={errors}
            dbfield={"Name"}
          />
        </div>

        <div className=" mb-3">
          {/* Label */}
          <label
            htmlFor="Description"
            className={`block font-normal text-secondaryl`}
          >
            Description <span className="text-red-500 text-base ml-1">*</span>
          </label>
          {/* Input */}
          <textarea
            type="text"
            id="Description"
            name="Description"
            {...register("Description")}
            className={`bg-[#FFFFFF80] focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none block h-40 max-h-40 min-h-40 w-full rounded-sm text-black px-2 ps-10 focus:border-rose-600 ${
              errors.Description ? "ring-1 ring-red-500" : ""
            }`}
            placeholder="Type Incident Description"
          />

          {/* Error */}
          <div className="h-[2vh] pt-1">
            {errors.Description && (
              <p className="text-red-500 text-xs  leading-none">
                {errors.Description.message}
              </p>
            )}
          </div>
        </div>
        {/* Save Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
          h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  `}
          >
            Add Incident
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateIncidentModal;
