import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LogoWithText1 } from "../../../../assets/images";
import { Icon } from "@iconify/react";

function ContractorSidebar() {
  const [showProjectsSublist, setShowProjectsSublist] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (showProjectsSublist) {
      setIsCollapsed(false);
    }
  }, [showProjectsSublist]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true); // Default collapsed on small screens
      } else {
        setIsCollapsed(false); // Default expanded on larger screens
      }
    };

    handleResize();
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("currentlisting") ||
      location.pathname.includes("appliedcontracts") ||
      location.pathname.includes("approvedcontracts")
    ) {
      setShowProjectsSublist(true);
    } else {
      setShowProjectsSublist(false);
    }
  }, [location]);

  return (
    <aside
      className={`my-3 ml-3 bg-gradient-to-b from-[rgba(40,42,115,0.15)] to-[rgba(0,148,191,0.15)]  rounded-2xl transition-all duration-300 ${
        isCollapsed ? "w-14" : "w-72"
      } 
    `}
    >
      <div className="flex justify-center py-2 md:py-0">
        <NavLink to="/">
          <img
            src={LogoWithText1} // Replace with your logo
            alt="Condominium Portal"
            className={`${
              isCollapsed ? "h-14 w-14 " : "h-40 w-40 "
            } transition-all duration-300 `}
          />
        </NavLink>
      </div>

      {/* Toggle Button */}
      <div className="flex justify-end px-2 py-1">
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-2 bg-primaryl text-white rounded-full cursor-pointer hover:bg-opacity-80"
        >
          {isCollapsed ? (
            <Icon icon="ion:chevron-forward-outline" className="text-xl" />
          ) : (
            <Icon icon="ion:chevron-back-outline" className="text-xl" />
          )}
        </div>
      </div>

      <nav className="mb-2">
        <ul className="space-y-1.5 euclidbold text-primaryl text-lg">
          <li
            className=""
            onClick={() => {
              setShowProjectsSublist(!showProjectsSublist);
            }}
          >
            <div className=" bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon
                icon="fluent-emoji-high-contrast:office-building"
                className="text-2xl"
              />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Project Listing
              </span>

              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showProjectsSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showProjectsSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  <NavLink
                    to="currentlisting?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Current Opportunities
                  </NavLink>

                  <NavLink
                    to="appliedcontracts?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Applied Opportunities
                  </NavLink>
                  <NavLink
                    to="approvedcontracts?page=1"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Approved Listings
                  </NavLink>
                </ul>
              </div>
            )}
          </li>

          <NavLink
            to="review"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:star-box" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              } `}
            >
              Review
            </span>
          </NavLink>

          <NavLink
            to="about"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:about" className="text-2xl" />
            <span
              className={`transition-all duration-300  ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              About Us
            </span>
          </NavLink>
          <NavLink
            to="contact"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white  ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:contact" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Contact Us
            </span>
          </NavLink>
          <NavLink
            to="message"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="tabler:message-filled" className="text-2xl" />

            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              } `}
            >
              Message
            </span>
          </NavLink>

          <NavLink
            to="profile"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="iconamoon:profile-fill" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              } `}
            >
              My Profile
            </span>
          </NavLink>
          <NavLink
            to="blog"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:email-newsletter" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              } `}
            >
              Blog
            </span>
          </NavLink>
        </ul>
      </nav>
    </aside>
  );
}

export default ContractorSidebar;
