import React, { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import axios from "axios";
import { meetingAPI } from "../../../../constants/API";
import MoMModal from "../../../../components/Corporation/Dashboard/Meetings/MoMModal";
import MoMReadModal from "../../../../components/Corporation/Dashboard/Meetings/MoMReadModal";
import MoMCard from "../../../../components/Resident/Meetings/MoMCard";
import MoMCardSkeleton from "../../../../components/Resident/Meetings/MoMCardSkeleton";

const MoM = () => {
  const cookies = new Cookies();
  const [MoMs, setMoMs] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedMoM, setSelectedMoM] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [load, setLoad] = useState(false);
  const token = cookies.get("token");
  const companyType = cookies.get("companyType");

  const fetchMoM = useCallback(async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${meetingAPI}/corporationmeeting`, {
        headers: {
          authorization: token,
          companyType: companyType,
        },
      });
      // console.log(response.data);
      setMoMs(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  }, [token, companyType]);

  useEffect(() => {
    fetchMoM();
  }, []);

  const handleAddMoM = () => {
    setIsEditModal(false);
    setSelectedMoM(null);
    setShowAddEditModal(true);
  };

  const handleCloseShowModal = () => {
    setShowViewModal(false);
    setSelectedMoM(null);
  };

  const handleViewMeeting = (meeting) => {
    setSelectedMoM(meeting);
    setShowViewModal(true);
  };

  const handleEditMeetingModal = (meeting) => {
    setIsEditModal(true);
    setSelectedMoM(meeting);
    setShowAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setIsEditModal(false);
    setShowAddEditModal(false);
    setSelectedMoM(null);
  };

  return (
    <div className="rounded-2xl">
      {/* <Loading load={load} /> */}

      <div className="w-full  rounded-2xl px-4 m-auto  p-6   flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            MoM
          </h1>

          {cookies.get("access") === "property manager" && (
            <div
              className="h-11 w-fit px-2.5 py-1.5 items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center bg-secondaryl text-[#ffffff] text-sm truncate   hover:bg-opacity-90  transition duration-300 ease-in-out gap-2"
              onClick={handleAddMoM}
            >
              <Icon
                icon="fluent-mdl2:calculator-addition"
                className="font-bold"
              />
              Add MoM
            </div>
          )}
        </div>

        <div className="">
          {load ? (
            <MoMCardSkeleton />
          ) : MoMs.length > 0 ? (
            MoMs.map((mom, index) => (
              <div key={index}>
                <MoMCard
                  key={mom.ID}
                  mom={mom}
                  onView={() => handleViewMeeting(mom)}
                  onEdit={() => handleEditMeetingModal(mom)}
                />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4">No MoMs found</div>
          )}
        </div>
      </div>

      {/* Add/Edit Meeting Modal */}
      {showViewModal && (
        <MoMReadModal mom={selectedMoM} onClose={handleCloseShowModal} />
      )}

      {showAddEditModal && cookies.get("access") === "property manager" && (
        <MoMModal
          mom={selectedMoM}
          onClose={handleCloseAddEditModal}
          fetchMoMs={fetchMoM}
          isEditModal={isEditModal}
        />
      )}
    </div>
  );
};

export default MoM;
