import React, { useState } from "react";
import { Chart } from "react-google-charts";

// Define static project types
const projectTypes = [
  { id: 2, Name: "Once" },
  { id: 3, Name: "Twice" },
  { id: 4, Name: "Quarterly" },
  { id: 5, Name: "Monthly" },
  { id: 6, Name: "Daily" },
  { id: 7, Name: "Seasonal" },
];

const getQuarter = (date) => {
  const month = date.getUTCMonth() + 1;
  if (month <= 3) return "1st";
  if (month <= 6) return "2nd";
  if (month <= 9) return "3rd";
  return "4th";
};

const processData = (contract, selectedQuarter) => {
  const projectTypeCount = {};

  // Initialize the count for each project type
  projectTypes?.forEach((type) => {
    projectTypeCount[type.Name] = 0;
  });

  // Count the occurrences of each project type in contracts
  contract?.forEach((ctr) => {
    const date = new Date(ctr.CreatedDate);
    const quarter = getQuarter(date);
    if (selectedQuarter === "All" || quarter === selectedQuarter) {
      const projectType = projectTypes.find(
        (type) => type.id === ctr.Project_Type_ID
      );
      if (projectType) {
        projectTypeCount[projectType.Name] += 1;
      }
    }
  });

  // Convert the count object to an array format for the chart
  const chartData = [["Project Type", "Count"]];
  for (const [type, count] of Object.entries(projectTypeCount)) {
    chartData.push([type, count]);
  }

  return chartData;
};

const options = {
  // title: "Contracts by Project Type",
};

const PieChart = ({ contract }) => {
  const [selectedQuarter, setSelectedQuarter] = useState("All");

  const handleQuarterChange = (e) => {
    setSelectedQuarter(e.target.value);
  };

  const data = processData(contract, selectedQuarter);

  return (
    <div className="bg-white shadow-xl flex-col rounded-lg p-2 h-[320px] flex items-center justify-center">
      <h1 className="font-bold text-xl mb-4">Contracts By Project Types</h1>
      <div className="">
        <select
          value={selectedQuarter}
          onChange={handleQuarterChange}
          className="border p-1 rounded"
        >
          <option value="All">All Quarters</option>
          <option value="1st">1st Quarter</option>
          <option value="2nd">2nd Quarter</option>
          <option value="3rd">3rd Quarter</option>
          <option value="4th">4th Quarter</option>
        </select>
      </div>
      {data.length > 1 ? (
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"100%"}
          height={"200px"}
        />
      ) : (
        <p className="text-red-500 font-semibold">
          No data available for the selected quarter
        </p>
      )}
    </div>
  );
};

export default PieChart;
