import React from "react";

import { Toaster } from "react-hot-toast";
import DashboardSidebar from "../../../components/Corporation/Dashboard/Layout/DashboardSidebar";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../../components/Layout/DashboardHeader";

const CorporationDashboardLayout = () => {
  return (
    <div className="flex flex-row min-h-screen">
      <Toaster />
      {/* Sidebar */}
      <DashboardSidebar />
      {/* Main Content */}
      <main className="flex-1 p-3 max-h-full min-h-0 overflow-hidden flex flex-col">
        <DashboardHeader />
        {/* overflow-auto remove */}
        <div className="bg-gradient-to-b flex-grow from-[#282a73]/15 to-[#0094bf]/15 rounded-2xl relative">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default CorporationDashboardLayout;
