import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { picSchema } from "../../../../../schema";
import InputBox from "../../../../Elements/InputBox";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../../assets/images";
import axios from "axios";
import { corporationPICAPI } from "../../../../../constants/API";
import Cookies from "universal-cookie";
import { toast } from "react-hot-toast";
import Loading from "../../../../Elements/Loading";

const PICModal = ({ onClose, fetchData }) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(picSchema) });
  const [load, setLoad] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const cookies = new Cookies();
  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
  const [picDocument, setPicDocument] = useState(null);
  const [picDocumentError, setPicDocumentError] = useState("");
  const [picDocumentUploded, setPicDocumentUploded] = useState("");
  const [picDocumentURL, setPicDocumentURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);

  const handleChangePicDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPicDocument(null);
      setPicDocumentError("");
      setPicDocumentUploded("");
      setPicDocumentURL("");
      setValue("PIC_URL", "");
      clearErrors("PIC_URL");
    }

    if (!file) {
      setPicDocument(null);
      setPicDocumentError("");
      setPicDocumentUploded("");
      setPicDocumentURL("");
      setValue("PIC_URL", "");
      clearErrors("PIC_URL");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setPicDocument(file);
        setPicDocumentUploded("");
        setPicDocumentError("");
        setPicDocumentURL("");
        setValue("PIC_URL", file);
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setPicDocument(null);
        setPicDocumentError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setPicDocumentUploded("");
        setPicDocumentURL("");
        setValue("PIC_URL", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setPicDocument(null);
        setPicDocumentError("File size exceeds limit (5 MB)");
        setPicDocumentUploded("");
        setPicDocumentURL("");
        setValue("PIC_URL", "");
      } else {
        setPicDocument(null);
        setPicDocumentError("Something went wrong! Please try again.");
        setPicDocumentUploded("");
        setPicDocumentURL("");
        setValue("PIC_URL", "");
      }
    }
  };

  // console.log(errors);

  const onSubmit = async (data) => {
    // console.log(data);

    try {
      setLoad(true);
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "PIC_URL") {
          formData.append(key, picDocument);
        } else {
          formData.append(key, data[key]);
        }
      });
      // console.log(formData);
      const response = await axios.post(corporationPICAPI, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
          // Don't manually set Content-Type here
        },
      });
      fetchData();
      // console.log(response.data);
      toast.success(`PIC added successfully`);
      onClose();
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      {load && <Loading load={load} />}
      <style>{`body { overflow: hidden; }`}</style>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-secondaryl font-extrabold text-xl euclidbold">
            Add Public Information Certificate
          </h2>
          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✖
          </button>
        </div>
        <div className="mb-3">
          {/* picDocument name */}
          <InputBox
            title={`Public Information Certificate Name`}
            register={register}
            filedName={"PIC_Name"}
            must={true}
            errors={errors}
            className={"md:text-sm lg:text-base"}
          />

          <div className="mb-3">
            <label
              className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
            >
              Public Information Certificate Upload
              <span className="text-red-500 text-base">*</span>
            </label>

            <div>
              <div className="">
                {/* Business Card File Input */}
                <div className="col-span-2 sm:col-span-2 md:col-span-1">
                  <input
                    type="file"
                    id={`PIC_URL`}
                    name="PIC_URL"
                    accept={SUPPORTED_FILE_TYPES.join(",")}
                    className="hidden"
                    onChange={handleChangePicDocument}
                    disabled={uploadingFile}
                  />

                  {/* Label For style is input tag and use in File input */}
                  <label
                    htmlFor="PIC_URL"
                    className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    style={{
                      boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                    }}
                  >
                    <div className="flex items-center h-full space-x-1 overflow-hidden">
                      <span>
                        <InputFileIcon />
                      </span>
                      {picDocument ? (
                        <span className="line-clamp-1">
                          <span>{picDocument.name}</span>
                        </span>
                      ) : (
                        <span className="text-infromation text-opacity-50   text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                          Upload Public Information Certificate
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>

              {/* Error */}
              <div className="">
                {uploadingDocument && <Spinner />}
                {picDocumentError && (
                  <p className="text-red-500 text-xs leading-none">
                    {picDocumentError}
                  </p>
                )}
                {errors.PIC_URL && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.PIC_URL.message}
                  </p>
                )}
                {picDocumentUploded && (
                  <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                    {/* {contractDocsUploded}  */}
                    <ApprovedIcon /> Attach Public Information Certificate{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="my-3 mx-auto w-fit">
          <button
            type="submit"
            className={`
          h-11 w-fit euclidbold rounded flex justify-center bg-secondaryl text-white  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center cursor-pointer euclidbold text-xl focus:outline-none  tracking-widest hover:ring-1 transition duration-300 ease-in-out  ${
            uploadingDocument ? "cursor-not-allowed" : "cursor-pointer"
          }`}
            // disabled={uploadingDocument}
          >
            Add PIC
          </button>
        </div>
      </form>
    </div>
  );
};

export default PICModal;
