import React from "react";

function InputFileIcon({ width = "30", height = "30" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_309_315)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9251 0C19.6368 0 21.2553 0.779361 22.3226 2.1175L26.8975 7.85327C27.6922 8.84962 28.125 10.0863 28.125 11.3608V24.375C28.125 27.4816 25.6066 30 22.5 30H7.5C4.3934 30 1.875 27.4816 1.875 24.375V5.625C1.875 2.5184 4.3934 0 7.5 0H17.9251ZM20.7703 10.6342C19.1079 8.97172 16.4782 8.80343 14.6228 10.2137C14.2106 10.527 14.1304 11.1151 14.4437 11.5273C14.757 11.9395 15.3452 12.0197 15.7574 11.7064C16.8687 10.8617 18.4472 10.9628 19.4445 11.96C20.5429 13.0584 20.5429 14.8392 19.4445 15.9375L16.1397 19.2507L14.5316 20.8505L14.4111 20.9642C13.3049 21.9494 11.6112 21.9116 10.5521 20.8525C9.49294 19.7934 9.45511 18.0997 10.4386 16.9952L10.4777 16.9523L10.5563 16.8836L12.7012 14.7387L12.7829 14.6659C13.1509 14.3745 13.687 14.3988 14.027 14.7387C14.3931 15.1049 14.3931 15.6984 14.027 16.0646L11.9068 18.1847L11.834 18.2665C11.5426 18.6345 11.5668 19.1706 11.9068 19.5106C12.2729 19.8767 12.8665 19.8767 13.2326 19.5106L15.3528 17.3904L15.4663 17.2702C16.4498 16.1657 16.412 14.472 15.3528 13.4129C14.2545 12.3146 12.4737 12.3146 11.3754 13.4129L10.557 14.2318L10.4754 14.3048L9.88304 14.8968L9.22911 15.5463C7.39566 17.3798 7.39566 20.3477 9.22624 22.1783C11.0568 24.0089 14.0248 24.0089 15.8554 22.1783L18.0231 20.0236L18.082 19.9573L20.7703 17.2633L20.9111 17.1165C22.5997 15.2773 22.5527 12.4166 20.7703 10.6342Z"
          fill="#202327"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_315">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InputFileIcon;
