import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBox from "../../Elements/InputBox";
import { Spinner } from "../../../assets/images";
import { CiImageOn } from "react-icons/ci";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import axios from "axios";

import { blogAPI } from "../../../constants/API";
import ReadInputBox from "../../Elements/ReadInputBox";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const EditBlog = ({ setLoad, setActiveTab, blog, fetchBlogData }) => {
  const cookies = new Cookies();
  const today = new Date().toISOString().split("T")[0];
  const [uploadingFile, setUploadingFile] = useState(false);
  const [blogImage, setBlogImage] = useState(null);
  const [blogImageError, setBlogImageError] = useState(null);
  const [displayBlogImage, setDisplayBlogImage] = useState(null);
  const [isPublishDateEdit, setIsPublishDateEdit] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const validateEditorContent = (content) => {
    return content.hasText();
  };

  useEffect(() => {
    setLoad(true);

    if (blog) {
      Object.keys(blog).forEach((key) => {
        setValue(key, blog[key]);
      });

      if (blog.Blog_Image) {
        setDisplayBlogImage(blog.Blog_Image);

        setBlogImage(blog.Blog_Image);
        setValue("Blog_Image_URL", blog.Blog_Image); // Set the image URL
      }

      if (blog.Blog_Description) {
        const rawContent = blog.Blog_Description;
        const contentState = convertFromRaw(JSON.parse(rawContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      if (blog.Blog_Publish_Date) {
        const currentDate = new Date();
        const publishDate = new Date(blog.Blog_Publish_Date).setHours(
          23,
          59,
          59,
          999
        );
        if (publishDate < currentDate) {
          setIsPublishDateEdit(false);
        }
      }
    }
    setLoad(false);
  }, [blog]);

  const schema = yup.object().shape({
    Blog_Title: yup.string().required(),
    Blog_Slug: yup.string().required(),
    Blog_Description: yup
      .mixed()
      .test(
        "is-valid-editor-content",
        "Blog description is required",
        (value) => {
          const content = editorState.getCurrentContent();
          return validateEditorContent(content);
        }
      ),
    Blog_Image: yup.string().required(),
    Blog_Publish_Date: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEditBlog = async (data) => {
    setLoad(true);
    try {
      // console.log("data : ", data);

      const contentState = editorState.getCurrentContent();
      const rawContent = JSON.stringify(convertToRaw(contentState));

      const formData = new FormData();
      formData.append("Blog_Title", data.Blog_Title);
      formData.append("Blog_Slug", data.Blog_Slug);
      formData.append("Blog_Publish_Date", data.Blog_Publish_Date);
      formData.append("Blog_Description", rawContent);
      formData.append("Blog_Image", blogImage);

      const response = await axios.put(`${blogAPI}/${data.ID}`, formData, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "admin",
        },
      });

      toast.success("Blog updated successfully");
      setActiveTab("Manage Blog");
      fetchBlogData();
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const SUPPORTED_BLOG_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const FILE_SIZE_LIMIT_BLOG_IMAGE = 5 * 1024 * 1024;

  const handleChangeBlogImage = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setBlogImageError("Please Upload a Image");
      clearErrors("Blog_Image");
      setDisplayBlogImage(null);
      setValue("Blog_Image", "");
      setBlogImage(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_BLOG_IMAGE &&
        SUPPORTED_BLOG_IMAGE_FORMATS.includes(file.type)
      ) {
        setBlogImageError("");
        clearErrors("Blog_Image");
        setValue("Blog_Image", file);
        setBlogImage(file);
        const displayImage = URL.createObjectURL(file);
        setDisplayBlogImage(displayImage);
      } else if (file && !SUPPORTED_BLOG_IMAGE_FORMATS.includes(file.type)) {
        setBlogImageError(
          "Unsupported file type file type must be jpg/jpeg/png"
        );
        clearErrors("Blog_Image");
        setValue("Blog_Image", "");
        setDisplayBlogImage(null);
        setBlogImage(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT_BLOG_IMAGE) {
        setBlogImageError(
          "Image size exceeds limit. File size must be less than 5 MB"
        );
        clearErrors("Blog_Image");
        setValue("Blog_Image", "");
        setDisplayBlogImage(null);
        setBlogImage(null);
      } else {
        setBlogImageError("Something went wrong please try again later");
        clearErrors("Blog_Image");
        setValue("Blog_Image", "");
        setDisplayBlogImage(null);
        setBlogImage(null);
      }
    }
  };

  return (
    <div>
      <h2 className="text-4xl font-bold mb-4  mx-3">Edit Blog </h2>
      <div className="overflow-y max-h-[60vh] w-full table-container">
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit(handleEditBlog)}
          className="mx-3"
        >
          <div className="grid grid-cols-1 md:mr-4 lg:mr-20">
            <InputBox
              title={"Blog Title"}
              register={register}
              filedName={"Blog_Title"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setErrors={setError}
            />
            <InputBox
              title={"Blog Slug"}
              register={register}
              filedName={"Blog_Slug"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />

            {isPublishDateEdit ? (
              <InputBox
                title={"Blog Publish Date"}
                register={register}
                filedName={"Blog_Publish_Date"}
                must={true}
                type="date"
                errors={errors}
                className={"md:text-sm lg:text-base"}
                min={today}
              />
            ) : (
              <ReadInputBox
                title={"Blog Publish Date"}
                register={register}
                filedName={"Blog_Publish_Date"}
                must={true}
                type="date"
                className={"md:text-sm lg:text-base"}
              />
            )}

            <div className=" mb-3">
              {/* Label */}
              <label
                htmlFor="Blog_Description"
                className={`block font-normal text-secondaryl`}
              >
                Blog Description <span className="text-red-500">*</span>
              </label>
              {/* Input */}
              {/* <textarea
                type="text"
                id="Blog_Description"
                name="Blog_Description"
                {...register("Blog_Description")}
                className={`bg-transparent block h-40 max-h-40 w-full rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                  errors.Blog_Description ? "ring-1 ring-red-500" : ""
                }`}
                placeholder="Type Blog Description here..."
              /> */}
              <div className="border border-gray-300 rounded-lg shadow-sm">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={(state) => setEditorState(state)}
                  toolbarClassName="bg-gray-100 border-b border-gray-300 p-2 rounded-t-lg"
                  editorClassName="p-4 bg-white rounded-b-lg min-h-[200px]"
                />
              </div>
              {/* Error */}
              <div className="h-[2vh] pt-1">
                {errors.Blog_Description && (
                  <p className="text-red-500 text-xs  leading-none">
                    {errors.Blog_Description.message}
                  </p>
                )}
              </div>
            </div>
            <div className="">
              <label className={`block font-normal text-base `}>
                Blog Image <span className="text-red-500">*</span>
              </label>
              <div className="flex items-center gap-5">
                <input
                  type="file"
                  id="Blog_Image"
                  name="Blog_Image"
                  className={`hidden ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  } `}
                  onChange={handleChangeBlogImage}
                  disabled={uploadingFile}
                  accept=".jpg,.jpeg,.png"
                />
                {/* <!-- Profile Photo --> */}
                <label
                  htmlFor="Blog_Image"
                  className={`h-32 w-32 mb-2 ${
                    uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                  }  rounded-sm  focus:ring-sky-600 focus:outline-none focus:border-rose-600 ${
                    blogImageError
                      ? "ring-1 ring-red-500"
                      : "ring-1 ring-gray-300"
                  } `}
                  disabled={uploadingFile}
                >
                  {displayBlogImage ? (
                    <img
                      src={displayBlogImage}
                      alt="Blog Image"
                      className="h-32 w-32 object-cover rounded-sm"
                    />
                  ) : (
                    <CiImageOn className="h-32 w-32 text-cyan-500" />
                  )}
                </label>
              </div>
              {/* Error */}
              <div className="h-[3vh] mb-3">
                {uploadingFile && <Spinner />}
                {blogImageError && (
                  <p className="text-red-500 text-xs leading-none">
                    {blogImageError}
                  </p>
                )}
                {errors.Blog_Image && ( // Error message from yup validation
                  <p className="text-red-500 text-xs leading-none">
                    {errors.Blog_Image.message}
                  </p>
                )}
              </div>
            </div>

            <div className="mb-3 flex gap-3">
              <button
                type="submit"
                className={`h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out
                   ${uploadingFile ? "cursor-not-allowed" : "cursor-pointer"}  
                `}
                disabled={uploadingFile}
              >
                Save
              </button>
              <div
                className={`h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out 
                   ${uploadingFile ? "cursor-not-allowed" : "cursor-pointer"}  
                `}
                disabled={uploadingFile}
                onClick={() => setActiveTab("Manage Blog")}
              >
                Cancel
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditBlog;
