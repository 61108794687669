import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogDetailsSkeleton = () => {
  return (
    <div className="w-full m-auto p-6 rounded-2xl flex flex-col justify-center">
      <div className="w-full md:w-[90%] lg:w-[85%] overflow-hidden">
        <div className="py-3 space-y-2">
          <div className="text-3xl">
            <Skeleton width={300} height={36} />
          </div>
          <p className="flex ">
            <Skeleton width={100} height={20} />
          </p>
        </div>
        <Skeleton width="100%" height={384} />
        <div className="pt-3 pb-2 text-justify">
          <Skeleton count={5} />
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsSkeleton;
