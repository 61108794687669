import React from "react";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";
import { OwnersLoginImg } from "../../../assets/images";

const OwnersLogin = () => {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Owners Login with Email ID"}
          requiredRegister={true}
          loginFieldName={"Email ID"}
          loginImage={OwnersLoginImg}
        />
      </Layout>
    </div>
  );
};

export default OwnersLogin;
