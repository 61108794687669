import CustomerSatisfaction from "../../../../pages/admin/CustomerSatisfaction";
import TotalRevenue from "../../../../pages/admin/TotalRevenue";
import PieChart from "../../../../pages/admin/PieChart";
import { FaCodePullRequest } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { GrUserWorker } from "react-icons/gr";
import { GrDocument } from "react-icons/gr";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../constants/API";
import { useLoading } from "../../../../context/LoadingContext";

const Home = () => {
  // console.log(useLoading());
  const { setLoad } = useLoading();
  const [homeData, setHomeData] = useState({
    corporations: null,
    contractors: null,
    enquirys: null,
    contracts: null,
  });

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${baseUrl}/admintest/homedata`);
      setHomeData({ ...homeData, ...response.data.data });
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" overflow-y-auto h-[80vh] overflow-auto table-container">
      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Dashboard
        </h1>
        <div className="flex justify-between gap-2 flex-wrap my-4">
          {/* Card 1 */}
          <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
            <div>
              <GoOrganization className="w-8 h-8" />
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-semibold">
              Corporations
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-bold">
              {!homeData.corporations ? 0 : homeData.corporations.length}
            </div>
          </div>

          {/* Card 2 */}
          <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
            <div>
              <GrUserWorker className="w-8 h-8" />
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-semibold">
              Contractors
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-bold">
              {!homeData.contractors ? 0 : homeData.contractors.length}
            </div>
          </div>

          {/* Card 3 */}
          <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
            <div>
              <GrDocument className="w-8 h-8" />
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-semibold">
              Contracts
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-bold">
              {!homeData.contracts ? 0 : homeData.contracts.length}
            </div>
          </div>

          {/* Card 4 */}
          <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
            <div>
              <FaCodePullRequest className="w-8 h-8" />
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-semibold">
              Enquiry
            </div>
            <div className="text-sm sm:text-xl md:text-xl font-bold">
              {!homeData.enquirys ? 0 : homeData.enquirys.length}
            </div>
          </div>
        </div>

        {homeData.corporations && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
            <TotalRevenue
              corporations={homeData.corporations}
              contractors={homeData.contractors}
            />
            <PieChart contract={homeData.contracts} />
            <CustomerSatisfaction contract={homeData.contracts} />
          </div>
        )}

        <div className="">
          <div className="flex justify-between items-center w-full">
            <div className="w-full  mt-4">
              <h3 className="text-lg font-semibold">Last 5 Corporations</h3>
              <ul className="w-full overflow-hidden table-container">
                {homeData.corporations
                  ?.slice(
                    homeData.corporations.length - 5,
                    homeData.corporations.length - 0
                  )
                  .reverse()
                  .map((corporation, index) => (
                    <li
                      key={index}
                      className="bg-white rounded-md p-2 my-4 shadow-lg hover:shadow-xl  flex gap-4 flex-wrap"
                    >
                      <div>
                        <span className="font-bold">Corporation Name</span> -
                        {corporation?.Corporation_Name}
                      </div>
                      <div>
                        <span className="font-bold">Employed By Condo</span> -
                        {corporation?.Employed_By_Condo ? "Yes" : "No"}
                      </div>
                      <div>
                        <span className="font-bold">Property Managers</span> -
                        {corporation?.cp_propertymanagers?.length}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            {/* Last 5 Contractors */}
            <div className="w-full mt-4">
              <h3 className="text-lg font-semibold ">Last 5 Contractors</h3>
              <ul className="w-full overflow-hidden ">
                {homeData.contractors
                  ?.slice(
                    homeData.contractors.length - 5,
                    homeData.contractors.length - 0
                  )
                  .reverse()
                  .map((contractor, index) => (
                    <li
                      key={index}
                      className="bg-white my-4 shadow-lg hover:shadow-xl flex gap-2 flex-wrap rounded-md p-2 "
                    >
                      <div>
                        <span className="font-bold">Company Name</span> -
                        {contractor?.Company_Name}
                      </div>
                      <div>
                        <span className="font-bold">Contractor Name</span> -
                        {contractor?.First_Name} {contractor?.Last_Name}
                      </div>
                      <div>
                        <span className="font-bold">Phone No.</span> -
                        {contractor?.Phone_No}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
