import { createSlice } from "@reduxjs/toolkit";

const corporationSlice = createSlice({
  name: "corporationformslice",
  initialState: {},
  reducers: {},
});
// add comment
export const corporationSliceActions = corporationSlice.actions;

export default corporationSlice;
