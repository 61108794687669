import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../assets/images";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { contractAPI } from "../../../../constants/API";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import InputBox from "../../../../components/Elements/InputBox";
import InputBoxLiveDate from "../../../../components/Elements/InputBoxLiveDate";
import DropDownStyle1 from "../../../../components/Elements/DropDownStyle1";
import CheckBoxDropDown1 from "../../../../components/Elements/CheckBoxDropDown1";
import {
  getApprovalContractors,
  viewFileHandler,
} from "../../../../constants/Request";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../../../components/Elements/Loading";
import { Icon } from "@iconify/react";
import Residents from "../../../Test";

const { useNavigate } = require("react-router-dom");
const notifysuccess = (props) => toast.success(props, { duration: 4000 });
const notifyfailure = (props) => toast.error(props, { duration: 2000 });

function EditContract({ residentRequest = false }) {
  // console.log("EditContract", residentRequest);
  const [status, setStatus] = useState(0);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const param = useParams();
  const contractId = param.id;
  const location = useLocation();
  const [isOwners, setIsOwners] = useState(false);
  const [residentData, setResidentData] = useState(null);
  const [isRequest, setIsRequest] = useState(false);
  const [requstStatus, setRequstStatus] = useState(0);
  // Default to an empty object if location.state is null or undefined
  const { page } = location.state || {};

  useEffect(() => {
    if (!page) {
      navigate(`/corporations/dashboard/home?page=1`);
    }
  }, [location, navigate]); // Adding location as dependency

  const [minStartDate, setMinStartDate] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const [minEndDate, setMinEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [todayDate, setTodayDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [maxProposalDeadline, setMaxProposalDeadline] = useState("");

  const [selectDate, setSelectDate] = useState(false);
  const [isSpecificContractor, setIsSpecificContractor] = useState(false);
  const [contractorsOptions, setContractorsOptions] = useState([]);

  // const currentDate = new Date();
  // Get the current year and month
  // const currentYear = currentDate.getFullYear();
  // const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if necessary

  const schema = yup.object().shape({
    Project_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Project name can only contain letters.")
      .required("Project Name is required"),
    Project_Location: yup.string().required("Project Location is required"),
    Project_Type_ID: yup.string().required("Project Type is required"),
    Budget: yup.string(),
    Start_Date: yup.string().required("Start Date is required"),
    End_Date: yup.string().required("End Date is required"),
    Proposal_Deadline: yup.string().required("Proposal Deadline is required"),
    Project_Description: yup
      .string()
      .required("Project Description is required"),
    Year_Built: yup.string().required("Year Built is required"),
    BuildingType: yup.array().min(1, "Building Type is required"),
    Project_Photos: yup.string(),
    Project_Photos_0: yup.string(),
    Project_Photos_1: yup.string(),
    Project_Photos_2: yup.string(),
    Project_Photos_3: yup.string(),
    Project_Photos_4: yup.string(),
    Project_Photos_5: yup.string(),
    Project_Photos_6: yup.string(),
    Project_Photos_7: yup.string(),
    Project_Photos_8: yup.string(),
    Project_Photos_9: yup.string(),
    Is_Specific_Contractor: yup.boolean(),
    Contractors_ID: yup
      .array()
      .test(
        "is-required-if-specific-contractor",
        "Select a Contractor Required",
        function (value) {
          if (isSpecificContractor) {
            return value && value.length > 0; // Array must have at least one element if condition is met
          }
          return true; // Skip validation if the condition is not met
        }
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getApprovalContractors()
      .then((data) => {
        setContractorsOptions(data);
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again later");
      });
  }, []);

  const onContractSave = async (data) => {
    // if (data.Budget === "") {
    //   data.budget = null;
    // }

    console.log("status save", status);

    if (data.Year_Built) {
      const foundYearValue = yearBuiltOption.find(
        (year) => year.ID === selectYearBuilt
      );
      const formattedDate = new Date(`${foundYearValue.year}-01-01`)
        .toISOString()
        .split("T")[0];
      data.Year_Built = formattedDate;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const keysToRemove = [
      "Project_Photos_0",
      "Project_Photos_1",
      "Project_Photos_2",
      "Project_Photos_3",
      "Project_Photos_4",
      "Project_Photos_5",
      "Project_Photos_6",
      "Project_Photos_7",
      "Project_Photos_8",
      "Project_Photos_9",
      "Project_Photos_10",
      "Project_Photos",
      "Is_Specific_Contractor",
    ];

    keysToRemove.forEach((key) => formData.delete(key));

    contractImages.forEach((image, index) => {
      formData.append(`Project_Photos_${index + 1}`, image);
    });

    if (contractDocs) {
      formData.append("Project_Photos", contractDocs);
    } else {
      formData.append("Project_Photos", data.Project_Photos);
    }

    let deleteBuildingType = [];
    let createBuildingType = [];
    data.BuildingType.forEach((building) => {
      if (!data.CpContractBuildingTypes.includes(building)) {
        createBuildingType.push(building);
      }
    });

    data.CpContractBuildingTypes.forEach((building) => {
      if (!data.BuildingType.includes(building)) {
        deleteBuildingType.push(building);
      }
    });

    formData.append("deleteBuildingType", deleteBuildingType);
    formData.append("createBuildingType", createBuildingType);

    let deleteSpecificContractors = [];
    let createSpecificContractors = [];

    if (isSpecificContractor) {
      formData.append("Is_Specific_Contractor", true);

      data.Contractors_ID.forEach((contractor) => {
        if (!data.CpContractToSpecificContractors.includes(contractor)) {
          createSpecificContractors.push(contractor);
        }
      });

      data.CpContractToSpecificContractors.forEach((contractor) => {
        if (!data.Contractors_ID.includes(contractor)) {
          deleteSpecificContractors.push(contractor);
        }
      });
    } else {
      formData.append("Is_Specific_Contractor", false);
      formData.delete("Contractors_ID");
      data.CpContractToSpecificContractors.forEach((contractor) => {
        deleteSpecificContractors.push(contractor);
      });
    }

    formData.append("deleteSpecificContractors", deleteSpecificContractors);
    formData.append("createSpecificContractors", createSpecificContractors);
    // console.log("deleteSpecificContractors", deleteSpecificContractors);
    // console.log("createSpecificContractors", createSpecificContractors);

    console.log("Request_Status", status);

    formData.set("Request_Status", status);

    setLoad(true);
    try {
      const response = await axios.put(
        `${contractAPI}/${contractId}`,
        formData,
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "corporation",
          },
        }
      );

      toast.success("Contract Save Successfully", { duration: 2000 });
      navigate(`/corporations/dashboard/home?page=${page}`);
    } catch (error) {
      notifyfailure(
        error.response.data.message ||
          "Failed to save contract data ! Please try again later"
      );
    } finally {
      setLoad(false);
    }
  };

  const getContractData = async () => {
    setLoad(true);

    try {
      const res = await axios.get(`${contractAPI}/${contractId}`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      });
      const data = res.data.data;
      // console.log("data", data);

      setIsRequest(data.Is_Request);

      if (data) {
        // reset(data);
        Object.keys(data).forEach((key) => {
          setValue(key, data[key]);
        });

        if (data.Resident_Data) {
          setResidentData(data.Resident_Data);
        }

        if (data.Is_Owners) {
          setIsOwners(data.Is_Owners);
        }

        if (data.Request_Status) {
          setRequstStatus(data.Request_Status);
        }

        if (data.Project_Type_ID) {
          setSelectProjectType(parseInt(data.Project_Type_ID));
        }

        if (data.CpContractBuildingTypes) {
          const buildingtype = data.CpContractBuildingTypes.map((building) => {
            return parseInt(building.Building_ID);
          });
          setValue("CpContractBuildingTypes", buildingtype);
          setSelectBuildingType(buildingtype);
        }

        if (data.Is_Specific_Contractor) {
          setIsSpecificContractor(data.Is_Specific_Contractor);
        }

        if (data.CpContractToSpecificContractors) {
          const specificContractors = data.CpContractToSpecificContractors.map(
            (contractor) => {
              return parseInt(contractor.Contractor_ID);
            }
          );
          setValue("CpContractToSpecificContractors", specificContractors);
          setSelectContractors(specificContractors);
        }

        if (data.Project_Photos) {
          const fileUrl = await viewFileHandler(
            data.Project_Photos,
            "contract"
          );
          setContractDocsURL(fileUrl);
          setValue("Project_Photos", data.Project_Photos);
        } else {
          setValue("Project_Photos", "");
        }

        if (data.Project_Photos_1) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[0] = data.Project_Photos_1;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[0] = data.Project_Photos_1;
            return update;
          });
          setValue("Project_Photos_0", data.Project_Photos_1);
        } else {
          setValue("Project_Photos_0", "");
        }

        if (data.Project_Photos_2) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[1] = data.Project_Photos_2;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[1] = data.Project_Photos_2;
            return update;
          });
          setValue("Project_Photos_1", data.Project_Photos_2);
        } else {
          setValue("Project_Photos_1", "");
        }
        if (data.Project_Photos_3) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[2] = data.Project_Photos_3;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[2] = data.Project_Photos_3;
            return update;
          });
          setValue("Project_Photos_2", data.Project_Photos_3);
        } else {
          setValue("Project_Photos_2", "");
        }
        if (data.Project_Photos_4) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[3] = data.Project_Photos_4;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[3] = data.Project_Photos_4;
            return update;
          });
          setValue("Project_Photos_3", data.Project_Photos_4);
        } else {
          setValue("Project_Photos_3", "");
        }
        if (data.Project_Photos_5) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[4] = data.Project_Photos_5;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[4] = data.Project_Photos_5;
            return update;
          });
          setValue("Project_Photos_4", data.Project_Photos_5);
        } else {
          setValue("Project_Photos_4", "");
        }
        if (data.Project_Photos_6) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[5] = data.Project_Photos_6;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[5] = data.Project_Photos_6;
            return update;
          });
          setValue("Project_Photos_5", data.Project_Photos_6);
        } else {
          setValue("Project_Photos_5", "");
        }
        if (data.Project_Photos_7) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[6] = data.Project_Photos_7;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[6] = data.Project_Photos_7;
            return update;
          });
          setValue("Project_Photos_6", data.Project_Photos_7);
        } else {
          setValue("Project_Photos_6", "");
        }
        if (data.Project_Photos_8) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[7] = data.Project_Photos_8;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[7] = data.Project_Photos_8;
            return update;
          });
          setValue("Project_Photos_7", data.Project_Photos_8);
        } else {
          setValue("Project_Photos_7", "");
        }
        if (data.Project_Photos_9) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[8] = data.Project_Photos_9;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[8] = data.Project_Photos_9;
            return update;
          });
          setValue("Project_Photos_8", data.Project_Photos_9);
        } else {
          setValue("Project_Photos_8", "");
        }

        if (data.Project_Photos_10) {
          setDisplayContractImages((prev) => {
            const update = [...prev];
            update[9] = data.Project_Photos_10;
            return update;
          });
          setContractImages((prev) => {
            const update = [...prev];
            update[9] = data.Project_Photos_10;
            return update;
          });
          setValue("Project_Photos_9", data.Project_Photos_10);
        } else {
          setValue("Project_Photos_9", "");
        }

        const formatMonth = (dateString) => {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding leading zero if necessary
          const formattedMonth = `${year}-${month}`;
          return formattedMonth;
        };

        if (data.Year_Built) {
          const year = new Date(data.Year_Built).getFullYear();
          const value = yearBuiltOption.find(
            (thisyear) => thisyear.year === year.toString()
          );

          setSelectYearBuilt(value.ID);
          setValue("Year_Built", data.Year_Built);
        }

        if (data.Start_Date) {
          setMinEndDate(
            new Date(new Date(data.Start_Date).getTime() + 24 * 60 * 60 * 1000)
              .toISOString()
              .split("T")[0]
          );
          setMaxProposalDeadline(
            new Date(new Date(data.Start_Date).getTime() - 24 * 60 * 60 * 1000)
              .toISOString()
              .split("T")[0]
          );
        }
      }
    } catch (error) {
      notifyfailure(error.response.data.message);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getContractData();
  }, [contractId]);

  useEffect(() => {
    // console.log("datda get value", getValues());
  });

  const buildingTypeOption = [
    { ID: 5, buildingType: "Apartments" },
    { ID: 6, buildingType: "Stacked Townhouse" },
    { ID: 7, buildingType: "Townhouse" },
    { ID: 8, buildingType: "Midrise" },
    { ID: 9, buildingType: "Highrise" },
    { ID: 10, buildingType: "Triplex" },
    { ID: 11, buildingType: "Detached" },
    { ID: 12, buildingType: "Semi Detached" },
    { ID: 13, buildingType: "Industrial condo" },
  ];

  const ProjectTypeOption = [
    { ID: 2, projectType: "Once" },
    { ID: 3, projectType: "Twice" },
    { ID: 4, projectType: "Quarterly" },
    { ID: 5, projectType: "Monthly" },
    { ID: 6, projectType: "Daily" },
    { ID: 7, projectType: "Seasonal" },
  ];
  const yearBuiltOption = [];
  const currentYear = new Date().getFullYear();
  const startYear = 1950; // Change as needed
  for (let year = startYear, index = 1; year <= currentYear; year++, index++) {
    yearBuiltOption.push({ ID: index, year: year.toString() });
  }

  const [selectBuildingType, setSelectBuildingType] = useState([]);
  const [selectProjectType, setSelectProjectType] = useState(null);
  const [selectYearBuilt, setSelectYearBuilt] = useState(null);
  const [selectContractors, setSelectContractors] = useState([]);

  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES = ["image/jpeg", "image/png", "image/jpg"]; // Example supported file types
  const FILE_SIZE_LIMIT_DOCS = 5 * 1024 * 1024; // 5MB
  const SUPPORTED_FILE_TYPES_DOCS = ["application/pdf"];

  const [contractDocs, setContractDocs] = useState(null);
  const [contractDocsError, setContractDocsError] = useState("");
  const [contractDocsUploded, setContractDocsUploded] = useState("");
  const [contractDocsURL, setContractDocsURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  const [uploadingContractDocs, setUploadingContractDocs] = useState(false);
  const [contractImages, setContractImages] = useState(Array(10).fill(""));
  const [displayContractImages, setDisplayContractImages] = useState(
    Array(10).fill("")
  );
  const [contractImagesError, setContractImagesError] = useState("");
  const [contractImagesUploading, setContractImagesUploading] = useState(false);

  const handleChangeContractDocs = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setContractDocs(null);
      setContractDocsError("Please Upload a Docs of Project/Building");
      setContractDocsUploded("");
      setContractDocsURL("");
      clearErrors("Project_Photos");
      setValue("Project_Photos", "");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_DOCS &&
        SUPPORTED_FILE_TYPES_DOCS.includes(file.type)
      ) {
        setContractDocs(file);
        setContractDocsError("");
        setContractDocsURL("");
        clearErrors("Project_Photos");
        setContractDocsUploded("");
        setValue("Project_Photos", file);
      } else if (file && !SUPPORTED_FILE_TYPES_DOCS.includes(file.type)) {
        setContractDocs(null);
        setContractDocsError("Unsupported file type file type must be PDF ");
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("Project_Photos");
        setValue("Project_Photos", "");
      } else if (file && !file.size <= FILE_SIZE_LIMIT_DOCS) {
        setContractDocs(null);
        setContractDocsError(
          "File size exceeds limit. File size must be less than 5 MB"
        );
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("Project_Photos");
        setValue("Project_Photos", "");
      } else {
        setContractDocs(null);
        setContractDocsError("Something went wrong please try again later");
        setContractDocsUploded("");
        setContractDocsURL("");
        clearErrors("Project_Photos");
        setValue("Project_Photos", "");
      }
    }
  };

  const handleChangeContractImages = (event, index) => {
    // setContractImagesUploading(true);
    const files = Array.from(event.target.files);
    let newContractImages = [...contractImages];
    let validFiles = [];
    let errorMessage = "";

    for (let file of files) {
      if (file.size > FILE_SIZE_LIMIT) {
        errorMessage = `File size should be less than 5MB is too large.`;
      } else if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        errorMessage = `File type should be .jpeg, .jpg, .png.`;
      } else {
        validFiles.push(file);
      }
    }

    if (validFiles.length > 0) {
      newContractImages[index] = validFiles[0];

      validFiles = validFiles.slice(1);

      for (let i = 0; i < validFiles.length; i++) {
        const newIndex = newContractImages.findIndex(
          (element) => element === ""
        );

        if (newIndex !== -1) {
          newContractImages[newIndex] = validFiles[i];
        } else {
          break;
        }
      }
    }

    if (newContractImages.filter(Boolean).length > 10) {
      newContractImages = newContractImages.slice(0, 10);
      errorMessage = "You can only upload up to 10 images.";
    }

    setContractImages(newContractImages);

    if (errorMessage) {
      setContractImagesError(errorMessage);
    } else {
      setContractImagesError("");
    }

    // console.log("newContractImages", newContractImages);
    const newDisplayImages = newContractImages.map((file) =>
      SUPPORTED_FILE_TYPES.includes(file?.type)
        ? URL.createObjectURL(file)
        : file
    );
    setDisplayContractImages(newDisplayImages);
    // setContractImagesUploading(false);
  };

  const handleRemoveImage = (index) => {
    setContractImagesError("");
    const newContractImages = [...contractImages];
    const newDisplayImages = [...displayContractImages];
    // newContractImages.splice(index, 1);
    // newDisplayImages.splice(index, 1);
    newContractImages[index] = "";
    newDisplayImages[index] = "";
    setContractImages(newContractImages);
    setDisplayContractImages(newDisplayImages);
  };

  const clearErrorsProjectAttachmentAndReject = async (status) => {
    // Reject Time Cancel Then Request Status 2
    setContractDocsError("");
    setContractImagesError("");

    console.log("status", status);

    if (status === 2 && isRequest) {
      console.log("isRequest", isRequest);

      setLoad(true);

      try {
        const response = await axios.put(
          `${contractAPI}/${contractId}`,
          { Request_Status: 2 },
          {
            headers: {
              authorization: cookies.get("token"),
              companyType: "corporation",
            },
          }
        );
        toast.success("Contract Rejected Successfully", { duration: 2000 });
        navigate(`/corporations/dashboard/home?page=${page}`);
      } catch (error) {
        toast.error("Failed to reject contract. Please try again later");
      } finally {
        setLoad(false);
      }
    } else if (status === 2 && !isRequest) {
      console.log("isRequest", isRequest);
      setLoad(true);

      try {
        const response = await axios.put(
          `${contractAPI}/${contractId}`,
          { Status: 2 },
          {
            headers: {
              authorization: cookies.get("token"),
              companyType: "corporation",
            },
          }
        );
        toast.success("Contract Rejected Successfully", { duration: 2000 });
        navigate(`/corporations/dashboard/home?page=${page}`);
      } catch (error) {
        toast.error("Failed to reject contract. Please try again later");
      } finally {
        setLoad(false);
      }
    } else {
      setStatus(status);
    }
  };

  return (
    <div>
      <Loading load={load} />

      <div className="w-full rounded-2xl px-4 m-auto p-6  flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          {!load && (residentRequest ? "Request Resident" : "Edit Listing")}
        </h1>

        {residentData && !load && (
          <div className="mt-4">
            <h2 className="text-secondaryl font-extrabold text-2xl euclidbold  items-center justify-start  mb-3 underline  gap-2  flex">
              <Icon icon="material-symbols:person" className="text-2xl" />
              Resident Details :
            </h2>

            <div className="space-y-1">
              <div>
                {" "}
                <label htmlFor="" className="text-secondaryl">
                  Resident Type :
                </label>{" "}
                <span className="text-infromation">
                  {" "}
                  {isOwners ? "Owners" : "Tenant"}
                </span>
              </div>

              <div>
                {" "}
                <label htmlFor="" className="text-secondaryl">
                  Resident Name :{" "}
                </label>{" "}
                <span className="text-infromation">
                  {" "}
                  {`${residentData?.First_Name}  ${residentData?.Last_Name}`}
                </span>
              </div>

              <div>
                {" "}
                <label htmlFor="" className="text-secondaryl">
                  Resident Address :{" "}
                </label>{" "}
                <span className="text-infromation">
                  {" "}
                  {residentData?.Residential_Address}{" "}
                </span>
              </div>

              <div>
                {" "}
                <label htmlFor="" className="text-secondaryl">
                  Resident Contact No :{" "}
                </label>{" "}
                <span className="text-infromation">
                  {" "}
                  {residentData?.Phone_No}{" "}
                </span>
              </div>
            </div>
          </div>
        )}

        <form
          onSubmit={handleSubmit(onContractSave)}
          className="mt-8"
          encType="multipart/form-data"
        >
          {/* Project Name */}
          <div className="mb-6">
            <InputBox
              title={" Project Name"}
              register={register}
              filedName={"Project_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/* Project Location, Type, Budget */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-14 mb-3">
            {/* Project Location */}
            <div className="">
              <InputBox
                title={"Project Location"}
                register={register}
                filedName={"Project_Location"}
                must={true}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            </div>
            {/* Project Type */}
            <div className="">
              <label htmlFor={"projectType"} className="block font-normal">
                <p>
                  Project Type
                  <span className="text-red-500 text-base">*</span>
                </p>
              </label>

              <DropDownStyle1
                title="Select Project Type"
                options={ProjectTypeOption}
                onSelect={setSelectProjectType}
                selectOption={selectProjectType}
                setValue={setValue}
                filedName={"Project_Type_ID"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"projectType"}
              />
            </div>

            {/* Budget */}
            <div className="mb-6 relative">
              <span className="absolute top-[45%] left-[2%]">$</span>
              <InputBox
                title={"Budget"}
                register={register}
                type="text"
                filedName={"Budget"}
                must={false}
                errors={errors}
                min={0}
                className={"md:text-sm lg:text-base pl-4"}
                requiredError={false}
              />
              <div className="flex justify-between h-[2vh] -mt-3 pt-1">
                {errors.Budget && (
                  <p className="text-red-500 text-xs leading-none">
                    {errors.Budget.message}
                  </p>
                )}

                <p className=" text-[12px] ml-auto">
                  (Remains hidden from Contractor)
                </p>
              </div>
            </div>
          </div>

          {/* Project Dates */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6  md:gap-14 mb-3">
            {/* Start Date */}
            <InputBoxLiveDate
              title={"Project Start Date"}
              register={register}
              filedName={"Start_Date"}
              must={true}
              type="date"
              setValue={setValue}
              min={minStartDate}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
              setMinEndDate={setMinEndDate}
              setMaxProposalDeadline={setMaxProposalDeadline}
              setSelectDate={setSelectDate}
              otherDependencies={true}
              changeMinStartDate={minStartDate}
              changeMinEndDate={minEndDate}
              changeMaxProposalDeadline={maxProposalDeadline}
            />
            {/* End Date */}

            <InputBoxLiveDate
              title={"Project End Date"}
              register={register}
              filedName={"End_Date"}
              must={true}
              type="date"
              errors={errors}
              min={minEndDate}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
              disabled={selectDate}
            />

            {/* Proposal Deadline */}
            <InputBoxLiveDate
              title={"Project Proposal Deadline"}
              register={register}
              filedName={"Proposal_Deadline"}
              must={true}
              type="date"
              max={maxProposalDeadline}
              min={todayDate}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
              disabled={selectDate}
            />
          </div>

          <div className="font-bold mb-4 text-xl euclidbold text-secondaryl">
            Property Details <span className="text-red-500 text-base">*</span>{" "}
          </div>

          {/* Property Details */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-14 mb-3">
            {/* Year Built */}

            <div className="">
              <label
                htmlFor="yearBuilt"
                className="block font-normal text-secondaryl"
              >
                <p>
                  Year Built
                  <span className="text-red-500 text-base ml-1">*</span>
                </p>
              </label>
              <DropDownStyle1
                title="Select Years Built"
                options={yearBuiltOption}
                onSelect={setSelectYearBuilt}
                selectOption={selectYearBuilt}
                setValue={setValue}
                filedName={"Year_Built"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"year"}
              />

              {/* <InputBoxLiveDate
                  title={""}
                  register={register}
                  filedName={"Year_Built"}
                  type="month"
                  min="1900" // Set the minimum allowed year
                  // max={currentMonth}
                  max={2024}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  schema={schema}
                  setError={setError}
                /> */}
            </div>

            {/* Building Type */}
            <div className="">
              <label
                htmlFor="buildingType"
                className="block font-normal text-secondaryl"
              >
                <p>
                  Building Type
                  <span className="text-red-500 text-base ml-1">*</span>
                </p>
              </label>

              <CheckBoxDropDown1
                title={"Building Type"}
                options={buildingTypeOption}
                onSelect={setSelectBuildingType}
                selectOption={selectBuildingType}
                setValue={setValue}
                filedName={"BuildingType"}
                clearErrors={clearErrors}
                errors={errors}
                dbfield={"buildingType"}
                setError={setError}
                schema={schema}
                must={true}
              />
            </div>

            {/* If Display A Building Type text box view comment box open */}
            <div className="my-auto md:pt-2.5">
              <input
                type="text"
                id="buildingTypeDisplay"
                name="buildingTypeDisplay"
                className={` block h-11 min-w-20 w-full cursor-default rounded-sm text-black  px-2 ps-10 bg-[#FFFFFF80]  focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none  `}
                style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                placeholder="Selected Building Type Display Here"
                value={
                  buildingTypeOption
                    ?.filter((item) => selectBuildingType?.includes(item.ID))
                    .map((item) => item.buildingType)
                    .join(", ") || ""
                }
                readOnly
              />
            </div>
          </div>

          {/* Project Description */}
          <div className="mb-6">
            <label
              htmlFor="projectDescription"
              className="block mb-2 text-secondaryl"
            >
              Project Description
              <span className="text-red-500 text-base">*</span>
            </label>
            <textarea
              id="projectDescription"
              name="projectDescription"
              {...register("Project_Description")}
              className={`bg-[#FFFFFF80] w-full max-h-40 min-h-40 px-2 py-2 h-36 rounded-sm text-black   focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
                errors.Project_Description ? "ring-[0.5px] ring-red-500" : ""
              }`}
              style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
              placeholder="Add Project Details Here"
            ></textarea>

            <div className="h-[2vh] pt-1">
              {errors.Project_Description && (
                <p className="text-red-500 text-xs leading-none">
                  {errors.Project_Description.message}
                </p>
              )}
            </div>
          </div>

          {/* Attach Photos */}
          <div className="">
            <div className="mb-2 lg:mb-0">
              {/* Input */}
              <label
                className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base mt-4`}
              >
                Document Upload :
              </label>
              {/* Input */}
              <div>
                <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-2 gap-5  md:gap-16 md:mr-4 lg:mr-20">
                  {/* Business Card File Input */}
                  <div className="col-span-2 sm:col-span-2 md:col-span-1">
                    <input
                      type="file"
                      id="companyContractDocs"
                      name="companyContractDocs"
                      className="hidden"
                      accept=".pdf"
                      onChange={handleChangeContractDocs}
                      disabled={uploadingFile}
                    />

                    {/* Label For style is input tag and use in File input */}
                    <label
                      htmlFor="companyContractDocs"
                      className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                        uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                    >
                      <div className="flex items-center h-full space-x-1 overflow-hidden">
                        <InputFileIcon />
                        {contractDocs && !contractDocsURL ? (
                          <span className="line-clamp-1 w-full">
                            {contractDocs.name}
                          </span>
                        ) : (
                          ""
                        )}

                        {!contractDocs && !contractDocsURL ? (
                          <span className="text-infromation  text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                            PDF files only
                          </span>
                        ) : (
                          ""
                        )}

                        {contractDocsURL && (
                          <a
                            className="text-white p-2 bg-secondaryl hover:text-secondaryd truncate flex justify-end"
                            href={contractDocsURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Click Here
                          </a>
                        )}
                      </div>
                    </label>
                  </div>
                </div>

                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {uploadingContractDocs && <Spinner />}
                  {contractDocsError && (
                    <p className="text-red-500 text-xs leading-none">
                      {contractDocsError}
                    </p>
                  )}
                  {errors.Project_Photos && ( // Error message from yup validation
                    <p className="text-red-500 text-xs leading-none">
                      {errors.Project_Photos.message}
                    </p>
                  )}
                  {contractDocsUploded && (
                    <p className="text-infromation text-sm leading-none flex items-center gap-2 mt-1">
                      {/* {contractDocsUploded}  */}
                      <ApprovedIcon /> Attach PDF of Project/Building uploaded
                      Successfully!
                    </p>
                  )}
                </div>
              </div>

              <label
                className={`block font-normal mb-3 text-secondaryl text-[12px] sm:text-[12px] md:text-base mt-4 `}
              >
                Attach Photos of Project/Building :
              </label>

              <div className="flex flex-wrap gap-5 max-w-[720px]">
                {displayContractImages.map((imageSrc, index) =>
                  imageSrc ? (
                    <div key={`contractImage${index}`} className="relative">
                      <img
                        src={imageSrc}
                        alt=""
                        className="object-cover h-40 w-40 rounded-sm ring-1 ring-gray-400"
                      />
                      <Icon
                        onClick={() => handleRemoveImage(index)}
                        icon="icon-park-solid:close-one"
                        className="absolute top-1 right-1 text-primaryl text-2xl hover:text-secondaryl cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div key={`companyContractImages${index}`}>
                      <div className="col-span-2 sm:col-span-2 md:col-span-1">
                        <input
                          type="file"
                          id={`companyContractImages${index}`}
                          name={`companyContractImages${index}`}
                          className="hidden"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) => handleChangeContractImages(e, index)}
                          disabled={contractImagesUploading}
                          multiple
                        />

                        <label
                          htmlFor={`companyContractImages${index}`}
                          className={`block bg-[#FFFFFF80] h-40 w-40 rounded-sm text-black  px-2  focus:ring-sky-600 focus:outline-none ${
                            contractImagesUploading
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          style={{
                            boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                          }}
                          disabled={contractImagesUploading}
                        >
                          <div className="flex flex-col opacity-50 justify-center items-center h-full space-x-1 overflow-hidden">
                            <InputFileIcon width="40" height="45" />

                            <span className="text-infromation text-center text-[11px] sm:text-[8px] md:text-[10px] lg:text-base">
                              .jpg, .png, .jpeg files only
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  )
                )}

                {/* Error */}
                <div className="h-[3vh] mb-3">
                  {contractImagesUploading && <Spinner />}

                  {contractImagesError && (
                    <p className="text-red-500 text-xs leading-none">
                      {contractImagesError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Specific Contractor */}
          <div className="flex gap-3 items-center mb-3">
            <input
              type="checkbox"
              id="isSpecificContractor"
              name="isSpecificContractor"
              checked={isSpecificContractor}
              onChange={() => setIsSpecificContractor(!isSpecificContractor)}
              className="bg-[#FFFFFF80] accent-secondaryl cursor-pointer text-black min-h-7 min-w-7"
            />
            <label
              htmlFor="isSpecificContractor"
              className="pt-2 text-secondaryl"
            >
              This Project is for Specific Contractor
            </label>
          </div>

          <div
            className={`transition-all duration-500 ease-in-out  ${
              isSpecificContractor ? "opacity-100" : "opacity-0"
            }`}
          >
            {isSpecificContractor &&
              (contractorsOptions.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-12 mb-14  h-28 ">
                  <div className="">
                    <CheckBoxDropDown1
                      title={"Select Contractor"}
                      options={contractorsOptions}
                      onSelect={setSelectContractors}
                      selectOption={selectContractors}
                      setValue={setValue}
                      filedName={"Contractors_ID"}
                      clearErrors={clearErrors}
                      errors={errors}
                      dbfield={"Company_Name"}
                      setError={setError}
                      schema={schema}
                      must={true}
                    />
                  </div>
                  <div className="mb-3 col-span-2">
                    <input
                      type="text"
                      id="specificContractorDisplay"
                      name="specificContractorDisplay"
                      className={` block h-11  min-w-20 w-full cursor-default rounded-sm text-black   px-2  ps-10 bg-[#FFFFFF80]  focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none`}
                      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                      placeholder="Selected Contractor Type Display Here"
                      value={
                        contractorsOptions
                          ?.filter((item) =>
                            selectContractors?.includes(item.ID)
                          )
                          .map((item) => item.Company_Name)
                          .join(", ") || ""
                      }
                      readOnly
                    />
                  </div>
                </div>
              ) : (
                "Contractor Data Not Found. Please try again later"
              ))}
          </div>

          {/* Upload Button */}
          <div className="my-3 gap-2 flex flex-wrap">
            {isRequest ? (
              <>
                <button
                  type="submit"
                  className={`
                h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                  disabled={uploadingFile}
                  onClick={() => clearErrorsProjectAttachmentAndReject(1)}
                >
                  {requstStatus === 0 ? "Approve and Save" : "Save"}
                </button>
              </>
            ) : (
              <button
                type="submit"
                className={`
                h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                disabled={uploadingFile}
                onClick={() => clearErrorsProjectAttachmentAndReject(1)}
              >
                Save
              </button>
            )}

            <button
              type="button"
              className={`
                h-11 w-fit euclidbold  ring-gray-600 rounded-md flex justify-center text-red-500  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              disabled={uploadingFile}
              onClick={() => clearErrorsProjectAttachmentAndReject(2)}
            >
              {isRequest ? "Reject and Close" : "Close"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditContract;
