import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
import IncidentReadModal from "./IncidentReadModal";
import { set } from "date-fns";

function IncidentCard({ data, onDelete, fetchData, setLoad }) {
  const cookies = new Cookies();

  const { Description, ID } = data;

  const [incident, setIncident] = useState({});

  const [showViewModel, setShowViewModel] = useState(false);

  const handleView = (value) => {
    if (value) {
      setIncident(data);
      setShowViewModel(true);
    } else {
      setIncident({});
      setShowViewModel(false);
    }
  };

  return (
    <>
      {showViewModel && incident && (
        <IncidentReadModal
          data={incident}
          handleView={handleView}
          fetchData={fetchData}
          setLoad={setLoad}
        />
      )}

      <div
        className="w-full flex items-center lg:flex-row flex-col  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
        key={ID}
      >
        <div className="flex-col w-full  justify-center leading-normal">
          <div className="text-secondaryl euclidbold text-2xl break-words line-clamp-3 mb-1">
            {data?.Incident?.Name}
          </div>
          <div className="text-secondaryl  text-base break-words line-clamp-3 mb-1">
            {Description}
          </div>
        </div>
        {/* Card Buttons */}

        <div className="flex-col justify-center my-auto relative w-full lg:w-[20%] space-y-3">
          <div
            onClick={() => handleView(true)}
            className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            VIEW
          </div>

          {cookies.get("access") === "security member" && (
            <div
              onClick={() => {
                const confirmation = window.confirm(
                  `Are you sure you want to delete this Incident ?`
                );
                if (confirmation) {
                  onDelete();
                }
              }}
              className="h-11 w-full px-2 py-2.5 uppercase euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
            >
              Delete
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default IncidentCard;
