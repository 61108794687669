import React from "react";

function NotificationIcons({ isNotification }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.38px"
      height="34.94px"
      viewBox="0 0 24 24"
    >
      <g fillRule="evenodd" strokeWidth="1.5" clipRule="evenodd">
        <path
          d="M18 8.4C18 6.70261 17.3679 5.07475 16.2426 3.87452C15.1174 2.67428 13.5913 2 12 2C10.4087 2 8.88258 2.67428 7.75736 3.87452C6.63214 5.07475 6 6.70261 6 8.4C6 15.8667 3 18 3 18H21C21 18 18 15.8667 18 8.4Z"
          fill="black"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>

        {isNotification && (
          <path
            fill="red"
            d="M15.25 5a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0"
          />
        )}

        <path
          fill="black"
          d="M9.894 20.351a.75.75 0 0 1 1.025.273a1.25 1.25 0 0 0 2.162 0a.75.75 0 1 1 1.298.753a2.75 2.75 0 0 1-4.758 0a.75.75 0 0 1 .273-1.026"
        />
      </g>
    </svg>
  );
}

export default NotificationIcons;
