import React from "react";

import { BuildingWithMan, TenantLoginImg } from "../../../assets/images";
import Layout from "../../../components/Layout/Layout.jsx";

import CreateTenantForm from "../../../components/Corporation/Dashboard/Resident/CreateTenantForm.jsx";

function CreateTenant() {
  return (
    <Layout>
      <div className="overflow-hidden   overflow-x-hidden md:p-6  md:px-10">
        <div className="min-h-screen w-full flex relative gap-10 ">
          <div className="w-[40%] hidden md:block">
            <img
              src={TenantLoginImg}
              className="min-h-screen object-contain"
              alt="Singup background"
            />
          </div>

          <div className="w-full md:w-[60%]">
            <CreateTenantForm />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreateTenant;
