import React from "react";

function PasswordIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23" fill="none">
      <path d="M10 17.4629C9.33696 17.4629 8.70107 17.233 8.23223 16.8236C7.76339 16.4142 7.5 15.859 7.5 15.2801C7.5 14.0686 8.6125 13.0972 10 13.0972C10.663 13.0972 11.2989 13.3272 11.7678 13.7366C12.2366 14.1459 12.5 14.7011 12.5 15.2801C12.5 15.859 12.2366 16.4142 11.7678 16.8236C11.2989 17.233 10.663 17.4629 10 17.4629ZM17.5 20.7372V9.8229H2.5V20.7372H17.5ZM17.5 7.64004C18.163 7.64004 18.7989 7.87002 19.2678 8.27938C19.7366 8.68875 20 9.24397 20 9.8229V20.7372C20 21.3162 19.7366 21.8714 19.2678 22.2808C18.7989 22.6901 18.163 22.9201 17.5 22.9201H2.5C1.83696 22.9201 1.20107 22.6901 0.732233 22.2808C0.263392 21.8714 0 21.3162 0 20.7372V9.8229C0 8.61141 1.1125 7.64004 2.5 7.64004H3.75V5.45717C3.75 4.00984 4.40848 2.62179 5.58058 1.59837C6.75269 0.57495 8.3424 0 10 0C10.8208 0 11.6335 0.141154 12.3918 0.415402C13.1501 0.689651 13.8391 1.09162 14.4194 1.59837C14.9998 2.10511 15.4602 2.70671 15.7742 3.3688C16.0883 4.0309 16.25 4.74052 16.25 5.45717V7.64004H17.5ZM10 2.18287C9.00544 2.18287 8.05161 2.52784 7.34835 3.14189C6.64509 3.75594 6.25 4.58877 6.25 5.45717V7.64004H13.75V5.45717C13.75 4.58877 13.3549 3.75594 12.6517 3.14189C11.9484 2.52784 10.9946 2.18287 10 2.18287Z" fill="#3E5CAA" fillOpacity="0.3" />
    </svg>
  );
}

export default PasswordIcon;
