import React, { useState, useEffect } from "react";
import ContractDetailsCard from "../../components/ContractDetalis/ContractDetailsCard.jsx";
import { useParams, useLocation } from "react-router-dom";
import ContractDetailsCardSkeleton from "../../components/ContractDetalis/ContractDetailsCardSkeleton.jsx";
import Cookies from "universal-cookie";
import ProposalForm from "../../components/ContractDetalis/ProposalForm";
import ContractorProposalView from "../../components/ContractDetalis/ContractorProposalView.jsx";
import Loading from "../../components/Elements/Loading.jsx";

function DetailsContractListing() {
  const location = useLocation();
  const cookies = new Cookies();
  const [contracts, setContracts] = useState([]);
  const { id } = useParams();
  const [notFound, setNotFound] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const projectDeadline = new Date(location.state.Proposal_Deadline).setHours(
      23,
      59,
      59,
      999
    );
    const currentDate = new Date();
    // console.log(currentDate, location.state.Proposal_Deadline);
    setIsExpired(projectDeadline < currentDate);
    // console.log(projectDeadline < currentDate);
    setStatus(cookies.get("status"));
  }, []);

  // console.log(location.state);

  return (
    <div>
      <Loading load={load} />
      {load ? (
        <ContractDetailsCardSkeleton />
      ) : notFound ? (
        <div className="text-center text-2xl text-infromation mt-10">
          Contract Not Found
        </div>
      ) : (
        <div className="w-full px-4 m-auto  p-6 rounded-2xl flex flex-col justify-center">
          <ContractDetailsCard contracts={location.state.contract} />

          {!location.state.proposal &&
            (isExpired ? (
              <div className="w-full my-3">
                <div className="flex-1">
                  <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                    Proposal
                  </p>
                </div>

                <div className="mt-2">
                  <p className="text-lg text-gray-600">
                    Proposal submission is closed for this project.
                  </p>
                </div>
              </div>
            ) : (
              !location.state.proposal &&
              (status !== 2 ? (
                <ProposalForm
                  setLoad={setLoad}
                  contractId={location.state.ID}
                />
              ) : (
                <div className="w-full my-3">
                  <div className="flex-1">
                    <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                      Proposal
                    </p>
                  </div>
                  <div className="mt-2">
                    <p className="text-lg text-gray-600">
                      Your Profile is Paused by Admin. You can't submit
                      proposal.
                    </p>
                  </div>
                </div>
              ))
            ))}

          {location.state.proposal &&
          Object.keys(location.state.proposal).length > 0 ? (
            <ContractorProposalView contract={location.state} load={load} />
          ) : (
            location.state.proposal && (
              <div className="w-full my-3">
                <div className="flex-1">
                  <p className="text-2xl text-indigo-900  euclidbold py-3 ">
                    Proposal
                  </p>
                </div>

                <div className="mt-2">
                  <p className="text-lg text-gray-600">
                    Proposal submission is closed for this project.
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default DetailsContractListing;
