import React from "react";
import Layout from "../../../components/Layout/Layout";
import Login from "../../../components/Login/Login";
import { TenantLoginImg } from "../../../assets/images";

const TenantLogin = () => {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Login
          loginRoleText={"Tenants Login with Email ID"}
          requiredRegister={true}
          loginFieldName={"Email ID"}
          loginImage={TenantLoginImg}
        />
      </Layout>
    </div>
  );
};

export default TenantLogin;
