import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import DocumentCard from "../../components/Corporation/Dashboard/Documents/DocumentCard";
import DocumentCardSkeleton from "../../components/Corporation/Dashboard/Documents/DocumentCardSkeleton";
import DocumentModal from "../../components/Corporation/Dashboard/Documents/DocumentModal";
import DocumentReadModal from "../../components/Corporation/Dashboard/Documents/DocumentReadModal";
import { corporationDocumentsAPI } from "../../constants/API";
import axios from "axios";
import Loading from "../../components/Elements/Loading";

const Documents = () => {
  const cookies = new Cookies();
  const [load, setLoad] = useState(false);
  const path = useLocation().pathname;
  const [data, setData] = useState([]);

  const [section, setSection] = useState("Rules");
  const [pageLoading, setPageLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${corporationDocumentsAPI}/resident`, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
        params: {
          Section: path.includes("rules")
            ? 1
            : path.includes("bylaws")
            ? 2
            : path.includes("declaration")
            ? 3
            : path.includes("budget")
            ? 4
            : null,
        },
      });
      setData(response.data.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);

  useEffect(() => {
    if (path.includes("rules")) {
      setSection("Rules");
    } else if (path.includes("bylaws")) {
      setSection("ByLaws");
    } else if (path.includes("declaration")) {
      setSection("Declaration");
    } else if (path.includes("budget")) {
      setSection("Budget");
    }
  }, [path]);

  return (
    <div className="rounded-2xl">
      <Loading load={pageLoading} />

      <div className="w-full  rounded-2xl px-4 m-auto  p-6 flex flex-col justify-center">
        <div className="flex flex-wrap gap-5 justify-between items-center pb-2">
          <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
            {section}
          </h1>
        </div>

        <div className="">
          {load ? (
            <DocumentCardSkeleton />
          ) : data.length > 0 ? (
            data.map((data, index) => (
              <div key={index}>
                <DocumentCard data={data} section={section} />
              </div>
            ))
          ) : (
            <div className="text-secondaryl text-lg py-4 text-center">
              No {section} found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
