import React from "react";

function Location({ className }) {
  return (
    <p className={className}>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 0C3.13425 0 0 3.02231 0 6.75C0 8.21784 0.499042 9.56475 1.32854 10.6678C1.34342 10.6943 1.34575 10.7238 1.36267 10.7491L6.02933 17.4991C6.24575 17.8121 6.61033 18 7 18C7.38967 18 7.75425 17.8121 7.97067 17.4991L12.6373 10.7491C12.6545 10.7238 12.6566 10.6943 12.6715 10.6678C13.501 9.56475 14 8.21784 14 6.75C14 3.02231 10.8657 0 7 0ZM7 9C5.71142 9 4.66667 7.99256 4.66667 6.75C4.66667 5.50744 5.71142 4.5 7 4.5C8.28858 4.5 9.33333 5.50744 9.33333 6.75C9.33333 7.99256 8.28858 9 7 9Z"
          fill="#1D1B55"
        />
      </svg>
    </p>
  );
}

export default Location;
