import React from "react";
import Cookies from "universal-cookie";
import { Navigate } from "react-router-dom";

function ProtectedAdmin({ children }) {
  const cookies = new Cookies();
  let companyType = cookies.get("companyType");
  let token = cookies.get("token");

  if (token && companyType === "admin") {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth"></Navigate>;
  }
}

export default ProtectedAdmin;
