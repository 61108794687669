import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Icon } from "@iconify/react";
import Cookies from "universal-cookie";
//   user={userData}

function OwnersTenantCard({
  user,
  showUserHandler,
  setRejectionId,
  setShowRejectionModal,
  isOwner,
  updateOwners,
  updateTanents,
  deleteOwners,
  deleteTanents,
}) {
  const [userImg, setUserImg] = useState("");
  const cookies = new Cookies();

  useEffect(() => {
    if (user?.Profile_Image) {
      setUserImg(user?.Profile_Image);
    }
  }, []);

  return (
    <div
      className="w-full  flex flex-col lg:flex-row  bg-[#FFFFFF80] rounded p-3 gap-5 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      {/* Card Images  */}
      <div className="h-full relative lg:w-[20%] flex-none bg-cover rounded-md text-center flex justify-center">
        {userImg !== "" ? (
          <img
            src={userImg}
            alt={user.First_Name}
            className="lg:h-36 lg:w-52  rounded-md"
          />
        ) : (
          <div className="h-32 flex items-center"> No Image </div>
        )}
      </div>
      {/* Card Content */}

      <div className="flex-col lg:w-[60%] justify-center leading-normal">
        {" "}
        <div className="space-y-1.5">
          {/* Title */}
          <div className="text-secondaryl euclidbold text-2xl ">
            {user.First_Name + " " + user.Last_Name}
          </div>
          {/* Location */}
          <div className="text-base flex gap-1 items-center">
            <Icon
              icon="carbon:location-filled"
              className="text-secondaryl text-lg"
            />
            <p className="text-infromation">{user.Residential_Address}</p>
          </div>

          {/* <p className="text-gray-500 flex gap-2 items-center">
            <FaLocationDot /> {user.Residential_Address}
          </p> */}
          <div className="text-base flex gap-1 items-center">
            <MdEmail className="text-secondaryl" />{" "}
            <p className="text-infromation">{user.Email}</p>
          </div>
          <div className="text-base flex gap-1 items-center ml-[1px]">
            <FaPhoneAlt className="text-secondaryl text-sm" />{" "}
            <p className="text-infromation"> {user.Phone_No}</p>
          </div>
        </div>
      </div>
      {/* Card Buttons */}
      <div className="flex-col justify-center my-auto relative w-auto lg:w-[20%] space-y-3">
        {cookies.get("access") === "property manager" && (
          <>
            {(user.Status === 1 || user.Status === 2) && (
              <div
                onClick={() => {
                  const confirmation = window.confirm(
                    `Are you sure you want to delete this ${
                      isOwner ? "Owner" : "Tenant"
                    }?`
                  );
                  if (confirmation) {
                    isOwner ? deleteOwners(user.ID) : deleteTanents(user.ID);
                  }
                }}
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                DELETE
              </div>
            )}
            {user.Status === 0 && (
              <div
                onClick={() =>
                  isOwner ? updateOwners(user.ID, 1) : updateTanents(user.ID, 1)
                }
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                APPROVE
              </div>
            )}
            {user.Status === 0 && (
              <div
                onClick={() => {
                  setRejectionId(user.ID);
                  setShowRejectionModal(true);
                }}
                className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
              >
                REJECT
              </div>
            )}
          </>
        )}
        <div
          onClick={() => showUserHandler(user)}
          className="h-11 w-full px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
        >
          VIEW
        </div>
      </div>
    </div>
  );
}

export default OwnersTenantCard;
