import CustomerSatisfaction from "../../pages/admin/CustomerSatisfaction";
import TotalRevenue from "../../pages/admin/TotalRevenue";
import PieChart from "../../pages/admin/PieChart";
import { FaCodePullRequest } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { GrUserWorker } from "react-icons/gr";
import { GrDocument } from "react-icons/gr";
import React from "react";

const Home = ({
  corporationCount,
  contractorCount,
  contractCount,
  enquiryCount,
  corporations,
  contractors,
  contract,
  // enquiry,
}) => {
  return (
    <div className=" overflow-y-auto pb-[30vh] h-[70vh] overflow-auto table-container">
      {/* <h2 className="text-2xl font-bold mb-4">Home</h2> */}

      <div className="flex justify-between gap-2 flex-wrap">
        {/* Card 1 */}
        <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
          <div>
            <GoOrganization className="w-8 h-8" />
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-semibold mb-2">
            Corporations
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-bold">
            {corporationCount}
          </div>
        </div>

        {/* Card 2 */}
        <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
          <div>
            <GrUserWorker className="w-8 h-8" />
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-semibold mb-2">
            Contractors
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-bold">
            {contractorCount}
          </div>
        </div>

        {/* Card 3 */}
        <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
          <div>
            <GrDocument className="w-8 h-8" />
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-semibold mb-2">
            Contracts
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-bold">
            {contractCount}
          </div>
        </div>

        {/* Card 4 */}
        <div className="flex-1 h-8  md:h-16 flex gap-4 items-center shadow-lg  text-black border-l-4 border-cyan-500 p-4 rounded-md hover:shadow-xl">
          <div>
            <FaCodePullRequest className="w-8 h-8" />
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-semibold mb-2">
            Enquiry
          </div>
          <div className="text-sm sm:text-xl md:text-xl font-bold">
            {enquiryCount}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        <TotalRevenue corporations={corporations} contractors={contractors} />
        <PieChart contract={contract} />
        <CustomerSatisfaction contract={contract} />
      </div>

      <div className="">
        <div className="flex justify-between items-center w-full">
          <div className="w-full  mt-4">
            <h3 className="text-lg font-semibold mb-2">Last 5 Corporations</h3>
            <ul className="w-full overflow-hidden table-container bg-white">
              {corporations
                ?.slice(corporationCount - 5, corporationCount - 0)
                .reverse()
                .map((corporation, index) => (
                  <li
                    key={index}
                    className="bg-white rounded-md p-2 my-4 shadow-lg hover:shadow-xl  flex gap-4 flex-wrap"
                  >
                    <div>
                      <span className="font-bold">Corporation Name</span> -
                      {corporation?.Corporation_Name}
                    </div>
                    <div>
                      <span className="font-bold">Employed By Condo</span> -
                      {corporation?.Employed_By_Condo ? "Yes" : "No"}
                    </div>
                    <div>
                      <span className="font-bold">Property Managers</span> -
                      {corporation?.cp_propertymanagers?.length}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="flex justify-between items-center w-full">
          {/* Last 5 Contractors */}
          <div className="w-full mt-4">
            <h3 className="text-lg font-semibold ">Last 5 Contractors</h3>
            <ul className="w-full overflow-hidden ">
              {contractors
                .slice(contractors.length - 5, contractors.length - 0)
                .reverse()
                .map((contractor, index) => (
                  <li
                    key={index}
                    className="bg-white my-4 shadow-lg hover:shadow-xl flex gap-2 flex-wrap rounded-md p-2 "
                  >
                    <div>
                      <span className="font-bold">Company Name</span> -
                      {contractor?.Company_Name}
                    </div>
                    <div>
                      <span className="font-bold">Contractor Name</span> -
                      {contractor?.First_Name} {contractor?.Last_Name}
                    </div>
                    <div>
                      <span className="font-bold">Phone No.</span> -
                      {contractor?.Phone_No}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
