import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import DisplayService from "../../Service/DisplayService";
import {
  serviceCorporationApi,
  serviceRequestOwnersAPI,
  serviceRequestTenantAPI,
} from "../../../constants/API";
import { debounce } from "../../../utils/helper";

function Service({
  selectMonth,
  selectDay,
  selectYear,
  selectStatus,
  selectService,
}) {
  const [load, setLoad] = useState(false);
  const cookies = new Cookies();
  const [services, setServices] = useState([]);
  const [displayServices, setDisplayServices] = useState([]);
  const [IsOwners, setIsOwners] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (!services.length > 0) {
        setLoad(true);
      }
      let apiUrl = "";
      if (cookies.get("companyType") === "owners") {
        setIsOwners(true);
        apiUrl = `${serviceRequestOwnersAPI}/owners`;
      } else if (cookies.get("companyType") === "tenant") {
        setIsOwners(false);
        apiUrl = `${serviceRequestTenantAPI}/tenant`;
      } else if (cookies.get("companyType") === "corporation") {
        apiUrl = `${serviceCorporationApi}/requests`;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
        params: {
          page,
          limit: 5,
        },
      });

      let data = response.data.data;
      setTotalPages(response?.data?.totalPages);

      if (cookies.get("access") === "security member") {
        data = data.filter((item) => item.Status === 1);
      }

      setServices((prevData) => [...prevData, ...data]);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoad(false);
      setTimeout(() => {
        setLoading(false); // Stop loading spinner
      }, 1000);
    }
  };

  const filterData = () => {
    // Panding for filter
    const filteredServices = services.filter(
      (service) =>
        (selectService ? service.Service_ID === selectService.value : true) &&
        (selectStatus && selectStatus?.value !== ""
          ? service.Status === selectStatus.value
          : true) &&
        (selectYear
          ? new Date(service.Book_From).getFullYear() === selectYear.value
          : true) &&
        (selectMonth
          ? new Date(service.Book_From).getMonth() + 1 === selectMonth.value
          : true) &&
        (selectDay
          ? new Date(service.Book_From).getDate() === selectDay.value
          : true)
    );
    setDisplayServices(filteredServices);
  };

  // const filterData = () => {
  //   const filteredServices = services.filter((service) => {
  //     return (
  //       (!selectService || service.Service_ID === selectService.value) &&
  //       (!selectStatus ||
  //         selectStatus.value === "" ||
  //         service.Status === selectStatus.value) &&
  //       (!selectYear ||
  //         new Date(service.Book_From).getFullYear() === selectYear.value) &&
  //       (!selectMonth ||
  //         new Date(service.Book_From).getMonth() + 1 === selectMonth.value) &&
  //       (!selectDay ||
  //         new Date(service.Book_From).getDate() === selectDay.value)
  //     );
  //   });
  //   setDisplayServices(filteredServices);
  // };

  useEffect(() => {
    filterData();
  }, [
    selectDay,
    selectMonth,
    selectYear,
    selectService,
    selectStatus,
    services,
  ]);

  useEffect(() => {
    const fetchDebouncedData = debounce(fetchData, 500);
    fetchDebouncedData();
  }, [page]);

  // Function to handle scroll event in the container
  const handleContainerScroll = debounce(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        // Load next page when the container reaches the bottom
        if (page < totalPages) {
          setPage(page + 1);
        }
      }
    }
  }, 200);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleContainerScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleContainerScroll);
      }
    };
  }, [page, totalPages]);

  return (
    <DisplayService
      title="Service Request"
      services={displayServices}
      load={load}
      loading={loading}
      fetchData={fetchData}
      containerRef={containerRef}
      setServices={setServices}
      setLoad={setLoad}
    />
  );
}

export default Service;
