import React from "react";
import { Toaster } from "react-hot-toast";
import DashboardSidebar from "../../../components/Owners/Dashboard/Layout/DashboardSidebar";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../../components/Layout/DashboardHeader";

const OwnersDashboardLayout = () => {
  return (
    <div className="flex flex-row min-h-screen">
      <Toaster />
      {/* <Loading load={load} /> */}
      {/* Sidebar  */}
      <DashboardSidebar />
      {/* Main Content */}
      <main className="flex-1 flex flex-col min-h-0 p-3 overflow-hidden max-h-full">
        <DashboardHeader />
        <div className="bg-gradient-to-b h-full from-[#282a73]/15 to-[#0094bf]/15 rounded-2xl relative">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default OwnersDashboardLayout;
