import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../../../../constants/API";
import Cookies from "universal-cookie";
import { useLoading } from "../../../../context/LoadingContext";

const UpdateEnquiryModal = ({
  isOpen,
  onClose,
  updateEnquiry,
  selectedEnquiry,
}) => {
  const [enquirySummary, setEnquirySummary] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedEnquiry?.Status === null || selectedEnquiry?.Status === 0) {
      setSelectedOption(null);
    } else if (selectedEnquiry?.Status === 1) {
      setSelectedOption(2);
    } else {
      setSelectedOption(1);
    }
  }, [selectedEnquiry?.Status]);
  const handleResolveClick = () => {
    setSelectedOption(1);
    setEnquirySummary(""); // Reset enquiry summary when resolving
  };

  const handleInProgressClick = () => {
    setSelectedOption(2);
    updateEnquiry("", 2);
    onClose(); // Close modal when moving to in progress
    setSelectedOption(null);
  };

  const handleSubmit = () => {
    if (!enquirySummary || selectedOption === null) return;
    updateEnquiry(enquirySummary, selectedOption);
    setEnquirySummary("");
    onClose();
  };

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center`}
    >
      <div className="bg-white w-4/5 rounded-lg p-8">
        <h2 className="text-xl font-bold mb-4">Update Enquiry</h2>
        {selectedOption === null ? (
          <>
            <button
              className="flex-1 px-4 py-2 rounded-md bg-green-500 text-white"
              onClick={handleResolveClick}
            >
              Resolve Enquiry
            </button>
            <button
              className="flex-1 px-4 py-2 rounded-md bg-orange-500 text-white"
              onClick={handleInProgressClick}
            >
              Move to In Progress
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
              onClick={onClose}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            {selectedOption === 1 && (
              <textarea
                type="text"
                placeholder="Enquiry Summary"
                className="border border-gray-300 rounded-md p-2 mb-4 w-full"
                value={enquirySummary}
                onChange={(e) => setEnquirySummary(e.target.value)}
              />
            )}

            {selectedOption === 2 ? (
              <>
                <span className="text-green-500"> Already Resolved Query</span>
                <button
                  className="bg-gray-300 ml-4 text-gray-800 px-4 py-2 rounded-md"
                  onClick={onClose}
                >
                  Close
                </button>
              </>
            ) : (
              <div className="flex justify-end">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ManageEnquiry = () => {
  const { setLoad } = useLoading();
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [allenquiries, setAllenquiries] = useState([]);
  const [filteredEnquiry, setFilteredEnquiry] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cookies = new Cookies();

  const fetchEnquiries = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${baseUrl}/contactus`);
      // console.log(response);
      setAllenquiries(response.data.data);
      setFilteredEnquiry(response.data.data);
    } catch (error) {
      console.error("Error fetching enquiries:", error);
    } finally {
      setLoad(false);
    }
  };

  const updateEnquiry = async (enquirySummary, status) => {
    setLoad(true);
    try {
      const response = await axios.put(
        `${baseUrl}/contactus/resolve/${selectedEnquiry.ID}`,
        {
          Status: status,
          EnquirySummary: enquirySummary,
        },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        }
      );
      fetchEnquiries();
      // console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  const handleUpdateClick = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setIsModalOpen(true);
  };

  const pendingEnquiries = allenquiries?.filter(
    (enquiry) => enquiry.Status === null || enquiry.Status === 0
  );
  const inProgressEnquiries = allenquiries?.filter(
    (enquiry) => enquiry.Status === 2
  );
  const resolvedEnquiries = allenquiries?.filter(
    (enquiry) => enquiry.Status === 1
  );

  useEffect(() => {
    fetchEnquiries();
  }, []);

  return (
    <div>
      <div className="w-full rounded-2xl px-4 m-auto p-6 flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          Manage Enquiry
        </h1>
        <div className="flex flex-col my-4">
          <div className="flex justify-center">
            <div className="overflow-auto max-h-[60vh] w-full table-container">
              <div className="mb-4 flex gap-4">
                <button
                  onClick={() => setFilteredEnquiry([...allenquiries])}
                  className="flex-1 px-4 py-2 rounded-md bg-gray-300 text-gray-800"
                >
                  All Enquiries
                </button>
                <button
                  onClick={() => setFilteredEnquiry(pendingEnquiries)}
                  className="flex-1 px-4 py-2 rounded-md bg-gray-300 text-gray-800"
                >
                  Pending Enquiries
                </button>
                <button
                  onClick={() => setFilteredEnquiry(inProgressEnquiries)}
                  className="flex-1 px-4 py-2 rounded-md bg-gray-300 text-gray-800"
                >
                  In Progress Enquiries
                </button>
                <button
                  onClick={() => setFilteredEnquiry(resolvedEnquiries)}
                  className="flex-1 px-4 py-2 rounded-md bg-gray-300 text-gray-800"
                >
                  Resolved Enquiries
                </button>
              </div>

              <div className="flex justify-center">
                <div className="overflow-x-auto max-h-[60vh] max-w-full table-container relative">
                  <table className="table-auto w-full relative">
                    <thead className="relative">
                      <tr>
                        <th className="px-4 py-2 text-sm">Name</th>
                        <th className="px-4 py-2 text-sm">Subject</th>
                        <th className="px-4 py-2 text-sm">Phone Number</th>
                        <th className="px-4 py-2 text-sm">Message</th>
                        <th className="px-4 py-2 text-sm">Status</th>
                        <th className="px-4 py-2 text-sm">Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEnquiry.length > 0 &&
                        filteredEnquiry.map((enquiry) => (
                          <tr key={enquiry.ID}>
                            <td className="border px-4 py-2">
                              {enquiry.First_Name} {enquiry.Last_Name}
                            </td>
                            <td className="border px-4 py-2">
                              {enquiry.Subject}
                            </td>
                            <td className="border px-4 py-2">
                              {enquiry.Phone_No}
                            </td>
                            <td className="border px-4 py-2">
                              {enquiry.Message}
                            </td>
                            <td className="border px-4 py-2">
                              {enquiry?.Status === null ||
                              enquiry?.Status === 0 ? (
                                <div className="flex items-center whitespace-nowrap text-blue-500">
                                  Pending
                                </div>
                              ) : enquiry?.Status === 1 ? (
                                <div className="flex items-center whitespace-nowrap text-green-500">
                                  Resolved
                                </div>
                              ) : (
                                <div className="flex items-center whitespace-nowrap text-orange-500">
                                  In Progress
                                </div>
                              )}
                            </td>
                            <td className="border px-4 py-2">
                              <FaEdit
                                className="text-cyan-500 cursor-pointer"
                                onClick={() => handleUpdateClick(enquiry)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UpdateEnquiryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          updateEnquiry={(enquirySummary, status) => {
            updateEnquiry(enquirySummary, status);
          }}
          selectedEnquiry={selectedEnquiry}
        />
      </div>
    </div>
  );
};

export { ManageEnquiry };
