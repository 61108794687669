import React, { useEffect, useState } from "react";

import Loading from "../../../../components/Elements/Loading.jsx";
import { useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import axios from "axios";
import {
  contractorProfileImagesAPI,
  saveContractorProfile,
  uploadBusinessCard,
} from "../../../../constants/API.js";
import {
  ApprovedIcon,
  InputFileIcon,
  Spinner,
} from "../../../../assets/images/index.jsx";
import toast from "react-hot-toast";
import InputBox from "../../../../components/Elements/InputBox.jsx";
import CheckBoxDropDown from "../../../../components/Elements/CheckBoxDropDown.jsx";
import {
  getCityArea,
  getContractorProfileData,
  getServies,
  viewFileHandler,
} from "../../../../constants/Request.jsx";
import InputBoxLive from "../../../../components/Elements/InputBoxLive.jsx";
import InputBoxTimeLive from "../../../../components/Elements/InputBoxTimeLive.jsx";
import { ImNewTab } from "react-icons/im";
import { TiBusinessCard } from "react-icons/ti";
import { RiFileUserFill } from "react-icons/ri";
import ReadInputBox1 from "../../../../components/Elements/ReadInputBox1.jsx";

function ContractorProfile() {
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const [selectServices, setSelectServices] = useState([]);
  const [selectArea, setSelectArea] = useState([]);
  const token = cookies.get("token");

  // image upload handling constants
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; //   5MB
  const SUPPORTED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/jpg",
  ]; // Example supported file types

  const FILE_SIZE_LIMIT_PROFILE_PHOTO = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FILE_TYPES_PROFILE_PHOTO = [
    "image/jpeg",
    "image/png",
    "image/jpg",
  ]; // Example supported file types
  const [isEditableCompanyFaxNo, setIsEditableCompanyFaxNo] = useState(true);

  const [businessCard, setBusinessCard] = useState(null);
  const [businessCardError, setBusinessCardError] = useState("");
  const [businessCardUploded, setBusinessCardUploded] = useState("");
  const [businessCardURL, setBusinessCardURL] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingBusinessCard, setUploadingBusinessCard] = useState(false);
  const [uploadingProfilePhoto, setUploadingProfilePhoto] = useState(false);
  const [uploadingInsuranceCertificate, setUploadingInsuranceCertificate] =
    useState(false);

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoError, setProfilePhotoError] = useState("");
  const [profilePhotoUploded, setProfilePhotoUploded] = useState("");
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const [displayProfilePhoto, setDisplayProfilePhoto] = useState(null);
  const [displayBusinessCard, setDisplayBusinessCard] = useState(null);
  const [displayInsuranceCertificate, setDisplayInsuranceCertificate] =
    useState(null);

  const [insuranceCertificate, setInsuranceCertificate] = useState(null);
  const [insuranceCertificateError, setInsuranceCertificateError] =
    useState("");
  const [insuranceCertificateUploded, setInsuranceCertificateUploded] =
    useState("");
  const [insuranceCertificateURL, setInsuranceCertificateURL] = useState("");

  // Form validation schema using yup
  const schema = yup.object().shape({
    First_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Property name can only contain letters.")
      .required("First Name is required"),
    Last_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Last name can only contain letters.")
      .required("LastName is required"),
    Recovery_Email: yup
      .string()
      .required("Recovery Email is required")
      .test("valid-recoveryEmail", "Invalid recovery email", (value) => {
        if (!value) return true; // Skip validation if the field is empty
        // Check if the value matches the pattern of an email
        return /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
      })
      .test(
        "not-same-as-uidOrEmail",
        "Recovery email cannot be the same as user email id",
        function (value) {
          const uidOrEmail = this.parent.uidOrEmail;
          // If either of the fields is empty, skip the validation
          if (!value || !uidOrEmail) return true;
          return value !== uidOrEmail;
        }
      ),
    Phone_No: yup
      .string()
      .required("Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Phone No must be exactly 10 digits"
      ),
    Company_Name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Company name can only contain letters.")
      .required("Company Name is required"),
    Company_Phone_No: yup
      .string()
      .required("Company Phone No is required")
      .matches(
        /^[0-9]{10}$/, // Regular expression for 10-digit phone number
        "Company Phone No must be exactly 10 digits"
      ),
    Company_Address: yup.string().required("Company Address is required"),
    Designation: yup.string(),
    Working_Hours_From: yup
      .string()
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format")
      .required("Start time is required"),

    Working_Hours_To: yup
      .string()
      .required("Working Hours To is required")
      .test(
        "is-not-same",
        "Working Hours To must be different from Working Hours From",
        function (value) {
          const workingHoursFrom = this.parent.Working_Hours_From;
          return value !== workingHoursFrom;
        }
      )
      .test(
        "greater-than-from",
        "To time must be greater than From time",
        function (value) {
          const workingHoursFrom = this.parent.Working_Hours_From;
          return value > workingHoursFrom;
        }
      ),
    Company_Fax_No: yup
      .string()
      .test("vaild-faxNo", "Invalid Fax No", function (value) {
        if (!value) return true; // Skip validation if the field is empty
        return /^[0-9]{10}$/.test(value);
      }),
    Insurance: yup.string(),
    Insurance_Certificate_URL: yup.string().nullable(),
    Service: yup.array().min(1, "Service Provided is requried"),
    Other_Service: yup.string(),
    Company_Business_Card: yup.string().nullable(),
    // Term_And_Policy: yup.boolean().oneOf([true], "This field must be checked"),
    Area: yup.array(),
    Profile_Photo: yup.string().nullable(),
  });

  // Designation dropdown options
  const [servicesOptions, setServicesOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [otherServiceOptionId, setOtherServiceOptionId] = useState(null);
  const [isOpenOtherService, setIsOpenOtherService] = useState(false);

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });

  // console.log("erros", errors);
  // console.log(getValues());

  useEffect(() => {
    if (selectServices.includes(otherServiceOptionId)) {
      setIsOpenOtherService(true);
    }
  }, [selectServices, otherServiceOptionId]);

  useEffect(() => {
    const getcontractordata = async () => {
      setLoad(true);
      try {
        // Fetch services
        const servicesData = await getServies();
        setServicesOptions(servicesData);
        const other = servicesData?.find((item) => item.Name === "Other");
        setOtherServiceOptionId(other?.ID);

        // Fetch city areas
        const cityAreasData = await getCityArea();
        setAreaOptions(cityAreasData);

        // Fetch contractor profile data
        const data = await getContractorProfileData(setLoad, token);
        if (data) {
          Object.keys(data).forEach((key) => {
            setValue(key, data[key]);
          });
          const areaids = data.Contractor_CityAreas.map((area, index) => {
            return area.City_Area_ID;
          });
          const serviceids = data.Contractor_Services.map((service) => {
            return service.Service_ID;
          });
          setSelectServices(serviceids);
          setSelectArea(areaids);

          if (data.Company_Business_Card) {
            const imgurl = await viewFileHandler(
              data.Company_Business_Card,
              "contractor"
            );
            setBusinessCardURL(imgurl);
          }

          if (data.Profile_Photo) {
            setProfilePhotoURL(data.Profile_Photo);
          }

          if (data.Insurance_Certificate_URL) {
            setLoad(true);

            const imgurl = await viewFileHandler(
              data.Insurance_Certificate_URL,
              "contractor"
            );
            setInsuranceCertificateURL(imgurl);
          }

          if (data.Company_Fax_No) {
            setIsEditableCompanyFaxNo(false);
          }
        }
      } catch (error) {
        toast.error("Failed to fetch Contractor data. Please try again later.");
      } finally {
        setLoad(false);
      }
    };
    getcontractordata();
  }, [register]);

  // Handle Contractor SignUp
  const onContractorProfileSave = async (data, e) => {
    // console.log("Data", data);
    e.preventDefault();
    if (profilePhotoURL) {
      setValue("Profile_Photo", profilePhotoURL); // set profile photo url to profilePhoto field
    }

    data.UpdatedDate = new Date();

    try {
      // contractorAPI use
      setLoad(true);
      const response = await axios.put(saveContractorProfile, data, {
        headers: {
          authorization: cookies.get("token"),
          companyType: "contractor",
        },
      });
      toast.success(response.data.message, { duration: 5000 });
      // reset();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "Failed to register contractor. Please check your input and try again later."
        );
      }
    } finally {
      setLoad(false);
    }
  };

  // Handle Business Card Upload Local
  const handleChangeBusinessCard = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setBusinessCard(null);
      setBusinessCardError("");
      setBusinessCardUploded("");
      setBusinessCardURL("");
      clearErrors("companyBusinessCard");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setBusinessCard(file);
        setBusinessCardUploded("");
        setBusinessCardError("");
        setBusinessCardURL("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setBusinessCard(null);
        setBusinessCardError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setBusinessCardUploded("");
        setBusinessCardURL("");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setBusinessCard(null);
        setBusinessCardError("File size exceeds limit (5 MB)");
        setBusinessCardUploded("");
        setBusinessCardURL("");
      } else {
        setBusinessCard(null);
        setBusinessCardError("Something went wrong! Please try again.");
        setBusinessCardUploded("");
        setBusinessCardURL("");
      }
    }
  };

  const handleChangeProfilePhoto = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setProfilePhoto(null);
      setProfilePhotoError("");
      setProfilePhotoUploded("");
      setProfilePhotoURL("");
      clearErrors("profilePhoto");
      setDisplayProfilePhoto(null);
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT_PROFILE_PHOTO &&
        SUPPORTED_FILE_TYPES_PROFILE_PHOTO.includes(file.type)
      ) {
        setProfilePhoto(file);
        setProfilePhotoUploded("");
        setProfilePhotoError("");
        setProfilePhotoURL("");
        setDisplayProfilePhoto(URL.createObjectURL(file));
      } else if (
        file &&
        !SUPPORTED_FILE_TYPES_PROFILE_PHOTO.includes(file.type)
      ) {
        setProfilePhoto(null);
        setProfilePhotoError(
          "Unsupported file type file type must be jpg , jpeg or png"
        );
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        setDisplayProfilePhoto(null);
      } else if (file && !file.size <= FILE_SIZE_LIMIT_PROFILE_PHOTO) {
        setProfilePhoto(null);
        setProfilePhotoError("File size exceeds limit (5 MB)");
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        setDisplayProfilePhoto(null);
      } else {
        setProfilePhoto(null);
        setProfilePhotoError("Something went wrong! Please try again.");
        setProfilePhotoUploded("");
        setProfilePhotoURL("");
        setDisplayProfilePhoto(null);
      }
    }
  }; // Empty dependency array means the function will not change after the initial render

  const handleChangeInsuranceCertificate = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setInsuranceCertificate(null);
      setInsuranceCertificateError("");
      setInsuranceCertificateUploded("");
      setInsuranceCertificateURL("");
      clearErrors("insuranceCertificate");
    } else {
      if (
        file &&
        file.size <= FILE_SIZE_LIMIT &&
        SUPPORTED_FILE_TYPES.includes(file.type)
      ) {
        setInsuranceCertificate(file);
        setInsuranceCertificateUploded("");
        setInsuranceCertificateError("");
        setInsuranceCertificateURL("");
      } else if (file && !SUPPORTED_FILE_TYPES.includes(file.type)) {
        setInsuranceCertificate(null);
        setInsuranceCertificateError(
          "Unsupported file type file type must be jpg , jpeg, png or pdf"
        );
        setInsuranceCertificateUploded("");
        setInsuranceCertificateURL("");
      } else if (file && !file.size <= FILE_SIZE_LIMIT) {
        setInsuranceCertificate(null);
        setInsuranceCertificateError("File size exceeds limit (5 MB)");
        setInsuranceCertificateUploded("");
        setInsuranceCertificateURL("");
      } else {
        setInsuranceCertificate(null);
        setInsuranceCertificateError("Something went wrong! Please try again.");
        setInsuranceCertificateUploded("");
        setInsuranceCertificateURL("");
      }
    }
  };

  // Handle Business Card Upload API
  const handleUploadBusinessCard = async () => {
    // console.log("Business Card", businessCard);
    if (!businessCard) {
      setBusinessCardError("Please Select a Company Business Card to Upload");
      setBusinessCardUploded("");
      return;
    }

    const formData = new FormData();
    formData.append("file", businessCard);

    try {
      setBusinessCardError("");
      setUploadingFile(true);
      setUploadingBusinessCard(true);

      const response = await axios.post(uploadBusinessCard, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setBusinessCardError("");
      const display = await viewFileHandler(
        response.data.data.url,
        "contractor"
      );
      setBusinessCardURL(display);
      setBusinessCardError("");
      setBusinessCardUploded("Uploaded Successfully");
      setValue("Company_Business_Card", response.data.data.url);
    } catch (error) {
      console.error("Error uploading image:", error);
      setBusinessCardError("Failed to upload image");
      setBusinessCardUploded("");
      setBusinessCardURL("");
      setBusinessCardUploded("");
    } finally {
      setUploadingFile(false);
      setUploadingBusinessCard(false);
    }
  };

  const handleUploadProfilePhoto = async () => {
    if (!profilePhoto) {
      setProfilePhotoError("Please Select a Profile Photo to Upload");
      setProfilePhotoUploded("");
      return;
    }

    const formData = new FormData();
    formData.append("file", profilePhoto);
    try {
      setProfilePhotoError("");
      setUploadingProfilePhoto(true);
      setUploadingFile(true);

      const response = await axios.post(contractorProfileImagesAPI, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setProfilePhotoError("");
      setProfilePhotoURL(response.data.data.url);
      setProfilePhotoError("");
      setProfilePhotoUploded("Uploaded Successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      setProfilePhotoError("Failed to upload image");
      setProfilePhotoUploded("");
      setProfilePhotoURL("");
      setProfilePhotoUploded("");
    } finally {
      setUploadingProfilePhoto(false);
      setUploadingFile(false);
    }
  };

  const handleUploadInsuranceCertificate = async () => {
    if (!insuranceCertificate) {
      setInsuranceCertificateError(
        "Please Select a InsuranceCertificate to Upload"
      );
      setInsuranceCertificateUploded("");
      return;
    }

    const formData = new FormData();
    formData.append("file", insuranceCertificate);

    try {
      setInsuranceCertificateError("");
      setUploadingFile(true);
      setUploadingInsuranceCertificate(true);

      const response = await axios.post(uploadBusinessCard, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setInsuranceCertificateError("");
      const display = await viewFileHandler(
        response.data.data.url,
        "contractor"
      );
      setInsuranceCertificateURL(display);
      setInsuranceCertificateError("");
      setInsuranceCertificateUploded("Uploaded Successfully");
      setValue("Insurance_Certificate_URL", response.data.data.url);
    } catch (error) {
      console.error("Error uploading image:", error);
      setInsuranceCertificateError("Failed to upload image");
      setInsuranceCertificateUploded("");
      setInsuranceCertificateURL("");
      setInsuranceCertificateUploded("");
    } finally {
      setUploadingFile(false);
      setUploadingInsuranceCertificate(false);
    }
  };

  // Clear Business Card Error
  const businessCardClearError = () => {
    setBusinessCardError("");
  };

  const insuranceCertificateClearError = () => {
    setInsuranceCertificateError("");
  };

  return (
    <div className="rounded-2xl">
      <Loading load={load} />
      <div className="w-full px-4 m-auto p-6 rounded-2xl flex flex-col justify-center">
        <h1 className="text-secondaryl font-extrabold text-3xl euclidbold ">
          My Profile
        </h1>

        <form
          onSubmit={handleSubmit(onContractorProfileSave)}
          encType="multipart/form-data"
          className="w-full md:w-[90%] lg:w-[85%] mt-8"
          // className="w-full mt-8"
        >
          {/* First Name and Last Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
            <InputBox
              title={"First Name"}
              register={register}
              filedName={"First_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />

            <InputBox
              title={"Last Name"}
              register={register}
              filedName={"Last_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/*  Email And Phone No */}
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
            <InputBoxLive
              title={"Recovery Email"}
              register={register}
              filedName={"Recovery_Email"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
              schema={schema}
              setError={setError}
            />

            <InputBoxLive
              title={"Phone No"}
              register={register}
              filedName={"Phone_No"}
              must={true}
              type="tel"
              errors={errors}
              className={"md:text-sm lg:text-base"}
              maxLength="10"
              schema={schema}
              setError={setError}
            />
          </div>

          {/* Company Name and Company Phone No */}
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 ">
            <ReadInputBox1
              title={"Company Name"}
              register={register}
              filedName={"Company_Name"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
            <ReadInputBox1
              title={" Company Phone No"}
              register={register}
              filedName={"Company_Phone_No"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/* Company Address */}
          <div className="grid grid-cols-1 ">
            <ReadInputBox1
              title={"Company Address"}
              register={register}
              filedName={"Company_Address"}
              must={true}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />
          </div>

          {/* Designation and Working Hours*/}
          <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-16  ">
            {/* Designation */}
            <InputBox
              title={"Designation"}
              register={register}
              filedName={"Designation"}
              must={false}
              errors={errors}
              className={"md:text-sm lg:text-base"}
            />

            <div className="mb-3">
              {/* Label */}
              <label className="block font-normal text-secondaryl">
                Working Hours
                <sup className="text-red-500 text-base">*</sup>
              </label>
              {/* Input */}
              <div className="flex flex-col w-full md:flex-row gap-5">
                <div className="w-full">
                  <InputBoxTimeLive
                    title={"From"}
                    register={register}
                    filedName={"Working_Hours_From"}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    schema={schema}
                    setError={setError}
                  />
                </div>
                <div className="w-full">
                  <InputBoxTimeLive
                    title={"To"}
                    register={register}
                    filedName={"Working_Hours_To"}
                    errors={errors}
                    className={"md:text-sm lg:text-base"}
                    schema={schema}
                    setError={setError}
                  />
                </div>
              </div>

              {/* Error */}
            </div>
          </div>

          {/* Company Fax No and Insurance */}
          <div className="grid grid-cols-1 lg:mb-4 md:grid-cols-1 lg:grid-cols-2 lg:gap-16">
            {isEditableCompanyFaxNo ? (
              <InputBoxLive
                title={"Company Fax No"}
                register={register}
                filedName={"Company_Fax_No"}
                errors={errors}
                className={"md:text-sm lg:text-base"}
                schema={schema}
                setError={setError}
                type="tel"
                maxLength="10"
              />
            ) : (
              <ReadInputBox1
                title={"Company Fax No"}
                register={register}
                filedName={"Company_Fax_No"}
                errors={errors}
                className={"md:text-sm lg:text-base"}
              />
            )}

            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* InputBox for Insurance */}
              <div className="col-span-1">
                <InputBox
                  title={"Insurance"}
                  register={register}
                  filedName={"Insurance"}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  requiredError={false}
                />
              </div>

              {/* File input and Upload button */}
              <div className="col-span-2 mt-4 lg:flex items-center">
                <div className="px-3 sm:-mt-3  lg:mt-0 sm:mb-3  md:mb-3 lg:mb-0 text-secondaryl ">
                  OR
                </div>
                {/* Business Card File Input */}
                <div className="">
                  <div className="flex gap-3">
                    <div className="">
                      <input
                        type="file"
                        id="Insurance_Certificate_URL"
                        name="Insurance_Certificate_URL"
                        className="hidden"
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={handleChangeInsuranceCertificate}
                        // disabled={uploadingFile}
                      />
                      {/* Label For style is input tag and use in File input */}
                      <label
                        htmlFor="Insurance_Certificate_URL"
                        className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                          uploadingFile
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        style={{
                          boxShadow: "0px 4px 4px 0px #FFFFFF1A inset",
                        }}
                      >
                        <div className="flex items-center h-full space-x-1 overflow-hidden">
                          <InputFileIcon />
                          {insuranceCertificate ? (
                            <span className="line-clamp-1">
                              <span>{insuranceCertificate.name}</span>
                            </span>
                          ) : (
                            <span className="text-infromation text-opacity-50 text-xs">
                              .jpg, .png, .jpeg, files only
                            </span>
                          )}
                        </div>
                      </label>
                    </div>

                    {/* Upload Button */}

                    <div className="">
                      {insuranceCertificateURL ? (
                        <div className="h-11 w-fit px-2 py-2.5 euclidbold items-center  cursor-not-allowed ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate  tracking-widest   md:mt-0 ">
                          UPLOADED
                        </div>
                      ) : (
                        <div
                          onClick={handleUploadInsuranceCertificate}
                          className={`h-11 w-fit px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap
                            ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                          disabled={uploadingFile}
                        >
                          UPLOAD
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 mb-3 lg:mb-0 md:grid-cols-1 lg:grid-cols-2  ">
            {/* <!-- First grid section without space --> */}
            <div className=""></div>

            {/* <!-- Second grid section with space --> */}
            <div className="grid grid-cols-1  lg:grid-cols-3 lg:-mr-52 ">
              <div></div>
              <div className="lg:-mt-12 lg:justify-end flex">
                {uploadingInsuranceCertificate && (
                  <div className="lg:justify-start flex pt-2">
                    <Spinner />
                  </div>
                )}

                {insuranceCertificateURL && (
                  <NavLink
                    className="text-white p-1 mt-4 rounded-sm bg-secondaryl hover:bg-opacity-90 truncate flex justify-center items-center"
                    to={insuranceCertificateURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Click Here
                  </NavLink>
                )}

                {insuranceCertificateError && (
                  <p className="text-red-500 text-xs leading-none mt-2">
                    {insuranceCertificateError}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Service Provided Checkbox */}
          <div className="">
            <div className="mb-3">
              {/* Label */}
              <label
                className={`block font-normal text-secondaryl
                  }`}
              >
                Service Provided{" "}
                <span className="text-red-500 text-base">*</span>
              </label>
              {/* Input */}
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16">
                <div className="">
                  <CheckBoxDropDown
                    title={"Service Provided"}
                    options={servicesOptions}
                    onSelect={setSelectServices}
                    selectOption={selectServices}
                    setValue={setValue}
                    filedName={"Service"}
                    clearErrors={clearErrors}
                    errors={errors}
                    setError={setError}
                    dbfield={"Name"}
                    schema={schema}
                    must={true}
                    isOther={true}
                    otherOptionId={otherServiceOptionId}
                    isOpenOther={isOpenOtherService}
                    setIsOpenOther={setIsOpenOtherService}
                  />
                </div>

                {/* If Display A Service Data text box view comment box open */}

                <div className="mb-3">
                  <input
                    type="text"
                    id="servicesDisplay"
                    name="servicesDisplay"
                    className={`block w-full  h-11   rounded-sm text-infromation    px-2  ps-10  bg-[#FFFFFF80]   focus:outline-none `}
                    placeholder="Display Selected Services"
                    value={
                      servicesOptions
                        ?.filter((item) => selectServices?.includes(item.ID))
                        .map((item) => item.Name)
                        .join(", ") || ""
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Service Provided */}
          {isOpenOtherService && (
            <div className="grid grid-cols-1 w-full transition-all">
              <InputBox
                title={"Other Service Provided"}
                register={register}
                filedName={"Other_Service"}
                errors={errors}
                must={true}
                placeHolder={"Type Other Service Sperated by Comma(,)"}
                className={"md:text-sm lg:text-base"}
              />
            </div>
          )}

          {/* Wroking Area */}
          <div className="">
            <div className="mb-3">
              {/* Label */}
              <label
                className={`block font-normal  
              text-secondaryl    }`}
              >
                Working Area
              </label>
              {/* Input */}
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16">
                <div className="">
                  <CheckBoxDropDown
                    title={"Select Area / City"}
                    options={areaOptions}
                    onSelect={setSelectArea}
                    selectOption={selectArea}
                    setValue={setValue}
                    filedName={"Area"}
                    clearErrors={clearErrors}
                    errors={errors}
                    dbfield={"Name"}
                    setError={setError}
                    schema={schema}
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    id="areaDisplay"
                    name="areaDisplay"
                    className={`block w-full  h-11   rounded-sm text-infromation   px-2  ps-10  bg-[#FFFFFF80]   focus:outline-none `}
                    placeholder="Display Selected Area/City"
                    value={
                      areaOptions
                        ?.filter((item) => selectArea?.includes(item.ID))
                        .map((item) => item.Name)
                        .join(", ") || ""
                    }
                    readOnly
                  />

                  <div className="h-[2vh] pt-1"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Company Business Card and Profile Photo */}
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16">
            <div className="mb-3 ">
              {/* Label */}
              <label
                className={`block font-normal text-secondaryl  text-base `}
              >
                Company Business Card
              </label>

              {/* Input */}
              <div className="flex w-full gap-5">
                <div className="w-full">
                  <input
                    type="file"
                    id="Company_Business_Card"
                    name="Company_Business_Card"
                    accept=".jpg,.jpeg,.png,.pdf"
                    className={`hidden ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    } `}
                    onChange={handleChangeBusinessCard}
                    disabled={uploadingFile}
                  />

                  {/* Label For style is input tag and use in File input */}
                  <label
                    htmlFor="Company_Business_Card"
                    className={`block bg-[#FFFFFF80] h-11 w-full rounded-sm text-black px-2 ps-10 focus:ring-[0.5px]  focus:ring-secondaryl focus:outline-none ${
                      businessCardError ? "ring-[0.5px] ring-red-500" : ""
                    }  ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    } `}
                    style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
                    disabled={uploadingFile}
                  >
                    <div className="flex items-center h-full space-x-2 overflow-hidden">
                      <TiBusinessCard className="h-full w-12 text-secondaryl" />

                      {businessCard && !businessCardURL ? (
                        <span className="line-clamp-1 w-full">
                          {businessCard.name}
                        </span>
                      ) : (
                        ""
                      )}

                      {!businessCard && !businessCardURL ? (
                        <span className="text-infromation/50  text-xs">
                          Upload company business card
                        </span>
                      ) : (
                        ""
                      )}

                      {businessCardURL && (
                        <NavLink
                          className="text-white p-2 bg-secondaryl hover:bg-opacity-90 truncate flex justify-end"
                          to={businessCardURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Click Here
                        </NavLink>
                      )}
                    </div>
                  </label>
                </div>

                <div className="">
                  {businessCardURL ? (
                    <div className="h-11 w-fit px-2 py-2.5 euclidbold items-center  cursor-not-allowed ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate  tracking-widest   md:mt-0 ">
                      UPLOADED
                    </div>
                  ) : (
                    <div
                      onClick={handleUploadBusinessCard}
                      className={`h-11 w-fit px-2 py-2.5 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap
                            ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                      disabled={uploadingFile}
                    >
                      UPLOAD
                    </div>
                  )}
                </div>
              </div>

              {/* Error */}
              <div className="h-[3vh] pt-0">
                {uploadingBusinessCard && <Spinner />}

                {businessCardError && (
                  <p className="text-red-500 text-xs leading-none">
                    {businessCardError}
                  </p>
                )}
                {businessCardUploded && (
                  <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                    <ApprovedIcon /> Businesscard uploaded Successfully!
                  </p>
                )}
              </div>
            </div>

            <div className="lg:row-span-2  flex lg:justify-center">
              <div className="">
                <label
                  className={`block font-normal text-base text-secondaryl `}
                >
                  Profile Photo
                </label>
                <div className="flex items-center gap-5">
                  <input
                    type="file"
                    id="Profile_Photo"
                    name="Profile_Photo"
                    accept=".jpg,.jpeg,.png"
                    className={`hidden ${
                      uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                    } `}
                    onChange={handleChangeProfilePhoto}
                    disabled={uploadingFile}
                  />
                  {/* <!-- Profile Photo --> */}
                  <label
                    htmlFor="Profile_Photo"
                    className={` h-24 w-24 md:h-28 md:w-28  mb-4 ${
                      uploadingFile
                        ? " cursor-not-allowed "
                        : " cursor-pointer "
                    }  rounded-sm  focus:ring-secondaryl focus:outline-none  ${
                      profilePhotoError
                        ? "ring-[0.5px] ring-red-500"
                        : "ring-[0.5px] ring-infromation/30"
                    } `}
                    disabled={uploadingFile}
                  >
                    {profilePhotoURL ? (
                      <img
                        className=" h-24 w-24 md:h-28 md:w-28 "
                        src={profilePhotoURL}
                        alt="Profile "
                      />
                    ) : profilePhoto ? (
                      <img
                        className=" h-24 w-24 md:h-28 md:w-28 "
                        src={displayProfilePhoto}
                        alt="Profile "
                      />
                    ) : (
                      <RiFileUserFill className=" h-24 w-24 md:h-28 md:w-28  text-secondaryl" />
                    )}
                  </label>

                  {/* <!-- Upload Button --> */}

                  <div className="">
                    {profilePhotoURL ? (
                      <div className="h-11 w-fit px-2 py-2.5 euclidbold items-center  cursor-not-allowed ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate  tracking-widest   md:mt-0 ">
                        UPLOADED
                      </div>
                    ) : (
                      <div
                        onClick={handleUploadProfilePhoto}
                        className={`h-11 w-fit px-3 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out flex-wrap
                            ${
                              uploadingFile
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                        disabled={uploadingFile}
                      >
                        UPLOAD
                      </div>
                    )}
                  </div>
                </div>
                {/* Error */}
                <div className="h-[3vh] pt-0">
                  {uploadingProfilePhoto && <Spinner />}

                  {profilePhotoError && (
                    <p className="text-red-500 text-xs leading-none">
                      {profilePhotoError}
                    </p>
                  )}
                  {profilePhotoUploded && (
                    <p className="text-gray-600 text-sm leading-none flex items-center gap-2 mt-2">
                      <ApprovedIcon /> Profile Photo uploaded Successfully!
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex gap-0 items-center">
                <Link
                  to="/contractors/reset"
                  className="text-secondaryl  euclidbold flex items-center gap-2"
                >
                  Reset Password <ImNewTab />
                </Link>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div className="mt-3">
            <button
              type="submit"
              onClick={businessCardClearError}
              className={`
                h-11 w-fit euclidbold  ring-secondaryl rounded-md flex justify-center text-secondaryl  truncate  font-bold ease-in-outh-11  px-6 py-2.5 items-center ring-1 cursor-pointer euclidbold  text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out  ${
                  uploadingFile ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              disabled={uploadingFile}
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContractorProfile;
