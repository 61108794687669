import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { Header, Hero } from "../../components/index";
import Footer from "../../components/Layout/Footer";

import { NavLink } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import WhoWeAre from "../../assets/images/home/WhoWeAre.png";
import OurMission from "../../assets/images/home/OurMission.png";
import OurVision from "../../assets/images/home/OurVision.png";
import WhyChooseUs from "../../assets/images/home/WhyChooseUs.png";
import WhyChooseUsSM from "../../assets/images/home/WhyChooseUsSM.png";
import HowItWork from "../../assets/images/home/HowItWork.png";
import HowItWorkSM from "../../assets/images/home/HowItWorkSM.png";

function Home() {
  const [signUnModel, setSignUnModel] = useState(false);
  const signUpModelRef = useRef(null);

  return (
    <div className="overflow-hidden " id="homepagestart">
      <Header />
      <Hero />

      <div className="w-full">
        <div className="py-20 w-[90%] md:w-[85%] lg:w-[80%] gap-4 md:gap-8 flex flex-col md:flex-row  md:items-center mx-auto">
          <div className="w-full md:w-[40%] lg:w-full flex justify-center">
            <img src={WhoWeAre} className="h-[400px] md:h-[500px] lg:h-full" />
          </div>
          <div className="w-full md:w-[60%] lg:w-full flex flex-col items-center gap-6">
            <h3
              className=" euclidbold  text-secondaryl text-3xl lg:text-4xl text-center w-[95%] md:w-[80%] lg:w-[55%] mx-auto py-3"
              style={{
                background:
                  "linear-gradient(90.46deg, rgba(62, 92, 169, 0.06) 2.66%, rgba(0, 148, 191, 0.06) 99.88%)",
                backdropFilter: "blur(80px)",
              }}
            >
              Who We Are?
            </h3>
            <p className="text-primaryl font-normal text-base lg:text-lg text-center md:w-[80%] lg:w-[550px] capitalize">
              At Condominum Portal Inc, we bridge the gap between corporations
              and contractors with our innovative portal designed for seamless
              collaboration. Our platform empowers corporations to post their
              contracts, allowing contractors to submit detailed proposals.
              Through our streamlined process, contracts are finalized upon
              approval from the corporation. We also provide a secure space for
              both parties to discuss terms and conditions, ensuring clarity and
              mutual agreement. Our mission is to facilitate efficient and
              transparent interactions, transforming the way contracts are
              managed and executed. Join us and experience a new era of contract
              management.
            </p>
          </div>
        </div>

        <div className="w-[90%] md:w-[85%] lg:w-[80%] flex flex-col md:flex-row mx-auto gap-4 md:gap-8">
          <div className="w-full flex justify-center">
            <img src={OurMission} className="" />
          </div>
          <div className="w-full flex justify-center">
            <img src={OurVision} className="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[85%] lg:w-[80%] mx-auto">
          <div className="w-full hidden md:block">
            <img src={WhyChooseUs} className="" />
          </div>
          <div className="w-full block md:hidden -mt-8">
            <img src={WhyChooseUsSM} className="" />
          </div>
        </div>

        <div className="w-[90%] md:w-full mx-auto flex flex-col items-center py-14 gap-4 md:gap-8">
          <h3 className=" euclidbold  text-secondaryl text-3xl lg:text-4xl ">
            How It’s Work?
          </h3>

          <img src={HowItWork} className="lg:pr-[15%] hidden md:block pt-4" />
          <img src={HowItWorkSM} className=" block md:hidden pt-4" />
        </div>

        <div className="w-[90%] md:w-[85%] lg:w-[80%] ml-[5%]  md:ml-[7.5%] lg:ml-[10%] my-12">
          <h2 className="text-2xl lg:text-4xl text-secondaryl euclidbold">
            Ready To Simplify Your Contract Management?
          </h2>
          <p className=" text-primaryl py-3">
            Join Our Platform Today And Streamline Your Contract Process
            Effortlessly.
          </p>
          <div
            className="text-center py-2 w-40 text-lg ring-2  cursor-pointer  ring-secondaryl text-secondaryl font-semibold rounded hover:bg-secondaryl hover:text-[#ffffff] transition "
            onClick={() => setSignUnModel(true)}
            ref={signUpModelRef}
          >
            Sign Up Now
          </div>
        </div>

        {signUnModel && (
          <>
            {/* Apply overflow-hidden to the body when modal is open */}
            <style>{`body { overflow: hidden; }`}</style>

            <div className="fixed inset-0 flex items-center justify-center z-[999] bg-white/60">
              <div className="relative w-full max-w-lg mx-4 p-6 bg-white rounded-lg shadow-lg transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
                <div className="absolute top-0 right-0 mt-2 mr-2">
                  <MdCancel
                    className="text-secondaryl text-3xl cursor-pointer hover:text-secondaryd transition duration-200"
                    onClick={() => setSignUnModel(false)}
                  />
                </div>
                <h2 className="text-2xl  euclidbold  text-center text-secondaryl mb-4">
                  Sign Up
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-lg">
                  <NavLink
                    to="/corporations/signup"
                    className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 tracking-widest"
                  >
                    Corporation
                  </NavLink>
                  <NavLink
                    to="/contractors/signup"
                    className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 tracking-widest"
                  >
                    Contractor
                  </NavLink>
                  <NavLink
                    // div
                    to="/tenant/signup"
                    className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 cursor-pointer tracking-widest"
                    // onClick={() => toast.success("Coming Soon")}
                  >
                    Tenant
                  </NavLink>
                  <NavLink
                    // div
                    to="/owners/signup"
                    className="py-3 px-4 rounded-lg bg-primaryl text-white text-center font-semibold shadow-md hover:bg-secondaryl hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 cursor-pointer tracking-widest"
                    // onClick={() => toast.success("Coming Soon")}
                  >
                    Owners
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Home;
