import React from "react";
import Cookies from "universal-cookie";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

function DashboardContractCard({ contract, getProposalsHandler }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      key={contract.ID}
      className="w-full flex flex-col lg:flex-row  hover:bg-opacity-50  bg-[#FFFFFF] rounded p-3 gap-3 mx-auto my-3 "
      style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
    >
      {/* Card Images  */}
      <div className="h-full relative lg:w-[25%] flex-none bg-cover rounded-md text-center flex justify-center">
        {contract.ContractCard_Image ? (
          <img
            src={contract.ContractCard_Image}
            alt={contract.Project_Name}
            className="w-[100%] lg:w-32 lg:h-[100px] object-cover rounded-md"
          />
        ) : (
          <div className="w-[100%] justify-center lg:w-32 ring-[0.2px] ring-infromation h-[100px] flex items-center text-infromation">
            No Image
          </div>
        )}
      </div>
      {/* Card Content */}

      <div className="flex-col w-full lg:w-[45%] my-auto leading-normal overflow-hidden">
        {" "}
        <div className="space-y-1.5">
          {/* Title */}
          <div className="text-secondaryl  euclidbold text-2xl line-clamp-2 ">
            {contract.Project_Name}
          </div>
          {/* Location */}
          <div className="text-base  flex gap-1 items-center line-clamp-1 ">
            <Icon
              icon="carbon:location-filled"
              className="text-secondaryl text-lg w-[10%]"
            />
            <p className="text-infromation w-[90%] line-clamp-1">
              {" "}
              {contract.Project_Location}
            </p>
          </div>
        </div>
      </div>
      {/* Card Buttons */}
      <div className="flex-col justify-center my-auto relative w-auto lg:w-[30%] space-y-3">
        <div
          onClick={() => {
            getProposalsHandler(contract.ID);
          }}
          className="h-11 w-full px-1.5 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
        >
          BIDS
          <span className="text-red-500">{` (${contract.proposalCount}) `}</span>
        </div>

        {cookies.get("access") === "board member" ? (
          ""
        ) : (
          <div
            onClick={() => {
              if (cookies.get("status") === 2) {
                return toast.error("profile paused");
              } else {
                return navigate(
                  `/corporations/dashboard/editcontract/${contract.ID}`,
                  {
                    state: {
                      page: searchParams.get("page"),
                      // contract: contract,
                    },
                  }
                );
              }
            }}
            className="h-11 w-full px-1.5 py-2 euclidbold items-center cursor-pointer ring-1 ring-secondaryl rounded-md flex justify-center text-secondaryl text-sm truncate hover:ring-cyan-600 font-bold tracking-widest hover:bg-secondaryl  hover:text-white transition duration-300 ease-in-out"
          >
            EDIT LISTING
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardContractCard;
