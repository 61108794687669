import React, { useState } from "react";
import PropTypes from "prop-types";

function InputBoxPassword({
  title,
  register,
  filedName,
  must,
  errors,
  placeHolder = "",
  className,
  type = "text",
  width = "w-full",
  min,
  max,
  maxLength = "",
  schema,
  setError,
  disabled = false,
  continueValidation = false,
}) {
  const [touched, setTouched] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const handleKeyDown = (event) => {
    if (type === "number" || type === "tel") {
      if (
        !(
          (
            (event.key >= "0" && event.key <= "9") ||
            event.key === "Tab" ||
            event.key === "F5" ||
            event.key === "ArrowLeft" ||
            event.key === "ArrowRight" ||
            event.key === "Delete" ||
            event.keyCode === 8 ||
            (event.ctrlKey && event.keyCode === 65) || // Ctrl + A
            (event.ctrlKey && event.keyCode === 86) || // Ctrl + V
            (event.ctrlKey && event.keyCode === 67)
          ) // Ctrl + C
        )
      ) {
        event.preventDefault();
      }
    }
  };

  const handlePaste = (event) => {
    if (type === "number" || type === "tel") {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text");

      if (!/^\d+$/.test(pastedData)) {
        event.preventDefault();
      }
    }
  };

  const handlePasswordStrength = (value) => {
    const criteria = [];
    if (value.length < 8) {
      criteria.push("Password must be at least 8 characters.");
    }
    if (!/[A-Z]/.test(value)) {
      criteria.push(
        "Password must contain at least one uppercase letter.(A,B,C,D,...)"
      );
    }
    if (!/[a-z]/.test(value)) {
      criteria.push(
        "Password must contain at least one lowercase letter.(a,b,c,d,...)"
      );
    }
    if (!/[0-9]/.test(value)) {
      criteria.push("Password must contain at least one number.(0,1,2,3,...)");
    }
    if (!/[!@#$%^&*]/.test(value)) {
      criteria.push(
        "Password must contain at least one special character.(!,@,#,$,%,^,&,*)"
      );
    }
    return criteria;
  };

  const handleKeyUp = (event) => {
    const value = event.target.value;
    setIsTyping(true);

    const criteria = handlePasswordStrength(value);
    if (criteria.length > 0) {
      setPasswordCriteria(criteria);
      setOverlayVisible(true);
    } else {
      setPasswordCriteria([]);
      setOverlayVisible(false);
    }

    if (touched) {
      schema
        .validateAt(filedName, { [filedName]: value })
        .then(() => {
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        });
    }
  };

  const handleBlur = (event) => {
    const value = event.target.value;
    setIsTyping(false);
    setOverlayVisible(false);

    if (value !== "") {
      schema
        .validateAt(filedName, { [filedName]: value })
        .then(() => {
          if (errors[filedName]) {
            delete errors[filedName];
          }
          setTouched(false);
        })
        .catch((err) => {
          setError(filedName, { type: "manual", message: err.errors[0] });
          setTouched(true);
        });
    } else if (passwordCriteria.length > 0) {
      setError(filedName, { type: "manual", message: passwordCriteria[0] });
    }
  };

  return (
    <div className="mb-3 relative w-full">
      <label
        htmlFor={filedName}
        className={`block font-normal text-secondaryl `}
      >
        <p>
          {title} {must && <span className="text-red-500 text-base">*</span>}
        </p>
      </label>
      <input
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={`block h-11 ${width} rounded-sm text-black  px-2 ps-10  focus:ring-[0.5px] focus:ring-secondaryl  focus:outline-none ${
          errors[filedName] ? "ring-[0.5px] ring-red-500" : ""
        } ${className} ${disabled ? "bg-primaryl/[05%]" : "bg-[#FFFFFF80] "}`}
        placeholder={placeHolder || `Type ${title}`}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyUp={handleKeyUp}
        onKeyDown={type === "number" || type === "tel" ? handleKeyDown : null}
        onBlur={handleBlur}
        onPaste={handlePaste}
        // style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      />
      <div className="h-[2vh] pt-1">
        {errors[filedName] && !isTyping && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
      {overlayVisible && isTyping && (
        <div className="absolute bg-white border border-gray-300 shadow-lg p-2 rounded-md w-full z-10">
          {passwordCriteria.map((criteria, index) => (
            <p key={index} className="text-red-500 text-xs leading-none w-full">
              {criteria}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export default InputBoxPassword;
