import React, { useState, useEffect, useRef } from "react";
import { IconDown, IconUp } from "../../assets/images";

const DropDownStyle1 = ({
  title,
  options,
  onSelect,
  selectOption,
  setValue,
  filedName,
  clearErrors,
  errors,
  dbfield,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectOption = (selectOptionsValue) => {
    onSelect(selectOptionsValue);
    const a = setValue(`${filedName}`, selectOptionsValue);

    if (errors[filedName]) {
      clearErrors(filedName);
    }
    toggleDropdown(); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block w-full">
      {/* Dropdown button */}
      <button
        className={`text-gray-500 bg-[#FFFFFF80] w-full h-11 focus:ring-[0.5px] focus:ring-secondaryl focus:outline-none focus:font-medium rounded-sm text-base px-auto py-1 text-center inline-flex min-w-44 items-center
        ${errors[filedName] ? "ring-[0.5px] ring-red-500" : ""} `}
        onClick={toggleDropdown}
        type="button"
      >
        <span className="grid grid-cols-2 w-full items-center px-2 truncate  justify-between">
          <p className="text-black">
            {selectOption
              ? options.find((item) => item.ID === selectOption)?.[dbfield]
              : title}
          </p>

          <p className="justify-end flex ">
            {isOpen ? <IconUp /> : <IconDown />}
          </p>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && options && (
        <div className="absolute z-50 max-h-44  overflow-auto top-12 left-0  bg-white  rounded-lg min-w-40 w-full text-gray-800 ring-1 ring-gray-200 ">
          <ul className="py-1 text-base text-gray-700 cursor-pointer">
            {options?.map((item, index) => (
              <li key={index} onClick={() => handleSelectOption(item.ID)}>
                <div className="block px-4 py-2   ">{item[dbfield]}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Error */}
      <div className="h-[3vh] pt-1">
        {errors[filedName] && (
          <p className="text-red-500 text-xs leading-none">
            {errors[filedName].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default DropDownStyle1;
