import React from "react";
import Skeleton from "react-loading-skeleton";

function ContractDetailsCardSkeleton() {
  return (
    <div className="w-[80%] lg:max-w-[80%] py-8 shadow-lg  lg:space-x-10 mx-auto my-4">
      <div className="max-w-6xl mx-auto px-4 py-4 sm:px-6 lg:px-8">


        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Images */}
          <div className="md:flex-1 px-4">
            <div className="h-[460px] rounded-lg   mb-4">
              <div className="object-cover w-full h-full">
                <Skeleton height={500} />
              </div>
            </div>


            {/* Job Other 5 Images */}
            <div className="flex-wrap hidden md:flex px-3 -mt-10">
              {
                Array(5).fill(0).map((item, i) => (
                  <div className="w-1/2 p-1 sm:w-1/5" key={i}>
                    <div
                      className="block"
                    >
                      <div className=" w-36 lg:w-32 lg:h-20" />
                      <Skeleton height={90}/>
                    </div>
                  </div>
                ))
              }

          </div>
        </div>

        {/* Content */}
        <div className="md:flex-1 px-4 mt-5">
          <div className="space-y-1">
            {/* Title */}
            <div className=" py-3 sm:pt-3 md:pt-3 lg:pt-0 ">
              <Skeleton />
            </div>
            {/* Location */}
            <div className="flex space-x-2 ">
              <Skeleton />
            </div>

            <div className="space-y-2 pt-4 pb-3 ">
              <p className="text-sm ">
                <Skeleton />
              </p>
              <p className="text-sm ">
                <Skeleton />
              </p>
            </div>

            {/* Description */}
            <p className="">
              <Skeleton />
            </p>
          </div>
        </div>
      </div>
    </div>

    </div >
  );
}

export default ContractDetailsCardSkeleton;
