import React from "react";

function CountDay({className,color="black"}) {
  return (
    <p className={className}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_488_5426)">
        <path
          d="M5.83758 1.8417C5.25938 1.01369 4.29582 0.475098 3.20996 0.475098C1.44078 0.475098 0 1.91607 0 3.68959C0 4.77576 0.538906 5.73451 1.36645 6.31268C2.29906 4.35955 3.88441 2.77432 5.83758 1.8417Z"
          fill=""
        />
        <path
          d="M16.7856 0.475098C15.6775 0.475098 14.7012 1.0401 14.123 1.89416C16.05 2.84869 17.6089 4.43838 18.5198 6.38736C19.4088 5.8224 19.9999 4.82377 19.9999 3.68959C19.9999 1.91607 18.5591 0.475098 16.7856 0.475098Z"
          fill=""
        />
        <path
          d="M18.5279 10.4034C18.5271 5.69284 14.7106 1.87671 10.0004 1.87573C5.29007 1.87671 1.47323 5.69284 1.47241 10.4034C1.47272 13.0835 2.7112 15.4719 4.64472 17.0352L4.07089 18.036C3.79757 18.5132 3.96222 19.1207 4.43901 19.3936C4.91503 19.666 5.52362 19.5008 5.79597 19.025L6.33022 18.0917C7.443 18.6242 8.68452 18.9307 10.0004 18.9313C11.3163 18.9307 12.5574 18.6242 13.6701 18.092L14.2042 19.025C14.4767 19.5008 15.0853 19.6659 15.5613 19.3936C16.0381 19.1206 16.2028 18.5131 15.9294 18.036L15.3556 17.0351C17.2889 15.4719 18.5274 13.0838 18.5279 10.4034ZM10.0004 3.97827C11.7781 3.97858 13.3779 4.69522 14.5438 5.85995C15.7084 7.02565 16.425 8.62565 16.4255 10.4034C16.425 12.1811 15.7084 13.7811 14.5438 14.9471C13.3779 16.1119 11.7781 16.8285 10.0004 16.8288C8.22253 16.8285 6.62269 16.1112 5.45647 14.9471C4.2919 13.7811 3.57511 12.1811 3.57479 10.4034C3.57511 8.62565 4.2919 7.02565 5.45647 5.85995C6.62269 4.69522 8.22253 3.97858 10.0004 3.97827Z"
          fill=""
        />
        <path
          d="M10.0004 11.0651L13.7094 10.9872C14.0113 10.9872 14.256 10.7422 14.256 10.4402C14.256 10.1383 14.0113 9.8936 13.7094 9.8936L10.6072 9.82825L10.4844 5.60228C10.4844 5.33474 10.2676 5.11841 10.0004 5.11841C9.7335 5.11841 9.51666 5.33474 9.51666 5.60228L9.37549 10.4402C9.37553 10.7854 9.65529 11.0651 10.0004 11.0651Z"
          fill=""
        />
      </g>
      <defs>
        <clipPath id="clip0_488_5426">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    </p>
  );
}

export default CountDay;
