import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { baseUrl, blogAPI, cityareaAPI, serviesAPI } from "../../constants/API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { getCityArea, getServies } from "../../constants/Request";
import { IoMdAdd } from "react-icons/io";

import { MdOutlineDelete } from "react-icons/md";

const ManageBlog = ({
  setLoad,
  blogs,
  setBlogs,
  setBlogCount,
  setActiveTab,
  setEditBlogData,
  fetchBlogData,
}) => {
  const cookies = new Cookies();

  const handleUpdateClick = (blog) => {
    setEditBlogData(blog);
    setActiveTab("Edit Blog");
  };

  const handleAddNewClick = () => {
    setActiveTab("Create Blog");
  };

  const handleDeleteClick = async (ID) => {
    setLoad(true);
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this blog?"
      );

      if (confirmed) {
        // console.log("id" , ID);
        const res = await axios.delete(`${blogAPI}/${ID}`, {
          headers: {
            authorization: cookies.get("token"),
            companyType: "admin",
          },
        });

        toast.success("Blog Deleted Successfully");
        setActiveTab("Manage Blog");
        fetchBlogData();
      }
    } catch (error) {
      // Handle any errors
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again later"
      );
      console.error("Error deleting blog:", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Blog </h2>
      <div className="flex flex-col">
        <div className="flex justify-center">
          <div className="overflow-auto max-h-[60vh] w-full table-container">
            <div className="mb-4">
              <button
                onClick={handleAddNewClick}
                className="mr-2 px-4 py-2 flex gap-2 items-center rounded-md bg-primaryl text-white font-bold"
              >
                Add New <IoMdAdd />
              </button>
            </div>
            <table className="table-auto w-full relative">
              <thead className="relative">
                <tr>
                  <th className="px-4 py-2 text-sm w-[80%]">Blog Title</th>
                  <th className="px-4 py-2 text-sm w-[80%]">Publish Date</th>
                  <th className="px-4 py-2 text-sm">Update</th>
                  <th className="px-4 py-2 text-sm">Delete</th>
                </tr>
              </thead>
              <tbody>
                {blogs?.map((blog) => (
                  <tr key={blog.ID}>
                    <td className="border px-4 py-2">{blog.Blog_Title}</td>
                    <td className="border px-4 py-2">
                      {blog.Blog_Publish_Date}
                    </td>
                    <td className="border px-4 py-2">
                      <FaEdit
                        className="text-cyan-500 cursor-pointer"
                        onClick={() => handleUpdateClick(blog)}
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <MdOutlineDelete
                        className="text-red-500 cursor-pointer"
                        onClick={() => {
                          const confirmation = window.confirm(
                            `Are you sure you want to delete this blog ?`
                          );
                          if (confirmation) {
                            handleDeleteClick(blog.ID);
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBlog;
