import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LogoWithText1 } from "../../../../assets/images";
import { Icon } from "@iconify/react";

function DashboardSidebar() {
  const [showDocumentsSublist, setShowDocumentsSublist] = useState(true);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();

  // Effect to manage Documents sublist without collapsing MoM
  useEffect(() => {
    // Only collapse other sublists if MoM is not active
    if (showDocumentsSublist) {
      setIsCollapsed(false);
    }
  }, [showDocumentsSublist]);

  // Handle sublist visibility based on URL location
  useEffect(() => {
    const path = location.pathname;
    // MoM and Documents sublist
    if (
      path.includes("rules") ||
      path.includes("bylaws") ||
      path.includes("mom") ||
      path.includes("budget") ||
      path.includes("declaration") ||
      path.includes("notices") ||
      path.includes("pic")
    ) {
      setShowDocumentsSublist(true); // Documents open for rules and bylaws
    } else {
      setShowDocumentsSublist(false);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true); // Default collapsed on small screens
      } else {
        setIsCollapsed(false); // Default expanded on larger screens
      }
    };

    handleResize();
  }, []);

  return (
    <aside
      className={`my-3 ml-3 bg-gradient-to-b from-[rgba(40,42,115,0.15)] to-[rgba(0,148,191,0.15)]  rounded-2xl transition-all duration-300  ${
        isCollapsed ? "w-14" : "w-72"
      } 
    `}
    >
      <div className="flex justify-center py-2 md:py-0">
        <NavLink to="/owners/dashboard/home">
          <img
            src={LogoWithText1} // Replace with your logo
            alt="Condominium Portal"
            className={`${
              isCollapsed ? "h-14 w-14 " : "h-40 w-40 "
            } transition-all duration-300 `}
          />
        </NavLink>
      </div>

      {/* Toggle Button */}
      <div className="flex justify-end px-2 py-1">
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-2 bg-primaryl text-white rounded-full cursor-pointer hover:bg-opacity-80"
        >
          {isCollapsed ? (
            <Icon icon="ion:chevron-forward-outline" className="text-xl" />
          ) : (
            <Icon icon="ion:chevron-back-outline" className="text-xl" />
          )}
        </div>
      </div>

      <nav className="mb-2">
        <ul className="space-y-1.5 euclidbold text-primaryl text-lg">
          {/* Dashboard */}
          <NavLink
            to="home"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="typcn:home" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Dashboard
            </span>
          </NavLink>

          <NavLink
            to="requestservice"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:account-service" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Request Service
            </span>
          </NavLink>

          <NavLink
            to="maintenance"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="wpf:maintenance" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Maintenance
            </span>
          </NavLink>

          {/* Document List */}
          <li
            className=""
            onClick={() => {
              setShowDocumentsSublist(!showDocumentsSublist);
            }}
          >
            <div className="service-li bg-[#ffffff] bg-opacity-70 hover:bg-primaryl hover:text-white rounded-md flex items-center px-3 py-2 gap-4 cursor-pointer ">
              <Icon icon="solar:documents-bold" className="text-2xl" />
              <span
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                }`}
              >
                Documents
              </span>

              <div
                className={`transition-all duration-300 ${
                  isCollapsed ? "hidden" : "inline-block"
                } ml-auto`}
              >
                {showDocumentsSublist ? (
                  <div className="rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                ) : (
                  <div className="-rotate-90 ">
                    <Icon icon="ion:chevron-back" className="text-2xl " />
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && showDocumentsSublist && (
              <div className="bg-[#ffffff] bg-opacity-50">
                <ul className=" euclidnormal text-base px-1 md:px-3 space-y-1 py-1">
                  {/* {cookies.get("access") === "property manager" && ( */}
                  <NavLink
                    to="rules"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Rules
                  </NavLink>
                  {/* )} */}

                  <NavLink
                    to="bylaws"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Bylaws
                  </NavLink>
                  <NavLink
                    to="declaration"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Declaration
                  </NavLink>
                  <NavLink
                    to="budget"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Budget
                  </NavLink>

                  <NavLink
                    to="notices"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Notices
                  </NavLink>
                  <NavLink
                    to="pic"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    PIC
                  </NavLink>
                  <NavLink
                    to="mom"
                    className={({ isActive }) =>
                      `flex items-center p-1 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                        isActive
                          ? "bg-primaryl text-white hover:bg-opacity-80"
                          : ""
                      }`
                    }
                  >
                    Minutes Of Meeting
                  </NavLink>
                </ul>
              </div>
            )}
          </li>

          <NavLink
            to="complaintsuggestion"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="hugeicons:complaint" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Complaint/Suggestion
            </span>
          </NavLink>

          {/* Blog */}
          <NavLink
            to="blog"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:email-newsletter" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Blog
            </span>
          </NavLink>

          {/* About Us */}
          <NavLink
            to="about"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:about" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              About Us
            </span>
          </NavLink>

          {/* Contact Us */}
          <NavLink
            to="contact"
            className={({ isActive }) =>
              `flex items-center px-3 py-2 gap-4 rounded-md cursor-pointer hover:bg-primaryl transition duration-200 hover:text-white ${
                isActive
                  ? "bg-primaryl text-white hover:bg-opacity-80"
                  : "bg-[#ffffff] bg-opacity-70"
              }`
            }
          >
            <Icon icon="mdi:contact" className="text-2xl" />
            <span
              className={`transition-all duration-300 ${
                isCollapsed ? "hidden" : "inline-block"
              }`}
            >
              Contact Us
            </span>
          </NavLink>
        </ul>
      </nav>
    </aside>
  );
}

export default DashboardSidebar;
