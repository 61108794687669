import * as yup from "yup";

const serviceSchema = yup.object().shape({
  Service_Name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Service name can only contain letters.")
    .required(),
  Service_Description: yup.string().required(),
  Service_Image: yup.string(),
  Is_Sub_Service: yup.boolean(),
  Sub_Services: yup.array().of(
    yup.object().shape({
      Sub_Service_Name: yup
        .string()
        .matches(/^[A-Za-z\s]+$/, "Sub Service name can only contain letters.")
        .required(),
      Sub_Service_Description: yup.string().required(),
    })
  ),
});

//  1 = Rules, 2 = Bylaws, 3 = Declaration, 4 = Budget
const documentSchema = yup.object().shape({
  Section: yup.number(),
  Document_Name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Name can only contain letters.")
    .required(),
  Document_URL: yup.string().required(),
  IsActive: yup.boolean(),
});

//  1 = Rules, 2 = Bylaws, 3 = Declaration, 4 = Budget
const noticeSchema = yup.object().shape({
  Notice_Title: yup.string().required(),
  Notice_Description: yup.string(),
  Notice_Document: yup
    .string()
    .test(
      "is-required-if-uploading-document",
      "Document is Required",
      function (value) {
        if (this.parent.Is_Uploading_Document === true) {
          return value && value.length > 0; // Array must have at least one element if condition is met
        }
        return true; // Skip validation if the condition is not met
      }
    )
    .nullable(),
  Notice_Start_Date: yup.string().required(),
  Notice_End_Date: yup.string().required(),
  // 0 both 1 owner 2 tenant 3 specific owner 4 specific tenant
  Sharing_Status: yup.string().required(),
  Is_Specific_Resident: yup.boolean(),
  Is_Owner: yup.boolean(),
  Resident_ID: yup
    .array()
    .test(
      "is-required-if-specific-resident",
      "Select a resident is Required",
      function (value) {
        if (this.parent.Is_Specific_Resident === true) {
          return value && value.length > 0; // Array must have at least one element if condition is met
        }
        return true; // Skip validation if the condition is not met
      }
    ),
  Is_Uploading_Document: yup.boolean(),
});

const momSchema = yup.object().shape({
  Meeting_Topic: yup.string().required("MoM Topic is required"),
  Meeting_Details: yup.string().required("MoM Summary is required"),
  Meeting_Outcome: yup.string().required("MoM Outcome is required"),
  Meeting_Document: yup.string().required("MoM Docs is required"),
  Sharing_Status: yup.number().required("MoM Who Can Show is required"),
  Meeting_Date: yup.string().required("MoM Date is required"),
});

const picSchema = yup.object().shape({
  PIC_Name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "PIC name can only contain letters.")
    .required("Pubic Information Certificate Name is required"),
  PIC_URL: yup
    .string()
    .required("Public Information Certificate Document is required"),
  IsActive: yup.boolean(),
});

const complaintSuggestionSchema = yup.object().shape({
  Description: yup
    .string()
    .required("Complaint/Suggestion Description is required"),
  Comment: yup.string(),
  Status: yup.boolean(),
});

const securityMemberSchema = yup.object().shape({
  Security_Member_Name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Last name can only contain letters.")
    .required("Security Member Name is required"),
  Security_Member_Email: yup
    .string()
    .required("Recovery Email is required")
    .matches(
      /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/,
      "Invalid Email Address"
    ),
  Security_Member_Phone_No: yup
    .string()
    .required("Security Member Phone Number is reuired")
    .matches(
      /^[0-9]{10}$/, // Regular expression for 10-digit phone number
      "Phone No must be exactly 10 digits"
    ),
  Term_And_Policy: yup.boolean().oneOf([true], "This field must be checked"),
});

const incidentSchema = yup.object().shape({
  Incident_ID: yup.string().required("Incident Type is required"),
  Description: yup.string().required("Incident Description is required"),
});

export {
  serviceSchema,
  documentSchema,
  securityMemberSchema,
  momSchema,
  noticeSchema,
  picSchema,
  complaintSuggestionSchema,
  incidentSchema,
};
