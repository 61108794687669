import axios from "axios";
import {
  designationAPI,
  cityareaAPI,
  getContractorProfileAPI,
  getContractorProposal,
  baseUrl,
  corporationProfile,
  boardMember,
  serviesAPI,
  proposalAPI,
  corporationAPI,
  contractorAPI,
  serviceCorporationApi,
} from "./API";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";

const cookies = new Cookies();

const getDesignation = async () => {
  try {
    const response = await axios.get(designationAPI);

    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

const getCityArea = async () => {
  try {
    const response = await axios.get(cityareaAPI);
    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

const getServies = async () => {
  try {
    const response = await axios.get(serviesAPI);
    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

const getCorporation = async () => {
  try {
    const response = await axios.get(`${corporationAPI}/corporation`);
    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

const getApprovalContractors = async () => {
  try {
    const response = await axios.get(`${contractorAPI}/approvalcontractors`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    // console.log(response);
    return response.data.data;
  } catch (error) {
    return error.response;
  }
};

const getContractorProfileData = async (setLoad, token) => {
  try {
    setLoad(true);
    const apiUrl = `${getContractorProfileAPI}?token=${token}`;
    const response = await axios.get(apiUrl, {
      headers: {
        authorization: cookies.get("token"),
        companyType: "contractor",
      },
    });
    return response.data.data;
  } catch (error) {
    return null;
  } finally {
    setLoad(false);
  }
};

const getCorporationProfileData = async (setLoad) => {
  try {
    setLoad(true);

    const response = await axios.get(corporationProfile, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    return response.data;
  } catch (error) {
    toast.error(
      error.response.data.message || "Error fetching corporation profile data"
    );
    return null;
  } finally {
    setLoad(false);
  }
};

const approveProposal = async (id, Assign_Board_Members_ID) => {
  try {
    const response = await axios.put(
      `${baseUrl}/proposal/approve/${id}`,
      { Status: 2, Assign_Board_Members_ID },
      {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const directApproveProposal = async (id) => {
  try {
    const response = await axios.put(
      `${baseUrl}/proposal/approve/${id}`,
      { Status: 2 },
      {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    return error;
  }
};

const getProposalData = async (contractId) => {
  try {
    // const apiUrl = `${proposalAPI}/${proposalid}`;
    const apiUrl = `${getContractorProposal}?contractId=${contractId}`;
    const response = await axios.get(apiUrl, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
};

const fetchCorporationMessages = async () => {
  try {
    const res = await axios.get(`${baseUrl}/corporation/messages`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    return res.data.data;
  } catch (error) {
    return error;
  }
};

const fetchContractorMessages = async () => {
  try {
    const res = await axios.get(`${baseUrl}/contractor/messages`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    // console.log(res);
    return res.data.data;
  } catch (error) {
    return error;
  }
};

const fetchChats = async (id) => {
  try {
    const res = await axios.get(`${baseUrl}/chat/proposal/${id}`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

const createChat = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/chat`, data, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    // console.log(res);
    return res;
  } catch (error) {
    return error;
  }
};

const updateProposalStatusToSeen = async (id) => {
  let Status = 1;
  try {
    const response = await axios.put(
      `${proposalAPI}/${id}`,
      { Status },
      {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const fetchProposals = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/proposal/contract/${id}`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    // console.log(response);
    return response;
  } catch (error) {
    return error;
  }
};

const getBoardMembers = async () => {
  try {
    const response = await axios.get(boardMember, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });

    return response.data;
  } catch (error) {
    toast.error(error.response.data.message || "Error fetching board members");
  }
};

const viewFileHandler = async (url, location) => {
  try {
    const fileName = url.split("/").pop(); // Extract filename from URL
    const response = await axios.get(
      `${baseUrl}/view/${fileName}/${location}`,
      {
        responseType: "blob",
      }
    );

    const imageUrl = URL.createObjectURL(response.data);
    return imageUrl;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getWorkingHoursByCorporationId = async (corporationId) => {
  try {
    const response = await axios.get(
      `${baseUrl}/corporationworkinghours/${corporationId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getServiceCorporation = async () => {
  try {
    const response = await axios.get(`${serviceCorporationApi}/owners`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });

    return response?.data?.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later");
  }
};

const getServiceCorporationByCorporationId = async () => {
  try {
    const response = await axios.get(
      `${serviceCorporationApi}/servicewithoutdata`,
      {
        headers: {
          authorization: cookies.get("token"),
          companyType: cookies.get("companyType"),
        },
      }
    );

    return response?.data?.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later");
  }
};

const getServiceCorporationByResident = async () => {
  try {
    const response = await axios.get(`${serviceCorporationApi}/resident`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });

    return response?.data?.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later");
  }
};

const getCorporationServiceRequest = async () => {
  try {
    const response = await axios.get(`${serviceCorporationApi}/requests`, {
      headers: {
        authorization: cookies.get("token"),
        companyType: cookies.get("companyType"),
      },
    });
    return response?.data;
  } catch (error) {
    toast.error("Something went wrong. Please try again later");
  }
};

export {
  getServiceCorporationByResident,
  getCorporationServiceRequest,
  getServiceCorporationByCorporationId,
  getServiceCorporation,
  getWorkingHoursByCorporationId,
  getDesignation,
  viewFileHandler,
  fetchProposals,
  fetchCorporationMessages,
  updateProposalStatusToSeen,
  getCityArea,
  getContractorProfileData,
  getProposalData,
  getServies,
  fetchContractorMessages,
  approveProposal,
  getCorporationProfileData,
  getBoardMembers,
  fetchChats,
  createChat,
  getCorporation,
  getApprovalContractors,
  directApproveProposal,
};
