import React from "react";

function ReadInputBox1({
  title,
  register,
  filedName,
  must,
  className,
  type = "text",
  width = "w-full",
  value,
  cursorNotAllowed = true,
}) {
  return (
    <div className="mb-3">
      {/* Label */}
      <label
        htmlFor={filedName}
        className={`block font-normal text-secondaryl`}
      >
        {title} {must ? <sup className="text-red-500 text-base">*</sup> : ""}
      </label>
      {/* Input */}
      <input
        type={type}
        id={filedName}
        name={filedName}
        {...register(filedName)}
        className={`block  h-11   bg-[#3F5CA91A]  text-infromation  rounded-sm   ${width}  px-2 ps-10 focus:outline-none  ${className} ${
          cursorNotAllowed ? "cursor-not-allowed" : ""
        } `}
        value={value}
        readOnly
        disabled
        style={{ boxShadow: "0px 4px 4px 0px #FFFFFF1A inset" }}
      />
    </div>
  );
}

export default ReadInputBox1;
