import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputBox from "../../../../components/Elements/ReadInputBox";

import Cookies from "universal-cookie";

import Loading from "../../../../components/Elements/Loading";

import { viewFileHandler } from "../../../../constants/Request";
import { momSchema } from "../../../../schema";
import ReadInputBox from "../../../../components/Elements/ReadInputBox";

function MoMReadModal({ mom, onClose }) {
  const cookies = new Cookies();

  const [modelLoading, setModelLoading] = useState(false);

  const {
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(momSchema),
  });

  const [MoMDocumnet, setMoMDocument] = useState(null);
  const [MoMDocumnetType, setMoMDocumentType] = useState(null);

  const assignMoMData = async () => {
    if (mom) {
      reset(mom);
      setValue(
        "Sharing_Status",
        getValues("Sharing_Status") === 0
          ? "Both"
          : getValues("Sharing_Status") === 1
          ? "Owner"
          : getValues("Sharing_Status") === 2
          ? "Tenant"
          : "Null"
      );
      if (mom.Meeting_Document?.endsWith(".pdf")) {
        const fileurl = await viewFileHandler(mom.Meeting_Document, "meetings");
        setMoMDocumentType("pdf");
        setMoMDocument(fileurl);
      } else {
        setMoMDocumentType("image");
        setMoMDocument(mom.Meeting_Document);
      }
    } else {
      reset();
    }
  };
  // Set the form values when the modal is opened in edit mode
  useEffect(() => {
    assignMoMData();
  }, [mom, reset]);

  return (
    <div
      className={`fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-30`}
    >
      <style>{`body { overflow: hidden; }`}</style>
      <Loading load={modelLoading} />
      <div className="flex  justify-center items-center h-full bg-transparent">
        <div className="bg-white rounded-2xl    w-[80%] h-[80%] overflow-auto ">
          <div className="bg-gradient-to-b from-[#282a73]/15 to-[#0094bf]/15 p-8 ">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-secondaryl font-extrabold text-2xl euclidbold">
                MoM Details
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <Icon
                  icon="carbon:close-filled"
                  className="text-secondaryl hover:text-opacity-95 text-3xl"
                />
              </button>
            </div>

            <form className="mb-3">
              <div className="mb-3">
                <InputBox
                  title={"MoM Topic"}
                  register={register}
                  filedName={"Meeting_Topic"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  type="date"
                  title={"MoM Date "}
                  register={register}
                  filedName={"Meeting_Date"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  title={"MoM Details "}
                  register={register}
                  filedName={"Meeting_Details"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  title={"MoM Outcome "}
                  register={register}
                  filedName={"Meeting_Outcome"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                <InputBox
                  title={"Who Can Show "}
                  register={register}
                  filedName={"Sharing_Status"}
                  must={true}
                  errors={errors}
                  className={"md:text-sm lg:text-base"}
                  cursorNotAllowed={false}
                />
              </div>

              <div className="mb-3">
                {mom && (
                  <>
                    <label
                      className={`block font-normal  text-secondaryl text-[12px] sm:text-[12px] md:text-base `}
                    >
                      Uploaded MoM Document :
                    </label>
                    <div className="pt-3">
                      {modelLoading ? (
                        "Loading..."
                      ) : MoMDocumnet ? (
                        MoMDocumnetType === "pdf" ? (
                          <object
                            data={MoMDocumnet}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                            className="w-full h-[90vh]"
                          >
                            <p>PDF could not be displayed</p>
                          </object>
                        ) : (
                          <img
                            src={MoMDocumnet}
                            alt="MoM Document"
                            className="w-full  h-[50vh] object-fill ring-[0.1px]  ring-infromation"
                          />
                        )
                      ) : (
                        <div className="text-infromation">
                          No Documents Provided
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-wrap gap-5 justify-start ">
                {/* {onDelete && (
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                )} */}
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoMReadModal;
