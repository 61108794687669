import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../constants/API";
import toast, { Toaster } from "react-hot-toast";

const DocumentDownload = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(`${baseUrl}/download-document`, {
          params: { token },
          responseType: "blob",
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "meeting-document.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 403) {
          toast.error("file already downloaded");
        }
        toast.error(error.response.data.message);
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError("Document can only be downloaded once.");
        }
        setLoading(false);
      }
    };

    if (token) {
      fetchDocument();
    } else {
      setError("Invalid or missing download token.");
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <div>...</div>;
  }

  if (error) {
    return (
      <div className="error-message">
        {" "}
        <Toaster />
        {error}
      </div>
    );
  }

  return null;
};

export default DocumentDownload;
