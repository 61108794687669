import React, { useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "../../constants/API";
import { Navigate } from "react-router-dom";

function SavePassword() {
  const cookies = new Cookies();
  const [showForm, setShowForm] = useState(false);
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!cookies.get("token")) {
    return <Navigate to="/"></Navigate>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/savepassword`,
        { password },
        {
          headers: {
            authorization: cookies.get("token"),
            companyType: "contractor",
          },
        }
      );
      setSuccessMessage(response.data.message);
      setPassword("");
      setShowForm(false);
    } catch (error) {
      setErrorMessage("Failed to save password. Please try again later.");
    }
  };

  const handleCancel = () => {
    setPassword("");
    setShowForm(false);
  };

  return (
    <div>
      {showForm ? (
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="password"
            placeholder="Enter new password"
            className={`bg-transparent block h-10 mt-4 min-w-8  cursor-default rounded-sm text-black  ring-1 px-2 ring-gray-400 ps-10 focus:ring-sky-600 focus:outline-none focus:border-rose-600 `}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="submit"
            className="h-11  px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex"
          >
            Save Password
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="h-11  px-6 py-2.5 items-center ring-1  euclidbold ring-cyan-500   text-cyan-500 text-xl  tracking-widest hover:bg-secondaryl  hover:ring-1 hover:ring-cyan-600 hover:text-white transition duration-300 ease-in-out mt-4 md:mt-0 flex"
          >
            Cancel
          </button>
        </form>
      ) : (
        <button onClick={() => setShowForm(true)}>Change Password</button>
      )}
      {successMessage && <div>{successMessage}</div>}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
}

export default SavePassword;
